import React, { FC, useEffect, useRef } from 'react';
import './card.scss';
import CardEditRequest from './CardEditRequest';
import LockIcon from '../../../assets/svg/lock.svg';
import UnlockIcon from '../../../assets/svg/unlock.svg';
import FavoriteIcon from '../../../assets/svg/favorite.svg';
import NotFavoriteIcon from '../../../assets/svg/not-favorite.svg';
import threedots from '../../../assets/svg/three-dots.svg';
import threedotsgreen from '../../../assets/svg/three-dots-green.svg';
import MaleImg from '../../../assets/png/male-img.png';
import BookClicked from './BookClicked';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { fetchAllSectors, fetchCurrentProjects } from '../../../redux/actions/tenderManagementActions';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { updateManualName } from '../../../redux/actions/manualManagementActions';
import { isNormalUser } from '../../../utils/checkAccess';

type props = {
  card: any;
  locked: boolean;
  isArchived: boolean;
};
const Card: FC<props> = ({ card, locked, isArchived }) => {
  // let isArchived = card.status?.id || card?.statusId === 3 ? true : false;
  // console.log(card);
  const [clicked, setClicked] = React.useState(false);
  const [editRequest, setEditRequest] = React.useState(false);
  const [isFavourite, setIsFavourite] = React.useState(false);
  const [svgHover, setSvgHover] = React.useState(false);
  const [nameChange, setNameChange] = React.useState({ show: false, name: '' });
  const { allSectors, currentProjects } = useSelector((state: RootState) => state.tenderManagement);
  const dispatch = useDispatch();
  const toast = useRef<Toast | null>(null);
  const { user } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    dispatch(fetchCurrentProjects(toast, { state: 1, page: 0, size: 0, sort: '' }));
    dispatch(fetchAllSectors(toast));
  }, [dispatch]);
  const splitDate = (backenddate: any) => {
    if (!backenddate) return null;
    let newdate = backenddate.split('T');
    if (newdate.length === 0) return null;
    return newdate[0];
  };
  const favoriteHandler = () => {};
  const changeNamheHandler = () => {
    //it has to be an admin
    !isNormalUser(5, user) && setNameChange({ ...nameChange, show: true });
  };

  const handleClick = () => {
    setClicked(!clicked);
  };
  const maintainers = () => {
    let str = '';
    for (let i = 0; i < card.maintainers.length; i++) {
      str += card.maintainers[i].userName + '\n';
    }
    return str;
  };
  if (editRequest) {
    return (
      <div>
        <CardEditRequest setEditRequest={setEditRequest} />
      </div>
    );
  }
  if (clicked) {
    return (
      <div onClick={handleClick}>
        <BookClicked
          /*setEditRequest={setEditRequest}*/
          changeNamheHandler={changeNamheHandler}
          locked={locked}
          manual={card}
          isArchived={isArchived}
        />
      </div>
    );
  } else {
    return (
      <>
        <div
          className="model-card-container"
          id="manual-card"
          onClick={() => {
            handleClick();
          }}
        >
          <div>
            {locked && <div className="top-dash-green"></div>}
            {!locked && !isArchived && <div className="top-dash-yellow"></div>}
            {isArchived && <div className="top-dash-black"></div>}

            <div className="model-card-title">
              <div className="Card">{card.title}</div>
              {/* <div
                onClick={() => {
                  //setClicked(false);
                  handleClick();
                }}
                onMouseEnter={() => setSvgHover(true)}
                onMouseLeave={() => setSvgHover(false)}
                className="threedots"
              >
                <div>
                  <img src={svgHover ? threedotsgreen : threedots} width={'23px'} alt="threeDotsIcon" />
                </div>
              </div> */}

              <div className={` ${locked ? 'title-icon' : 'title-icon-unlocked'}`}>
                {card.status?.id !== 3 &&
                  (locked ? (
                    <img className="lock-icon" src={LockIcon} alt="LockIcon" />
                  ) : (
                    <img className="unlock-icon" src={UnlockIcon} alt="UnlockIcon" />
                  ))}
              </div>
            </div>
            <div onClick={handleClick}>
              <div className="sector-title">
                قطاع{' '}
                {
                  allSectors.find((sector) => {
                    return sector.sectorCode === card.sectorCode;
                  })?.sectorName
                }
              </div>
              <div className="classifcation-container">
                <div className="classifaction-icon"></div>
                <div className="classifaction-title">
                  {
                    currentProjects.find((project) => {
                      return project.projectId === card.projectId;
                    })?.prtsLookupMainCodeByStageCode?.prtsLookupType?.lookupTypeName
                  }{' '}
                  {
                    currentProjects.find((project) => {
                      return project.projectId === card.projectId;
                    })?.prtsLookupMainCodeByStageCode?.lookupMainName
                  }
                </div>
              </div>
              <div className="classifcation-container">
                <div className="classifaction-icon"></div>
                <div className="classifaction-title">
                  {
                    currentProjects.find((project) => {
                      return project.projectId === card.projectId;
                    })?.prtsLookupMainCodeByTypeCode?.prtsLookupType?.lookupTypeName
                  }{' '}
                  {
                    currentProjects.find((project) => {
                      return project.projectId === card.projectId;
                    })?.prtsLookupMainCodeByTypeCode?.lookupMainName
                  }
                </div>
              </div>
            </div>
            <div className="card-table">
              <div className="table-header">اخر تعديل</div>
              <div className="table-header">الحاله</div>
            </div>
            <div className="table-content">
              <div className="card-date">
                {' '}
                {splitDate(card?.lastModifyDate) || splitDate(card?.createDate) || '4-4-2022'}
              </div>
              <div
                className={`${
                  isArchived === true ? 'card-status-black' : locked ? 'card-status-green' : 'card-status-yellow'
                }`}
              >
                {isArchived === true ? 'مؤرشف' : locked ? 'معتمد' : 'تحت التعديل '}
              </div>
            </div>
          </div>
          <div>
            <div className="model-card-user-by-title">بواسطه</div>
            <div className="model-card-user-by">
              <div className="user">
                <img className="male-img" src={MaleImg} alt="MaleImg" />
                <InputText
                  type="text"
                  placeholder="اسماء طاقم العمل"
                  tooltip={maintainers()}
                  className="maintainers-tooltip"
                  tooltipOptions={{ position: 'bottom' }}
                />
              </div>
              <div
                className="card-fav"
                onClick={() => {
                  setIsFavourite(!isFavourite);
                  favoriteHandler();
                }}
              >
                {isFavourite ? (
                  <img className="favorite-icon" src={FavoriteIcon} alt="FavoriteIcon" />
                ) : (
                  <img className="not-favorite-icon" src={NotFavoriteIcon} alt="NotFavoriteIcon" />
                )}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          className="primereact-dialouge-model model-info-dialog"
          visible={nameChange.show}
          onHide={() => setNameChange({ ...nameChange, show: false })}
          draggable={false}
          showHeader={true}
          dismissableMask={true}
          header="تغير اسم الكراسه "
        >
          <div className="create-new-model">
            <div className="modelname">
              <div style={{ margin: '10px' }}>
                اسم الكراسة الجديد<span>*</span>
              </div>

              <input
                value={nameChange.name}
                onChange={(e) => setNameChange({ ...nameChange, name: e.target.value })}
                className="model-name-input"
                placeholder="مثال :: كراسه المشاريع الانشائيه"
              />
            </div>
            <br></br>

            <button
              disabled={nameChange.name === '' ? true : false}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(updateManualName({ ...card, title: nameChange.name }));
                setNameChange({ name: '', show: false });
              }}
              className="contine-model-btn"
            >
              حفظ اسم الكراسة
            </button>
          </div>
        </Dialog>
      </>
    );
  }
};

export default Card;
