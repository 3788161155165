import React from 'react';
import noFoundImg from '../../../assets/png/no-found.png';

import './NoProjects.scss';

const NoProjects: React.FC<{ btnHandler: () => void }> = ({ btnHandler }) => {
  return (
    <div className="no-projects">
      <div className="no-found-img">
        <img src={noFoundImg} alt="no-found-img" />
      </div>
      <div className="msg1">لا توجد نتائج لاي مشاريع</div>
      <div className="msg2">حاول تعديل البحث للعثور على ما تبحث عنه</div>
      <button onClick={btnHandler} className="projects-no-found">
        تخصيص
      </button>
    </div>
  );
};

export default NoProjects;
