import { Toast } from 'primereact/toast';
import api from '../../api';
import { Project, ProjectType } from '../../models/add-project.model';
import { Initiative } from '../../models/sector.model';
import { showToast } from '../../utils/showToast';
import {
  ADD_INITIATIVE,
  ADD_PROJECT,
  ALL_INITIATIVES,
  ALL_SECTORS,
  ARCHIVED_PROJECTS,
  CURRENT_PROJECTS,
  DELETE_INITIATIVE,
  DELETE_PROJECT,
  EDIT_INITIATIVE,
  EDIT_PROJECT,
  GET_ROLES,
  GET_SIDE_LEVEL_CODES,
  GET_USERS,
  MAIN_LOOKUPS,
  SEARCH_PROJECT,
  SET_LOADER,
  ANALYIZE_DATA,
  DOWNLOAD_TENDER_MANUAL,
  DOWNLOAD_TENDER_MANUAL_FOR_MODEL,
  DOWNLOAD_TENDER_MANUAL_FOR_BOOK,
} from './types';

export const downloadTenderFile =
  (tenderId: string, title: string, toast: React.MutableRefObject<Toast | null>, history?: any) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      await api.get(`/tender/wordFile/${tenderId}`, { params: { pdfFileName: title } });

      const response = await api.get(`/tender/view`, { params: { fileName: title + '.pdf' } });
      console.log(response, '35');

      dispatch({ type: DOWNLOAD_TENDER_MANUAL, payload: { file: response } });
    } catch (error) {
      showToast(toast, error);
      console.log(error);
    }
  };

export const downloadTenderFileWord =
  (tenderId: string, title: string, toast: React.MutableRefObject<Toast | null>, history?: any) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      console.log('word');

      await api.get(`/tender/pdfFile/${tenderId}`, { params: { wordFileName: title } });
      const response = await api.get(`/tender/download`, { params: { fileName: `${title}.docx` } });
      console.log(response, 'response');
      const linkSource = `data:application/msword;base64,${response}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `${title}.docx`;
      downloadLink.click();
      //  dispatch({ type: DOWNLOAD_TENDER_MANUAL_FOR_BOOK, payload: { file: response } });
    } catch (error) {
      showToast(toast, error);
      console.log(error);
    }
  };
///viewModel
export const viewModelHandler =
  (modelId: string, title: string, toast: React.MutableRefObject<Toast | null>, history?: any) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    console.log(title, '71');

    try {
      await api.get(`/tender/viewModelFile/${modelId}`, { params: { pdfFileName: title } });
      const response = await api.get(`/tender/view`, { params: { fileName: title + '.pdf' } });
      console.log(response, '71');
      dispatch({ type: DOWNLOAD_TENDER_MANUAL_FOR_MODEL, payload: { file: response } });
    } catch (error) {
      showToast(toast, error);
    }
  };

export const searchProjects =
  (data: any, { page, size, sort, state }: { state: number; page: number; size: number; sort: string }) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      const params: Record<string, number | string> = {};
      if (page >= 0) params.page = page;
      if (size) params.size = size;
      if (sort) params.sort = sort;
      dispatch({ type: SET_LOADER, payload: true });
      const response: any = await api.post(`/prts/projects/search/${state}`, data, { params });
      dispatch({
        type: SEARCH_PROJECT,
        payload: { projects: response.data.content || [], total: response.data.totalElements, state: 1 },
      });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
    }
  };

export const addProject =
  (project: Project, history: any, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.post(`/prts/projects`, project);
      dispatch({ type: ADD_PROJECT, payload: response.data });
      history.push('/tender/current-projects');
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const editProject =
  (
    project: Project,
    state: ProjectType,
    updateType: 'retreive' | 'archived' | 'edit',
    history: any,
    toast: React.MutableRefObject<Toast | null>,
  ) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.put(`/prts/projects/${project.projectId}`, {
        ...project,
        state: state === 'current' ? 1 : 2,
      });
      dispatch({ type: EDIT_PROJECT, payload: { project: response.data, updateType } });
      history.push(updateType === 'retreive' ? '/tender/archived-projects' : '/tender/current-projects');
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const deleteProject =
  (projectId: number, history: any, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      await api.delete(`/prts/projects/${projectId}`);
      dispatch({ type: DELETE_PROJECT, payload: { projectId } });
      history.push('/tender/archived-projects');
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const fetchCurrentProjects =
  (
    toast: React.MutableRefObject<Toast | null>,
    { state, page, size, sort }: { state: number; page: number; size: number; sort: string },
  ) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      const params: Record<string, number | string> = { state };
      if (page >= 0) params.page = page;
      if (size) params.size = size;
      if (sort) params.sort = sort;
      dispatch({ type: SET_LOADER, payload: true });
      const { data }: any = await api.get('/prts/projects', {
        params,
      });
      dispatch({
        type: params.state === 1 ? CURRENT_PROJECTS : ARCHIVED_PROJECTS,
        payload: { projects: data.content || [], total: data.totalElements },
      });
    } catch (error) {
      dispatch({
        type: state === 1 ? CURRENT_PROJECTS : ARCHIVED_PROJECTS,
        payload: { projects: [], total: 0 },
      });
      showToast(toast, error);
    }
  };

export const fetchAllSideLevelCodes =
  (toast: React.MutableRefObject<Toast | null>) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.get('/adm/side-level-codes');
      dispatch({ type: GET_SIDE_LEVEL_CODES, payload: response.data });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const fetchAllUsers =
  (moduleId: number, roleId: number, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const { data }: any = await api.get(`/adm/users/modules/${moduleId}/roles/${roleId}`, { params: { state: '1' } });
      dispatch({ type: GET_USERS, payload: data.content });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const fetchAllRoles =
  (toast: React.MutableRefObject<Toast | null>) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.get('/prts/projects/users-roles');
      dispatch({ type: GET_ROLES, payload: response.data });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const fetchMainLookups =
  (toast: React.MutableRefObject<Toast | null>) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const { data } = await api.get('/prts/main-lookups');
      dispatch({ type: MAIN_LOOKUPS, payload: data || [] });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const fetchAllSectors =
  (toast: React.MutableRefObject<Toast | null>) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.get('/prts/sectors');
      dispatch({ type: ALL_SECTORS, payload: response.data });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const getAllInitiatives =
  (toast: React.MutableRefObject<Toast | null>) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.get('/prts/initiatives');

      dispatch({ type: ALL_INITIATIVES, payload: response.data });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const addInitiative =
  ({ sectorCode, ...data }: Initiative, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.post(`/prts/sectors/${sectorCode}/initiatives`, data);
      dispatch({ type: ADD_INITIATIVE, payload: response.data });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const editInitiative =
  (oldSectoreCode: number, data: Initiative, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.put(`/prts/sectors/${oldSectoreCode}/initiatives/${data.initiativeCode}`, data);
      dispatch({ type: EDIT_INITIATIVE, payload: response.data });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const deleteInitiative =
  (sectorCode: number, initiativeCode: number, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      await api.delete(`/prts/sectors/${sectorCode}/initiatives/${initiativeCode}`);
      dispatch({ type: DELETE_INITIATIVE, payload: { initiativeCode } });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const analyisData =
  (data: any, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const response = await api.post('/prts/dashboard/v2', data);
      dispatch({
        type: ANALYIZE_DATA,
        payload: {
          analysisDate: response.data?.analysisDate,

          // sectorIdAnaylsis: response.data?.sectorsIdsAnalysis || [],
          // initiativesCodeAnalysis: response.data?.initiativesCodesAnalysis || [],
          // statusAnalysis: response.data?.statusAnalysis || [],
          // geoIdAnaylsis: response.data?.geographicalAnalysis || [],

          dataAnalysis: response.data?.dataAnalysis || [],

          totalFinanceValueOfProjects: response.data?.totalFinanceValueOfProjects || 0,
          totalNumberOfProjects: response.data?.totalNumberOfProjects || 0,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: SET_LOADER, payload: false });
      showToast(toast, error);
    }
  };
