import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import profileImg from '../../assets/png/avatar.png';
import logout from '../../assets/png/logout.png';
import bottomArrow from '../../assets/svg/bottom-arrow.svg';
import { logoutUser } from '../../redux/actions/user';
import { RootState } from '../../redux/reducers';
import './Profile.scss';

const Profile = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const handleLogout = () => {
    toggleMenu();
    dispatch(logoutUser(user?.id || 0, null, router));
  };
  return (
    <div className="profile">
      <div className="profile-data" onClick={toggleMenu}>
        <img src={profileImg} alt="profile-img" />
        <span>{user?.username}</span>
        <img src={bottomArrow} alt="bottom-arrow" />
      </div>
      {openMenu && (
        <div className="menu">
          <ul>
            <li onClick={handleLogout}>
              <img src={logout} alt="logout" />
              تسجيل الخروج
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Profile;
