import React from 'react';
import { Button } from 'primereact/button';
import './Segment.scss';

type Props = {
  btnHandler: (idx: number) => void;
  buttons: string[];
  activeIndex: number[];
};

const Segment: React.FC<Props> = ({ btnHandler, activeIndex, buttons }) => {
  return (
    <div className="segment">
      {buttons.map((btn, idx) => (
        <Button
          key={idx}
          label={btn}
          onClick={() => btnHandler(idx)}
          className={`p-button ${
            activeIndex && activeIndex.some((index: number) => index === idx) ? 'p-button-active' : ''
          }`}
        />
      ))}
    </div>
  );
};

export default Segment;
