import React, { FC } from 'react';
import './CreateConditionsManual.scss';
import chevronLeft from '../../../../../assets/svg/chevron-left.svg';

type Props = {
  activePage: any;
  setActivePage: any;
  submitModalHandler: () => void;
};

const ModalFooter: FC<Props> = ({ activePage, setActivePage, submitModalHandler }) => {
  return (
    <div className="modal-footer">
      {activePage.id !== 1 && (
        <button
          id="prevPageBtn"
          onClick={() => {
            setActivePage({ id: activePage.id - 1, isContentFilled: activePage.isContentFilled });
          }}
        >
          العودة
        </button>
      )}

      <button
        id="continueBtn"
        disabled={
          (activePage.id === 1 && activePage.isContentFilled.page1) ||
          (activePage.id === 2 && activePage.isContentFilled.page2) ||
          (activePage.id === 3 && activePage.isContentFilled.page3)
            ? false
            : true
        }
        style={{
          opacity:
            (activePage.id === 1 && activePage.isContentFilled.page1) ||
            (activePage.id === 2 && activePage.isContentFilled.page2) ||
            (activePage.id === 3 && activePage.isContentFilled.page3)
              ? '1'
              : '0.5',
        }}
        onClick={() => {
          activePage.id < 3
            ? setActivePage({ id: activePage.id + 1, isContentFilled: activePage.isContentFilled })
            : submitModalHandler();
        }}
      >
        {activePage.id === 3 ? 'اتمام الإنشاء' : 'متابعة الإنشاء'}
        <img src={chevronLeft} alt="chevron-left" />
      </button>
    </div>
  );
};

export default ModalFooter;
