import React from 'react';
// import { Accordion, AccordionTab } from 'primereact/accordion';
// import PersonalInfo from './PersonalInfo/PersonalInfo';
// import PersonalReports from './PersonalReports/PersonalReports';
// import ProjectsGroups from './ProjectsGroups/ProjectsGroups';
// import Permissions from './Permissions/Permissions';
// import Segment from '../../Segment/Segment';

const PersonalSettings: React.FC = () => {
  // const [activeIndex, setActiveIndex] = useState<any>(null);
  // const [segmentButtons] = useState(['البيانات الشخصية', 'الصلاحيات', 'مجموعات المشاريع', 'التقارير الشخصية']);

  // const segmentHandler = (itemIndex: number) => {
  //   let _activeIndex = activeIndex ? [...activeIndex] : [];

  //   if (_activeIndex.length === 0) {
  //     _activeIndex.push(itemIndex);
  //   } else {
  //     const index = _activeIndex.indexOf(itemIndex);
  //     if (index === -1) {
  //       _activeIndex.push(itemIndex);
  //     } else {
  //       _activeIndex.splice(index, 1);
  //     }
  //   }

  //   setActiveIndex(_activeIndex);
  // };

  // return (
  //   <div className="plans">
  //     <div className="plans-data">
  //       <Segment buttons={segmentButtons} activeIndex={activeIndex} btnHandler={segmentHandler} />
  //     </div>
  //     <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
  //       <AccordionTab header="البيانات الشخصية">
  //         <PersonalInfo />
  //       </AccordionTab>
  //       <AccordionTab header="الصلاحيات">
  //         <Permissions />
  //       </AccordionTab>
  //       <AccordionTab header="مجموعات المشاريع">
  //         <ProjectsGroups />
  //       </AccordionTab>
  //       <AccordionTab header="التقارير الشخصية">
  //         <PersonalReports />
  //       </AccordionTab>
  //     </Accordion>
  //   </div>

  // );
  return <div></div>;
};

export default PersonalSettings;
