import Home from '../../pages/Home/Home';
import Login from '../../pages/Auth/Login/Login';
import tenderRoutes from './tender';
import userRoutes from './user';
import modelroutes from './model';

import Panel from '../../components/Tender/Panel/showCharts';
import libraryRoutes from './library';

import { IRoutes } from '../../models/routes.model';

const routes: IRoutes = {
  LOGIN: {
    path: '/login',
    name: 'login',
    component: Login,
    exact: true,
  },
  HOME: {
    path: '/home',
    name: 'home',
    component: Home,
    exact: true,
  },
  showCharts: {
    path: '/tender/panel/charts/:viewType',
    name: 'panel',
    component: Panel,
    exact: true,
  },
  ...tenderRoutes,
  ...userRoutes,
  ...modelroutes,
  ...libraryRoutes,
};
export default routes;
