import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initiativeRequiredData } from '../../../constants/initiativeRequiredData';
import { Initiative, initiativeDefaultValue } from '../../../models/sector.model';
import {
  addInitiative,
  deleteInitiative,
  editInitiative,
  fetchAllSectors,
  getAllInitiatives,
} from '../../../redux/actions/tenderManagementActions';
import { RootState } from '../../../redux/reducers';
import CustomFilterDropDown from '../../CustomFilterDropDown/CustomFilterDropDown';
import Loader from '../../Loader/Loader';
import InputErrorMsg from '../AddProject/InputErrorMsg';
import './Initiatives.scss';
import InputNumberComp from '../../Form/InputNumber';
import { isNormalUser } from '../../../utils/checkAccess';

const Initiatives: React.FC = () => {
  const dispatch = useDispatch();
  const alInitiatives = useSelector((state: RootState) => state.tenderManagement.initiatives);
  const allSectors = useSelector((state: RootState) => state.tenderManagement.allSectors);
  const loading = useSelector((state: RootState) => state.tenderManagement.loading);
  const { user } = useSelector((state: RootState) => state.user);

  const [initiativesData, setInitiativesData] = useState<Initiative[]>([]);
  const [selectedInitiative, setSelectedInitiative] = useState<Initiative | null>(null);
  const [initiativeData1, setinitiativeData1] = useState('');
  const [initiativeData2, setinitiativeData2] = useState('');
  const [initiativeData3, setinitiativeData3] = useState('');
  const [initiativeData4, setinitiativeData4] = useState('');
  const [initiativeData5, setinitiativeData5] = useState('');
  const [initiativeName, setinitiativeName] = useState('');
  const [initiativeShortName, setinitiativeShortName] = useState('');
  const [initiativeOperationalExpense, setinitiativeOperationalExpense] = useState<number | undefined>(undefined);
  const [initiativeCapitalExpenses, setinitiativeCapitalExpenses] = useState(0);
  const [sector, setSector] = useState<number>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const [visible, setVisible] = useState<boolean>(false);
  const [saveDialogVisibleVisible, setSaveDialogVisible] = useState<boolean>(false);

  const [oldSectorCode, setOldSectorCode] = useState<number>(0);

  // check if input is touched
  const [touchedInputs, setTouchedInputs] = useState({ ...initiativeRequiredData });

  const toast = useRef<Toast | null>(null);

  useEffect(() => {
    dispatch(fetchAllSectors(toast));
    dispatch(getAllInitiatives(toast));
  }, [dispatch]);

  useEffect(() => {
    setInitiativesData(alInitiatives);
  }, [alInitiatives]);

  const filterInitiatives = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value;
    setSearchInputValue(inputVal);
    if (!inputVal) {
      setInitiativesData(alInitiatives);
      return;
    }
    const data = alInitiatives.filter((ele) => ele.initiativeShortName.indexOf(inputVal) >= 0);
    setInitiativesData(data);
  };

  const setInitiativeHandler = (item: Initiative, selected = false) => {
    setinitiativeData1(item.initiativeData1);
    setinitiativeData2(item.initiativeData2);
    setinitiativeData3(item.initiativeData3);
    setinitiativeData4(item.initiativeData4);
    setinitiativeData5(item.initiativeData5);
    setinitiativeShortName(item.initiativeShortName);
    setinitiativeName(item.initiativeName);
    setinitiativeOperationalExpense(item.initiativeOperationalExpense);
    setinitiativeCapitalExpenses(item.initiativeCapitalExpenses);
    setSector(item.sectorCode);
    setIsEdit(true);
    setSelectedInitiative(selected ? item : null);
    setOldSectorCode(selected ? item.sectorCode : 0);
    setTouchedInputs({ ...initiativeRequiredData });
  };

  const prepareInitiativeData: any = () => {
    return {
      ...selectedInitiative,
      sectorCode: sector,
      initiativeData1,
      initiativeData2,
      initiativeData3,
      initiativeData4,
      initiativeData5,
      initiativeName,
      initiativeShortName,
      initiativeOperationalExpense,
      initiativeCapitalExpenses,
    };
  };

  const resetInitiativeHandler = () => {
    setInitiativeHandler(initiativeDefaultValue);
    setIsEdit(false);
  };

  const deleteInitiativeHandler = () => {
    const { sectorCode, initiativeCode } = prepareInitiativeData();
    if (!sectorCode || !initiativeCode) return;
    dispatch(deleteInitiative(sectorCode, initiativeCode, toast));
    resetInitiativeHandler();
  };

  const saveInitiativeHandler = () => {
    const data = prepareInitiativeData();
    if (!data || !data.sectorCode) return;
    if (isEdit) {
      if (!oldSectorCode || !data.initiativeCode) return;
      dispatch(editInitiative(oldSectorCode, data, toast));
    } else {
      dispatch(addInitiative(data, toast));
    }
    resetInitiativeHandler();
  };

  const saveBtnHandler = () => {
    const data: Initiative = prepareInitiativeData();
    if (!data || !data.sectorCode || !data.initiativeName || !data.initiativeShortName) {
      showToast();
      return;
    }
    setSaveDialogVisible(true);
  };

  const showToast = () => {
    toast.current?.show({ severity: 'error', detail: 'خطا', summary: 'من فضلك ادخل البيانات المطلوبة', sticky: true });
  };

  // blur handler
  const handleBlur = (name: keyof typeof touchedInputs) => {
    //
    if (!name || touchedInputs[name]) return;
    setTouchedInputs({
      ...touchedInputs,
      [name]: true,
    });
  };

  return (
    <div className="initiatives">
      {loading && <Loader />}
      {/* delete dialog */}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="هل تريد حذف هذة المبادرة؟"
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        icon="pi pi-exclamation-triangle"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={deleteInitiativeHandler}
      />
      {/* update dialog */}
      <ConfirmDialog
        visible={saveDialogVisibleVisible}
        onHide={() => setSaveDialogVisible(false)}
        message="هل تريد حفظ هذة المبادرة؟"
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        icon="pi pi-exclamation-triangle"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={saveInitiativeHandler}
      />
      <Toast ref={toast} position="top-left" />
      <div className="initatives-card">
        <div className="initiatives-type">
          <CustomFilterDropDown
            inputValue={searchInputValue}
            inputValueHandler={filterInitiatives}
            title="المبادرات المختصرة"
            items={initiativesData}
            handler={setInitiativeHandler}
          />
        </div>
        <div className="initiative-form">
          <div className="intatives-inputs">
            {/*  <div className="input">
              <label>بيان 1</label>
              <InputText
                className="input-prime"
                placeholder=""
                onChange={(e) => setinitiativeData1(e.target.value)}
                value={initiativeData1 || ''}
              />
            </div>
            <div className="input">
              <label>بيان 2</label>
              <InputText
                className="input-prime"
                placeholder=""
                onChange={(e) => setinitiativeData2(e.target.value)}
                value={initiativeData2 || ''}
              />
            </div>
            <div className="input">
              <label>بيان 3</label>
              <InputText
                className="input-prime"
                placeholder=""
                onChange={(e) => setinitiativeData3(e.target.value)}
                value={initiativeData3 || ''}
              />
            </div>
            <div className="input">
              <label>بيان 4</label>
              <InputText
                className="input-prime"
                placeholder=""
                onChange={(e) => setinitiativeData4(e.target.value)}
                value={initiativeData4 || ''}
              />
            </div>
            <div className="input">
              <label>بيان 5</label>
              <InputText
                className="input-prime"
                placeholder=""
                onChange={(e) => setinitiativeData5(e.target.value)}
                value={initiativeData5 || ''}
              />
            </div> */}
            <div className="input">
              <label className="label-required">
                اسم المبادرة المختصر <span>*</span>
              </label>
              <InputText
                className="input-prime"
                placeholder=""
                onChange={(e) => setinitiativeShortName(e.target.value.trim())}
                value={initiativeShortName || ''}
                onBlur={(e) => handleBlur('initiativeShortName')}
              />
              <InputErrorMsg showInputError={!initiativeShortName && touchedInputs.initiativeShortName} />
            </div>
            <div className="input">
              <label className="label-required">
                اسم المبادرة الكامل <span>*</span>
              </label>
              <InputText
                className="input-prime"
                placeholder=""
                onChange={(e) => setinitiativeName(e.target.value.trim())}
                value={initiativeName || ''}
                onBlur={(e) => handleBlur('initiativeName')}
              />
              <InputErrorMsg showInputError={!initiativeName && touchedInputs.initiativeName} />
            </div>
            <div className="input">
              <label className="label-required">
                القطاع <span>*</span>
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="sectorName"
                value={sector}
                optionValue={'sectorCode'}
                options={allSectors}
                onChange={(e) => setSector(e.target.value)}
                onBlur={(e) => handleBlur('sectorCode')}
                name="sectorCode"
                placeholder="القطاع"
              />
              <InputErrorMsg showInputError={!sector && touchedInputs.sectorCode} />
            </div>
            <div className="input">
              <label>المصاريف التشغيلية</label>
              <InputNumberComp
                changeHandler={setinitiativeOperationalExpense}
                val={initiativeOperationalExpense || undefined}
              />
            </div>
            <div className="input">
              <label>المصاريف الرأسمالية</label>
              <InputNumberComp
                changeHandler={setinitiativeCapitalExpenses}
                val={initiativeCapitalExpenses || undefined}
              />
            </div>
          </div>
          <div className="btns">
            <Button
              label="جديد"
              disabled={isNormalUser(4, user) ? true : false}
              onClick={resetInitiativeHandler}
              className="new-btn"
              icon="addIcon"
            />
            <Button
              disabled={isNormalUser(4, user) ? true : false}
              label="حذف"
              onClick={() => {
                if (!selectedInitiative) return;
                setVisible(true);
              }}
              className="delete-btn"
              icon="deleteIcon"
            />
            <Button
              label="حفظ"
              disabled={isNormalUser(4, user) ? true : false}
              onClick={saveBtnHandler}
              className="save-btn"
              icon="saveIcon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Initiatives;
