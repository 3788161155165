import BookPrepartion from '../components/BookPrepration/BookPrepration/BookPrepartion';
import ModelPrepration from '../components/BookPrepration/ModelPrepartion/ModelPrepration';
import ModelSettiengs from '../components/BookPrepration/ModelSettiengs/ModelSettiens';
import MyBussMang from '../components/BookPrepration/MyBussniesMang/MyBussMang';

import SystemTenders from '../components/BookPrepration/SystemTenders/SystemTenders';

import SystemTendersIcon from '../assets/svg/system-tenders.svg';
import SystemTendersIconFilled from '../assets/svg/system-tenders-filled.svg';

import ModelManagementIcon from '../assets/svg/model-management.svg';
import ModelManagementIconFilled from '../assets/svg/model-management-filled.svg';

import ConditionsManualManagementIcon from '../assets/svg/conditions-manual-management.svg';
import ConditionsManualManagementIconFilled from '../assets/svg/conditions-manual-management-filled.svg';

import BussinessManagementIcon from '../assets/svg/bussiness-management.svg';
import BussinessManagementIconFilled from '../assets/svg/bussiness-management-filled.svg';

import SettingsIcon from '../assets/svg/manuals-management-settings.svg';
import SettingsIconFilled from '../assets/svg/manuals-management-settings-filled.svg';
import DocumetsHome from '../components/Documents/Home/DocumetsHome';
// import DocumentsProjects from '../components/Documents/Projects/DocumentsProjects';
import DocumentsUsers from '../components/Documents/Users/DocumentsUsers';
import DocumentsSettings from '../components/Documents/Settings/DocumentsSettings';

export const sidemenuItemsDocuments = [
  // {
  //   id: 0,
  //   icon: `${SystemTendersIcon}`,
  //   iconFilled: `${SystemTendersIconFilled}`,
  //   title: ' الرئيسية',
  //   menuLink: 'home',
  //   component: DocumetsHome,
  // },
  // {
  //   id: 1,
  //   icon: `${ModelManagementIcon}`,
  //   iconFilled: `${ModelManagementIconFilled}`,
  //   title: 'الرئيسية',
  //   menuLink: 'projects',
  //   component: DocumentsProjects,
  // },
  {
    id: 2,
    icon: `${ConditionsManualManagementIcon}`,
    iconFilled: `${ConditionsManualManagementIconFilled}`,
    title: '  إدارة المستخدمين ',
    menuLink: 'users',
    component: DocumentsUsers,
  },
  {
    id: 4,
    icon: `${SettingsIcon}`,
    iconFilled: `${SettingsIconFilled}`,
    title: 'اعدادات',
    menuLink: 'settings',
    component: DocumentsSettings,
  },
];

const newsidemenuitems = [
  {
    id: 0,
    icon: `${SystemTendersIcon}`,
    iconFilled: `${SystemTendersIconFilled}`,
    title: 'مناقصات النظام',
    menuLink: 'tenders',
    component: SystemTenders,
  },
  {
    id: 1,
    icon: `${ModelManagementIcon}`,
    iconFilled: `${ModelManagementIconFilled}`,
    title: 'اداره النماذج',
    menuLink: 'mangment',
    component: ModelPrepration,
  },
  {
    id: 2,
    icon: `${ConditionsManualManagementIcon}`,
    iconFilled: `${ConditionsManualManagementIconFilled}`,
    title: 'اداره كراسات الشروط ',
    menuLink: 'book',
    component: BookPrepartion,
  },
  {
    id: 3,
    icon: `${BussinessManagementIcon}`,
    iconFilled: `${BussinessManagementIconFilled}`,
    title: 'اداره اعمالي',
    menuLink: '',
    component: MyBussMang,
  },
  {
    id: 4,
    icon: `${SettingsIcon}`,
    iconFilled: `${SettingsIconFilled}`,
    title: 'اعدادات',
    menuLink: '',
    component: ModelSettiengs,
  },
];

export default newsidemenuitems;
