import axios from 'axios';
import {
  MANUAL_CREATED,
  MANUAL_CREATION_FAILED,
  GET_MANUAL,
  EDIT_MANUAL_BASIC_INFO,
  GET_ALL_MANUALS_START,
  GET_ALL_MANUALS_SUCESS,
  GET_ALL_MANUALS_FAIL,
  EDIT_MANUAL_STATUS,
  EDIT_MANUAL_CONTENT,
  GET_ALL_USERS_WITH_MODULE_ID,
  GET_ALL_USER_RECENT_DOCUMENTS,
  EDIT_MANUAL_NAME,
} from './types';
import { Toast } from 'primereact/toast';
import { errorMessages } from '../../constants/error-messages';
import { getStorageData } from '../../utils/storage';
import api from '../../api';

const baseURL = 'http://3.130.224.154:8080/api/tds/tenders';

export const manualServer = axios.create({ baseURL });
manualServer.interceptors.request.use((config) => {
  const token = getStorageData('token');
  const headers = config.headers || {};
  if (token && config.url !== '/users/login') {
    headers.Authorization = `JWT ${token}`;
  }
  config.headers = headers;
  return config;
});

const showToast = (toast: React.MutableRefObject<Toast | null>, error: any) => {
  const toastData = toast.current;
  const msg =
    error.response && error.response.data && error.response.data.message ? error.response.data.message : 'حدث خطا';
  if (toastData) {
    const arMsg = errorMessages.find((ele) => ele.en === msg);
    toastData.show({ severity: 'error', detail: 'خطا', summary: arMsg ? arMsg.ar : msg, life: 3000 });
  }
};

export const createNewManual =
  (manual: any, toast: React.MutableRefObject<Toast | null>, history: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    console.log(manual, 'manual before sending to the db <<<<<<<');
    try {
      const res = await manualServer.post('', manual);
      console.log(res);
      dispatch({ type: MANUAL_CREATED, payload: res.data });
      history.push(`/edit-conditions-manual/${res.data.tenderId}`);
    } catch (error) {
      dispatch({ type: MANUAL_CREATION_FAILED });
      showToast(toast, error);
      console.log(error);
    }
  };

export const getAllManualsByStatus =
  (statusId: Number, userId: Number, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      const res = await manualServer.get(`?statusId=${statusId}&userId=${userId}`);
      dispatch({ type: GET_ALL_MANUALS_SUCESS, payload: { manuals: res.data, statusId } });
    } catch (error) {
      dispatch({ type: GET_ALL_MANUALS_FAIL });
      showToast(toast, error);
      console.log(error);
    }
  };

export const getManual =
  (manualID: number, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      const res = await manualServer.get(`/${manualID}`);

      dispatch({ type: GET_MANUAL, payload: res.data });
    } catch (error) {
      showToast(toast, error);
      console.log(error);
    }
  };

export const updateManualStatus =
  (statusId: Number, manual: any, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    console.log(manual);
    try {
      await manualServer.patch(`/${manual.tenderId}/status/${statusId}`);

      dispatch({ type: EDIT_MANUAL_STATUS, payload: { ...manual, statusId: statusId } });
    } catch (error) {
      showToast(toast, error);
      console.log(error);
    }
  };

export const updateManualContent =
  (sectionId: number, content: string) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      const response = await manualServer.patch(`/sections/${sectionId}`, { content: content });

      dispatch({ type: EDIT_MANUAL_CONTENT, payload: response.data });
    } catch (err) {
      console.log(err);
    }
  };

export const updateManualBasicInfo =
  (manual: any) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    try {
      const response = await manualServer.put('', manual);

      dispatch({ type: EDIT_MANUAL_BASIC_INFO, payload: { project: response.data } });
    } catch (err) {
      console.log(err);
    }
  };
export const updateManualName = (manual: any) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
  try {
    console.log(manual);
    const response = await manualServer.put('', manual);
    console.log(response);
    dispatch({ type: EDIT_MANUAL_NAME, payload: { project: response.data } });
  } catch (err) {
    console.log(err);
  }
};
export const getRentlyOpenTenders =
  (history: any) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    dispatch({ type: GET_ALL_MANUALS_START, payload: true });
    try {
      const { data } = await manualServer.get('/allUserTenders');
      let arr: Array<any> = data || [];
      arr.length === 0 && history.push('/model/book');
      dispatch({ type: GET_ALL_USER_RECENT_DOCUMENTS, payload: data });
    } catch (err) {
      console.log(err);
      dispatch({ type: GET_ALL_MANUALS_FAIL, payload: false });
    }
  };

export const getAllUsersWithModuleId =
  (toast: any) => async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: GET_ALL_MANUALS_START, payload: true });
      const { data }: any = await api.get(`/adm/users/tenders`);
      dispatch({ type: GET_ALL_USERS_WITH_MODULE_ID, payload: { users: data } });
    } catch (err) {
      // dispatch({ type: SET_USER_LOADER, payload: false });
      showToast(toast, err);
      console.log(err);
    }
  };
