import React, { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
//import { CascadeSelect } from 'primereact/cascadeselect';
import BarChartIcon from '../../../assets/svg/bar-chart.svg';
import Print from '../../../assets/svg/print.svg';
import PanelInsights from '../../../assets/svg/panel-insights.svg';
import TagLightGreen from '../../../assets/svg/tag-light-green.svg';
import Tag from '../../../assets/svg/tag.svg';
import Wallet from '../../../assets/svg/wallet.svg';
import Calendar from '../../../assets/svg/calendar.svg';
import './Panel.scss';
import { Dropdown } from 'primereact/dropdown';
import BarChart from './BarChart';
import SwitchCharts from './SwitchCharts';
import DoughnutChart from './DoughnutChart';
import LineChart from './LineChart';
import DataTablePanel from './DataTablePanel';
import {
  analyisData,
  fetchAllSectors,
  fetchMainLookups,
  getAllInitiatives,
} from '../../../redux/actions/tenderManagementActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { Toast } from 'primereact/toast';
import CascadeMultiSelector from './CascadeMultiSelector';
import { Button } from 'primereact/button';
import { analysisData, handleAnalysisResp, getDoughnutData, getSpecificDoughnutData } from './getDimensionChoices';
import { AnalysisObject, displayData } from '../../../models/Analysis';
import { isNormalUser } from '../../../utils/checkAccess';
import { useHistory } from 'react-router-dom';

//import { MultiSelect } from 'primereact/multiselect';

const statuses = [
  {
    id: 1,
    title: 'لوحات العرض',
  },
  // {
  //   id: 2,
  //   title: 'اللوحات المحفوظة',
  // },
  // {
  //   id: 3,
  //   title: 'اعدادات اللوحات',
  // },
];
let viewTypes = [
  { id: 1, name: 'رسم بياني' },
  { id: 2, name: 'جداول' },
  { id: 3, name: 'رسم بياني وجداول' },
];

const dimension = {
  id: 0,
  remove: false,
  selectionId: 0,
  selectionName: '',
  choices: [],
};
const formatter = new Intl.NumberFormat('ar-SA-u-nu-latn', {
  currency: 'SAR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const Panel: FC = () => {
  const [selectedItem, setSelectedItem] = useState(1);
  const [viewType, setViewType] = useState({ id: 0, name: '' });
  const [dataToBeDisplayed, setDataToBeDisplayed] = useState<displayData>({ labels: [], datasets: [] });
  const [chartData, setChartData] = useState<displayData>({ labels: [], datasets: [] });
  const [selectedChart, setSelectedChart] = useState(1);
  const [geoLocations, setGeoLocations] = useState<Array<any>>([]);
  const [prjStatuses, setPrjStatuses] = useState<Array<any>>([]);
  const [listId, setListId] = useState(0);

  const { allSectors, initiatives, mainLookups, anlysis } = useSelector((state: RootState) => state.tenderManagement);
  const { user } = useSelector((state: RootState) => state.user);

  const [dimension1, setDimension1] = useState({ ...dimension, id: 1 });
  const [dimension2, setDimension2] = useState({ ...dimension, id: 2 });
  const [dimension3, setDimension3] = useState({ ...dimension, id: 3 });
  const allOptions = [
    {
      id: 1,
      name: 'القطاعات',
      options: allSectors,
    },
    {
      id: 2,
      name: 'مجالات العمل',
      options: initiatives,
    },
    {
      id: 3,
      name: 'المناطق الجغرافية',
      options: geoLocations,
    },
    {
      id: 4,
      name: 'الحالة',
      options: prjStatuses,
    },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useRef<Toast | null>(null);

  useEffect(() => {
    if (isNormalUser(4, user)) {
      history.push('/');
      return;
    }
    dispatch(fetchAllSectors(toast));
    dispatch(getAllInitiatives(toast));
    dispatch(fetchMainLookups(toast));

    handleAnalysis(); // TO RESTET THE GLOBAL REDUX STATE WITH THE LOCAL EMPTY DIMEMSIONS
  }, []);

  useEffect(() => {
    const data = handleAnalysisResp(anlysis);
    const pieData = getSpecificDoughnutData(anlysis); //handleAnalysisResp(anlysis, 'pie');//getDoughnutData(anlysis);

    setDataToBeDisplayed(data);
    setChartData(pieData);
  }, [anlysis]);

  useEffect(() => {
    const geolocation = mainLookups.find((lookup) => lookup.lookupType === 3);
    const prjStates = mainLookups.find((lookup) => lookup.lookupType === 9);

    setGeoLocations(geolocation?.prtsLookupMainCodes || []);
    setPrjStatuses(prjStates?.prtsLookupMainCodes || []);
  }, [mainLookups]);
  useEffect(() => {
    handleAnalysis();
  }, [dimension1, dimension2, dimension3]);

  const handleAnalysis = () => {
    const data = analysisData([dimension1, dimension2, dimension3]); //GET THE CHOICES FROM EACH DIMENSION AND CONSTRUCT ANLYSIS DTO
    dispatch(analyisData(data, toast));
  };
  const setChartsData = () => {
    localStorage.setItem('chartsData', JSON.stringify(dataToBeDisplayed));
    localStorage.setItem('bieChart', JSON.stringify(chartData));
  };
  if (dimension2.remove) {
    viewTypes = [{ id: 1, name: 'رسم بياني' }];
  } else {
    viewTypes = [
      { id: 1, name: 'رسم بياني' },
      { id: 2, name: 'جداول' },
      { id: 3, name: 'رسم بياني وجداول' },
    ];
  }

  return (
    <div
      className="model-preration-container"
      onClick={() => {
        setListId(0);
      }}
    >
      <div className="create-tp-secton">
        <div className="steps-container">
          {statuses.map((stp, i) => (
            <div key={i} onClick={() => setSelectedItem(stp.id)}>
              <div key={i} className={selectedItem === stp.id ? 'step-active' : 'step'} style={{ marginLeft: '10px' }}>
                {stp.title}
                <div key={i} className={`${selectedItem === stp.id ? 'selected-step' : 'hide'} `}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="panel margin-gap">
        {selectedItem === 1 && (
          <>
            <div className="panel-top">
              <div className="dimensions-container">
                <div className="dimension">
                  <label>البعد الأول</label>

                  <div className="dimension-area">
                    <CascadeMultiSelector
                      options={allOptions}
                      dimension={dimension1}
                      setDimension={setDimension1}
                      listId={listId}
                      setListId={setListId}
                    />
                    {dimension1.remove === true && (
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger p-button-text"
                        onClick={() => {
                          setDimension1({ id: 1, remove: false, selectionId: 0, selectionName: '', choices: [] });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="dimension">
                  <label>البعد الثاني</label>
                  <div className="dimension-area">
                    <CascadeMultiSelector
                      options={allOptions}
                      dimension={dimension2}
                      setDimension={setDimension2}
                      listId={listId}
                      setListId={setListId}
                    />
                    {dimension2.remove === true && (
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger p-button-text"
                        onClick={() => {
                          setDimension2({ id: 2, remove: false, selectionId: 0, selectionName: '', choices: [] });
                          setSelectedChart(1);
                        }}
                      />
                    )}
                  </div>
                </div>
                {/* <div className="dimension">
                  <label>البعد الثالث</label>
                  <div className="dimension-area">
                    <CascadeMultiSelector
                      options={allOptions}
                      dimension={dimension3}
                      setDimension={setDimension3}
                      listId={listId}
                      setListId={setListId}
                    />
                    {dimension3.remove === true && (
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger p-button-text"
                        onClick={() => {
                          setDimension3({ id: 3, remove: false, selectionId: 0, selectionName: '', choices: [] });
                        }}
                      />
                    )}
                  </div>
                </div> */}

                <div className="dimension">
                  <label>نوع العرض</label>
                  <Dropdown
                    value={viewType}
                    optionLabel="name"
                    options={viewTypes}
                    onChange={(e) => {
                      setViewType(e.target.value);
                    }}
                    //optionLabel="sectorName"
                    placeholder="- اختر المناسب -"
                  />
                </div>
              </div>
              <div className="panel-top-btn">
                <button onClick={() => handleAnalysis()} className="analyze-btn">
                  <span>حلل البيانات</span>
                  <img src={BarChartIcon} alt="bar-chart" />
                </button>
                <button className="print-panel-btn" onClick={() => window.print()}>
                  <span>طباعة</span>
                  <img src={Print} alt="bar-chart" />
                </button>
              </div>
            </div>
            <div className="panel-body">
              {viewType.id === 0 && (
                <div className="empty-panel">
                  <img src={PanelInsights} alt="panel-insights" className="panel-insights" />
                  <p>مساحة التحليل البياني</p>
                </div>
              )}
              {viewType.id === 1 && (
                <div className="chart-panel">
                  <div className="d-flex">
                    <div>
                      <SwitchCharts
                        selectedChart={selectedChart}
                        setSelectedChart={setSelectedChart}
                        isDisplayed={dimension2.remove}
                      />
                    </div>
                    <Link to={`/tender/panel/charts/${selectedChart}`} target="_blank" className="link">
                      <button onClick={() => setChartsData()}> عرض كامل</button>
                    </Link>
                  </div>
                  {selectedChart === 1 && <BarChart data={dataToBeDisplayed} />}

                  <div>
                    {selectedChart === 2 && <DoughnutChart data={chartData} />}
                    {selectedChart === 3 && <LineChart data={dataToBeDisplayed} />}
                  </div>
                </div>
              )}
              {viewType.id === 2 && (
                <div className="table-panel">
                  <DataTablePanel />
                </div>
              )}
              {viewType.id === 3 && (
                <div className="chart-table-panel">
                  <div className="right-panel chart-panel">
                    <div className="d-flex">
                      <SwitchCharts selectedChart={selectedChart} setSelectedChart={setSelectedChart} isDisplayed />
                      <Link to={`/tender/panel/charts/${selectedChart}`} target="_blank" className="link">
                        <button onClick={() => setChartsData()}> عرض كامل</button>
                      </Link>
                    </div>
                    {selectedChart === 1 && <BarChart data={dataToBeDisplayed} />}

                    {selectedChart === 2 && <DoughnutChart data={chartData} />}
                    {selectedChart === 3 && <LineChart data={dataToBeDisplayed} />}
                  </div>
                  <div className="left-panel table-panel">
                    <DataTablePanel />
                  </div>
                </div>
              )}
              <div className="total-panel-data">
                <div className="total-projects">
                  <img src={TagLightGreen} alt="" className="total-projects-img" />
                  <p>اجمالي عدد المشروعات</p>
                  <div className="total-projects-div">
                    <img src={Tag} alt="" />
                  </div>
                  <p> {anlysis.totalNumberOfProjects !== 0 ? anlysis.totalNumberOfProjects + ' مشروع' : '__ __'}</p>
                </div>
                <div className="total-price">
                  <img src={Wallet} alt="" className="total-price-img" />
                  <p>اجمالي قيمة المشروعات</p>
                  <div className="total-price-div">
                    <img src={Wallet} alt="" />
                  </div>
                  <p>
                    {anlysis.totalFinanceValueOfProjects !== 0
                      ? formatter.format(anlysis.totalFinanceValueOfProjects) + '  ريال'
                      : '__ __'}
                  </p>
                </div>
                <div className="date">
                  <img src={Calendar} alt="" className="date-img" />
                  <p>تاريخ التحليل</p>
                  <div className="date-div">
                    <img src={Calendar} alt="" />
                  </div>
                  <p> {anlysis.analysisDate !== '' ? anlysis.analysisDate : '__ __'}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Panel;
