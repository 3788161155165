import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { Toast } from 'primereact/toast';
import routes from '../../../constants/routes';
import { forceLogoutUser, loginUser, resetUserPassword } from '../../../redux/actions/user';
import PasswordModal from '../../../components/modals/PasswordModal';
import Loader from '../../../components/Loader/Loader';
import { ConfirmDialog } from 'primereact/confirmdialog';
import './Login.scss';

type props = {
  show: any;
};
const LoginForm: React.FC<props> = ({ show }) => {
  const ref = useRef<Toast | null>(null);
  const [userEmail, setUser] = useState('');
  const [userPassword, setPassword] = useState('');
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { user, loading, errorMsg } = useSelector((state: RootState) => state.user);

  const resetPasswordHandler = (newPassword: string) => {
    const data = { newPassword, oldPassword: userPassword };
    if (!user || data.newPassword === data.oldPassword) {
      setIsError(true);
      return;
    }
    setIsError(false);
    dispatch(resetUserPassword({ userId: user?.id, ...data, email: userEmail }, dispatch, ref));
  };
  const forceLogoutHandler = () => {
    setVisible(false);
    dispatch(forceLogoutUser({ userPassword, userEmail }, ref));
  };
  const loginUserHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userEmail && userPassword) {
      dispatch(loginUser({ userEmail, userPassword }, ref));
    } else if (!userEmail && !userPassword) {
      ref.current?.show({
        severity: 'error',
        detail: 'خطا',
        summary: 'ادخل اسم المستخدم وكلمة المرور',
        sticky: true,
      });
    } else {
      ref.current?.show({
        severity: 'error',
        detail: 'خطا',
        summary: 'اسم المستخدم وكلمة المرور غير صحيحة',
        sticky: true,
      });
    }
  };

  useEffect(() => {
    //
    if (!user) return;
    if (user && user.isFirstLogin) {
      setResetPasswordModal(true);
    } else {
      history.push(routes.HOME.path);
    }
  }, [user, history]);

  useEffect(() => {
    setVisible(true);
  }, [errorMsg]);

  return (
    <section className={show ? 'd-block  login-form' : 'd-none'}>
      {loading && <Loader />}
      <Toast ref={ref} position="top-left" />
      {errorMsg && (
        <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message={errorMsg}
          header="تاكيد"
          acceptLabel="نعم"
          rejectLabel="لا"
          icon="pi pi-exclamation-triangle"
          acceptClassName="accept"
          rejectClassName="reject"
          accept={forceLogoutHandler}
        />
      )}
      <form onSubmit={loginUserHandler}>
        <input
          style={{ backgroundColor: 'transparent', border: '1px solid #fff' }}
          type="email"
          value={userEmail}
          onChange={(e) => {
            setUser(e.target.value);
          }}
          placeholder="   البريد الالكتروني"
          className="login-input"
        />
        <input
          style={{ backgroundColor: 'transparent', border: '1px solid #fff' }}
          type="password"
          value={userPassword}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="كلمة السر"
          className="login-input"
        />

        {/* <div className="login-btn"> */}
        <input
          value="تسجيل الدخول"
          type="submit"
          style={{ backgroundColor: 'transparent', border: '1px solid #fff', color: '#fff' }}
        />
        {/* </div>  */}
      </form>
      {resetPasswordModal && (
        <PasswordModal
          isError={isError}
          submitModal={(data) => {
            resetPasswordHandler(data);
          }}
          closeModal={() => setResetPasswordModal(false)}
          showModal={resetPasswordModal}
        />
      )}
    </section>
  );
};
export default LoginForm;
