import React, { useRef, useState } from 'react';
import '../ModelPrepartion/modelprepration.scss';
import { Link } from 'react-router-dom';
// import Filter1 from '../ModelPrepartion/FilterButtons/Filter1';
// import Filter2 from '../ModelPrepartion/FilterButtons/Filter2';
// import Filter3 from '../ModelPrepartion/FilterButtons/Filter3';
import plus from '../../../assets/svg/plus.svg';
import SearchIcon from '../../../assets/svg/search.svg';
import nomodelssvg from '../../../assets/svg/nomodels.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { Toast } from 'primereact/toast';
import Card from '../components/Card';
import Loader from '../../Loader/Loader';
import { isNormalUser } from '../../../utils/checkAccess';

// const FlterButtons = [
//   {
//     id: 1,
//     title: 'القطاع',
//   },
//   {
//     id: 2,
//     title: 'التصنيف 1',
//   },
//   {
//     id: 3,
//     title: '2 لتصنيف ',
//   },
// ];

const statuses = [
  {
    id: 2,
    title: 'الكراسات المعتمده',
  },
  {
    id: 1,
    title: 'الكراسات تحت التعديل',
  },
  {
    id: 3,
    title: '  الكراسات المؤرشفه ',
  },
];

const BookPrepartion: React.FC = () => {
  const [selectedItem, setSelectedItem] = React.useState(2);
  const [filterId, setfilterId] = useState({ show: false, id: 0 });
  const contentRef = React.useRef(null);
  const { approvedManuals, underModificationManuals, archivedManuals, loading } = useSelector(
    (state: RootState) => state.manualManagement,
  );
  const { user } = useSelector((state: RootState) => state.user);

  const toast = useRef<Toast | null>(null);
  const [searchText, setSearchText] = React.useState<string>('');
  const [filteredUnderModificationManuals, setFilteredUnderModificationManuals] = React.useState<Array<any>>([]);
  const [filteredApprovedManuals, setFilteredApprovedManuals] = React.useState<Array<any>>([]);
  const [filteredArchivedManuals, setFilteredArchivedManuals] = React.useState<Array<any>>([]);

  const FilterHandler = () => {
    document.querySelector('.dropdown-filter-list')?.remove();
    const buttoncontainer = document.querySelector(`.button-container-filter-${filterId.id}`);
    if (filterId.show === true) {
      const dropdownlist = document.createElement('div');
      contentRef.current && dropdownlist.append(contentRef.current);
      buttoncontainer?.append(dropdownlist);
    }
  };
  const searchManuals = (expression: string) => {
    if (selectedItem === 1) {
      let filteredManuals = underModificationManuals.filter((manual) => manual.title.includes(expression));
      setFilteredUnderModificationManuals(filteredManuals);
    } else if (selectedItem === 2) {
      let filteredManuals = approvedManuals.filter((manual) => manual.title.includes(expression));
      setFilteredApprovedManuals(filteredManuals);
    } else {
      let filteredManuals = archivedManuals.filter((manual) => manual.title.includes(expression));
      setFilteredArchivedManuals(filteredManuals);
    }
  };

  React.useEffect(() => {
    setFilteredUnderModificationManuals(underModificationManuals);
  }, [underModificationManuals]);

  React.useEffect(() => {
    setFilteredApprovedManuals(approvedManuals);
  }, [approvedManuals]);

  React.useEffect(() => {
    setFilteredArchivedManuals(archivedManuals);
  }, [archivedManuals]);

  React.useEffect(() => {
    searchManuals(searchText);
  }, [searchText]);

  React.useEffect(() => {
    setSearchText('');
  }, [selectedItem]);

  React.useEffect(() => {
    FilterHandler();
  }, [filterId]);

  return (
    <>
      <Toast ref={toast} position="top-left" />
      <div className="model-preration-container">
        <div className="create-tp-secton">
          <div className="steps-container">
            {statuses.map((stp, i) => (
              <div key={i} onClick={() => setSelectedItem(stp.id)}>
                <div
                  key={i}
                  className={selectedItem === stp.id ? 'step-active' : 'step'}
                  style={{ marginLeft: '10px' }}
                >
                  {stp.title}
                  <div key={i} className={`${selectedItem === stp.id ? 'selected-step' : 'hide'} `}></div>
                </div>
              </div>
            ))}
          </div>
          <div className="top-left-section">
            <input
              className="search-doc"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder=" بحث جميع المستندات"
            />
            <img className="search-icon" src={SearchIcon} alt="SearchIcon" />
          </div>
        </div>
        <div className="create-manual-container">
          {isNormalUser(5, user) ? (
            <>
              <button style={{ backgroundColor: isNormalUser(5, user) ? 'grey' : '' }} className="model-create-button">
                <img className="plussvg" src={plus} alt="plusIcon" />
                <p> انشاء كراسه جديده </p>
              </button>
            </>
          ) : (
            <>
              <Link replace={true} to={(loc) => ({ ...loc, pathname: '/model/tenders' })}>
                <button className="model-create-button">
                  <img className="plussvg" src={plus} alt="plusIcon" />
                  <p> انشاء كراسه جديده </p>
                </button>
              </Link>{' '}
            </>
          )}

          {/* <div className="buttons-con">
            {FlterButtons.map((button, i) => (
              <div className={`button-container-filter-${button.id}`} key={i}>
                <button onClick={() => setfilterId({ show: !filterId.show, id: button.id })} key={i}>
                  {button.title}
                </button>
              </div>
            ))}
          </div>
          <div ref={contentRef} className={filterId.show ? 'content-container' : 'hide'}>
            {filterId.id === 1 && <Filter1 />}
            {filterId.id === 2 && <Filter2 />}
            {filterId.id === 3 && <Filter3 />}
          </div> */}
        </div>
        {selectedItem === 2 && (
          <div className="modelcards-container margin-gap">
            {loading && <Loader />}
            {filteredApprovedManuals.length !== 0 ? (
              filteredApprovedManuals.map((card, i) => <Card locked={true} isArchived={false} card={card} key={i} />)
            ) : (
              <div className="nomodels-found-container">
                <img src={nomodelssvg} alt="noModelIcon" />
                {!loading && <h1>لا توجد نتائج </h1>}
              </div>
            )}
          </div>
        )}
        {selectedItem === 1 && (
          <div className="modelcards-container margin-gap">
            {loading && <Loader />}
            {filteredUnderModificationManuals.length !== 0 ? (
              filteredUnderModificationManuals.map((card, i) => (
                <Card locked={false} isArchived={false} card={card} key={i} />
              ))
            ) : (
              <div className="nomodels-found-container">
                <img src={nomodelssvg} alt="noModelIcon" />
                {!loading && <h1>لا توجد نتائج </h1>}
              </div>
            )}
          </div>
        )}
        {selectedItem === 3 && (
          <div className="modelcards-container margin-gap">
            {loading && <Loader />}
            {filteredArchivedManuals.length !== 0 ? (
              filteredArchivedManuals.map((card, i) => <Card isArchived={true} locked={false} card={card} key={i} />)
            ) : (
              <div className="nomodels-found-container">
                <img src={nomodelssvg} alt="noModelIcon" />
                {!loading && <h1>لا توجد نتائج </h1>}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BookPrepartion;
