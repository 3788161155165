import { combineReducers } from 'redux';
import userReducer from './user';
import modelMangmentReducer from './modelMangmentReducer';
import tenderManagementReducer from './tenderManagementReducer';
import manualManagementReducer from './manualManagementReducer';
import researchReducer from './researchReducer';

const reducers = combineReducers({
  tenderManagement: tenderManagementReducer,
  user: userReducer,
  modelMangment: modelMangmentReducer,
  manualManagement: manualManagementReducer,
  research: researchReducer,

});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
