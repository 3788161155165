import React, { FC } from 'react';
import './EditConditionsManual.scss';

type props = {
  note: any;
  location: any;
};

const AdminNotesSection: FC<props> = ({ note, location }) => {
  return (
    <>
      {/* <div className="admin-note">
       برجاء توضيف كامل التعريفات بحسث استوفاء كامل شروط بنود المشروع المذكورة في المناقصة من قبل الجهة المختصة في تحديد
     فنيات المشروع وكذلك كل الجوانب الغير فن
     </div> */}
      <div className="admin-note">{note.noteText}</div>
    </>
  );
};

export default AdminNotesSection;

/*

//import { useEffect, useState } from 'react';
import { FC } from 'react';
//import AdminNoteCard from './Cards/AdminNoteCard';

type props = {
  mainHeadersList: any;
  location: any;
};

const AdminNotesSection: FC<props> = ({ mainHeadersList, location }) => {
  {
    /*const [notes, setNotes] = useState([]);
  useEffect(() => {
    if (location.child === '') {
      setNotes(mainHeadersList.find((header: any) => header.id === location.parent)?.notes);
    } else {
      setNotes(
        mainHeadersList
          .find((mainHeader: any) => mainHeader.id === location.parent)
          ?.subSections.find((subSection: any) => subSection.id === location.child)?.notes,
      );
    }
  }, [location]);

  }
  return (
    <>
      {/* <h3>ملاحظات الإعداد</h3>
      {notes && notes.map((note, i)=>{
        return(
            <AdminNoteCard
              note={note}
              key={i}
            />
        );
      })
      }}
    </>

*/
