import CreateUser from '../../components/Users/CreateUser/CreateUser';
import UserInfo from '../../components/Users/UserInfo/UserInfo';
import UsersMangment from '../../components/Users/UsersMangment/UsersMangment';
import UsersSettings from '../../components/Users/UsersSettings/UsersSettings';
import Users from '../../pages/Users/Users';

import { IRoutes } from '../../models/routes.model';

const routes: IRoutes = {
  USERS: {
    path: '/users',
    name: 'users',
    component: Users,
    exact: false,
    routes: {
      UsersMangment: {
        path: '/users/mangment',
        name: 'mangment',
        component: UsersMangment,
        exact: true,
      },
      UsersSettings: {
        path: '/users/settings',
        name: 'settings',
        component: UsersSettings,
        exact: true,
      },
      CreateUser: {
        path: '/users/createuser',
        name: 'createuser',
        component: CreateUser,
        exact: true,
      },
      UserInfo: {
        path: '/users/userinfo',
        name: 'userinfo',
        component: UserInfo,
        exact: true,
      },
    },
  },
};

export default routes;
