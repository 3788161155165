import React from 'react';
import { Link } from 'react-router-dom';
import '../createuser.scss';

type Props = {
  nextStep: boolean;
  handleData: (data: any) => void;
  goBackStep: (step: number) => void;
  data?: any;
  currentStep: number;
};
const TwoButtons: React.FC<Props> = ({ nextStep, data, handleData, currentStep, goBackStep }) => {
  return (
    <>
      <div className="userinfo-button-container">
        <button
          onClick={() => {
            goBackStep(currentStep - 1);
          }}
          disabled={currentStep <= 1}
          style={{ backgroundColor: '#2e293b', opacity: '.6' }}
          className="save"
        >
          رجوع
        </button>
        <Link to={'/users'}>
          <button className="cancel">إلغاء</button>
        </Link>
        <button
          onClick={() => {
            handleData(data);
          }}
          disabled={!nextStep}
          className={nextStep ? 'save' : 'save-disabled'}
        >
          متابعة الإنشاء
        </button>
      </div>
    </>
  );
};

export default TwoButtons;
