import React from 'react';
import './userinfo.scss';
import { Link } from 'react-router-dom';
import SysAllowComp from './SysAllowComp';
import { User } from '../../../models/User';

type Props = {
  saveUser: (user: any) => void;
  cancelEdit: () => void;
  user: User;
  setArrayInputs: any;
  arrayInputs: Isysallow[];
};
export interface Isysallow {
  admModule: {
    moduleId: number;
    moduleNameA: string;
    moduleNameE: string;
    moduleShortname: string;
  };
  userType: number;
}

const SystemAllowance: React.FC<Props> = ({ cancelEdit, saveUser, user, arrayInputs, setArrayInputs }) => {
  return (
    <>
      <SysAllowComp user={user} arrayInputs={arrayInputs} setArrayInputs={setArrayInputs} />
      <div className="userinfo-button-container">
        <Link to="/users">
          <button className="cancel" onClick={cancelEdit}>
            إلغاء
          </button>
        </Link>
        <button className="save" onClick={() => saveUser(user)}>
          حفظ التغييرات
        </button>
      </div>
    </>
  );
};

export default SystemAllowance;
