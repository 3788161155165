import React, { FC } from 'react';
import TextEditor from './TextEditor';
import { ConfirmDialog } from 'primereact/confirmdialog';
import './text-editor.scss';
import TextEditor2 from './TextEditor2';
type props = {
  readOnly: boolean;
  setHTMLContent: any;
  mapContentToHeader: any;
  location: any;
  preDisplayedContent: any;
  setPreDisplayedContent: any;
  deleteContentFromHeader: any;
  viewNotesSection: any;
  HTMLcontent: any;
};
const RichEditorSemiComp: FC<props> = ({
  readOnly,
  setHTMLContent,
  mapContentToHeader,
  location,
  preDisplayedContent,
  setPreDisplayedContent,
  deleteContentFromHeader,
  viewNotesSection,
  HTMLcontent,
}) => {
  const [showMsg, setShowMsg] = React.useState({ show: false, msg: '' });
  const [biggerScreen, setBiggerScreen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const onSave = () => {
    mapContentToHeader();
    setPreDisplayedContent('-');

    setShowMsg({ show: true, msg: 'green' });
    const timer = setTimeout(() => {
      setShowMsg({ show: false, msg: '' });
    }, 1500);

    return () => clearTimeout(timer);
  };

  const onDelete = () => {
    deleteContentFromHeader();
    setPreDisplayedContent('');
    setShowMsg({ show: true, msg: 'red' });
    const timer = setTimeout(() => {
      setShowMsg({ show: false, msg: '' });
    }, 1500);

    return () => clearTimeout(timer);
  };

  return (
    <div
      className={
        location.parent === ''
          ? 'text-editor-container-disapled'
          : biggerScreen
          ? 'text-editor-container full-screen'
          : 'text-editor-container'
      }
    >
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message={`هل تريد حذف هذا ${location?.child !== '' ? 'العنوان الفرعي' : 'العنوان الرئيسي'}`}
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={onDelete}
        className="modalBootbox"
      />

      <h3 className="text-editor-title">تحرير محتوي القسم</h3>

      <div className="text-editor">
        <TextEditor
          viewOnly={readOnly}
          preDisplayedContent={preDisplayedContent}
          setHTMLContent={setHTMLContent}
          HTMLcontent={HTMLcontent}
          location={location}
          biggerScreen={biggerScreen}
        />
        {/* <TextEditor2
          viewOnly={readOnly}
          preDisplayedContent={preDisplayedContent}
          setHTMLContent={setHTMLContent}
          HTMLcontent={HTMLcontent}
          location={location}
          biggerScreen={biggerScreen}
        /> */}
      </div>

      <br></br>
      <br></br>

      <div className="editor-button-container">
        <div className="editable"></div>
        <button disabled={readOnly} onClick={() => onSave()} className="button-save">
          حفظ التغييرات
        </button>
        {/*<button disabled={readOnly} onClick={() => setVisible(true)} className="buttton-delete">
          حذف القسم
        </button>*/}
        <label
          style={{ color: showMsg.msg === 'red' ? 'red' : '#136F3B' }}
          className={showMsg.show ? 'label-show' : 'hide'}
        >
          {showMsg.msg === 'red' ? '!تم حذف المقطع!' : '!تم حفظ التغيرات'}
        </label>
      </div>

      <button
        onClick={() => {
          setBiggerScreen(!biggerScreen);
          viewNotesSection(biggerScreen);
        }}
        className="fullscreen-button"
      >
        {!biggerScreen ? 'عرض شاشة كاملة' : 'تصغير الشاشه'}
      </button>
      <br></br>
    </div>
  );
};

export default RichEditorSemiComp;
