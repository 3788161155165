import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './modelprepration.scss';
import nomodelssvg from '../../../assets/svg/nomodels.svg';
import SearchIcon from '../../../assets/svg/search.svg';
import plus from '../../../assets/svg/plus.svg';
import ModelCard from '../components/ModelCard';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { createNewModel, getAllModels, createModelBasedonModel } from '../../../redux/actions/modelMangmentActions';
import { Toast } from 'primereact/toast';
import Loader from '../../Loader/Loader';
import { isNormalUser } from '../../../utils/checkAccess';

const statuses = [
  {
    id: 1,
    title: 'النماذج المعتمده',
  },
  {
    id: 2,
    title: 'نماذج تحت التعديل',
  },
  {
    id: 3,
    title: '  النماذج المؤرشفه ',
  },
];

const ModelPrepration: React.FC = () => {
  const [selectedItem, setSelectedItem] = React.useState(1);
  const [createModel, setcreateModel] = React.useState(false);
  const [modelName, setModelName] = React.useState({ show: false, name: '' });
  const [modelBasedonModel, setModelBasedonModel] = React.useState({ show: false, name: '', selectedModel: 0 });

  const Models = useSelector((state: RootState) => state.modelMangment.models);
  const archivedModels = useSelector((state: RootState) => state.modelMangment.archivedModels);
  const approvedmodels = useSelector((state: RootState) => state.modelMangment.approvedmodels);
  const loading = useSelector((state: RootState) => state.modelMangment.loading);
  const { user } = useSelector((state: RootState) => state.user);

  const [filteredModels, setfilteredModels] = React.useState<Array<any>>([]);
  const [filterUnderEditingModels, setFilterUnderEditingModels] = React.useState<Array<any>>([]);
  const [searchText, setSearchText] = React.useState<string>('');

  const history = useHistory();
  const toast = useRef<Toast | null>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Models.length === 0) {
      dispatch(getAllModels(toast));
    }
  }, []);
  React.useEffect(() => {
    setfilteredModels(approvedmodels);
  }, [approvedmodels]);

  React.useEffect(() => {
    setFilterUnderEditingModels(Models);
  }, [Models]);

  React.useEffect(() => {
    searchModels(searchText);
  }, [searchText]);
  React.useEffect(() => {
    setSearchText('');
  }, [selectedItem]);

  const searchModels = (expression: string) => {
    if (selectedItem === 1) {
      let newfilteredmodels = approvedmodels.filter((model) => model.name.includes(expression));
      setfilteredModels(newfilteredmodels);
    } else {
      let newfilteredmodels = Models.filter((model) => model.name.includes(expression));
      setFilterUnderEditingModels(newfilteredmodels);
    }
  };

  
  return (
    <>
      <Toast ref={toast} position="top-left" />
      <div className="model-preration-container">
        <div className="create-tp-secton">
          <div className="steps-container">
            {statuses.map((stp, i) => (
              <div key={i} onClick={() => setSelectedItem(stp.id)}>
                <div key={i} className={selectedItem === stp.id ? 'step-active' : 'step'}>
                  {stp.title}
                  <div key={i} className={`${selectedItem === stp.id ? 'selected-step' : 'hide'} `}></div>
                </div>
              </div>
            ))}
          </div>

          <div className="top-left-section">
            <input
              className="search-doc"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="بحث جميع المستندات"/>
              
            <img className="search-icon" src={SearchIcon} alt="SearchIcon" />
          </div>
        </div>

        <button
          disabled={isNormalUser(5, user)}
          className="model-create-button"
          id="create-new-model-btn"
          onClick={() => setcreateModel(true)}
          style={{ backgroundColor: isNormalUser(5, user) ? 'grey' : '' }}
        >
          <img className="plussvg" src={plus} alt="plusIcon" />
          <p> انشاء نموذج جديد </p>
        </button>

        {selectedItem === 1 && (
          <div className="modelcards-container margin-gap">
            {loading && <Loader />}
            {filteredModels.length !== 0 ? (
              filteredModels.map((model, index) => <ModelCard card={model} key={index} locked={true} archived={false} />)
            ) : (
              <div className="nomodels-found-container">
                <img src={nomodelssvg} alt="noModelIcon" />
                <h1>لا توجد نتائج لأي نماذج</h1>
                <p>إبدأ بإنشاء النماذج</p>
              </div>
            )}
          </div>
        )}

        {selectedItem === 2 && (
          <div className="modelcards-container margin-gap">
            {filterUnderEditingModels.length !== 0 ? (
              filterUnderEditingModels.map((model, i) => (
                <ModelCard card={model} key={i} locked={false} archived={false} />
              ))
            ) : (
              <div className="nomodels-found-container">
                <img src={nomodelssvg} alt="noModelIcon" />
                <h1>لا توجد نتائج لأي نماذج</h1>
                <p>إبدأ بإنشاء النماذج</p>
              </div>
            )}
          </div>
        )}

        {selectedItem === 3 && (
          <div className="modelcards-container margin-gap">
            {archivedModels.length !== 0 ? (
              archivedModels.map((card, i) => <ModelCard card={card} key={i} locked={true} archived={true} />)
            ) : (
              <div className="nomodels-found-container">
                <img src={nomodelssvg} alt="noModelIcon" />
                <h1>لا توجد نتائج لأي نماذج</h1>
                <p>إبدأ بإنشاء النماذج</p>
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog
        className="primereact-dialouge-model"
        visible={createModel}
        onHide={() => setcreateModel(false)}
        draggable={false}
        showHeader={true}
        dismissableMask={true}
        header=" اختر نوع إنشاء النموذج المطلوب"
      >
        <div className="margin-div">
          <div className="create-new-model">
            <button onClick={() => setModelBasedonModel({ ...modelBasedonModel, show: true })}>
              {' '}
              مبني علي نموذج معتمد آخر
            </button>

            <button onClick={() => setModelName({ ...modelName, show: true })}>نموذج جديد كلياً</button>
          </div>
        </div>
      </Dialog>
      <Dialog
        className="primereact-dialouge-model model-info-dialog"
        visible={modelName.show}
        onHide={() => setModelName({ ...modelName, show: false })}
        draggable={false}
        showHeader={true}
        dismissableMask={true}
        header="بيانات النموذج "
      >
        <div className="create-new-model">
          <div className="modelname">
            اسم النموذج <span>*</span>
            <input
              required
              value={modelName.name}
              onChange={(e) => setModelName({ ...modelName, name: e.target.value })}
              className="model-name-input"
              placeholder="مثال :: نموذج المشاريع الانشائيه"
            />
          </div>
          <br></br>

          <button
            disabled={modelName.name === '' ? true : false}
            onClick={() => dispatch(createNewModel(modelName.name, history, toast))}
            className="contine-model-btn"
          >
            متابعه انشاء النموذج
          </button>
        </div>
      </Dialog>




      <Dialog
        className="primereact-dialouge-model approvedmodelsinmodalcontainer "
        visible={modelBasedonModel.show}
        onHide={() => setModelBasedonModel({ ...modelBasedonModel, show: false })}
        draggable={false}
        showHeader={true}
        dismissableMask={true}
        header="بيانات النموذج "
        style={{ height: '70vh' }}
      >
        <Toast ref={toast} position="top-left" />

        <div className="create-new-model">
          <div className="modelname">
            اسم النموذج <span>*</span>
            <br />
            <input
              required
              value={modelBasedonModel.name}
              onChange={(e) => setModelBasedonModel({ ...modelBasedonModel, name: e.target.value })}
              className="model-name-input"
              placeholder="مثال :: نموذج المشاريع الانشائيه"
            />
          </div>
          <br></br>
          <>
            <h5>اختر نموذج معتمد مناسب لإعداد </h5>

            <div className="approvedmodelsinmodal">
              {loading && <Loader />}
              <div>
                {approvedmodels.map((model, i) => {
                  return (
                    <div>
                      <label key={i}>
                        <input
                        
                          checked={modelBasedonModel.selectedModel === model.modelId ? true : false}
                          type="radio"
                          className="templateRadioBtnList"
                          name="templateList"
                          id={`${model.modelId}`}
                          value={model.modelId}
                          disabled={modelBasedonModel.name === '' && true}
                          onChange={() => {
                            setModelBasedonModel({ ...modelBasedonModel, selectedModel: model.modelId });
                          }}
                        />
                        {model.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </>

          <button
            disabled={modelBasedonModel.name === '' ? true : false}
            onClick={() => dispatch(createModelBasedonModel(modelBasedonModel, history, toast))}
            className="contine-model-btn"
          >
            متابعه انشاء النموذج
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default ModelPrepration;
