import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import InnerFooter from '../../components/InnerFooter/InnerFooter';
import InnerHeader from '../../components/InnerHeader/InnerHeader';
import tenderMenuItems from '../../constants/tenderMenuItems';
import { useLocation } from 'react-router';
import './Tender.scss';
import cards from '../../constants/cards';
import { IRoutes } from '../../models/routes.model';
import NewSideMenu from '../../components/NewSideMenu/NewSideMenu';
import { isNormalUser } from '../../utils/checkAccess';
import {  useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';



type Props = {
  subRoutes: IRoutes;
};

const Tender: React.FC<Props> = ({ subRoutes }) => {
  const { pathname } = useLocation();
  const [sideMenuWidth, setSideMenuWidth] = useState(true);
  const [mainSection, setSelectedMainSection] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const { user, modules } = useSelector((state: RootState) => state.user);


  useEffect(() => {
    const [, section, currentSubRoute] = pathname.split('/');
    const baseUrl = cards.find((ele) => ele.cardLink === `/${section}`)?.title || '';
    const data = tenderMenuItems.find((ele) => ele.menuLink === currentSubRoute);


    setSelectedMainSection(baseUrl);
    setSelectedItem(data ? data.title : tenderMenuItems[0].title);

    return () => {
      setSelectedMainSection('');
      setSelectedItem('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedItemHandler = (item: any) => {
    setSelectedItem(item.title);
  };

  const toggledSideMenuHandler = (sideMenu: any) => {
    setSideMenuWidth(sideMenu);
  };

  const showSideMenuItemsHandler = () => {
    if(isNormalUser(4, user)){
      let filterSideMenu = tenderMenuItems.filter(ele => ele.id !== 3)
      return filterSideMenu
    } else {
    return tenderMenuItems
    }
  }

  return (
    <>
      <div
        className="tender-management"
        style={{
          gridTemplateColumns: `${sideMenuWidth === true ? 'repeat(2, 20% 80%)' : 'repeat(1, 6% 94%)'}`,
        }}
      >
        <NewSideMenu
          selectedItem={selectedItemHandler}
          toggledSideMenu={toggledSideMenuHandler}
          sideMenuItems={showSideMenuItemsHandler()}
        />

        {/* {console.log('===>>>', selectedItemHandler)} */}
        {/* {console.log('...', tenderMenuItems)} */}

        <div className="internal-routes">
          <InnerHeader mainSection={mainSection} selectedItem={selectedItem} />
          {/* sub routes for tender management */}
          <div className="routes-container">
            <Switch>
              <Redirect exact from="/tender" to="/tender/current-projects" />
              {Object.values(subRoutes).map((route) => (
                <Route key={route.name} exact={route.exact} path={route.path} component={route.component} />
              ))}
            </Switch>
          </div>
        </div>
          <InnerFooter />
      </div>
    </>
  );
};

export default Tender;
