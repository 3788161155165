import React from 'react';
import evara from '../../assets/svg/poweredby-evara.svg';
import segal from '../../assets/svg/segal-logo.svg';
import './OuterFooter.scss';

const OuterFooter = () => {
  return (
    <footer>
      <div className="powered-by">
        powered by
        <div className="footer-imgs">
          <img src={segal} alt="segal" />
          &
          <img src={evara} alt="evara" />
        </div>
      </div>
    </footer>
  );
};

export default OuterFooter;
