import React, { FC, useState } from 'react';
import { Chart } from 'primereact/chart';

type props = {
  data: any;
};
const DoughnutChart: FC<props> = ({ data }) => {
  let labels = [];
  let dataset = [];
  labels = data?.labels?.map((ele: any) => {
    return ele;
  });

  dataset = data.datasets?.map((el: any) => {
    return el.data;
  });

  const RandomColorsGenerator = () => {
    let color = '#ffffff';
    color = '#' + Math.ceil(Math.random() * 16777215).toString(16);
    return color;
  };
  let generatedColor = dataset[0].map(() => {
    return RandomColorsGenerator();
  });

  const [chartData] = useState({
    labels: labels,
    datasets: [
      {
        data: dataset[0],
        backgroundColor: generatedColor,
        hoverBackgroundColor: generatedColor,
      },
    ],
  });
  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: '#495057',
        },
      },
    },
  });

  return (
    <div className="doughnut-chart">
      <div className="card">
        <Chart type="doughnut" data={chartData} options={lightOptions} />
      </div>
    </div>
  );
};
export default DoughnutChart;
