import { Toast } from 'primereact/toast';
import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllModels } from '../../../../../../redux/actions/modelMangmentActions';
import { RootState } from '../../../../../../redux/reducers';
import Loader from '../../../../../Loader/Loader';
import '../CreateConditionsManual.scss';

type Props = {
  setActivePage: any;
  modalData: any;
  setModalData: any;
};

const Page3: FC<Props> = ({ setActivePage, modalData, setModalData }) => {
  const Models = useSelector((state: RootState) => state.modelMangment.approvedmodels);
  const loading = useSelector((state: RootState) => state.modelMangment.loading);
  const toast = useRef<Toast | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (Models.length === 0) {
      dispatch(getAllModels(toast));
    }
  }, []);

  return (
    <>
      <Toast ref={toast} position="top-left" />
      <div className="modalForm" id="modal-page3">
        <h5>اختر نموذج معتمد مناسب لإعداد الكراسة</h5>
        {loading && <Loader />}
        <div>
          {Models.map((model, i) => {
            return (
              <label key={i}>
                <input
                  checked={modalData.selectedModel === model.modelId ? true : false}
                  type="radio"
                  className="templateRadioBtnList"
                  name="templateList"
                  id={`${model.modelId}`}
                  value={modalData.selectedModel}
                  onChange={() => {
                    setModalData({ ...modalData, selectedModel: model.modelId });
                    setActivePage({
                      id: 3,
                      isContentFilled: {
                        page1: true,
                        page2: true,
                        page3: true,
                      },
                    });
                  }}
                />
                {model.name}
              </label>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Page3;
