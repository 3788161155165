import React, { FC } from 'react';
import './CreateConditionsManual.scss';

type Props = {
  activePage: any;
};

const ModalAllPagesHeader: FC<Props> = ({ activePage }) => {
  return (
    <div className="allPages">
      <div id="page1">
        <div
          style={{
            background:
              (activePage.id === 1 && activePage.isContentFilled.page1) || activePage.id > 1 ? '#1EB05D' : '#EDEDED',
          }}
        >
          <span
            style={{
              color:
                (activePage.id === 1 && activePage.isContentFilled.page1) || activePage.id > 1 ? 'white' : '#2E293B',
              textAlign: 'center',
            }}
          >
            ١
          </span>
        </div>
        <p style={{ borderBottom: activePage.id === 1 ? '3px solid #136F3B' : '' }}>بيانات الكراسة</p>
      </div>
      <hr className="separator"></hr>
      <div id="page2">
        <div
          style={{
            background:
              (activePage.id === 2 && activePage.isContentFilled.page2) || activePage.id > 2 ? '#1EB05D' : '#EDEDED',
          }}
        >
          <span
            style={{
              color:
                (activePage.id === 2 && activePage.isContentFilled.page2) || activePage.id > 2 ? 'white' : '#2E293B',
            }}
          >
            ٢
          </span>
        </div>
        <p style={{ borderBottom: activePage.id === 2 ? '3px solid #136F3B' : '' }}>معدين الكراسة</p>
      </div>
      <hr className="separator"></hr>
      <div id="page3">
        <div
          style={{
            background: activePage.id === 3 && activePage.isContentFilled.page3 ? '#1EB05D' : '#EDEDED',
          }}
        >
          <span
            style={{
              color: activePage.id === 3 && activePage.isContentFilled.page3 ? 'white' : '#2E293B',
            }}
          >
            ٣
          </span>
        </div>
        <p style={{ borderBottom: activePage.id === 3 ? '3px solid #136F3B' : '' }}>نموذج عمل الكراسة</p>
      </div>
    </div>
  );
};

export default ModalAllPagesHeader;
