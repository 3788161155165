import { Toast } from 'primereact/toast';
import api from '../../api';
import { errorMessages } from '../../constants/error-messages';
import { User } from '../../models/User';
import { showToast } from '../../utils/showToast';
import { removeStorageData, setStorageData } from '../../utils/storage';
import {
  ADD_USER,
  DELETE_USER,
  FORCE_LOGOUT_USER,
  GET_ALL_MODULES,
  GET_ALL_USERS,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_TOKEN,
  SEARCH_USER,
  SET_ERROR_MSG,
  SET_USER_LOADER,
  UPDATE_USER,
  EMAIL_EXISTS,
} from './types';

export const loginUser =
  (body: { userEmail: string; userPassword: string }, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      const { data }: any = await api.post('/users/login', body);
      //
      const { token, jwtExpirationinMs, ...user } = data;
      setStorageData('user', user);
      setStorageData('token', token);
      const tokenExpireDate = Date.now() + jwtExpirationinMs;
      setStorageData('tokenExpireDate', tokenExpireDate);
      dispatch({ type: LOGIN_USER, payload: { user, token, tokenExpireDate } });
    } catch (error: any) {
      dispatch({ type: SET_USER_LOADER, payload: false });

      const msg =
        error.response && error.response.data && error.response.data.message ? error.response.data.message : 'حدث خطا';
      if (msg === 'This user is already logged in from another device, please logout first and try to login again') {
        const arMsg = errorMessages.find((ele) => ele.en.indexOf(msg.indexOf(':') > -1 ? msg.split(':')[0] : msg) > -1);
        //
        dispatch({ type: SET_ERROR_MSG, payload: arMsg?.ar || '' });
      } else {
        showToast(toast, error);
      }
    }
  };

export const forceLogoutUser =
  (body: { userEmail: string; userPassword: string }, toast: any) => async (dispatch: any) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      await api.post(`/users/force-logout`, body);
      removeStorageData('user');
      removeStorageData('token');
      removeStorageData('tokenExpireDate');
      dispatch({ type: FORCE_LOGOUT_USER, payload: true });
      dispatch(loginUser(body, toast));
    } catch (error: any) {
      dispatch({ type: SET_USER_LOADER, payload: false });

      showToast(toast, error);
    }
  };

export const logoutUser =
  (userId: number, toast: any, history: any) => async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      await api.post(`/users/${userId}/logout`);
      removeStorageData('user');
      removeStorageData('token');
      removeStorageData('tokenExpireDate');
      dispatch({ type: LOGOUT_USER, payload: true });
      // console.log('logout functionn');
      history.replace('/login');
    } catch (error: any) {
      dispatch({ type: SET_USER_LOADER, payload: false });

      if (toast) {
        showToast(toast, error);
      }
    }
  };

export const refreshToken =
  (body: { userId: number }) => async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      const { data }: any = await api.post('users/refresh-token', body);
      //
      const { accessToken, jwtExpirationinMs } = data;
      const tokenExpireDate = Date.now() + jwtExpirationinMs;
      setStorageData('token', accessToken);
      setStorageData('tokenExpireDate', tokenExpireDate);
      dispatch({ type: REFRESH_TOKEN, payload: { token: accessToken, tokenExpireDate } });
    } catch (error: any) {
      dispatch({ type: SET_USER_LOADER, payload: false });
    }
  };

export const addUser =
  (payload: User, history: any, toast: any) => async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      const { data }: any = await api.post('/adm/users', payload);
      //
      dispatch({ type: ADD_USER, payload: data });
      history.push('/users');
    } catch (error: any) {
      dispatch({ type: SET_USER_LOADER, payload: false });

      showToast(toast, error);
    }
  };

export const updateUser =
  (payload: any, history: any, toast: any) => async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      if (!payload.userId) return;
      const { data }: any = await api.put(`/adm/users/${payload.userId}`, payload);
      //
      dispatch({ type: UPDATE_USER, payload: data });
      history.push('/users');
    } catch (error: any) {
      dispatch({ type: SET_USER_LOADER, payload: false });

      showToast(toast, error);
    }
  };

export const deleteUser =
  (userId: number, history: any, toast: any) => async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      await api.delete(`/adm/users/${userId}`);
      //
      dispatch({ type: DELETE_USER, payload: userId });
      history.push('/users');
    } catch (error) {
      dispatch({ type: SET_USER_LOADER, payload: false });

      showToast(toast, error);
    }
  };

export const resetUserPassword =
  (
    { userId, ...payload }: { userId: number; newPassword: string; oldPassword: string; email: string },
    dispatc: any,
    toast: any,
  ) =>
  async (dispatch: (data: { type: string; payload: any }) => void, getState: any) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      await api.put(`/adm/users/${userId}/password`, payload);
      dispatc(loginUser({ userEmail: payload.email, userPassword: payload.newPassword }, toast));
    } catch (error) {
      dispatch({ type: SET_USER_LOADER, payload: false });

      showToast(toast, error);
    }
  };

export const getAllUsers =
  (toast: any, params: any) => async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      const { data }: any = await api.get('/adm/users', { params: params });

      dispatch({ type: GET_ALL_USERS, payload: { users: data } });
    } catch (error) {
      dispatch({ type: SET_USER_LOADER, payload: false });
      showToast(toast, error);
    }
  };

export const getAllModules = (toast: any) => async (dispatch: (data: { type: string; payload: any }) => void) => {
  try {
    dispatch({ type: SET_USER_LOADER, payload: true });
    const { data }: any = await api.get('/adm/modules');
    //
    dispatch({ type: GET_ALL_MODULES, payload: data });
  } catch (error) {
    dispatch({ type: SET_USER_LOADER, payload: false });
    showToast(toast, error);
  }
};

export const searchUser =
  (email: any, toast: any) => async (dispatch: (data: { type: string; payload: any }) => void) => {
    try {
      dispatch({ type: SET_USER_LOADER, payload: true });
      const params: Record<string, string> = email;
      const { data }: any = await api.get(`/adm/users/search/1`, { params });
      console.log(data);
      dispatch({ type: SEARCH_USER, payload: { users: data } });
    } catch (error) {
      // dispatch({ type: SET_USER_LOADER, payload: false });
      showToast(toast, error);
    }
  };
export const isEmailExists = (email: string) => async (dispatch: (data: { type: string; payload: any }) => void) => {
  // dispatch({ type: SET_USER_LOADER, payload: true });
  try {
    const { data }: any = await api.get(`/adm/users/${email}/exist`);

    dispatch({ type: EMAIL_EXISTS, payload: data });
  } catch (error) {
    // dispatch({ type: SET_USER_LOADER, payload: false });
  }
};
