import React, { FC, useRef, useState } from 'react';
import './CreateConditionsManual.scss';
import Page1 from './ModalPages/Page1';
import Page2 from './ModalPages/Page2';
import Page3 from './ModalPages/Page3';
import { ConfirmDialog } from 'primereact/confirmdialog';
import ModalFooter from './ModalFooter';
import ModalAllPagesHeader from './ModalAllPagesHeader';
import { Dialog } from 'primereact/dialog';
import { createNewManual } from '../../../../../redux/actions/manualManagementActions';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';

type Props = {
  show: boolean;
  hide: any;
  selectedTender: any;
  setSelectedTender: any;
  SectorName: string;
};

const CreateConditionsManual: FC<Props> = ({ SectorName, show, hide, selectedTender, setSelectedTender }) => {
  const [activePage, setActivePage] = useState({
    id: 1,
    isContentFilled: { page1: false, page2: false, page3: false },
  });
  const [showBootBox, setShowBootBox] = useState(false);
  const state = {
    onGoing: 'جاري التعديل',
    notYet: 'لم يتم البدأ',
  };
  const [modalData, setModalData] = React.useState<any>({
    selectedTender: selectedTender,
    manualTitle: '',
    manualSector: '',
    type1: `${selectedTender.prtsLookupMainCodeByStageCode?.prtsLookupType?.lookupTypeName} ${selectedTender.prtsLookupMainCodeByStageCode?.lookupMainName}`, //'jsjx',
    type2: `${selectedTender.prtsLookupMainCodeByTypeCode?.prtsLookupType?.lookupTypeName} ${selectedTender.prtsLookupMainCodeByTypeCode?.lookupMainName}`, //'xs',
    employees: [],
    selectedModel: 0,
  });
  const dispatch = useDispatch();
  const toast = useRef<Toast | null>(null);
  const history = useHistory();
  const submitModalHandler = () => {
    let employeesId: any = [];
    modalData.employees.forEach((employee: any) => {
      employeesId.push(employee.userId);
    });
    const newManual = {
      maintainersIdList: employeesId, //modalData.employees,
      modelId: modalData.selectedModel,
      projectId: selectedTender.projectId,
      projectSubClassId: 1, //tmp val
      sectorId: selectedTender.sectorCode,
      title: modalData.manualTitle,
    };

    dispatch(createNewManual(newManual, toast, history));

    setSelectedTender({ ...selectedTender, conditionsManual: modalData, state: state.onGoing }); ////change tender manual state to ongoing (created)
    hide(true);
  };
  const footer = (
    <ModalFooter activePage={activePage} setActivePage={setActivePage} submitModalHandler={submitModalHandler} />
  );

  return (
    <>
      <Toast ref={toast} position="top-left" />
      {showBootBox ? (
        <ConfirmDialog
          visible={showBootBox}
          onHide={() => setShowBootBox(false)}
          message=" في حالة التأكيد سيتم مسح التغييرات و لن يتم انشاء كراسة الشروط"
          header="تأكيد إلغاء الانشاء"
          acceptLabel="تأكيد"
          rejectLabel="تجاهل"
          acceptClassName="accept"
          rejectClassName="reject"
          accept={() => {
            hide(true);
          }}
          className="modalBootbox"
        />
      ) : (
        <Dialog
          id="createConditionsManualModal"
          visible={show}
          onHide={() => {
            setShowBootBox(true);
          }}
          header="انشاء كراسة شروط جديدة"
          footer={footer}
          blockScroll={true}
          rtl={true}
          closeOnEscape={false}
          draggable={false}
          resizable={false}
        >
          <div className="modal-body">
            <ModalAllPagesHeader activePage={activePage} />

            <div className="pageContent">
              {activePage.id === 1 ? (
                <Page1
                  tenderName={selectedTender.projectName}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  modalData={modalData}
                  setModalData={setModalData}
                  SectorName={SectorName}
                />
              ) : activePage.id === 2 ? (
                <Page2
                  activePage={activePage}
                  setActivePage={setActivePage}
                  modalData={modalData}
                  setModalData={setModalData}
                />
              ) : activePage.id === 3 ? (
                <Page3 setActivePage={setActivePage} modalData={modalData} setModalData={setModalData} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default CreateConditionsManual;
