import React from 'react'

const ModelStructure = () => {
    return (
        <div className='after-create-tp-secton'>
            Model Structure
        </div>
    )
}

export default ModelStructure
