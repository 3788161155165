import React, { useState, useEffect, FC } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

const DataTablePanel: FC = () => {
  const { anlysis } = useSelector((state: RootState) => state.tenderManagement);
  const [tableCols, setTableCols] = useState<any[]>([]);
  const [tableRows, setTableRows] = useState<any[]>([]);

  const Data = [
    {
      initiave: 'Egypt',
      sector: 'Cairo',
      status: '',
      geoloaction: '',
    },
    {
      initiave: 'Egypt',
      sector: 'Cairo',
      status: '',
      geoloaction: '',
    },
    {
      initiave: 'Egypt',
      sector: 'Cairo',
      status: '',
      geoloaction: '',
    },
    {
      initiave: 'Egypt',
      sector: 'Cairo',
      status: '',
      geoloaction: '',
    },
    {
      initiave: 'Egypt',
      sector: 'Cairo',
      status: '',
      geoloaction: '',
    },
  ];

  const generateCols = () => {
    let cols = [];
    if (anlysis.dataAnalysis.length !== 0) {
      cols.push({ field: 'initiave', header: '  الاسم' });
      cols.push({ field: 'initiaveNumber', header: ' عدد الشروعات ' });
    }
    return cols;
  };
  const generatData = () => {
    let rows = [];
    const { dataAnalysis } = anlysis;
    let count = Math.max(
      // initiativesCodeAnalysis.length,
      // sectorIdAnaylsis.length,
      // geoIdAnaylsis.length,
      // statusAnalysis.length,
      dataAnalysis.length,
    );
    for (let i = 0; i < count; i++) {
      let row = {
        initiave: '',
        sector: '',
        status: '',
        geoloaction: '',
        initiaveNumber: '',
        sectorNumber: '',
        statusNumber: '',
        geoNumber: '',
      };
      let empty = true;
      if (dataAnalysis.length !== 0 && dataAnalysis.length > i) {
        row = {
          ...row,
          initiave: dataAnalysis[i]?.nameOfDimension,
          initiaveNumber: dataAnalysis[i]?.countOfProjects.toString(),
        };
        empty = false;
      }
      !empty && rows.push(row);
    }
    // console.log(rows, 'rows returned');
    return rows;
  };
  useEffect(() => {
    setTableCols(generateCols());
    setTableRows(generatData());
    // console.log(tableRows, tableCols);
  }, [anlysis]);

  // console.log(tableCols, anlysis);
  return (
    <div className="datatable-scroll-demo">
      <div className="">
        <DataTable value={tableRows}>
          {tableCols.map((col, i) => (
            <Column key={i} field={col.field} header={col.header} style={{ minWidth: '200px' }}></Column>
          ))}
        </DataTable>
      </div>
    </div>
  );
};
export default DataTablePanel;
