import React, { useEffect, useState } from 'react';
import '../createuser.scss';

import Switch from 'react-switch';
import { ConfirmDialog } from 'primereact/confirmdialog';

import confirm from '../../../../assets/svg/confirm.svg';

import { InputText } from 'primereact/inputtext';
import TwoButtons from './TwoButtons';
import { User } from '../../../../models/User';
import { passvalidator } from '../../../../constants/regex';

type Props = {
  addUserHandler: (data: any) => void;
  goBackStep: (step: number) => void;
  user: Partial<User>;
  currentStep: number;
};

const UserAccessbility: React.FC<Props> = ({ addUserHandler, user, currentStep, goBackStep }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [visible, setVisible] = useState(false);
  const [activated, setActivated] = useState(true);
  const [nextStep, setNextStep] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if (password && confirmPassword && confirmPassword === password && !passwordError) {
      setNextStep(true);
    } else {
      setNextStep(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPassword]);

  useEffect(() => {
    if (!user) return;
    setPassword(user.userPass || '');
    setConfirmPassword(user.userPass || '');
    setActivated(user.isEnable || true);
  }, [user]);

  return (
    <>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="في حالة حذف السماح لن يتمكن المستخدم من الوصول مرة آخري إلي هذا البرنامج"
        header="تأكيد إلغاء السماح"
        acceptLabel="تأكيد"
        rejectLabel="تجاهل"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={() => console.log('sa')}
        className="modalBootbox"
      />

      <div className="accountstate-container">
        <label>
          كلمة السر <span>*</span>
        </label>
        <div className="flex-center">
          <InputText
            value={password}
            onChange={(e) => {
              const val = e.target.value;
              setPassword(val);
              if (passvalidator.test(val)) {
                setPasswordError(false);
              } else {
                setPasswordError(true);
              }
            }}
            type="password"
            readOnly={false}
            className="modalInputs"
          />
          {password && !passwordError && (
            <div className="input-filled-icon">
              <img src={confirm} alt="confirmIcon" />
            </div>
          )}
        </div>
        {passwordError && password !== '' && (
          <p style={{ color: 'red', marginTop: '5px', fontSize: '11px' }}>
            كلمه السر يجب ان تكون مكونه علي الاقل من عشره احرف و لا تزيد عن عشرون حرف و تحتوي علي الاقل رقم و علامه
            مميزه (@ ,_,)و حرف كبير
          </p>
        )}
        <br></br>
        <br></br>
        <label>
          إعادة كتابة كلمة السر <span>*</span>
        </label>
        <div className="flex-center">
          <InputText
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            readOnly={false}
            className="modalInputs"
          />
          {nextStep && (
            <div className="input-filled-icon">
              <img src={confirm} alt="confirmIcon" />
            </div>
          )}
        </div>
        <br></br>
        <div className="flex-center">
          <div className="valid-account" style={{ color: `${activated ? '#136F3B' : '#969696'}` }}>
            حساب فعال
          </div>
          <Switch
            onChange={() => setActivated(!activated)}
            checked={activated}
            offHandleColor="#969696"
            activeBoxShadow="0 0 2px 3px #c8d0d4"
            uncheckedIcon={false}
            offColor="#ffffff"
            handleDiameter={14}
            height={22}
            width={50}
            className="switch-class"
          />
        </div>
      </div>
      <TwoButtons
        goBackStep={goBackStep}
        nextStep={nextStep}
        handleData={addUserHandler}
        data={{ userPass: password, isEnable: activated }}
        currentStep={currentStep}
      />
      <br />
      <br />
    </>
  );
};

export default UserAccessbility;
