import React, { useRef, useState, useEffect } from 'react';
import './modelclicked.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getManual, updateManualStatus } from '../../../redux/actions/manualManagementActions';
import ViewModel from './Modals/ViewModel/ViewModel';
import { downloadTenderFileWord } from '../../../redux/actions/tenderManagementActions';
import { Toast } from 'primereact/toast';

type Props = {
  locked: boolean;
  manual: any;
  isArchived: boolean;
  changeNamheHandler: any;
};

const BookClicked: React.FC<Props> = ({ locked, manual, isArchived, changeNamheHandler }) => {
  //let modelName = 'نموذج المشاريع الإنشائية الصناعية';
  const [viewManual, setViewManual] = useState(false);

  let history = useHistory();
  const toast = useRef<Toast | null>(null);

  const dispatch = useDispatch();
  const routeChange = () => {
    dispatch(getManual(manual.id, toast));
    history.push(`/edit-conditions-manual/${manual.tenderId}`);
  };
  const approveManual = () => {
    dispatch(updateManualStatus(2, manual, toast));
  };
  const archiveManual = () => {
    dispatch(updateManualStatus(3, manual, toast));
  };
  const underModificationManual = () => {
    dispatch(updateManualStatus(1, manual, toast));
  };

  const handleDownloadWord = () => {
    console.log(manual, '40');
    dispatch(downloadTenderFileWord(manual.tenderId, manual.title, toast));
  };

  return (
    <>
      <Toast ref={toast} position="top-left" />
      {isArchived ? (
        <div className="model-clicked-container-black">
          <h3>التعديل عن طريق ؟</h3>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setViewManual(true);
            }}
          >
            عرض الكراسة
          </button>
          <button onClick={underModificationManual}>تعديل حالة الكراسة </button>
          <div className="model-clicked-bottom">
            <div onClick={underModificationManual}>طلب تعديل</div>
            <div className="print-pdf" onClick={() => window.print()}>
              طباعه
            </div>
          </div>
        </div>
      ) : locked ? (
        <div className="model-clicked-container-green">
          <h3>التعديل عن طريق ؟</h3>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setViewManual(true);
            }}
          >
            عرض الكراسة
          </button>
          {/* <button
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadWord();
            }}
          >
            تحميل الكراسة
          </button> */}
          <button onClick={underModificationManual}>تعديل حالة الكراسة </button>
          <div className="model-clicked-bottom">
            <div onClick={underModificationManual}>طلب تعديل</div>
            <div className="print-pdf" onClick={() => window.print()}>
              طباعه
            </div>
          </div>
        </div>
      ) : (
        <div className="model-clicked-container-yellow">
          <h3>التعديل عن طريق ؟</h3>

          <button onClick={routeChange}> استمرار التعديل</button>

          <button onClick={approveManual}> اعتماد كراسة الشروط</button>
          <button onClick={changeNamheHandler}> تغير اسم الكراسة </button>
          <div className="model-clicked-bottom">
            <div onClick={archiveManual}>أرشفة</div>
            <div className="print-pdf" onClick={() => window.print()}>
              طباعه
            </div>
          </div>
        </div>
      )}
      {viewManual && (
        <ViewModel
          show={viewManual}
          hide={() => {
            setViewManual(false);
          }}
          model={manual}
        />
      )}
    </>
  );
};

export default BookClicked;
