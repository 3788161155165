import React, { useEffect } from 'react';
import '../createuser.scss';
import SysAllowComp from '../../UserInfo/SysAllowComp';
import TwoButtons from './TwoButtons';
import { User } from '../../../../models/User';

type Props = {
  addUserHandler: (data: any) => void;
  goBackStep: (step: number) => void;
  user: Partial<User>;
  currentStep: number;
  setUser: any;
};
export interface Isysallow {
  admModule: {
    moduleId: number;
    moduleNameA: string;
    moduleNameE: string;
    moduleShortname: string;
  };
  userType: number;
}

const SystemAcess: React.FC<Props> = ({ addUserHandler, user, setUser, currentStep, goBackStep }) => {
  const [arrayInputs, setArrayInputs] = React.useState<Isysallow[]>([]);

  useEffect(() => {
    if (user && user.admUserModules && user.admUserModules.length > 0) {
      setArrayInputs(user.admUserModules);
    }
  }, []);
  useEffect(() => {
    setUser({ ...user, admUserModules: arrayInputs });
  }, [arrayInputs]);

  return (
    <>
      <SysAllowComp setArrayInputs={setArrayInputs} arrayInputs={arrayInputs} user={user} createNewUser={true} />
      <TwoButtons
        goBackStep={goBackStep}
        handleData={addUserHandler}
        data={{ admUserModules: arrayInputs }}
        nextStep={true}
        currentStep={currentStep}
      />
    </>
  );
};

export default SystemAcess;
