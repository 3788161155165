import React from 'react';
import './recentlyopened.scss';

type Props = {
  first: any;
  second: any;
  third: any;
};

const BreadCrumb2: React.FC<Props> = ({ first, second, third }) => {
  return (
    <div className="breadcrumb2">
      <ul>
        <li> {first} </li>
        {second && (
          <>
            <li>{second}</li>
            <li>{third}</li>
          </>
        )}
      </ul>
    </div>
  );
};

export default BreadCrumb2;
