let timeoutTracker: any;
const timeout = 1800000;
// const timeout = 600000;

const updateExpiredTime = (onExpired: () => void) => {
  return () => {
    clearTimeout(timeoutTracker);
    if (timeoutTracker) {
      clearTimeout(timeoutTracker);
    }
    timeoutTracker = setTimeout(() => {
      onExpired();
    }, timeout);
  };
};

export const initIdleTimeOut = (onExpired: () => void) => {
  window.addEventListener('mousemove', updateExpiredTime(onExpired));
  window.addEventListener('scroll', updateExpiredTime(onExpired));
  window.addEventListener('keydown', updateExpiredTime(onExpired));
};

export const cleanUpTimeOut = (onExpired: () => void) => {
  clearTimeout(timeoutTracker);
  if (timeoutTracker) {
    clearTimeout(timeoutTracker);
  }
  window.removeEventListener('mousemove', updateExpiredTime(onExpired));
  window.removeEventListener('scroll', updateExpiredTime(onExpired));
  window.removeEventListener('keydown', updateExpiredTime(onExpired));
};
