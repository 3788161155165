import axios from 'axios';
import { manualServer } from '../redux/actions/manualManagementActions';
import { getStorageData } from '../utils/storage';

const baseURL = 'http://85.208.48.114:8070/api/tds/models';

export const modelServer = axios.create({ baseURL });

modelServer.interceptors.request.use((config) => {
  const token = getStorageData('token');
  const headers = config.headers || {};
  if (token && config.url !== '/users/login') {
    headers.Authorization = `JWT ${token}`;
  }
  config.headers = headers;
  return config;
});

export const updateManualContent = async (sectionId: number, content: string) => {
  try {
    await manualServer.patch(`/sections/${sectionId}`, { content: content });
  } catch (err) {
    console.log(err);
  }
};

export const deleteSection = async (id: number, newarr: any, modelID: number) => {
  try {
    await modelServer.delete(`/sections/${id}`);

    updateSectionsArray(newarr, modelID);
  } catch (err) {
    console.log(err);
  }
};
export const editNote = async (note: any, newnote: string) => {
  try {
    await modelServer.put(`/sections/note`, { ...note, noteText: newnote });
  } catch (err) {
    console.log(err);
  }
};
export const deleteNote = async (id: number) => {
  try {
    await modelServer.delete(`/sections/notes/${id}`);
  } catch (err) {
    console.log(err);
  }
};

export const updateSectionsArray = async (array: Array<any>, modelId: number) => {
  const orderedArray = handleOrder(array);

  try {
    await modelServer.put(`/${modelId}/sections`, orderedArray);
  } catch (err) {
    console.log(err);
  }
};

export const updateSectionModel = async (section: any, modelId: number) => {
  try {
    await modelServer.post(`/${modelId}/sections`, section);
  } catch (error) {
    console.log(error);
  }
};

export const handleOrder = (sections: Array<any>) => {
  const orderedSections = sections.map((section, i: number) => {
    let newsubSections = section.subSections.map((subsection: any, i: number) => {
      return {
        ...subsection,
        order: i,
      };
    });
    return {
      ...section,
      order: i,
      subSections: newsubSections,
    };
  });
  return orderedSections;
};
