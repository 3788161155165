export type ProjectType = 'current' | 'archived';

export interface Project {
  projectId?: number;
  projectCode: string;
  projectName: string;
  projectNameRural: string;
  projectDuration: number;
  brochuresNum: number;
  sectorCode: number;
  initiativeCode: number;
  sideCode: number;
  stageCode: number;
  typeCode: number;
  state: 1 | 2;
  prtsInitiativeCode: PrtsInitiativeCode | null;
  admSideCode: AdmSideCode | null;
  prtsLookupMainCodeByStageCode: PrtsLookupMainCodeBy | null;
  prtsLookupMainCodeByTypeCode: PrtsLookupMainCodeBy | null;
  prtsProjectDescription: PrtsProjectDescription | null;
  prtsProjectDocuments: PrtsProjectDocument[];
  prtsProjectEngineeringStudy: (PrtsProject & { engineeringId?: number }) | null;
  prtsProjectFinance: PrtsProjectFinance | null;
  prtsProjectNotes: PrtsProjectNote[];
  prtsProjectOfferingComponent: PrtsProjectOfferingComponent | null;
  prtsProjectPeople: PrtsProjectPeople[];
  prtsProjectProcedures: PrtsProjectProcedure[];
  prtsProjectPurchase: PrtsProjectPurchase | null;
  prtsProjectStatus: PrtsProjectStatus | null;
  prtsProjectTechnicalStudy: (PrtsProject & { technicalId?: number }) | null;
  prtsProjectTenderWriting: (PrtsProject & { writingId?: number }) | null;
  prtsProjectTiming: PrtsProjectTiming | null;
}

export interface PrtsProjectPeople {
  userRoleCode: number;
  userId?: number;
  isVisable: boolean;
}

export interface AdmSideCode {
  sideCode: number;
  sideName: string;
  sideLevelCode: number;
  parentSideCode: number;
  sideDesc: string;
}

export interface PrtsInitiativeCode {
  sectorCode: number;
  initiativeCode: number;
  initiativeName: string;
  initiativeShortName: string;
  initiativeOperationalExpense: number;
  initiativeCapitalExpenses: number;
  state: number;
}

export interface PrtsLookupMainCodeBy {
  lookupId: number;
  lookupType: number;
  lookupMainCode: number;
  lookupMainName: string;
  prtsLookupType?: PrtsLookupType;
}

export interface PrtsLookupType {
  lookupType: number;
  lookupTypeName: string;
}

export interface PrtsProjectDescription {
  descriptionId?: number;
  projectDesc: string;
  projectGoal: string;
  projectOutput: string;
  lookupId3: number;
  lookupId4: number;
  lookupId5: number;
  lookupId6: number;
  lookupId7: number;
  lookupId8: number;
  prtsLookupMainCodeByLookupId3: PrtsLookupMainCodeBy;
  prtsLookupMainCodeByLookupId4: PrtsLookupMainCodeBy;
  prtsLookupMainCodeByLookupId5: PrtsLookupMainCodeBy;
  prtsLookupMainCodeByLookupId6: PrtsLookupMainCodeBy;
  prtsLookupMainCodeByLookupId7: PrtsLookupMainCodeBy;
  prtsLookupMainCodeByLookupId8: PrtsLookupMainCodeBy;
}

export interface PrtsProjectDocument {
  projectId?: number;
  documentId?: number;
  userId: number;
  documentDesc: string;
  uploadDate: string;
  documentFile?: null;
  fileFullPath: string;
}

export interface PrtsProject {
  planStartDate?: string;
  actualStartDate?: string;
  planEndDate?: string;
  actualEndDate?: string;
  elapsedTimeRate?: number;
  completionRate?: number;
}

export interface PrtsProjectFinance {
  financeId?: number;
  projectSignedValue?: number;
  projectOperationalValue?: number;
  projectCapitalValue?: number;
  projectOperationalExpenses?: number;
  projectCapitalExpenses?: number;
}

export interface PrtsProjectNote {
  projectId?: number;
  noteId?: number;
  noteText: string;
  noteDate: string;
  userId: number;
  username?: string;
}

export interface PrtsProjectOfferingComponent {
  offeringId?: number;
  projectDocumentApproval: number;
  docPlandate: string;
  docActualdate: string;
  technicalStudyApproval: number;
  technicalPlandate: string;
  technicalActualdate: string;
  engineerStudyApproval: number;
  engineerPlandate: string;
  engineerActualdate: string;
  tenderApproval: number;
  tenderPlandate: string;
  tenderActualdate: string;
  landAllocation: number;
  landAllocatePlandate: string;
  landAllocateActualdate: string;
  programApproval: number;
  programPlandate: string;
  programActualdate: string;
  nationalDirectorApproval: number;
  nationalPlandate: string;
  nationalActualdate: string;
  ownerApproval: number;
  ownerPlandate: string;
  ownerActauldate: string;
}

export interface PrtsProjectProcedure {
  procedureId?: number;
  procedureTypeCode?: number;
  procedureText: string;
  procedureDate: string;
  userId: number;
}

export interface PrtsProjectPurchase {
  purchaseId?: number;
  purchaseOrderNum?: string;
  budgetReserveNum?: string;
  competitionNum?: string;
  awardNum?: string;
  secretLetterIssuedNum?: string;
  anotherNum?: string;
}

export interface PrtsProjectStatus {
  statusId?: number;
  lookupId9?: number;
  changeDate9?: string;
  userId?: number;
  lookupId10?: number;
  changeDate10?: string;
  userId10?: number;
  lookupId11?: number;
  changeDate11?: string;
  userId11?: number;
  lookupId12?: number;
  changeDate12?: string;
  userId12?: number;
  prtsLookupMainCodeByLookupId9?: PrtsLookupMainCodeBy;
  prtsLookupMainCodeByLookupId10?: PrtsLookupMainCodeBy;
  prtsLookupMainCodeByLookupId11?: PrtsLookupMainCodeBy;
  prtsLookupMainCodeByLookupId12?: PrtsLookupMainCodeBy;
}

export interface PrtsProjectTiming {
  timingId?: number;
  planOfferingDate?: string;
  actualOfferingDate?: string;
  planReceivingOffers?: string;
  actualReceivingOffers?: string;
  planConfidentialLetter?: string;
  actualConfidentialLetter?: string;
  planOpeningDate?: string;
  actualOpeningDate?: string;
  planDecisionDate?: string;
  actualDecisionDate?: string;
  planAwardDate?: string;
  actualAwardDate?: string;
  planContractDate?: string;
  actualContractDate?: string;
  planSiteReceiptDate?: string;
  actualSiteReceiptDate?: string;
}

export const addProjectsDefaultValue: Readonly<Project> = {
  projectCode: '',
  projectName: '',
  projectNameRural: '',
  projectDuration: 0,
  brochuresNum: 0,
  sectorCode: 0,
  initiativeCode: 0,
  sideCode: 0,
  stageCode: 0,
  typeCode: 0,
  prtsProjectDescription: null,
  prtsInitiativeCode: null,
  admSideCode: null,
  prtsLookupMainCodeByStageCode: null,
  prtsLookupMainCodeByTypeCode: null,
  prtsProjectDocuments: [],
  prtsProjectEngineeringStudy: null,
  prtsProjectFinance: null,
  prtsProjectNotes: [],
  prtsProjectOfferingComponent: null,
  prtsProjectPeople: [],
  prtsProjectProcedures: [],
  prtsProjectPurchase: null,
  prtsProjectStatus: null,
  prtsProjectTechnicalStudy: null,
  prtsProjectTenderWriting: null,
  prtsProjectTiming: null,
  state: 1,
};
