import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { FC, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clearImg from '../../../assets/png/clear.png';
import cancel from '../../../assets/svg/cancel.svg';
import arrowDown from '../../../assets/svg/filter-down-arrow.svg';
import filterImg from '../../../assets/svg/filter.svg';
import { offeringApprovalData } from '../../../constants/OfferingComponent';
import { AdmSideCode, PrtsLookupMainCodeBy } from '../../../models/add-project.model';
import { ActionType, StateData } from '../../../models/Filter.model';
import {
  // fetchAllRoles,
  // fetchAllUsers,
  fetchAllSideLevelCodes,
  fetchMainLookups,
  getAllInitiatives,
} from '../../../redux/actions/tenderManagementActions';
import { RootState } from '../../../redux/reducers';
import { addToDate, formatDate, getDateFromString } from '../../../utils/moment';
import { monthNavigatorTemplate, yearNavigatorTemplate } from '../../../utils/yearMonthTemplate';
import './Filter.scss';

const reducer = (
  state: any,
  {
    type,
    payload,
  }: {
    type: ActionType;
    payload:
      | string
      | number
      | { from: string | number; to: string | number }
      | { userRoleCode: number; userId: number }[];
  },
) => {
  switch (type) {
    case 'projectCode':
      return { ...state, projectCode: payload };
    case 'projectName':
      return { ...state, projectName: payload };
    case 'projectNameRural':
      return { ...state, projectNameRural: payload };
    case 'projectDuration':
      return { ...state, projectDuration: payload > 0 ? +payload : '' };
    case 'initiativeCode':
      return { ...state, initiativeCode: payload > 0 ? +payload : '' };
    case 'stageCode':
      return { ...state, stageCode: payload > 0 ? +payload : '' };
    case 'typeCode':
      return { ...state, typeCode: payload > 0 ? +payload : '' };
    case 'sideCode':
      return { ...state, sideCode: payload > 0 ? +payload : '' };
    case 'lookupId3':
      return { ...state, lookupId3: payload > 0 ? +payload : '' };
    case 'lookupId4':
      return { ...state, lookupId4: payload > 0 ? +payload : '' };
    case 'lookupId5':
      return { ...state, lookupId5: payload > 0 ? +payload : '' };
    case 'lookupId6':
      return { ...state, lookupId6: payload > 0 ? +payload : '' };
    case 'lookupId7':
      return { ...state, lookupId7: payload > 0 ? +payload : '' };
    case 'lookupId8':
      return { ...state, lookupId8: payload > 0 ? +payload : '' };
    case 'lookupId9':
      return { ...state, lookupId9: payload > 0 ? +payload : '' };
    case 'lookupId10':
      return { ...state, lookupId10: payload > 0 ? +payload : '' };
    case 'lookupId11':
      return { ...state, lookupId11: payload > 0 ? +payload : '' };
    case 'lookupId12':
      return { ...state, lookupId12: payload > 0 ? +payload : '' };
    case 'projectSignedValue':
      return { ...state, projectSignedValue: payload };
    case 'projectOperationalValue':
      return { ...state, projectOperationalValue: payload };
    case 'projectCapitalValue':
      return { ...state, projectCapitalValue: payload };
    case 'projectOperationalExpenses':
      return { ...state, projectOperationalExpenses: payload };
    case 'projectCapitalExpenses':
      return { ...state, projectCapitalExpenses: payload };
    case 'purchaseOrderNum':
      return { ...state, purchaseOrderNum: payload };
    case 'budgetReserveNum':
      return { ...state, budgetReserveNum: payload };
    case 'competitionNum':
      return { ...state, competitionNum: payload };
    case 'awardNum':
      return { ...state, awardNum: payload };
    case 'secretLetterIssuedNum':
      return { ...state, secretLetterIssuedNum: payload };
    case 'anotherNum':
      return { ...state, anotherNum: payload };
    case 'projectDocumentApproval':
      return { ...state, projectDocumentApproval: payload > 0 ? +payload : '' };
    case 'technicalStudyApproval':
      return { ...state, technicalStudyApproval: payload > 0 ? +payload : '' };
    case 'engineerStudyApproval':
      return { ...state, engineerStudyApproval: payload > 0 ? +payload : '' };
    case 'tenderApproval':
      return { ...state, tenderApproval: payload > 0 ? +payload : '' };
    case 'landAllocation':
      return { ...state, landAllocation: payload > 0 ? +payload : '' };
    case 'programApproval':
      return { ...state, programApproval: payload > 0 ? +payload : '' };
    case 'nationalDirectorApproval':
      return { ...state, nationalDirectorApproval: payload > 0 ? +payload : '' };
    case 'ownerApproval':
      return { ...state, ownerApproval: payload > 0 ? +payload : '' };
    case 'planOfferingDate':
      return { ...state, planOfferingDate: payload };
    case 'actualOfferingDate':
      return { ...state, actualOfferingDate: payload };
    case 'planReceivingOffers':
      return { ...state, planReceivingOffers: payload };
    case 'actualReceivingOffers':
      return { ...state, actualReceivingOffers: payload };
    case 'planConfidentialLetter':
      return { ...state, planConfidentialLetter: payload };
    case 'actualConfidentialLetter':
      return { ...state, actualConfidentialLetter: payload };
    case 'planOpeningDate':
      return { ...state, planOpeningDate: payload };
    case 'actualOpeningDate':
      return { ...state, actualOpeningDate: payload };
    case 'planDecisionDate':
      return { ...state, planDecisionDate: payload };
    case 'actualDecisionDate':
      return { ...state, actualDecisionDate: payload };
    case 'planAwardDate':
      return { ...state, planAwardDate: payload };
    case 'actualAwardDate':
      return { ...state, actualAwardDate: payload };
    case 'planContractDate':
      return { ...state, planContractDate: payload };
    case 'actualContractDate':
      return { ...state, actualContractDate: payload };
    case 'planSiteReceiptDate':
      return { ...state, planSiteReceiptDate: payload };
    case 'actualSiteReceiptDate':
      return { ...state, actualSiteReceiptDate: payload };
    case 'prtsProjectPeople':
      return { ...state, prtsProjectPeople: payload };
    case 'resetFilter':
      return {};
    default:
      return state;
  }
};

const CalComponent: FC<{
  value: Date;
  type: ActionType;
  placeHolder: string;
  isFrom: boolean;
  anotherValue: string;
  reducerDispatch: React.Dispatch<{
    type: ActionType;
    payload:
      | string
      | number
      | {
          from: string | number;
          to: string | number;
        };
  }>;
}> = ({ reducerDispatch, value, placeHolder, type, isFrom, anotherValue }) => {
  return (
    <Calendar
      value={value}
      disabled={!isFrom && !!!anotherValue}
      minDate={!isFrom ? addToDate(getDateFromString(anotherValue), 1) : undefined}
      onChange={(e) =>
        reducerDispatch({
          type,
          payload: {
            from: isFrom ? formatDate(e.value as Date) : anotherValue,
            to: !isFrom ? formatDate(e.value as Date) : anotherValue,
          },
        })
      }
      monthNavigator
      yearNavigator
      dateFormat="yy-mm-dd"
      placeholder={placeHolder}
      yearRange={`${(new Date().getFullYear() - 10).toString()}:${(new Date().getFullYear() + 10).toString()}`}
      monthNavigatorTemplate={monthNavigatorTemplate}
      yearNavigatorTemplate={yearNavigatorTemplate}
    />
  );
};

const Filter: React.FC<{
  toggleFilter: boolean;
  searchProjectHandler: (payload: any) => void;
  toggleFilterHandler: () => void;
}> = ({ searchProjectHandler, toggleFilter, toggleFilterHandler }) => {
  const dispatch = useDispatch();
  const allInitiatives = useSelector((state: RootState) => state.tenderManagement.initiatives);
  const mainLookups = useSelector((state: RootState) => state.tenderManagement.mainLookups);
  const sideLevelCodes = useSelector((state: RootState) => state.tenderManagement.sideLevelCodes);
  // const roles = useSelector((state: RootState) => state.tenderManagement.roles);
  // const users = useSelector((state: RootState) => state.tenderManagement.users);

  // people data
  // const [projectPeople, setProjectPeople] = useState<{
  //   [key: number]: {
  //     userRoleCode: number;
  //     userId: number;
  //   };
  // }>({});

  const [mainLookupStage, setMainLookupStage] = useState<any[]>([]);
  const [mainLookupCode, setMainLookupCode] = useState<any[]>([]);

  const [mainSides, setMainSides] = useState<AdmSideCode[]>([]);
  const [subSides1, setSubSides1] = useState<AdmSideCode[]>([]);
  const [subSides2, setSubSides2] = useState<AdmSideCode[]>([]);
  const [subSides3, setSubSides3] = useState<AdmSideCode[]>([]);

  const [selectedMainSide, setSelectedMainSide] = useState<number>();
  const [selectedSubSide1, setSelectedSubSide1] = useState<number>();
  const [selectedSubSide2, setSelectedSubSide2] = useState<number>();
  const [selectedSubSide3, setSelectedSubSide3] = useState<number>();

  const [mainLookupStatus3, setMainLookupStatus3] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus4, setMainLookupStatus4] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus5, setMainLookupStatus5] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus6, setMainLookupStatus6] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus7, setMainLookupStatus7] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus8, setMainLookupStatus8] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus9, setMainLookupStatus9] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus10, setMainLookupStatus10] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus11, setMainLookupStatus11] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus12, setMainLookupStatus12] = useState<PrtsLookupMainCodeBy[]>([]);

  const [basics, setBasics] = useState(false);
  const [financial, setFinancial] = useState(false);
  const [timers, setTimers] = useState(false);
  const [perches, setPerches] = useState(false);
  const [launching, setLaunching] = useState(false);
  const [persons, setPersons] = useState(false);
  // const [filter, setFilter] = useState(toggleFilter);
  const [reducerState, reducerDispatch] = useReducer(reducer, {});

  const toast = useRef<Toast | null>(null);

  useEffect(() => {
    dispatch(getAllInitiatives(toast));
    dispatch(fetchMainLookups(toast));
    // dispatch(fetchAllUsers(toast));
    // dispatch(fetchAllRoles(toast));
    dispatch(fetchAllSideLevelCodes(toast));
  }, [dispatch]);

  // admsidecodes
  useEffect(() => {
    if (sideLevelCodes.length === 0) return;
    const admSideCodes = sideLevelCodes[0].admSideCodes;
    setMainSides([...admSideCodes]);
  }, [sideLevelCodes]);

  // lookups
  useEffect(() => {
    if (mainLookups.length === 0) return;
    // eslint-disable-next-line
    mainLookups.map((mainLookup) => {
      // stage
      if (mainLookup.lookupType === 1) {
        setMainLookupStage(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 2) {
        // type
        setMainLookupCode(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 3) {
        // المنطقة الغرافية
        setMainLookupStatus3(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 4) {
        // تصنيف الأهمية
        setMainLookupStatus4(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 5) {
        // الصفة 3
        setMainLookupStatus5(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 6) {
        // الصفة 4
        setMainLookupStatus6(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 7) {
        // الصفة 5
        setMainLookupStatus7(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 8) {
        // الصفة 6
        setMainLookupStatus8(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 9) {
        // الحالة الراهنة 1
        setMainLookupStatus9(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 10) {
        // الحالة الراهنة 2
        setMainLookupStatus10(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 11) {
        // الحالة الراهنة 3
        setMainLookupStatus11(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 12) {
        // الحالة الراهنة 4
        setMainLookupStatus12(mainLookup.prtsLookupMainCodes);
      }
    });
  }, [mainLookups]);

  const checkDataBeforeSendApiCall = (data: Record<string, { from: number | string; to: number | string }>) => {
    const values = Object.values(data);
    for (let item of values) {
      if (item) {
        const { from, to } = item;
        if (!to || to <= from) {
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    const {
      projectSignedValue,
      projectOperationalValue,
      projectCapitalValue,
      projectOperationalExpenses,
      projectCapitalExpenses,
      planOfferingDate,
      actualOfferingDate,
      planReceivingOffers,
      actualReceivingOffers,
      planConfidentialLetter,
      actualConfidentialLetter,
      planOpeningDate,
      actualOpeningDate,
      planDecisionDate,
      actualDecisionDate,
      planAwardDate,
      actualAwardDate,
      planContractDate,
      actualContractDate,
      planSiteReceiptDate,
      actualSiteReceiptDate,
    } = reducerState as StateData;
    const dataChecked = checkDataBeforeSendApiCall({
      projectSignedValue,
      projectOperationalValue,
      projectCapitalValue,
      projectOperationalExpenses,
      projectCapitalExpenses,
      planOfferingDate,
      actualOfferingDate,
      planReceivingOffers,
      actualReceivingOffers,
      planConfidentialLetter,
      actualConfidentialLetter,
      planOpeningDate,
      actualOpeningDate,
      planDecisionDate,
      actualDecisionDate,
      planAwardDate,
      actualAwardDate,
      planContractDate,
      actualContractDate,
      planSiteReceiptDate,
      actualSiteReceiptDate,
    });

    if (!dataChecked) return;
    //
    searchProjectHandler(reducerState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducerState]);

  const toggleHandler = (type: string) => {
    if (type === 'basics') {
      setBasics(!basics);
    } else if (type === 'financial') {
      setFinancial(!financial);
    } else if (type === 'timers') {
      setTimers(!timers);
    } else if (type === 'perches') {
      setPerches(!perches);
    } else if (type === 'launching') {
      setLaunching(!launching);
    } else if (type === 'persons') {
      setPersons(!persons);
    }
  };

  const admSideCodesHandler = (level: 10 | 20 | 30 | 40, value: number) => {
    reducerDispatch({ type: 'sideCode', payload: value });
    if (level === 10) {
      setSelectedMainSide(value);
      const admSideCodes1 = sideLevelCodes[1].admSideCodes.filter((ele) => ele.parentSideCode === value);
      setSubSides1(admSideCodes1);
      setSubSides2([]);
      setSubSides3([]);
      setSelectedSubSide1(undefined);
      setSelectedSubSide2(undefined);
      setSelectedSubSide3(undefined);
    } else if (level === 20) {
      setSelectedSubSide1(value);
      const admSideCodes2 = sideLevelCodes[2].admSideCodes.filter((ele) => ele.parentSideCode === value);
      setSubSides2(admSideCodes2);
      setSubSides3([]);
      setSelectedSubSide2(undefined);
      setSelectedSubSide3(undefined);
    } else if (level === 30) {
      setSelectedSubSide2(value);
      const admSideCodes3 = sideLevelCodes[3].admSideCodes.filter((ele) => ele.parentSideCode === value);
      setSubSides3(admSideCodes3);

      setSelectedSubSide3(undefined);
    } else if (level === 40) {
      setSelectedSubSide3(value);
    }
  };

  const formatDateHandler = (date: string) => {
    return getDateFromString(date);
  };

  // people handler
  // const peopleHandler = (userRoleCode: number, userId: number) => {
  //   let peopleData = { ...projectPeople };
  //   peopleData[userRoleCode] = { userRoleCode, userId };
  //   setProjectPeople({ ...peopleData });
  //   reducerDispatch({
  //     type: 'prtsProjectPeople',
  //     payload: Object.values(peopleData) || [],
  //   });
  // };

  return (
    <div className={`filter ${toggleFilter ? 'show-filter' : 'hide-filter'}`}>
      <Toast ref={toast} position="top-left" />
      <div className="filter-title">
        <span>
          <button className="close-icon" onClick={toggleFilterHandler}>
            <img width="16px" src={cancel} alt="cancel-icon" />
          </button>
          <span style={{ color: '#deac43' }}>فرز</span>
        </span>
        <div>
          <img
            title="ازالة"
            style={{ margin: '0 20px', width: '20px', cursor: 'pointer' }}
            src={clearImg}
            alt="filter"
            onClick={() => {
              if (Object.keys(reducerState).length === 0) return;
              reducerDispatch({ type: 'resetFilter', payload: '' });
              // setProjectPeople({});
              setSelectedMainSide(0);
              setSelectedSubSide1(0);
              setSelectedSubSide2(0);
              setSelectedSubSide3(0);
            }}
          />
          <img style={{ marginLeft: '8px', width: '20px' }} src={filterImg} alt="filter" />
        </div>
      </div>
      <div className="filter-content">
        {/* البيانات الاساسية */}
        <div className="basics">
          <div className="basics-title-parent" onClick={() => toggleHandler('basics')}>
            <div className="basics-title" style={{ textAlign: 'center', padding: '4px 0' }}>
              <span style={{ fontSize: '14px' }}>البيانات الاساسية</span>
              <img style={{ marginRight: '8px' }} width="10px" src={arrowDown} alt="arrow-down" />
            </div>
          </div>
          {basics && (
            <div className="financials-content">
              <div className="filter-content-item">
                <label>كود المشروع</label>
                <InputText
                  type="text"
                  name="projectCode"
                  className="input-prime"
                  value={reducerState.projectCode || ''}
                  onChange={(e) => {
                    reducerDispatch({ type: 'projectCode', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>مسمى المشروع</label>
                <InputText
                  type="text"
                  name="projectName"
                  className="input-prime"
                  value={reducerState.projectName || ''}
                  onChange={(e) => {
                    reducerDispatch({ type: 'projectName', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>مسمى المشروع فى التنمية الريفية</label>
                <InputText
                  type="text"
                  name="projectNameRural"
                  className="input-prime"
                  value={reducerState.projectNameRural || ''}
                  onChange={(e) => {
                    reducerDispatch({ type: 'projectNameRural', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>مدة المشروع بالأشهر</label>
                <InputText
                  type="number"
                  name="projectDuration"
                  className="input-prime"
                  value={reducerState.projectDuration || ''}
                  onChange={(e) => {
                    reducerDispatch({ type: 'projectDuration', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>المبادرة</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="initiativeShortName"
                  name="initiativeCode"
                  optionValue="initiativeCode"
                  value={reducerState.initiativeCode || ''}
                  options={allInitiatives}
                  onChange={(e) => {
                    reducerDispatch({ type: 'initiativeCode', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>المرحلة</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="stageCode"
                  value={reducerState.stageCode}
                  options={mainLookupStage}
                  onChange={(e) => {
                    reducerDispatch({ type: 'stageCode', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>النوع (تشغيلي/رأسمالي)</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="typeCode"
                  value={reducerState.typeCode || ''}
                  options={mainLookupCode}
                  onChange={(e) => {
                    reducerDispatch({ type: 'typeCode', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الجهة الفنية الأساسية</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="sideName"
                  optionValue="sideCode"
                  name="mainSideCode"
                  value={selectedMainSide || ''}
                  options={mainSides}
                  onChange={(e) => {
                    admSideCodesHandler(10, e.target.value);
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الجهة الفنية الفرعية 1</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="sideName"
                  optionValue="sideCode"
                  name="subSideCode1"
                  value={selectedSubSide1 || ''}
                  options={subSides1}
                  onChange={(e) => {
                    admSideCodesHandler(20, e.target.value);
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الجهة الفنية الفرعية 2</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="sideName"
                  optionValue="sideCode"
                  name="subSideCode2"
                  value={selectedSubSide2 || ''}
                  options={subSides2}
                  onChange={(e) => {
                    admSideCodesHandler(30, e.target.value);
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الجهة الفنية الفرعية 3</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="sideName"
                  optionValue="sideCode"
                  name="subSideCode3"
                  value={selectedSubSide3 || ''}
                  options={subSides3}
                  onChange={(e) => {
                    admSideCodesHandler(40, e.target.value);
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>المنطقة الجغرافية</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId3"
                  value={reducerState.lookupId3 || ''}
                  options={mainLookupStatus3}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId3', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>تصنيف الأهمية</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId4"
                  value={reducerState.lookupId4 || ''}
                  options={mainLookupStatus4}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId4', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الصفة 3</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId5"
                  value={reducerState.lookupId5 || ''}
                  options={mainLookupStatus5}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId5', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الصفة 4</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId6"
                  value={reducerState.lookupId6 || ''}
                  options={mainLookupStatus6}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId6', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الصفة 5</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId7"
                  value={reducerState.lookupId7 || ''}
                  options={mainLookupStatus7}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId7', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الصفة 6</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId8"
                  value={reducerState.lookupId8 || ''}
                  options={mainLookupStatus8}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId8', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>حالة الطرح</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId9"
                  value={reducerState.lookupId9 || ''}
                  options={mainLookupStatus9}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId9', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الحالة الراهنة 2</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId10"
                  value={reducerState.lookupId10 || ''}
                  options={mainLookupStatus10}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId10', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الحالة الراهنة 3</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId11"
                  value={reducerState.lookupId11 || ''}
                  options={mainLookupStatus11}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId11', payload: e.target.value });
                  }}
                />
              </div>
              <div className="filter-content-item">
                <label>الحالة الراهنة 4</label>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="lookupMainName"
                  optionValue="lookupId"
                  name="lookupId12"
                  value={reducerState.lookupId12 || ''}
                  options={mainLookupStatus12}
                  onChange={(e) => {
                    reducerDispatch({ type: 'lookupId12', payload: e.target.value });
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* الماليات */}
        <div className="financials">
          <div className="financial-title-parent" onClick={() => toggleHandler('financial')}>
            <div className="financials-title" style={{ textAlign: 'center', padding: '4px 0' }}>
              <span style={{ fontSize: '14px' }}>الماليات</span>
              <img style={{ marginRight: '8px' }} width="10px" src={arrowDown} alt="arrow-down" />
            </div>
          </div>
          {financial && (
            <div className="financials-content">
              <div className="financials-title">
                <span className="financials-title-span">قيمة المشروع المتوقعة</span>
                <InputText
                  type="number"
                  min={1}
                  value={reducerState?.projectSignedValue?.from || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectSignedValue',
                      payload: {
                        from: +e.target.value,
                        to: reducerState?.projectSignedValue?.to || '',
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="من"
                />
                <InputText
                  type="number"
                  min={reducerState?.projectSignedValue?.from ? reducerState?.projectSignedValue?.from + 1 : 1}
                  disabled={!reducerState?.projectSignedValue?.from}
                  value={reducerState?.projectSignedValue?.to || ''}
                  onChange={(e) => {
                    // const val = +e.target.value;
                    // if (val <= +reducerState?.projectSignedValue?.from) return;
                    reducerDispatch({
                      type: 'projectSignedValue',
                      payload: {
                        from: reducerState?.projectSignedValue?.from || '',
                        to: +e.target.value,
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="الى"
                />
              </div>
              <div className="financials-title">
                <span className="financials-title-span">قيمة بند المشروع التشغيلية</span>
                <InputText
                  type="number"
                  min={1}
                  value={reducerState?.projectOperationalValue?.from || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectOperationalValue',
                      payload: {
                        from: +e.target.value,
                        to: reducerState?.projectOperationalValue?.to || '',
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="من"
                />
                <InputText
                  type="number"
                  min={
                    reducerState?.projectOperationalValue?.from ? reducerState?.projectOperationalValue?.from + 1 : 1
                  }
                  disabled={!reducerState?.projectOperationalValue?.from}
                  value={reducerState?.projectOperationalValue?.to || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectOperationalValue',
                      payload: {
                        from: reducerState?.projectOperationalValue?.from || '',
                        to: +e.target.value,
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="الى"
                />
              </div>
              <div className="financials-title">
                <span className="financials-title-span">قيمة بند المشروع الرآسمالية</span>
                <InputText
                  type="number"
                  min={1}
                  value={reducerState?.projectCapitalValue?.from || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectCapitalValue',
                      payload: {
                        from: +e.target.value,
                        to: reducerState?.projectCapitalValue?.to || '',
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="من"
                />
                <InputText
                  type="number"
                  min={reducerState?.projectCapitalValue?.from ? reducerState?.projectCapitalValue?.from + 1 : 1}
                  disabled={!reducerState?.projectCapitalValue?.from}
                  value={reducerState?.projectCapitalValue?.to || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectCapitalValue',
                      payload: {
                        from: reducerState?.projectCapitalValue?.from || '',
                        to: +e.target.value,
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="الى"
                />
              </div>
              <div className="financials-title">
                <span className="financials-title-span">المصاريف التشغيلية</span>
                <InputText
                  type="number"
                  min={1}
                  value={reducerState?.projectOperationalExpenses?.from || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectOperationalExpenses',
                      payload: {
                        from: +e.target.value,
                        to: reducerState?.projectOperationalExpenses?.to || '',
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="من"
                />
                <InputText
                  type="number"
                  min={
                    reducerState?.projectOperationalExpenses?.from
                      ? reducerState?.projectOperationalExpenses?.from + 1
                      : 1
                  }
                  disabled={!reducerState?.projectOperationalExpenses?.from}
                  value={reducerState?.projectOperationalExpenses?.to || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectOperationalExpenses',
                      payload: {
                        from: reducerState?.projectOperationalExpenses?.from || '',
                        to: +e.target.value,
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="الى"
                />
              </div>
              <div className="financials-title">
                <span className="financials-title-span"> المصاريف الرآسمالية</span>
                <InputText
                  type="number"
                  min={1}
                  value={reducerState?.projectCapitalExpenses?.from || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectCapitalExpenses',
                      payload: {
                        from: +e.target.value,
                        to: reducerState?.projectCapitalExpenses?.to || '',
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="من"
                />
                <InputText
                  type="number"
                  min={reducerState?.projectCapitalExpenses?.from ? reducerState?.projectCapitalExpenses?.from + 1 : 1}
                  disabled={!reducerState?.projectCapitalExpenses?.from}
                  value={reducerState?.projectCapitalExpenses?.to || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectCapitalExpenses',
                      payload: {
                        from: reducerState?.projectCapitalExpenses?.from || '',
                        to: +e.target.value,
                      },
                    });
                  }}
                  className="input-prime"
                  placeholder="الى"
                />
              </div>
            </div>
          )}
        </div>
        {/* التوقيتات */}
        <div className="timers">
          <div className="timers-title-parent" onClick={() => toggleHandler('timers')}>
            <div className="timers-title" style={{ textAlign: 'center', padding: '4px 0' }}>
              <span style={{ fontSize: '14px' }}>التوقيتات</span>
              <img style={{ marginRight: '8px' }} width="10px" src={arrowDown} alt="arrow-down" />
            </div>
          </div>
          {timers && (
            <div className="timers-content">
              <div className="timers-title">
                <span className="timers-title-span">تاريخ الطرح المخطط</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planOfferingDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.planOfferingDate?.to || ''}
                  type="planOfferingDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planOfferingDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.planOfferingDate?.from || ''}
                  type="planOfferingDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ الطرح الفعلى</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualOfferingDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.actualOfferingDate?.to || ''}
                  type="actualOfferingDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualOfferingDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.actualOfferingDate?.from || ''}
                  type="actualOfferingDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">آخر تاريخ لتلقي العروض المخطط</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planReceivingOffers?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.planReceivingOffers?.to || ''}
                  type="planReceivingOffers"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planReceivingOffers?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.planReceivingOffers?.from || ''}
                  type="planReceivingOffers"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">آخر تاريخ لتلقي العروض الفعلى</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualReceivingOffers?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.actualReceivingOffers?.to || ''}
                  type="actualReceivingOffers"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualReceivingOffers?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.actualReceivingOffers?.from || ''}
                  type="actualReceivingOffers"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ فتح المظاريف المخطط</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planConfidentialLetter?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.planConfidentialLetter?.to || ''}
                  type="planConfidentialLetter"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planConfidentialLetter?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.planConfidentialLetter?.from || ''}
                  type="planConfidentialLetter"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ فتح المظاريف الفعلى</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualConfidentialLetter?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.actualConfidentialLetter?.to || ''}
                  type="actualConfidentialLetter"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualConfidentialLetter?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.actualConfidentialLetter?.from || ''}
                  type="actualConfidentialLetter"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ البت والترسية المخطط</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planDecisionDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.planDecisionDate?.to || ''}
                  type="planDecisionDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planDecisionDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.planDecisionDate?.from || ''}
                  type="planDecisionDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ البت والترسية الفغلى</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualDecisionDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.actualDecisionDate?.to || ''}
                  type="actualDecisionDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualDecisionDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.actualDecisionDate?.from || ''}
                  type="actualDecisionDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ التعميد المخطط</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planAwardDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.planAwardDate?.to || ''}
                  type="planAwardDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planAwardDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.planAwardDate?.from || ''}
                  type="planAwardDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ التعميد الفعلى </span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualAwardDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.actualAwardDate?.to || ''}
                  type="actualAwardDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualAwardDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.actualAwardDate?.from || ''}
                  type="actualAwardDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ التعاقد المخطط</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planContractDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.planContractDate?.to || ''}
                  type="planContractDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planContractDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.planContractDate?.from || ''}
                  type="planContractDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ التعاقد الفعلى</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualContractDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.actualContractDate?.to || ''}
                  type="actualContractDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualContractDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.actualContractDate?.from || ''}
                  type="actualContractDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ استلام الموقع المخطط</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planSiteReceiptDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.planSiteReceiptDate?.to || ''}
                  type="planSiteReceiptDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.planSiteReceiptDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.planSiteReceiptDate?.from || ''}
                  type="planSiteReceiptDate"
                  placeHolder="الي"
                />
              </div>
              <div className="timers-title">
                <span className="timers-title-span">تاريخ استلام الموقع الفعلى</span>
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualSiteReceiptDate?.from || '')}
                  isFrom={true}
                  anotherValue={reducerState?.actualSiteReceiptDate?.to || ''}
                  type="actualSiteReceiptDate"
                  placeHolder="من"
                />
                <CalComponent
                  reducerDispatch={reducerDispatch}
                  value={formatDateHandler(reducerState?.actualSiteReceiptDate?.to || '')}
                  isFrom={false}
                  anotherValue={reducerState?.actualSiteReceiptDate?.from || ''}
                  type="actualSiteReceiptDate"
                  placeHolder="الي"
                />
              </div>
            </div>
          )}
        </div>
        {/* المشتريات */}
        <div className="purches-info">
          <div className="purches-info-title-parent" onClick={() => toggleHandler('perches')}>
            <div className="purches-info-title" style={{ textAlign: 'center', padding: '4px 0' }}>
              <span style={{ fontSize: '14px' }}>بيانات المشتريات</span>
              <img style={{ marginRight: '8px' }} width="10px" src={arrowDown} alt="arrow-down" />
            </div>
          </div>
          {perches && (
            <div className="purches-info-content">
              <div className="purches-info-title">
                <span className="purches-info-title-span">رقم طلب الشراء</span>
                <InputText
                  type="text"
                  className="input-prime"
                  placeholder=""
                  value={reducerState.purchaseOrderNum || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'purchaseOrderNum',
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="purches-info-title">
                <span className="purches-info-title-span">رقم الحجز من الميزانية</span>
                <InputText
                  type="text"
                  className="input-prime"
                  placeholder=""
                  value={reducerState.budgetReserveNum || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'budgetReserveNum',
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="purches-info-title">
                <span className="purches-info-title-span">رقم المنافسة</span>
                <InputText
                  type="text"
                  className="input-prime"
                  placeholder=""
                  value={reducerState.competitionNum || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'competitionNum',
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="purches-info-title">
                <span className="purches-info-title-span"> رقم التعميد</span>
                <InputText
                  type="text"
                  className="input-prime"
                  placeholder=""
                  value={reducerState.awardNum || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'awardNum',
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="purches-info-title">
                <span className="purches-info-title-span">رقم صادر الخطاب السرى</span>
                <InputText
                  type="text"
                  className="input-prime"
                  placeholder=""
                  value={reducerState.secretLetterIssuedNum || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'secretLetterIssuedNum',
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="purches-info-title">
                <span className="purches-info-title-span">رقم أخر</span>
                <InputText
                  type="text"
                  className="input-prime"
                  placeholder=""
                  value={reducerState.anotherNum || ''}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'anotherNum',
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* مكونات الطرح */}
        <div className="launching-info">
          <div className="launching-info-title-parent" onClick={() => toggleHandler('launching')}>
            <div className="launching-info-title" style={{ textAlign: 'center', padding: '4px 0' }}>
              <span style={{ fontSize: '14px' }}>مكونات الطرح</span>
              <img style={{ marginRight: '8px' }} width="10px" src={arrowDown} alt="arrow-down" />
            </div>
          </div>
          {launching && (
            <div className="launching-info-content">
              <div className="launching-info-title">
                <span className="launching-info-title-span">اعتماد وثيقه المشروع</span>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="name"
                  optionValue="id"
                  value={reducerState.projectDocumentApproval || ''}
                  options={offeringApprovalData}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'projectDocumentApproval',
                      payload: +e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </div>
              <div className="launching-info-title">
                <span className="launching-info-title-span">الدراسات الفنية</span>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="name"
                  optionValue="id"
                  value={reducerState.technicalStudyApproval || ''}
                  options={offeringApprovalData}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'technicalStudyApproval',
                      payload: +e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </div>
              <div className="launching-info-title">
                <span className="launching-info-title-span">الدراسات الهندسية</span>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="name"
                  optionValue="id"
                  value={reducerState.engineerStudyApproval || ''}
                  options={offeringApprovalData}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'engineerStudyApproval',
                      payload: +e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </div>
              <div className="launching-info-title">
                <span className="launching-info-title-span">كراسة الشروط والمناقصات</span>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="name"
                  optionValue="id"
                  value={reducerState.tenderApproval || ''}
                  options={offeringApprovalData}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'tenderApproval',
                      payload: +e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </div>
              <div className="launching-info-title">
                <span className="launching-info-title-span">تخصيص الأرض</span>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="name"
                  optionValue="id"
                  value={reducerState.landAllocation || ''}
                  options={offeringApprovalData}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'landAllocation',
                      payload: +e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </div>
              <div className="launching-info-title">
                <span className="launching-info-title-span">اعتماد البرنامج</span>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="name"
                  optionValue="id"
                  value={reducerState.programApproval || ''}
                  options={offeringApprovalData}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'programApproval',
                      payload: +e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </div>
              <div className="launching-info-title">
                <span className="launching-info-title-span">اعتماد المدير الوطنى</span>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="name"
                  optionValue="id"
                  value={reducerState.nationalDirectorApproval || ''}
                  options={offeringApprovalData}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'nationalDirectorApproval',
                      payload: +e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </div>
              <div className="launching-info-title">
                <span className="launching-info-title-span">اعتماد الجهة المالكة</span>
                <Dropdown
                  emptyMessage="لا توجد بيانات"
                  optionLabel="name"
                  optionValue="id"
                  value={reducerState.ownerApproval || ''}
                  options={offeringApprovalData}
                  onChange={(e) => {
                    reducerDispatch({
                      type: 'ownerApproval',
                      payload: +e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </div>
            </div>
          )}
        </div>
        {/* الاشخاص */}
        {/* <div className="persons">
          <div className="persons-title-parent" onClick={() => toggleHandler('persons')}>
            <div className="persons-title" style={{ textAlign: 'center', padding: '4px 0' }}>
              <span style={{ fontSize: '14px' }}>الأشخاص</span>
              <img style={{ marginRight: '8px' }} width="10px" src={arrowDown} alt="arrow-down" />
            </div>
          </div>
          {persons && (
            <div className="persons-content">
              {roles &&
                roles.map((role) => (
                  <div key={role.userRoleCode} className="persons-title">
                    <span className="persons-title-span">{role.userRoleName}</span>
                    <Dropdown  emptyMessage="لا توجد بيانات"
                      optionLabel="userName"
                      optionValue="userId"
                      value={projectPeople[role.userRoleCode]?.userId ? projectPeople[role.userRoleCode].userId : 0}
                      options={users}
                      onChange={(e) => {
                        peopleHandler(role.userRoleCode, e.value);
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Filter;
