import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import './Panel.scss';
import BarChart from './BarChart';
import SwitchCharts from './SwitchCharts';
import DoughnutChart from './DoughnutChart';
import LineChart from './LineChart';
import DataTablePanel from './DataTablePanel';
import { displayData } from '../../../models/Analysis';

const Panel: FC = () => {
  const { viewType } = useParams<{ viewType?: any }>();
  const [dataToBeDisplayed, setDataToBeDisplayed] = useState<displayData>({ labels: [], datasets: [] });
  const [chartData, setChartData] = useState<displayData>({ labels: [], datasets: [] });
  const [selectedChart, setSelectedChart] = useState(1);

  useEffect(() => {
    const tableData: any = localStorage.getItem('chartsData');
    const bieData: any = localStorage.getItem('bieChart');
    setDataToBeDisplayed(JSON.parse(tableData));
    setChartData(JSON.parse(bieData));
    setSelectedChart(parseInt(viewType));
  }, [localStorage]);

  const printDocument = () => {
    const input: any = document.getElementById('divToPrint');
    html2canvas(input).then((canvas: any) => {
      const imgData: any = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 15, 40, 180, 160);
      pdf.save('chart.pdf');
    });
  };

  return (
    <div className="model-preration-container">
      <div className="panel margin-gap">
        <div className="chart-table-panel">
          <div className="right-panel chart-panel">
            <div className="d-flex">
              <SwitchCharts selectedChart={selectedChart} setSelectedChart={setSelectedChart} isDisplayed />
              <button className="btn-save" onClick={() => printDocument()}>
                {' '}
                حفظ{' '}
              </button>
            </div>
            <div id="divToPrint">
              {selectedChart === 1 && <BarChart data={dataToBeDisplayed} />}
              {selectedChart === 2 && <DoughnutChart data={chartData} />}
              {selectedChart === 3 && <LineChart data={dataToBeDisplayed} />}
            </div>
          </div>
          <div className="left-panel table-panel">
            <DataTablePanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
