import React, { FC } from 'react';
import CardHeader from '../Card/CardHeader';
import Profile from '../Profile/Profile';
import Logo from '../Logo/Logo';
import reefLogo from '../../assets/png/reef-logo.png';
import HeaderBK from '../../assets/svg/header-bk.svg';
import './OuterHeader.scss';

const OuterHeader: FC<{ cards: any[] }> = ({ cards }) => {
  return (
    // <div className="outer-header-style" style={{ backgroundImage: `url(${HeaderBK})` }}>
    <div className="outer-header-style">
      <CardHeader />

      <Logo />
      <Profile />
    </div>
  );
};

export default OuterHeader;
