import LibrarySettings from '../../components/Library/LibrarySettings';
import SearchDocuments from '../../components/Library/SearchDocuments/SearchDocuments';
import { IRoutes } from '../../models/routes.model';
import Library from '../../pages/Library/Library';

const routes: IRoutes = {
  LIBRARY: {
    path: '/library',
    name: 'library',
    exact: false,
    component: Library,
    routes: {
      SEARCHDOCUMENTS: {
        path: '/library/search-documents',
        name: 'search-documnets',
        component: SearchDocuments,
        exact: true,
      },
      SETTINGS: {
        path: '/library/library-settings',
        name: 'library-settings',
        component: LibrarySettings,
        exact: true,
      },
      // CreateDocument: {
      //   path: '/users/createuser',
      //   name: 'createuser',
      //   component: ,
      //   exact: true,
      // },
      
    },
    
  },
};

export default routes;
