type storageKeys = 'token' | 'tokenExpireDate' | 'user';

export const getStorageData = (key: storageKeys) => {
  const data = localStorage.getItem(key);
  if (data) return JSON.parse(data);
  return null;
};

export const setStorageData = (key: storageKeys, data: any) => {
  return localStorage.setItem(key, JSON.stringify(data));
};

export const removeStorageData = (key: storageKeys) => {
  localStorage.removeItem(key);
};
