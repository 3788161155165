import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'primereact/carousel';
import { useHistory } from 'react-router-dom';
import Logo from '../../../components/Logo/Logo';
import sliderImg from '../../../assets/png/login-slider-img.png';
import poweredBy from '../../../assets/png/Untitled-2.png';
import leftArrow from '../../../assets/png/left-arrow.png';
import LoginForm from './LoginForm';
import './Login.scss';

// const productTemplate: React.FC = (product: any) => {
//  // const slideImges = [vara_1, vara_2, vara_3, vara_4, vara_5, vara_6, vara_7, vara_8];

//   return (
//     <div className="product-item">
//       <div className="product-item-content">
//         <div>
//           <div className="slider-item-header">
//             <h2>{product.title}</h2>
//             <p>{product.description}</p>
//           </div>
//           {/* <img width="100%" height="100%" src={sliderImg} alt="img-alt" /> */}
//           <img className="sliderImg" src={product.src} alt="img-alt" />
//         </div>
//       </div>
//     </div>
//   );
// };
type props = {
  show: any;
};

const Login: React.FC<props> = ({ show }) => {
  const [isShown, setIsShown] = useState(false);
  // const [products] = useState([
  //   {
  //     id: 1,
  //     title: 'منصة إدارة الأعمال والمصادر',
  //     description: 'Business & Resources Mangement Platform',
  //     src: vara_1,
  //   },
  //   {
  //     id: 2,
  //     title: 'منصة إدارة الأعمال والمصادر',
  //     description: 'Business & Resources Mangement Platform',
  //     src: vara_2,
  //   },
  //   {
  //     id: 3,
  //     title: 'منصة إدارة الأعمال والمصادر',
  //     description: 'Business & Resources Mangement Platform',
  //     src: vara_3,
  //   },
  //   {
  //     id: 4,
  //     title: 'منصة إدارة الأعمال والمصادر',
  //     description: 'Business & Resources Mangement Platform',
  //     src: vara_4,
  //   },
  //   {
  //     id: 5,
  //     title: 'منصة إدارة الأعمال والمصادر',
  //     description: 'Business & Resources Mangement Platform',
  //     src: vara_5,
  //   },
  //   {
  //     id: 6,
  //     title: 'منصة إدارة الأعمال والمصادر',
  //     description: 'Business & Resources Mangement Platform',
  //     src: vara_6,
  //   },
  //   {
  //     id: 7,
  //     title: 'منصة إدارة الأعمال والمصادر',
  //     description: 'Business & Resources Mangement Platform',
  //     src: vara_7,
  //   },
  // ]);
  const handleShown = () => {
    setIsShown(!isShown);
    // console.log(isShown, 'lkjhgfrdes');
  };

  return (
    <div className="login">
      <LoginForm show={isShown} />
      {/* <div className="login-field"></div> */}
      <div className="login-background">
        {/* <div className="slider-field">
        <div className="card">
          <Carousel
            value={products}
            numVisible={1}
            numScroll={1}
            className="custom-carousel"
            circular
            autoplayInterval={3000}
            itemTemplate={productTemplate}
          />
        </div>
      </div> */}

        {/* <Logo /> */}
        <div>
          <div className="enter" onClick={() => handleShown()}>
            <input type="button" value="الدخول" className="enter-btn" />
            <img src={leftArrow} alt="left-arrow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
