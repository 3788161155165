import React from 'react';
import { InputText } from 'primereact/inputtext';
import search from '../../assets/svg/search.svg';
import './CustomFilterDropDown.scss';

type Props = {
  title: string;
  items: any[];
  inputValue?: string;
  inputValueHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handler?: (item: any, selected: boolean) => void;
};
const CustomFilterDropDown: React.FC<Props> = ({ title, items, inputValue, inputValueHandler, handler }) => {
  return (
    <div className="custom-filter-drop-down">
      <div className="header">
        <span className="title">{title}</span>
        <InputText className="input-prime" value={inputValue} onChange={inputValueHandler} />
        <span className="icon">
          <img src={search} alt="search" />
        </span>
      </div>
      <div className="content">
        <ul>
          {items.map((item: any, idx) => (
            <li
              key={idx}
              onClick={() => {
                handler?.(item, true);
              }}
            >
              {item.initiativeShortName ? item.initiativeShortName : item.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CustomFilterDropDown;
