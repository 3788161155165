import { Carousel } from 'primereact/carousel';
import { Toast } from 'primereact/toast';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import leftArrow from '../../assets/png/left-arrow.png';
import HomeSliderImg from '../../assets/svg/home-slider-img.svg';
import Card from '../../components/Card/Card';
import OuterFooter from '../../components/OuterFooter/OuterFooter';
import OuterHeader from '../../components/OuterHeader/OuterHeader';
import { Module } from '../../models/User';
import { CURRENT_MODULE } from '../../redux/actions/types';
import { getAllModules } from '../../redux/actions/user';
import { RootState } from '../../redux/reducers';
import { hasAccessToModule, isSuperAdmin } from '../../utils/checkAccess';
import './Home.scss';

const productTemplate = (product: any) => {
  return (
    <div className="product-item">
      <div className="product-item-content">
        {/* <div className="slider-content">
          <h2>{product.title}</h2>
          <div className="button">
            <button className="slider-btn">
              <img src={leftArrow} style={{ marginRight: '6px' }} alt="left-arrow" />
              <span>المزيد</span>
            </button>
          </div>
        </div> */}
        {/* <img src={HomeSliderImg} width="100%" height="100%" alt="img-alt" /> */}
      </div>
    </div>
  );
};

const Home: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { modules, user } = useSelector((state: RootState) => state.user);
  const [modulesToBeShown, setmodulesToBeShown] = useState<Array<any>>([]);
  const [products]= useState([
    {
      id: 1,
      title: 'منصة إدارة الأعمال والمصادر',
    },
    {
      id: 2,
      title: 'منصة إدارة الأعمال والمصادر',
    },
  ]);
  const ref = useRef<Toast | null>(null);

  useEffect(() => {
    dispatch(getAllModules(ref));
  }, [dispatch]);

  const moduleHandler = (module: Module) => {
    return () => {
      if (!user) return;
      if(module.moduleId === 9) return;
      if (module.moduleNameE === 'LIBRARY') {
        history.push('/library');
      }
      const hasAccess = hasAccessToModule(module, user);
      // console.log(hasAccess);
      if (hasAccess) {
        history.push(module.moduleLink);
        dispatch({ type: CURRENT_MODULE, payload: module });
        //TODO module link recieved from database
      }
    };
  };
  useEffect(() => {
    if (isSuperAdmin(user)) {
      setmodulesToBeShown(modules);
    } else {
      let newmodules = modules.filter((module) => module.moduleId !== 22);
      setmodulesToBeShown(newmodules);
    }
  }, [modules, user]);
  // console.log(modulesToBeShown);

  return (
    <>
      <Toast ref={ref} position="top-left" />
      {/* header */}
      {/* Carousel  */}
      <div className="home-slider">
        <OuterHeader cards={modules} />

        {/* <div className="card">
          <Carousel value={products} numVisible={1} numScroll={1} itemTemplate={productTemplate} />
        </div> */}
      </div>
      {/* cards */}
      <div className="cards-style">
        {modulesToBeShown.map((card, idx) => {
          {console.log('object', card);}
          return (
            <Fragment key={card.moduleId}>
              <Card
                bgcolor={ !hasAccessToModule(card, user) && !isSuperAdmin(user)  ? '#cacaca': '' || card.moduleId === 9 ? '#cacaca' : ''}
                cardHandler={moduleHandler(card)}
              >
                <div>
                  <div className="card-icon">
                    <span className={`${card.moduleIcon} card-img`} />
                  </div>
                  <div className="card-title">{card.moduleNameA}</div>
                </div>
              </Card>
            </Fragment>
          );
        })}
      </div>
      <OuterFooter />
    </>
  );
};

export default Home;
