import React from 'react';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import CardHeader from '../Card/CardHeader';
import Profile from '../Profile/Profile';
import './InnerHeader.scss';

type Props = {
  mainSection: string;
  selectedItem: string;
};

const InnerHeader: React.FC<Props> = ({ selectedItem, mainSection }) => {
  return (
    <div className="inner-header-style">
      <div>
        <CardHeader />
        <BreadCrumb mainSection={mainSection} selectedItem={selectedItem} />
      </div>
      <Profile />
    </div>
  );
};

export default InnerHeader;
