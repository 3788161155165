import React, { useEffect, useState } from 'react';
import './Users.scss';
import '../../styles/modelprepration.scss';
import '../../styles/newmodel.scss';
import userMenuItems, { defaultUserMenuItems } from '../../constants/userMenuItems';
import InnerFooter from '../../components/InnerFooter/InnerFooter';
import InnerHeader from '../../components/InnerHeader/InnerHeader';
import { Redirect, Switch, useLocation, Route } from 'react-router';
import cards from '../../constants/cards';
import { IRoutes } from '../../models/routes.model';
import NewSideMenu from '../../components/NewSideMenu/NewSideMenu';

type Props = {
  subRoutes: IRoutes;
};

const names = [
  {
    currentpath: 'create-new-model',
    title: 'انشاء نموذج جديد',
  },
  {},
];

const Users: React.FC<Props> = ({ subRoutes }) => {
  const [sideMenuWidth, setSideMenuWidth] = useState(true);
  const [mainSection, setSelectedMainSection] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [sideMenuItems, setSideMenuItems] = useState<any[]>([]);

  const { pathname } = useLocation();

  useEffect(() => {
    const [, , currentsubroute] = pathname.split('/');
    const modelObject = names.find((o) => o.currentpath === currentsubroute)?.title;
    if (modelObject) {
      setSelectedItem(modelObject);
    }
    if (currentsubroute === 'userinfo') {
      setSideMenuItems(userMenuItems);
      return;
    }
    setSideMenuItems(defaultUserMenuItems);
  }, [pathname]);

  useEffect(() => {
    const [, section, currentsubroute] = pathname.split('/');
    const baseurl = cards.find((ele) => ele.cardLink === `/${section}`)?.title || '';
    const data = userMenuItems.find((ele) => ele.menuLink === currentsubroute);
    setSelectedMainSection(baseurl);
    setSelectedItem(data ? data.title : userMenuItems[0].title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedItemHandler = (item: any) => {
    setSelectedItem(item.title);
  };

  const toggledSideMenuHandler = (sideMenu: any) => {
    setSideMenuWidth(sideMenu);
  };

  return (
    <div
      className="tender-management"
      style={{
        gridTemplateColumns: `${sideMenuWidth === true ? 'repeat(2, 20% 80%)' : 'repeat(1, 6% 94%)'}`,
      }}
    >
      <NewSideMenu
        sideMenuItems={sideMenuItems}
        selectedItem={selectedItemHandler}
        toggledSideMenu={toggledSideMenuHandler}
      />

      <div className="internal-routes">
        <InnerHeader mainSection={mainSection} selectedItem={selectedItem} />
        <div className="routes-container ">
          <Switch>
            <Redirect exact from="/users" to="/users/mangment" />
            {Object.values(subRoutes).map((route) => (
              <Route key={route.name} exact={route.exact} path={route.path} component={route.component} />
            ))}
          </Switch>
        </div>
      </div>
      <InnerFooter />
    </div>
  );
};

export default Users;
