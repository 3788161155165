import React, { useState } from 'react';
import OptionsList from '../OptionList/OptionsList';

interface Props {
  lvlOneSelected: string;
  lvlTwoSelected: string;
  handleAdd: any;
  arrayOfOptions: any[];
}

const ReseachDataThree: React.FC<Props> = ({ lvlOneSelected, lvlTwoSelected, handleAdd, arrayOfOptions }) => {
  const [addTitle, setAddTitle] = useState('');
  const [addOption, setAddOption] = useState('');

  const handleTitle = (e: any) => {
    setAddTitle(e.target.value);
  };

  function handleOption() {
    if (!lvlTwoSelected) return;
    handleAdd(addOption, 3, '', lvlOneSelected, lvlTwoSelected);
  }

  const handleUpdatedOption = (oldValue: string, newValue: string) => {
    handleAdd(newValue, 3, oldValue, lvlOneSelected, lvlTwoSelected);
  };
  console.log('lvl three rendderd');

  return (
    <>
      {lvlTwoSelected && (
        <div className="research-data">
          <div className="address-section">
            <label>عنوان البيان</label>
            <input
              onChange={(e) => {
                handleTitle(e);
              }}
              type="text"
              placeholder="الجهة الثالثه"
            />
          </div>
          <div className="menu-choices">
            <div className="display-selected-option">
              <label>اختيارات القائمة</label>
              <p className="selected-option  scrollable-selected-option">" {lvlTwoSelected} "</p>
            </div>
            <input
              value={addOption}
              onKeyDown={(e) => {
                e.key === 'Enter' && handleOption();
                e.key === 'Enter' && setAddOption('');
              }}
              onChange={(e) => setAddOption(e.target.value)}
              placeholder="اضافة اختيار جديد"
              type="text"
              disabled={addTitle.length === 0 ? true : false}
              className={addTitle.length === 0 ? ' ' : 'active-to-add'}
            />
          </div>

          <OptionsList
            handleUpdatedOption={handleUpdatedOption}
            id={3}
            selectedItem={''}
            arrayOfOptions={arrayOfOptions}
          />
        </div>
      )}
    </>
  );
};

export default ReseachDataThree;
