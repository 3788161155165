import React from 'react';

export interface IMarginerProps {
  margin: number | string;
  direction?: 'horizontal' | 'vertical';
}

const HorizontalMargin = ({ margin }: { margin: string | number }) => {
  return (
    <span
      style={{
        display: 'flex',
        backgroundColor: '#707070',
        height: '3px',
        width: typeof margin === 'string' ? margin : `${margin}px`,
      }}
    ></span>
  );
};
const VerticalMargin = ({ margin }: { margin: string | number }) => {
  return (
    <span
      style={{
        display: 'flex',
        width: '3px',
        backgroundColor: '#707070',
        opacity: "0.7",
        height: typeof margin === 'string' ? margin : `${margin}px`,
      }}
    ></span>
  );
};

const Marginer: React.FC<IMarginerProps> = ({ direction, ...props }) => {
  return <>{direction === 'horizontal' ? <HorizontalMargin {...props} /> : <VerticalMargin {...props} />}</>;
};

export default Marginer;
