import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { ConfirmDialog } from 'primereact/confirmdialog';
import './userinfo.scss';
import { User } from '../../../models/User';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { userTypes } from '../../../constants/userTypes';
export interface Isysallow {
  admModule: {
    moduleId: number;
    moduleNameA: string;
    moduleNameE: string;
    moduleShortname: string;
  };
  userType: number;
}
type Props = {
  setArrayInputs: any;
  user: Partial<User>;
  arrayInputs: Isysallow[];
  createNewUser?: boolean;
};

type Sys = {
  moduleId?: number;
  moduleNameA?: string;
  moduleNameE?: string;
  moduleShortname?: string;
};

type visible = {
  sys?: Sys;
  show?: boolean;
};

const SysAllowComp: React.FC<Props> = ({ user, createNewUser, arrayInputs, setArrayInputs }) => {
  const { modules } = useSelector((state: RootState) => state.user);
  const [systemAllow, setSystemAllow] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [numberAllow, setNumberAllow] = useState(0);

  const [visible, setVisible] = useState<visible>({ show: false, sys: {} });

  const checkedOrNot = (module: Sys, incomingrole: number) => {
    const input =
      arrayInputs &&
      arrayInputs.length > 0 &&
      arrayInputs?.find((ele: any) => ele?.admModule.moduleId === module?.moduleId && ele.userType === incomingrole);

    if (isSuperAdmin && incomingrole === 2) {
      return true;
    }
    if (input) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setNumberAllow(arrayInputs.length);
    if (arrayInputs.length === 0) return;
    let checked = false;
    arrayInputs.forEach((ele) => {
      if (ele.userType === userTypes.SUPER_ADMIN) {
        checked = true;
      }
    });
    setSystemAllow(checked);
    setIsSuperAdmin(checked);
  }, [arrayInputs]);

  const handleChange = (sys: Sys, e: any) => {
    const alreadyexists = arrayInputs.find((input: Isysallow) => input.admModule.moduleId === sys.moduleId);

    const userType = parseInt(e, 10);
    if (alreadyexists) {
      let newarrayinputs = arrayInputs.map((input: Isysallow) => {
        if (input.admModule.moduleId === sys.moduleId) {
          return { ...input, userType };
        } else {
          return input;
        }
      });
      setArrayInputs(newarrayinputs);
    } else {
      setArrayInputs([...arrayInputs, { admModule: sys, userType }]);
    }
  };

  const onDeactivate = () => {
    if (isSuperAdmin) return;
    const newarrayofinputs = arrayInputs.filter((input) => input.admModule.moduleId !== visible?.sys?.moduleId);
    setArrayInputs(newarrayofinputs);
  };

  useEffect(() => {
    if (systemAllow) {
      const newarray = modules.map((sys) => {
        return { admModule: sys, userType: userTypes.SUPER_ADMIN };
      });
      setArrayInputs(newarray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemAllow]);

  useEffect(() => {
    if (createNewUser) {
      setArrayInputs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdminToggle = () => {
    setSystemAllow(!systemAllow);
    setIsSuperAdmin(!isSuperAdmin);
    !!isSuperAdmin && setArrayInputs([]);
  };
  return (
    <>
      <ConfirmDialog
        visible={!isSuperAdmin && visible?.show}
        onHide={() => setVisible({ show: false, sys: {} })}
        message="في حالة حذف الصلاحيه لن يتمكن المستخدم من الوصول مرة آخري إلي هذا البرنامج"
        header="تأكيد إلغاء الصلاحيه"
        acceptLabel="تأكيد"
        rejectLabel="تجاهل"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={onDeactivate}
        className="modalBootbox"
      />
      <div className="system-allowance-switch-container">
        <div className="system-allowance-switch-container-title"> تمكين الصلاحيات بالكامل ؟</div>
        <Switch
          onChange={() => {
            handleAdminToggle();
          }}
          checked={systemAllow}
          offHandleColor="#969696"
          activeBoxShadow="0 0 2px 3px #c8d0d4"
          uncheckedIcon={false}
          offColor="#ffffff"
          handleDiameter={14}
          height={22}
          width={50}
          className="switch-class"
        />
      </div>
      <div className="system-allowance-switch-container">
        <div className="system-allowance-switch-container-title "> عدد الصلاحيات بالكامل </div>
        <div className="allowwnumber"> {numberAllow}</div>
      </div>

      <div className="systemallowance-container">
        <div className="sys-all-col">
          <div className="sys-all-first-item">صلاحيات النظام</div>
          {modules.map((sys, i) => (
            <div key={i} className="sys-all-first-item-name">
              {sys.moduleNameA}
            </div>
          ))}
        </div>

        <div className="sys-all-col">
          <div className="sys-all-first-item">مستخدم</div>
          {modules.map((sys, i) => (
            <input
              type="radio"
              className="templateRadioBtnList"
              value={1}
              key={i}
              disabled={systemAllow}
              checked={checkedOrNot(sys, userTypes.USER)}
              name={sys.moduleNameA}
              onChange={(e) => handleChange(sys, 1)}
            />
          ))}
        </div>

        <div className="sys-all-col">
          <div className="sys-all-first-item">مدير</div>

          {modules.map((sys, i) => (
            <input
              type="radio"
              className="templateRadioBtnList"
              value={2}
              key={i}
              disabled={systemAllow}
              checked={checkedOrNot(sys, 2)}
              name={sys.moduleNameA}
              onChange={(e) => handleChange(sys, userTypes.ADMIN)}
            />
          ))}
        </div>

        <div className="sys-all-col">
          <div className="sys-all-first-item">حالة الصلاحيه</div>
          {modules.map((sys: Sys, i) => (
            <div key={i} className="sys-all-first-item-status">
              {arrayInputs.find((input: Isysallow) => input.admModule.moduleId === sys.moduleId)?.userType ? (
                <div
                  onClick={() => {
                    !isSuperAdmin && setVisible({ show: true, sys: sys });
                  }}
                  className="disable-allowance"
                >
                  إلغاء الصلاحيه
                </div>
              ) : (
                <div>غير فعال</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SysAllowComp;
