import CurrentProjectsIcon from '../assets/svg/current-projects.svg';
import CurrentProjectsIconFilled from '../assets/svg/current-projects-filled.svg';

import ArchivedProjectsIcon from '../assets/svg/archived-projects.svg';
import ArchivedProjectsIconFilled from '../assets/svg/archived-projects-filled.svg';

import InitiativesIcon from '../assets/svg/project-initiatives.svg';
import InitiativesIconFilled from '../assets/svg/project-initiatives-filled.svg';

import PerformanceIcon from '../assets/svg/performance.svg';
import PerformanceIconFilled from '../assets/svg/performance-filled.svg';

import PanelIcon from '../assets/svg/project-panel.svg';
import PanelIconFilled from '../assets/svg/project-panel-filled.svg';

import ReportsIcon from '../assets/svg/project-report.svg';
import ReportsIconFilled from '../assets/svg/project-report-filled.svg';


import PersonalSettingsIcon from '../assets/svg/bussiness-management.svg';
import PersonalSettingsIconFilled from '../assets/svg/bussiness-management-filled.svg';


import SettingsIcon from '../assets/svg/manuals-management-settings.svg';
import SettingsIconFilled from '../assets/svg/manuals-management-settings-filled.svg';


import ArchivedProjects from '../components/Tender/ArchivedProjects/ArchivedProjects';
import CurrentProjects from '../components/Tender/CurrentProjects/CurrentProjects';


const tenderMenuItems = [
  {
    id: 1,
    icon: `${CurrentProjectsIcon}`,
    iconFilled: `${CurrentProjectsIconFilled}`,
    title: 'المشاريع الحالية',
    menuLink: 'current-projects',
    Component: CurrentProjects,
  },
  {
    id: 2,
    icon: `${ArchivedProjectsIcon}`,
    iconFilled: `${ArchivedProjectsIconFilled}`,
    title: 'المشاريع المؤرشفة',
    menuLink: 'archived-projects',
    Component: ArchivedProjects,
  },
  {
    id: 3,
    icon: `${InitiativesIcon}`,
    iconFilled: `${InitiativesIconFilled}`,
    title: 'المبادرات',
    menuLink: 'initiatives',
  },
  // {
  //   id: 4,
  //   icon: `${PerformanceIcon}`,
  //   iconFilled: `${PerformanceIconFilled}`,
  //   title: 'مؤشرات الأداء',
  //   menuLink: '',
  //   // menuLink: 'performance',
  // },
  {
    id: 5,
    icon: `${PanelIcon}`,
    iconFilled: `${PanelIconFilled}`,
    title: 'لوحة المعلومات',
    menuLink: 'panel',
  },
  // {
  //   id: 6,
  //   icon: `${ReportsIcon}`,
  //   iconFilled: `${ReportsIconFilled}`,
  //   title: 'التقارير',
  //   menuLink: '',
  //   // menuLink: 'reports',
  // },
  // {
  //   id: 7,
  //   icon: `${PersonalSettingsIcon}`,
  //   iconFilled: `${PersonalSettingsIconFilled}`,
  //   title: 'الإعدادات الشخصية',
  //   menuLink: '',
  //   // menuLink: 'personal-settings',
  // },
  // {
  //   id: 8,
  //   icon: `${SettingsIcon}`,
  //   iconFilled: `${SettingsIconFilled}`,
  //   title: 'إعدادات النظام',
  //   menuLink: '',
  //   // menuLink: 'settings',
  // },
];

export default tenderMenuItems;
