import React from 'react';

const ProjectName: React.FC<{ name?: string }> = ({ name }) => {
  return (
    <div className="project-name" style={{ display: 'flex', alignItems: 'center' }}>
      <label
        style={{
          textAlign: 'end',
          margin: '0 1rem',
          width: '20rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name ? name : 'اســــــــــــم المــــشـروع'}
      </label>
    </div>
  );
};

export default ProjectName;
