import imgPreview from '../assets/png/img.png';
import pdfPreview from '../assets/png/pdf.png';
import wordPreview from '../assets/png/word.png';
import excelPreview from '../assets/png/excel.jpg';
import filePreview from '../assets/png/file.png';

const imgExtensions = ['jpg', 'jpeg', 'png'];
const excelExtensions = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xls'];
const wordExtensions = ['doc', 'docm', 'docx', 'docx'];
const pdfExtensions = ['pdf'];

export const fileType = (file: string) => {
  const [, ext] = file.split('.');
  if (imgExtensions.includes(ext)) {
    return imgPreview;
  } else if (excelExtensions.includes(ext)) {
    return excelPreview;
  } else if (wordExtensions.includes(ext)) {
    return wordPreview;
  } else if (pdfExtensions.includes(ext)) {
    return pdfPreview;
  } else {
    return filePreview;
  }
};
