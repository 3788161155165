import React, { FC } from 'react';
import { MultiSelect } from 'primereact/multiselect';

type props = {
  options: any[];
  dimension: any;
  setDimension: any;
  listId: number;
  setListId: any;
};

const CascadeMultiSelector: FC<props> = ({ options, dimension, setDimension, listId, setListId }) => {
  return (
    <div
      className="p-cascadeselect p-component p-inputwrapper p-inputwrapper-focus"
      style={{ minWidth: '14rem' }}
      onClick={(e) => {
        e.stopPropagation();
        setListId(dimension.id);
      }}
    >
      <div className="p-hidden-accessible">
        <input type="text" readOnly aria-haspopup="listbox" value="" />
      </div>
      <span className="p-cascadeselect-label  p-placeholder">
        {dimension.selectionName === '' ? '- اختر المناسب -' : dimension.selectionName}
      </span>
      <div className="p-cascadeselect-trigger" role="button" aria-haspopup="listbox" aria-expanded="true">
        <span className="p-cascadeselect-trigger-icon pi pi-chevron-down"></span>
      </div>
      {dimension.id === listId && (
        <div
          className="p-cascadeselect-panel p-component p-connected-overlay-enter-done"
          style={{ zIndex: 1002, transformOrigin: 'center top', top: '27px', left: '0px' }}
        >
          <div className="p-cascadeselect-items-wrapper">
            <ul
              className="p-cascadeselect-panel p-cascadeselect-items p-cascadeselect-items"
              role="listbox"
              aria-orientation="horizontal"
            >
              {options &&
                options.map((option: any, i: any) => {
                  const optionName =
                    option.id === 1 ? 'sectorName' : option.id === 2 ? 'initiativeShortName' : 'lookupMainName';
                  return (
                    <li
                      key={i}
                      className="p-cascadeselect-item p-cascadeselect-item-group"
                      role="none"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="p-cascadeselect-item-content" tabIndex={0}>
                        <MultiSelect
                          options={option.options}
                          optionLabel={optionName}
                          placeholder={option.name}
                          value={
                            dimension.selectionId === option.id && dimension.selectionId !== 0 ? dimension.choices : []
                          }
                          onChange={(e) => {
                            if (e.value.length === 0 && dimension.selectionId !== 0) {
                              setDimension({
                                id: dimension.id,
                                remove: false,
                                selectionId: 0,
                                selectionName: '',
                                choices: [],
                              });
                            } else if (dimension.selectionId === option.id || dimension.selectionId === 0) {
                              setDimension({
                                id: dimension.id,
                                remove: e.value.length === 0 ? false : true,
                                selectionId: option.id,
                                selectionName: option.name,
                                choices: e.value,
                              });
                            }
                          }}
                        />
                      </div>
                      {/*{showSubList && <div className="p-cascadeselect-panel p-cascadeselect-items p-cascadeselect-sublist" role="listbox" aria-orientation="horizontal">
                                    <MultiSelect options={option.options} optionLabel="SectorName" placeholder="" maxSelectedLabels={3} />

                                    </div>}
                                    */}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
export default CascadeMultiSelector;
