import { Project } from '../../models/add-project.model';
import { analyisResponse } from '../../models/Analysis';
import { Lookup } from '../../models/lookups.model';
import { Roles, User } from '../../models/Roles';
import { Initiative, Sector } from '../../models/sector.model';
import { Sides } from '../../models/side-codes';
import {
  ADD_INITIATIVE,
  ADD_PROJECT,
  ALL_INITIATIVES,
  ALL_SECTORS,
  ARCHIVED_PROJECTS,
  CURRENT_PROJECTS,
  DELETE_INITIATIVE,
  DELETE_PROJECT,
  EDIT_INITIATIVE,
  EDIT_PROJECT,
  GET_ROLES,
  GET_SIDE_LEVEL_CODES,
  GET_USERS,
  MAIN_LOOKUPS,
  SEARCH_PROJECT,
  SET_LOADER,
  ANALYIZE_DATA,
  EDIT_MANUAL_NAME,
  DOWNLOAD_TENDER_MANUAL,
  DOWNLOAD_TENDER_MANUAL_FOR_MODEL,
  DOWNLOAD_TENDER_MANUAL_FOR_BOOK,
} from '../actions/types';

type InitialState = {
  allSectors: Sector[];
  currentProjects: Project[];
  archivedProjects: Project[];
  mainLookups: Lookup[];
  initiatives: Initiative[];
  users: User[];
  roles: Roles[];
  sideLevelCodes: Sides[];
  updateSector: boolean;
  loading: boolean;
  totalRecords: number;
  anlysis: analyisResponse;
  previewTenderFile: any;
  previewTenderModel: any;
};

const INITIAL_STATE: InitialState = {
  currentProjects: [],
  archivedProjects: [],
  mainLookups: [],
  allSectors: [],
  initiatives: [],
  users: [],
  roles: [],
  sideLevelCodes: [],
  updateSector: false,
  loading: false,
  totalRecords: 0,
  previewTenderFile: null,
  previewTenderModel: null,
  anlysis: {
    analysisDate: '',
    // sectorIdAnaylsis: [],
    // initiativesCodeAnalysis: [],
    // statusAnalysis: [],
    // geoIdAnaylsis: [],
    dataAnalysis: [],
    totalFinanceValueOfProjects: 0,
    totalNumberOfProjects: 0,
  },
};

const editProjects = (state: InitialState, payload: any) => {
  let { currentProjects, archivedProjects } = state;
  const { project, updateType } = payload;
  if (updateType === 'retreive') {
    archivedProjects = archivedProjects.filter((ele) => ele.projectId !== project.projectId);
    currentProjects = [...currentProjects, project];
  } else if (updateType === 'archived') {
    currentProjects = currentProjects.filter((ele) => ele.projectId !== project.projectId);
    archivedProjects = [...archivedProjects, project];
  } else if (updateType === 'edit') {
    const eleIdx = currentProjects.findIndex((ele) => ele.projectId === project.projectId);
    if (eleIdx >= 0) {
      currentProjects[eleIdx] = project;
    }
  }
  return {
    ...state,
    loading: false,
    currentProjects,
    archivedProjects,
  };
};
const searchProjects = (state: InitialState, payload: any) => {
  let { currentProjects, archivedProjects } = state;
  if (payload.state === 1) {
    currentProjects = [...payload.projects];
  } else {
    archivedProjects = [...payload.projects];
  }
  return {
    ...state,
    loading: false,
    totalRecords: payload.total,
    currentProjects,
    archivedProjects,
  };
};

const tenderManagementReducer = (state = INITIAL_STATE, action: { type: string; payload: any }): InitialState => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case SEARCH_PROJECT:
      return searchProjects(state, action.payload);
    case DOWNLOAD_TENDER_MANUAL:
      return {
        ...state,
        previewTenderFile: action.payload.file.data,
      };
    case DOWNLOAD_TENDER_MANUAL_FOR_MODEL:
      return {
        ...state,
        previewTenderModel: action.payload.file.data,
      };
    // case DOWNLOAD_TENDER_MANUAL_FOR_BOOK:
    //   return {
    //     ...state,
    //     previewTenderFile: action.payload.file.data,
    //   };
    case CURRENT_PROJECTS:
      return {
        ...state,
        loading: false,
        currentProjects: action.payload.projects,
        totalRecords: action.payload.total,
      };
    case ARCHIVED_PROJECTS:
      return {
        ...state,
        loading: false,
        archivedProjects: action.payload.projects,
        totalRecords: action.payload.total,
      };
    case ADD_PROJECT:
      return {
        ...state,
        loading: false,
        currentProjects: [action.payload, ...state.currentProjects],
      };
    case EDIT_MANUAL_NAME:
      return {
        ...state,
        loading: false,
      };
    case EDIT_PROJECT:
      return editProjects(state, action.payload);
    case DELETE_PROJECT:
      const archivedProjects: Project[] = [...state.archivedProjects];
      const projectIdx: number = archivedProjects.findIndex((ele) => ele.projectId === action.payload.projectId);
      if (projectIdx >= 0) {
        archivedProjects[projectIdx] = action.payload;
      }
      return {
        ...state,
        loading: false,
        archivedProjects: [...archivedProjects],
      };
    case MAIN_LOOKUPS:
      return {
        ...state,
        loading: false,
        mainLookups: action.payload,
      };
    case ALL_SECTORS:
      return {
        ...state,
        updateSector: true,
        loading: false,
        allSectors: action.payload,
      };
    case GET_SIDE_LEVEL_CODES:
      return {
        ...state,
        loading: false,
        sideLevelCodes: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case GET_ROLES:
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };
    case ALL_INITIATIVES:
      return {
        ...state,
        loading: false,
        initiatives: action.payload,
      };
    case ADD_INITIATIVE:
      return {
        ...state,
        loading: false,
        initiatives: [action.payload, ...state.initiatives],
      };
    case EDIT_INITIATIVE:
      const initiatives: Initiative[] = [...state.initiatives];
      const eleIdx: number = initiatives.findIndex((ele) => ele.initiativeCode === action.payload.initiativeCode);
      if (eleIdx >= 0) {
        initiatives[eleIdx] = action.payload;
      }
      return {
        ...state,
        loading: false,
        initiatives: [...initiatives],
      };
    case DELETE_INITIATIVE:
      const allInitiatives = state.initiatives.filter((ele) => +ele.initiativeCode !== +action.payload.initiativeCode);
      return {
        ...state,
        loading: false,
        initiatives: [...allInitiatives],
      };

    case ANALYIZE_DATA:
      return {
        ...state,
        loading: false,
        anlysis: action.payload,
      };

    default:
      return state;
  }
};

export default tenderManagementReducer;
