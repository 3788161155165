import React, { useState } from 'react';
import nomodelssvg from '../../assets/svg/nomodels.svg';
import eye from '../../assets/svg/eye.svg';
import { PanelMenu } from 'primereact/panelmenu';
import './librarySetting.scss';
import { useHistory } from 'react-router-dom';
import MultiLevelMenus from './MultiLevelMenus/MultiLevelMenus';
import MiniDataEntry from './MiniDataEntry';
import LargeDataEntry from './LargeDataEntry';
import DateEntry from './DateEntry';

type Props = {
  
};

const LibrarySettings: React.FC<Props> = ({}) => {
    let history = useHistory();
    const [allReports, setAllReports] = useState([]);
    const [reportTemplateId, setReportTemplateId] = useState <number>(1);
    const items = [
        {
            label:"مدخل بيانات جديد",
            icon:'pi pi-fw pi-plus',
            items:[
                {   
                    id: 0,
                    label:'قوائم متعددة المستويات',
                    command:()=>{
                        setReportTemplateId(1);
                    }
                    
                },
                {
                    id:1,
                    label:'مدخل بيانات صغير',
                    command:()=>{
                        setReportTemplateId(2);
                    }
                },
                {
                    id:2,
                    label:'مدخل بيانات كبير',
                    command:()=>{
                        setReportTemplateId(3);
                    }
                },
                {
                    id:3,
                    label:'مدخل تاريخ',
                    command:()=>{
                        setReportTemplateId(4);
                    }
                }
            ]
        },
    ];

    let filteredItem =items[0].items.filter((item)=>item.id !== reportTemplateId-1)
    console.log(filteredItem);

    const reportTemplate = {
        id: 0,
        isMultilevel: false,

    }
    const forms =[
        <></>,
        <MultiLevelMenus />,
        <MiniDataEntry />,
        <LargeDataEntry />,
        <DateEntry/>

    ]

    const createReportPageRedirect = () => {
        history.push(`/library/report`);
    }

    const createReport = () => {

    }

  return (
    <div className='library'>
        <div className='top-part-container'>
            {reportTemplateId !== 0 && <div className='header-container'>
                <h1 id="title">
                    بيانات الأبحاث
                </h1>
                <div className='button-container'>
                    <button>
                    <img src={eye} alt="eye" width={20} className='eye-icon'/>

                    عرض كمستخدم
                    </button>
                    <PanelMenu  model={[{...items[0] , items:filteredItem}]} />
                </div>

            </div>}
        { reportTemplateId === 0 &&
            <div className="nomodels-found-container">
                <img src={nomodelssvg} alt="noModelIcon" />
                <h1>لم يتم إضافة أي بيانات</h1>
                <div className="panel-menu">
                    <PanelMenu model={items}/>
                </div>
            </div>
        }
        </div>
        <div>
            
            {forms[reportTemplateId]}
        </div>

        
        
    </div>
  );
};

export default LibrarySettings;
