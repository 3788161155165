import React, { FC } from 'react';
import evarafooter from '../../assets/svg/evara-footer.svg';
import sigalfooter from '../../assets/png/sigal-footer.png';
import './InnerFooter.scss';
import Marginer from '../Marginer/Marginer';

const InnerFooter :FC = () => {
  return (
    <footer className="inner-footer">
      <span className="poweredBy">Powered by:</span>
      <img src={evarafooter} alt="evara-footer" />
      <span className="marginer">
        <Marginer direction="vertical" margin="4rem" />
      </span>
      <img src={sigalfooter} alt="sigal-footer" />
    </footer>
  );
};

export default InnerFooter;
