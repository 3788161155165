import React from 'react';
import reefLogo from '../../assets/png/reef.png';
import './Logo.scss';

const Logo = () => {
  return (
    <div className="logo">
      <img src={reefLogo} alt="reef-logo" className="reef" />
    </div>
  );
};

export default Logo;
