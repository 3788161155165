import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import routes from './constants/routes';
import NotFound from './pages/NotFound/NotFound';
import './App.scss';
import { locale, addLocale } from 'primereact/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/reducers';
import { LOGOUT_USER } from './redux/actions/types';
import { removeStorageData } from './utils/storage';
import { logoutUser, refreshToken } from './redux/actions/user';
import { cleanUpTimeOut, initIdleTimeOut } from './utils/idleTimer';

let interval: any;

const App: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, token, tokenExpireDate } = useSelector((state: RootState) => state.user);

  // handle dates in arabic
  locale('ar');
  addLocale('ar', {
    firstDayOfWeek: 1,
    dayNames: ['الاحد', 'الاتنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعه', 'السبت'],
    dayNamesShort: ['الاحد', 'الاتنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعه', 'السبت'],
    dayNamesMin: ['الاحد', 'الاتنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعه', 'السبت'],
    monthNames: [
      'يناير',
      'فبراير',
      'مارس',
      'ابرايل',
      'مايو',
      'يونيو',
      'يوليو',
      'اغسطس',
      'سبتمبر',
      'اكتوبر',
      'نوفمبر',
      'ديسمبر',
    ],
    today: 'اليوم',
    clear: 'مسح',
  });

  const handleRefreshtoken = () => {
    if (!tokenExpireDate) return;
    const ms = +new Date(tokenExpireDate) - +new Date() - 60000;
    if (interval) {
      clearTimeout(interval);
    }
    interval = setTimeout(() => {
      if (!user) return;
      dispatch(refreshToken({ userId: user.id }));
    }, ms);
  };

  const handleInactive = () => {
    // console.log('handleInactive1', history.location.pathname);
    if (history.location.pathname !== '/login') {
      // console.log('handleInactive2');
      dispatch(logoutUser(user?.id || 0, null, history));
    }
  };

  const handleLogoutUser = () => {
    if (history.location.pathname !== '/login') {
      dispatch({ type: LOGOUT_USER });
      removeStorageData('token');
      removeStorageData('user');
      removeStorageData('tokenExpireDate');
      history.push(routes.LOGIN.path);
    }
  };

  const handleUserAuth = () => {
    if (!user || !token || !tokenExpireDate || (tokenExpireDate && Date.now() > +new Date(tokenExpireDate) - 60000)) {
      handleLogoutUser();
      return;
    }

    handleRefreshtoken();
  };

  useEffect(() => {
    if (user && user.id) {
      initIdleTimeOut(handleInactive);
    }

    return () => {
      cleanUpTimeOut(handleLogoutUser);
    };
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    handleUserAuth();

    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
    // eslint-disable-next-line
  }, [user, token, tokenExpireDate]);

  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/home" />
        {Object.values(routes).map((route) => (
          <Route
            key={route.name}
            exact={route.exact}
            path={route.path}
            render={() => {
              if (route.name === 'homePage') return <Redirect to={routes.HOME.path} />;
              return <route.component subRoutes={route.routes} />;
            }}
          />
        ))}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default App;
