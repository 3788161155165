import axios from 'axios';
import { getStorageData, removeStorageData } from '../utils/storage';

export const baseURL = 'http://85.208.48.114:8070/api';

const api = axios.create({
  baseURL,
});

api.interceptors.request.use((config) => {
  const token = getStorageData('token');
  const headers = config.headers || {};
  if (token && config.url !== '/users/login') {
    headers.Authorization = `JWT ${token}`;
  }
  config.headers = headers;
  return config;
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { data, status } = error.response;

      if (
        status === 401 &&
        data.message &&
        data.message === 'Full authentication is required to access this resource'
      ) {
        removeStorageData('token');
        removeStorageData('user');
        removeStorageData('tokenExpireDate');
        window.location.reload();
      }
    }

    return Promise.reject(error);
  },
);

export default api;
