import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { approveModel, archiveModel, modelEditrequest } from '../../../redux/actions/modelMangmentActions';
import ViewModelForModels from './Modals/ViewModel/ViewModelForModels';
import './modelclicked.scss';
import { Toast } from 'primereact/toast';

type Props = {
  locked: boolean;
  setEditRequest: any;
  model: any;
  archived: boolean;
  changeNamheHandler: any;
  //id: number;
};

const ModelClicked: React.FC<Props> = ({ locked, setEditRequest, model, archived, changeNamheHandler }) => {
  const [viewModels, setViewModels] = useState(false);

  const toast = useRef<Toast | null>(null);
  const dispatch = useDispatch();

  return (
    <>
      <Toast ref={toast} position="top-left" />
      {archived ? (
        <div className="model-clicked-container-black" id="model-clicked">
          <h3>التعديل عن طريق ؟</h3>
          {/* <button
            onClick={(e) => {
              e.stopPropagation();
              //   setViewModels(true);
            }}
          >
            عرض النموذج
          </button> */}
          <div className="model-clicked-bottom">
            <div onClick={() => dispatch(modelEditrequest(model.modelId))}>طلب تعديل</div>
            <div className="print-pdf" onClick={() => window.print()}>
              طباعه
            </div>
          </div>
        </div>
      ) : locked ? (
        <div className="model-clicked-container-green" id="model-clicked">
          <h3>التعديل عن طريق ؟</h3>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setViewModels(true);
            }}
          >
            عرض النموذج
          </button>
          <div className="model-clicked-bottom">
            <div onClick={() => dispatch(modelEditrequest(model.modelId))}>طلب تعديل</div>
            <div className="print-pdf" onClick={() => window.print()}>
              طباعه
            </div>
          </div>
        </div>
      ) : (
        <div className="model-clicked-container-yellow" id="edit-container">
          <h3>التعديل عن طريق ؟</h3>
          <Link to={{ pathname: `/editmodel/${model.modelId}` }}>
            <button> استمرار التعديل</button>
          </Link>
          <button
            onClick={() => {
              dispatch(approveModel(model.modelId));
            }}
          >
            اعتماد النموذج
          </button>
          <button
            onClick={(e) => {
              changeNamheHandler();
            }}
          >
            تغير اسم النموذج
          </button>

          <div className="model-clicked-bottom">
            <div onClick={() => dispatch(archiveModel(model.modelId))}> أرشفة</div>
            <div className="print-pdf" onClick={() => window.print()}>
              طباعه
            </div>
          </div>
        </div>
      )}

      {viewModels && (
        <ViewModelForModels
          show={viewModels}
          hide={() => {
            setViewModels(false);
          }}
          model={model || {}}
        />
      )}
    </>
  );
};

export default ModelClicked;
