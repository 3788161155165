export const SET_REACHERES_LOADER = 'SET_REACHERES_LOADER';
export const SET_LOADER = 'SET_LOADER';
export const CURRENT_PROJECTS = 'CURRENT_PROJECTS';
export const ARCHIVED_PROJECTS = 'ARCHIVED_PROJECTS';
export const SEARCH_PROJECT = 'SEARCH_PROJECT';
export const MAIN_LOOKUPS = 'MAIN_LOOKUPS';
export const ALL_SECTORS = 'ALL_SECTORS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const GET_SIDE_LEVEL_CODES = 'GET_SIDE_LEVEL_CODES';

export const GET_USERS = 'GET_USERS';
export const GET_ROLES = 'GET_ROLES';

export const ALL_INITIATIVES = 'ALL_INITIATIVES';
export const ADD_INITIATIVE = 'ADD_INITIATIVE';
export const EDIT_INITIATIVE = 'EDIT_INITIATIVE';
export const DELETE_INITIATIVE = 'DELETE_INITIATIVE';
export const ANALYIZE_DATA = 'ANALYIZE_DATA';

export const SET_USER_LOADER = 'SET_USER_LOADER';
export const FORCE_LOGOUT_USER = 'FORCE_LOGOUT_USER';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const CURRENT_MODULE = 'CURRENT_MODULE';
export const GET_ALL_MODULES = 'GET_ALL_MODULES';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SEARCH_USER = 'SEARCH_USER';
export const DELETE_USER = 'DELETE_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const EMAIL_EXISTS = 'EMAIL_EXISTS';

export const SET_ERROR_MSG = 'SET_ERROR_MSG';

// models
export const GET_ALL_MODELS_START = 'GET_ALL_MODELS_START';
export const GET_ALL_MODELS_SUCESS = 'GET_ALL_MODELS_SUCESS';
export const GET_ALL_MODELS_FAIL = 'GET_ALL_MODELS_FAIL';
export const GET_SINGLE_MODEL = 'GET_SINGLE_MODEL';
export const CREATESUBSECTION = 'CREATESUBSECTION';
export const MAPCONTENTTOSECTION = 'MAPCONTENTTOSECTION';
export const DELETESECTION = 'DELETESECTION';
export const MODEL_CREATION_FAILED = 'MODEL_CREATION_FAILED';
export const MODEL_CREATED = 'MODEL_CREATED';
export const APPROVE_MODEL = 'APPROVE_MODEL';
export const NEW_MODEL_SECTIONID = 'NEW_MODEL_SECTIONID';
export const NEW_MODEL_SUBSECTIONID = 'NEW_MODEL_SUBSECTIONID';
export const ARCHIVE_MODEL = 'ARCHIVE_MODEL';
export const MODEL_NOTE = 'MODEL_NOTE';

//SystemTenderManual
export const MANUAL_CREATION_FAILED = 'MANUAL_CREATION_FAILED';
export const MANUAL_CREATED = 'MANUAL_CREATED';
export const GET_MANUAL = 'GET_MANUAL';
export const DOWNLOAD_TENDER_MANUAL = 'DOWNLOAD_TENDER_MANUAL';
export const DOWNLOAD_TENDER_MANUAL_FOR_BOOK = 'DOWNLOAD_TENDER_MANUAL_FOR_BOOK';
export const DOWNLOAD_TENDER_MANUAL_FOR_MODEL = 'DOWNLOAD_TENDER_MANUAL_FOR_MODEL';

export const EDIT_MANUAL_BASIC_INFO = 'EDIT_MANUAL_BASIC_INFO';
export const GET_ALL_MANUALS_START = 'GET_ALL_MANUALS_START';
export const GET_ALL_MANUALS_SUCESS = 'GET_ALL_MANUALS_SUCESS';
export const GET_ALL_MANUALS_FAIL = 'GET_ALL_MANUALS_FAIL';
export const EDIT_MANUAL_STATUS = 'EDIT_MANUAL_STATUS';
export const EDIT_MANUAL_CONTENT = 'EDIT_MANUAL_CONTENT';
export const MODEL_EDIT_REQUEST = 'MODEL_EDIT_REQUEST';
export const GET_ALL_USERS_WITH_MODULE_ID = '  GET_ALL_USERS_WITH_MODULE_ID';
export const GET_ALL_USER_RECENT_DOCUMENTS = 'GET_ALL_USER_RECENT_DOCUMENTS';
export const EDIT_MANUAL_NAME = 'EDIT_MANUAL_NAME';

//researchs
export const GET_ALL_RESEARCHS = 'GET_ALL_RESEARCHS ';
export const ADD_RESEARCH = 'ADD_RESEARCH';
export const UPDATE_RESEARCH = 'UPDATE_RESEARCH';
export const SEARCH_RESEARCH = 'SEARCH_RESEARCH';
export const DELETE_RESEARCH = 'DELETE_RESEARCH';
