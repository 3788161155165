const cards = [
  {
    id: 1,
    icon: 'icon-Stratgey',
    title: 'التخطيط الإستراتيجى',
    cardLink: '#',
  },
  {
    id: 2,
    icon: 'icon-Wallets',
    title: 'إدارة المحافظ',
    cardLink: '#',
  },
  {
    id: 3,
    icon: 'icon-Projects',
    title: 'إدارة المشاريع',
    cardLink: '#',
  },
  {
    id: 4,
    icon: 'icon-Missions',
    title: 'إدارة المناقصات',
    cardLink: '/tender',
  },
  {
    id: 5,
    icon: 'icon-Contracts',
    title: 'إعداد الكراسات',
    cardLink: '/model',
  },
  {
    id: 6,
    icon: 'icon-Financial',
    title: 'المالية',
    cardLink: '#',
  },
  {
    id: 7,
    icon: 'icon-HumanResource',
    title: 'الموارد البشرية',
    cardLink: '#',
  },
  {
    id: 8,
    icon: 'icon-News',
    title: 'الإعلام',
    cardLink: '#',
  },
  {
    id: 9,
    icon: 'icon-documents',
    title: 'إدارة الوثائق',
    cardLink: '#',
  },
  {
    id: 10,
    icon: 'icon-Value',
    title: 'هندسة القيمة',
    cardLink: '#',
  },
  {
    id: 11,
    icon: 'icon-Quality',
    title: 'هندسة الجودة',
    cardLink: '#',
  },
  {
    id: 12,
    icon: 'icon-Internal',
    title: 'المراجعة الداخلية',
    cardLink: '#',
  },
  {
    id: 13,
    icon: 'icon-Partners',
    title: 'الشركاء',
    cardLink: '#',
  },
  {
    id: 14,
    icon: 'icon-Consultants',
    title: 'الإستشاريين',
    cardLink: '#',
  },
  {
    id: 15,
    icon: 'icon-Suppliers',
    title: 'الموردون',
    cardLink: '#',
  },
  {
    id: 16,
    icon: 'icon-Settings',
    title: 'الحسابات الشخصية',
    cardLink: '#',
  },
  {
    id: 17,
    icon: 'icon-Reports',
    title: 'التقارير',
    cardLink: '#',
  },
  {
    id: 18,
    icon: 'icon-InfoPanel',
    title: 'لوحة المعلومات',
    cardLink: '#',
  },
  {
    id: 19,
    icon: 'icon-Resources',
    title: 'مكتبة الأبحاث',
    cardLink: '/library',
  },
  {
    id: 20,
    icon: 'icon-Construction',
    title: 'المقاولون',
    cardLink: '#',
  },
  {
    id: 21,
    icon: 'icon-Committees',
    title: 'الخبراء',
    cardLink: '#',
  },
  {
    id: 22,
    icon: 'icon-Users',
    title: 'إدارة المستخدمين',
    cardLink: '/users',
  },
  {
    id: 23,
    icon: 'icon-Agenda',
    title: 'الأجندة',
    cardLink: '#',
  },
  {
    id: 24,
    icon: 'icon-Settings',
    title: 'إعدادات النظام',
    cardLink: '#',
  },
  
];

export default cards;
