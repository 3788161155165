import React from 'react';
import './requiredinfo.scss';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import { InputText } from 'primereact/inputtext';
import { fetchAllSectors } from '../../../../redux/actions/tenderManagementActions';
import { Toast } from 'primereact/toast';

const RequiredInfo = () => {
  const toast = React.useRef<Toast | null>(null);
  const dispatch = useDispatch();
  const [test, setTest] = React.useState({ name: '', sector: null });
  const allSectors = useSelector((state: RootState) => state.tenderManagement.allSectors);

  React.useEffect(() => {
    dispatch(fetchAllSectors(toast));
  }, [dispatch]);

  const sectorHandler = (e: any) => {
    let sector = e.target.value;
    setTest({ ...test, sector: sector });
  };

  return (
    <div className='after-create-tp-secton'>
      <Toast ref={toast} position="top-left" />
      <div className="requiredinfo-container">
        <div className="requirment-ocontainer">
          <label>
            اسم النموذج <span>*</span>{' '}
          </label>
          <InputText
            readOnly={false}
            className="input-prime"
            value={test.name}
            onChange={(e: any) => setTest({ ...test, name: e.target.value })}
          />
        </div>

        <div className="requirment-ocontainer">
          <label htmlFor="sector">
            قطاع النموذج <span>*</span>
          </label>
          <Dropdown
            disabled={false}
            optionLabel="sectorName"
            value={test.sector}
            options={allSectors}
            onChange={sectorHandler}
            placeholder="القطاع"
          />
        </div>
        <div className="requirment-ocontainer">
          <label htmlFor="sector">
            تصنيف النموذج ١ <span>*</span>
          </label>
          <Dropdown
            disabled={false}
            optionLabel="sectorName"
            value={test.sector}
            options={allSectors}
            placeholder="تصنيف الاول"
          />
        </div>
        <div className="requirment-ocontainer">
          <label htmlFor="sector">
            تصنيف النموذج ٢ <span>*</span>
          </label>
          <Dropdown
            disabled={false}
            optionLabel="sectorName"
            value={test.sector}
            options={allSectors}
            placeholder="تصنيف تاني"
          />
        </div>
      </div>
    </div>
  );
};

export default RequiredInfo;
