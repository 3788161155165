import React, { useEffect, useRef, useState } from 'react';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import filter from '../../../assets/png/filter.png';
import print from '../../../assets/png/print.png';
import { fetchAllSectors, searchProjects } from '../../../redux/actions/tenderManagementActions';
import Loader from '../../Loader/Loader';
import Filter from '../Shared/Filter';

import { RootState } from '../../../redux/reducers';
import ProjectTable from '../ProjectTable/ProjectTable';
import { Toast } from 'primereact/toast';

import '../../../styles/Projects.scss';
import { isNormalUser } from '../../../utils/checkAccess';

const CurrentProjects: React.FC = () => {
  const dispatch = useDispatch();
  const { currentProjects, totalRecords } = useSelector((state: RootState) => state.tenderManagement);
  const loading = useSelector((state: RootState) => state.tenderManagement.loading);

  const { user } = useSelector((state: RootState) => state.user);
  const [params, setParams] = useState({
    page: 0,
    size: 10,
    sort: '',
    direction: '',
    state: 1,
  });
  const [first, setFirst] = useState(0);
  const [filterPayload, setFilterPayload] = useState({});

  const [toggleFilter, setToggleFilter] = useState(false);
  const history = useHistory();
  const toast = useRef<Toast | null>(null);

  useEffect(() => {
    // dispatch actions
    dispatch(fetchAllSectors(toast));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // dispatch actions
    dispatch(searchProjects(filterPayload, { ...params }));
    // eslint-disable-next-line
  }, [params]);

  // toggle filter side menu
  const toggleFilterHandler = () => {
    setToggleFilter(!toggleFilter);
  };

  const searchProjectHandler = (payload: any) => {
    setFilterPayload(payload);
    setParamsHandler('page', 0);
    setFirst(0);
  };

  const setParamsHandler = (key: string, value: string | number) => {
    setParams({ ...params, [key]: value });
  };

  const clickedRowHandler = (e: DataTableRowClickEventParams) => {
    history.push({
      pathname: '/tender/add-project',
      state: { project: e.data, edit: true },
    });
  };

  const goToProjectDetails = () => {
    history.push('/tender/add-project');
  };

  return (
    <div className="projects">
      {loading && <Loader />}
      <Toast ref={toast} position="top-left" />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="titles" style={{ width: '43%' }}>
          <label>عناوين المجموعات المعرفة بالفعل</label>
          <Dropdown emptyMessage="لا توجد بيانات" optionLabel="name" value={''} options={[]} placeholder="" />
        </div>

        <Filter
          searchProjectHandler={searchProjectHandler}
          toggleFilterHandler={toggleFilterHandler}
          toggleFilter={toggleFilter}
        />

        <div className="btns" style={{ marginTop: '0' }}>
          <button
            disabled={isNormalUser(4, user) ? true : false}
            onClick={goToProjectDetails}
            className="add-new-project"
          >
            إضافة مشروع جديد
          </button>
          <button
            title="طباعة"
            style={{ backgroundColor: 'unset', border: '0', cursor: 'pointer' }}
            className="print-pdf"
            onClick={() => window.print()}
          >
            <img width="52px" src={print} alt="print-pdf" />
          </button>
          <button
            title="فرز"
            style={{ backgroundColor: 'unset', border: '0', cursor: 'pointer' }}
            className="filter-btn"
            onClick={toggleFilterHandler}
          >
            <img width="50px" src={filter} alt="filter" />
          </button>
        </div>
      </div>
      <ProjectTable
        projects={currentProjects}
        totalRecords={totalRecords}
        perPage={params.size}
        first={first}
        setFirst={setFirst}
        setParamsHandler={setParamsHandler}
        rowClickHandler={clickedRowHandler}
        gotToAddProjectHandler={goToProjectDetails}
      />
    </div>
  );
};

export default CurrentProjects;
