import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import notFoundImage from '../../assets/svg/not-found-image.svg';
import routes from '../../constants/routes';
import './NotFound.scss';

const NotFound: FC = () => {
  return (
    <div className="not-found">
      <img src={notFoundImage} alt="not-found" />
      <div className="not-found__title">الصفحة غير موجودة</div>
      <Link className="projects-no-found" to={routes.HOME.path}>
        الرجوع للصفحة السابقة
      </Link>
    </div>
  );
};

export default NotFound;
