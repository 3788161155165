import { Research } from '../../models/addResearch.model';
import {
    ADD_RESEARCH,
    DELETE_RESEARCH,
    GET_ALL_RESEARCHS,
    SEARCH_RESEARCH,
    UPDATE_RESEARCH,
    SET_REACHERES_LOADER
} from '../actions/types';

type InitialState = {
    allResearchs: Research[];
    loading: boolean;
    errorMsg: string;
    totalRecords: number;
};

const INITIAL_STATE: InitialState = {
    allResearchs: [],
    loading: false,
    errorMsg: '',
    totalRecords: 0,
};

const researchReducer = (state = INITIAL_STATE, { payload, type }: { type: string; payload: any }): InitialState => {
    switch (type) {
        case SET_REACHERES_LOADER:
            return {
                ...state,
                loading: payload,
                errorMsg: '',
            };

        case DELETE_RESEARCH:
            return {
                ...state,
                loading: false,
                allResearchs: state.allResearchs.filter((ele) => ele.researchId !== payload),
                errorMsg: '',
            };
        case GET_ALL_RESEARCHS:
            return {
                ...state,
                allResearchs: payload.researchs,
                totalRecords: payload.researchs.totalElements,
                loading: false,
            };
        case SEARCH_RESEARCH:
            return {
                ...state,
                allResearchs: payload.researchs,
                totalRecords: payload.users.totalElements,
                loading: false,
                errorMsg: '',
            };
        case ADD_RESEARCH:
            return {
                ...state,
                allResearchs: [...state.allResearchs, payload],
                loading: false,
                errorMsg: '',
            };
        case UPDATE_RESEARCH:
            const allResearchs = [...state.allResearchs];
            const researchIdx = allResearchs.findIndex((ele) => ele.researchId === payload.researchId);
            if (researchIdx >= 0) {
                allResearchs[researchIdx] = payload;
            }
            return {
                ...state,
                allResearchs,
                loading: false,
                errorMsg: '',
            };
        default:
            return state;
    }
};
export default researchReducer;
