import React, { useRef } from 'react';
import './recentlyopened.scss';
import BreadCrumb2 from './BreadCrumb2';
import threedotsyellow from '../../../assets/svg/thre-dots-yellow.svg';
import pin from '../../../assets/svg/pin.svg';
import share from '../../../assets/svg/share.svg';
import { getRentlyOpenTenders } from '../../../redux/actions/manualManagementActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { fetchAllSectors, fetchCurrentProjects } from '../../../redux/actions/tenderManagementActions';
import nomodelssvg from '../../../assets/svg/nomodels.svg';
import { Toast } from 'primereact/toast';
import Loader from '../../Loader/Loader';
import { useHistory } from 'react-router-dom';

const RecentlyOpened: React.FC = () => {
  const [hover, setHover] = React.useState({ show: false, id: -1 });
  const { userRecentlyOpen, loading } = useSelector((state: RootState) => state.manualManagement);
  const { allSectors, currentProjects } = useSelector((state: RootState) => state.tenderManagement);
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useRef<Toast | null>(null);

  const splitDate = (backenddate: any) => {
    if (!backenddate) return null;
    let newdate = backenddate.split('T');
    if (newdate.length === 0) return null;
    return newdate[0];
  };
  React.useEffect(() => {
    userRecentlyOpen.length === 0 && dispatch(getRentlyOpenTenders(history));
    dispatch(fetchCurrentProjects(toast, { state: 1, page: 0, size: 0, sort: '' }));
    dispatch(fetchAllSectors(toast));
  }, []);

  console.log(loading);
  return (
    <div className="model-preration-container">
      <h1 className="recently-opened-title">المستندات المفتوحة مؤخراً</h1>
      {userRecentlyOpen.length !== 0 && (
        <div className="tablecontainer">
          <div className="firstitem">
            <h2 className="right-item">اسم الملف</h2>
            <h2 className="left-first-item">آخر تعديل</h2>
          </div>
          {!loading &&
            userRecentlyOpen.map((doc, i) => (
              <div
                className="item"
                key={i}
                onMouseEnter={() => setHover({ show: true, id: i })}
                onMouseLeave={() => setHover({ show: false, id: -1 })}
              >
                <div className="right-item">
                  <div
                    onClick={() => history.push(`/edit-conditions-manual/${doc.tenderId}`)}
                    className="document-info"
                  >
                    <div>{doc.title}</div>
                    <div>{doc.desc}</div>
                    <BreadCrumb2
                      first={`قطاع ${
                        allSectors.find((sector) => {
                          return sector.sectorCode === doc.sectorCode;
                        })?.sectorName
                      }`}
                      second={`${
                        currentProjects.find((project) => {
                          return project.projectId === doc.projectId;
                        })?.prtsLookupMainCodeByStageCode?.prtsLookupType?.lookupTypeName
                      }
                  ${
                    currentProjects.find((project) => {
                      return project.projectId === doc.projectId;
                    })?.prtsLookupMainCodeByStageCode?.lookupMainName
                  }`}
                      third={`${
                        currentProjects.find((project) => {
                          return project.projectId === doc.projectId;
                        })?.prtsLookupMainCodeByTypeCode?.prtsLookupType?.lookupTypeName
                      }
                  ${
                    currentProjects.find((project) => {
                      return project.projectId === doc.projectId;
                    })?.prtsLookupMainCodeByTypeCode?.lookupMainName
                  }`}
                    />
                  </div>

                  {hover.show && hover.id === i && (
                    <div className="document-hover-icon">
                      <img src={threedotsyellow} alt="threeDotsIcon" />
                      <img src={pin} alt="pinIcon" />
                      <img src={share} alt="shareIcon" />
                    </div>
                  )}
                </div>
                <div className="left-item-div">
                  <div className="left-item">
                    <div className="doc-date">
                      {splitDate(doc?.lastModifyDate) || splitDate(doc?.createDate) || '4-4-2022'}{' '}
                    </div>
                    <div className="doc-by-user">بواسطة {doc.modifiedBy.userName}</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default RecentlyOpened;
