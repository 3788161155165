import React, { FC, useEffect, useRef, useState } from 'react';
import './ViewModel.scss';

import { ConfirmDialog } from 'primereact/confirmdialog';
//import ModalFooter from './ModalFooter';
import { Dialog } from 'primereact/dialog';
import FilesPreview from '../../../../FilesPreview';
import { useDispatch, useSelector } from 'react-redux';
import { viewModelHandler } from '../../../../../redux/actions/tenderManagementActions';
import { Toast } from 'primereact/toast';
import { RootState } from '../../../../../redux/reducers';
// import { PDFViewer } from '@react-pdf/renderer';
// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

type Props = {
  show: boolean;
  hide: any;
  model: any;
};

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'column',
//     padding: '20px',
//   },
//   section: {
//     flexGrow: 1,
//   },
// });
const ViewModel: FC<Props> = ({ show, hide, model }) => {
  const [showBootBox, setShowBootBox] = useState(false);
  const { previewTenderModel } = useSelector((state: RootState) => state.tenderManagement);
  const toast = useRef<Toast | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(model, '36');
    dispatch(viewModelHandler(model.modelId, model.name, toast));
  }, [model, dispatch, toast]);

  const removeHTML = (HTMLcontent: string) => {
    var tmp = document.createElement('div');
    tmp.innerHTML = HTMLcontent;
    return tmp.textContent || tmp.innerText || '';
  };
  if (!previewTenderModel) return <div>loading...</div>;
  return (
    <>
      {showBootBox ? (
        <ConfirmDialog
          visible={showBootBox}
          onHide={() => setShowBootBox(false)}
          message=" في حالة التأكيد سيتم مسح التغييرات و لن يتم انشاء كراسة الشروط"
          header="تأكيد إلغاء الانشاء"
          acceptLabel="تأكيد"
          rejectLabel="تجاهل"
          acceptClassName="accept"
          rejectClassName="reject"
          accept={() => {
            hide(true);
          }}
          className="modalBootbox"
        />
      ) : (
        <FilesPreview
          id="ViewConditionsManual"
          handlePreviewOpen={() => {
            setShowBootBox(true);
          }}
          isPreviewOpen={show}
          fileBase64={previewTenderModel}
          fileType="pdf"
        />
      )}
    </>
  );
};

export default ViewModel;
