import { User } from '../../models/User';
import {
  MANUAL_CREATED,
  MANUAL_CREATION_FAILED,
  GET_MANUAL,
  EDIT_MANUAL_BASIC_INFO,
  GET_ALL_MANUALS_START,
  GET_ALL_MANUALS_SUCESS,
  GET_ALL_MANUALS_FAIL,
  EDIT_MANUAL_STATUS,
  EDIT_MANUAL_CONTENT,
  GET_ALL_USERS_WITH_MODULE_ID,
  GET_ALL_USER_RECENT_DOCUMENTS,
} from '../actions/types';

type InitialState = {
  manualCreationErr: boolean;
  newManualId: number;
  manualSections: Array<any>;
  underModificationManuals: Array<any>;
  archivedManuals: Array<any>;
  approvedManuals: Array<any>;
  loading: boolean;
  err: boolean;
  getManual: any;
  editManual: any;
  statusId: number;
  allUsersWithModuleId: User[];
  userRecentlyOpen: Array<any>;
  previewTenderModel: any;
};

const INITIAL_STATE: InitialState = {
  underModificationManuals: [],
  archivedManuals: [],
  approvedManuals: [],
  err: false,
  manualCreationErr: false,
  newManualId: 0,
  manualSections: [],
  loading: false,
  getManual: {},
  editManual: {},
  statusId: 2,
  allUsersWithModuleId: [],
  userRecentlyOpen: [],
  previewTenderModel: '',
};

const manualManagementReducer = (state = INITIAL_STATE, action: { type: string; payload: any }): InitialState => {
  switch (action.type) {
    case MANUAL_CREATED:
      return {
        ...state,
        newManualId: action.payload.id,
        manualCreationErr: false,
        manualSections: [...action.payload.sections],
        underModificationManuals: [...state.underModificationManuals, action.payload],
      };
    case MANUAL_CREATION_FAILED:
      return { ...state, manualCreationErr: true };
    case GET_MANUAL:
      return { ...state, getManual: action.payload };
    case EDIT_MANUAL_BASIC_INFO:
      return { ...state, editManual: action.payload };
    case EDIT_MANUAL_CONTENT:
      return { ...state };
    case GET_ALL_MANUALS_START:
      return { ...state, loading: true };
    case GET_ALL_MANUALS_SUCESS:
      state.statusId = action.payload.statusId;

      if (state.statusId === 1)
        return { ...state, underModificationManuals: [...action.payload.manuals], newManualId: 0 };
      else if (state.statusId === 2) return { ...state, approvedManuals: [...action.payload.manuals], newManualId: 0 };
      else return { ...state, archivedManuals: [...action.payload.manuals], newManualId: 0 };
    case GET_ALL_MANUALS_FAIL:
      return { ...state, loading: false, err: true };
    case GET_ALL_USER_RECENT_DOCUMENTS:
      return { ...state, userRecentlyOpen: action.payload, loading: false };
    case EDIT_MANUAL_STATUS:
      console.log(action.payload);
      let archivedManuals = state.archivedManuals.filter((manual: any) => manual.tenderId !== action.payload.tenderId);
      let approvedManuals = state.approvedManuals.filter((manual: any) => manual.tenderId !== action.payload.tenderId);
      let underModificationManual = state.underModificationManuals.filter(
        (manual: any) => manual.tenderId !== action.payload.tenderId,
      );
      if (action.payload.statusId === 1)
        return {
          ...state,
          underModificationManuals: [...state.underModificationManuals, action.payload],
          archivedManuals: [...archivedManuals],
          approvedManuals: [...approvedManuals],
        };
      else if (action.payload.statusId === 2)
        return {
          ...state,
          approvedManuals: [...state.approvedManuals, action.payload],
          archivedManuals: [...archivedManuals],
          underModificationManuals: [...underModificationManual],
        };
      else if (action.payload.statusId === 3)
        return {
          ...state,
          archivedManuals: [...state.archivedManuals, action.payload],
          approvedManuals: [...approvedManuals],
          underModificationManuals: [...underModificationManual],
        };
      else return { ...state };

    case GET_ALL_USERS_WITH_MODULE_ID:
      return {
        ...state,
        allUsersWithModuleId: action.payload.users,
        loading: false,
      };

    default:
      return state;
  }
};

export default manualManagementReducer;
