import { LoginUser, Module, User } from '../../models/User';
import { getStorageData } from '../../utils/storage';
import {
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_TOKEN,
  SET_USER_LOADER,
  GET_ALL_USERS,
  GET_ALL_MODULES,
  SEARCH_USER,
  DELETE_USER,
  RESET_PASSWORD,
  ADD_USER,
  UPDATE_USER,
  SET_ERROR_MSG,
  FORCE_LOGOUT_USER,
  CURRENT_MODULE,
  EMAIL_EXISTS,
} from '../actions/types';

type InitialState = {
  user: LoginUser | null;
  allUsers: User[];
  modules: Module[];
  currentModule: Module | null;
  token: string | null;
  tokenExpireDate: string | null;
  loading: boolean;
  errorMsg: string;
  totalRecords: number;
  isEmailExist: boolean;
};

const INITIAL_STATE: InitialState = {
  user: getStorageData('user'),
  allUsers: [],
  modules: [],
  token: getStorageData('token'),
  tokenExpireDate: getStorageData('tokenExpireDate'),
  loading: false,
  currentModule: null,
  errorMsg: '',
  totalRecords: 0,
  isEmailExist: false,
};

const userReducer = (state = INITIAL_STATE, { payload, type }: { type: string; payload: any }): InitialState => {
  switch (type) {
    case SET_USER_LOADER:
      return {
        ...state,
        loading: payload,
        errorMsg: '',
      };
    case SET_ERROR_MSG:
      return {
        ...state,
        errorMsg: payload,
      };
    case CURRENT_MODULE:
      return {
        ...state,
        currentModule: payload,
      };
    case LOGIN_USER:
      return {
        ...state,
        user: payload.user,
        token: payload.token,
        tokenExpireDate: payload.tokenExpireDate,
        loading: false,
        errorMsg: '',
      };
    case RESET_PASSWORD:
      return {
        ...state,
        user: payload,
        loading: false,
        errorMsg: '',
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        token: payload.token,
        tokenExpireDate: payload.tokenExpireDate,
        loading: false,
        errorMsg: '',
      };
    case LOGOUT_USER:
    case FORCE_LOGOUT_USER:
      return {
        ...state,
        user: null,
        token: null,
        tokenExpireDate: null,
        allUsers: [],
        modules: [],
        loading: false,
        errorMsg: '',
      };
    case DELETE_USER:
      return {
        ...state,
        loading: false,
        allUsers: state.allUsers.filter((ele) => ele.userId !== payload),
        errorMsg: '',
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: payload.users.content,
        totalRecords: payload.users.totalElements,
        loading: false,
      };
    case SEARCH_USER:
      return {
        ...state,
        allUsers: payload.users.content,
        totalRecords: payload.users.totalElements,
        loading: false,
        errorMsg: '',
      };
    case ADD_USER:
      return {
        ...state,
        allUsers: [...state.allUsers, payload],
        loading: false,
        errorMsg: '',
      };
    case UPDATE_USER:
      const allUsers = [...state.allUsers];
      const userIdx = allUsers.findIndex((ele) => ele.userId === payload.userId);
      if (userIdx >= 0) {
        allUsers[userIdx] = payload;
      }
      return {
        ...state,
        allUsers,
        loading: false,
        errorMsg: '',
      };
    case GET_ALL_MODULES:
      return {
        ...state,
        modules: payload,
        loading: false,
        errorMsg: '',
      };
    case EMAIL_EXISTS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        isEmailExist: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
