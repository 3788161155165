const handlelvlOneinsertion = (arrayOfLevels: any, setArrayOfLevels: any, addOption: string, oldValue?: string) => {
  let newarrayoflevels = arrayOfLevels.map((lvl: any) => {
    if (lvl.id === 1) {
      return oldValue
        ? {
            ...lvl,
            arrayofoptions: lvl.arrayofoptions.map((opt: any) => {
              if (oldValue === opt.option) {
                return { ...opt, option: addOption };
              } else {
                return opt;
              }
            }),
          }
        : { ...lvl, arrayofoptions: [...lvl.arrayofoptions, { option: addOption, arrayofoptions: [] }] };
    } else {
      return lvl;
    }
  });
  setArrayOfLevels(newarrayoflevels);
};
const handlelvlTwoinsertion = (
  arrayOfLevels: any,
  setArrayOfLevels: any,
  addOption: string,
  oldValue?: string,
  lvlOneSelected?: string,
) => {
  let newarray = arrayOfLevels.map((lvl: any) => {
    if (lvl.id === 1) {
      return {
        ...lvl,
        arrayofoptions: lvl.arrayofoptions.map((opt: any) => {
          if (opt.option === lvlOneSelected) {
            return oldValue
              ? {
                  ...opt,
                  arrayofoptions: opt.arrayofoptions.map((opt: any) => {
                    if (opt.option === oldValue) {
                      return { ...opt, option: addOption };
                    } else {
                      return opt;
                    }
                  }),
                }
              : { ...opt, arrayofoptions: [...opt.arrayofoptions, { option: addOption, arrayofoptions: [] }] };
          } else {
            return opt;
          }
        }),
      };
    } else {
      return lvl;
    }
  });
  setArrayOfLevels(newarray);
};

const handlelvlThreeinsertion = (
  arrayOfLevels: any,
  setArrayOfLevels: any,
  addOption: string,
  oldValue?: string,
  lvlOneSelected?: string,
  lvlTwoSelected?: string,
) => {
  let newarray = arrayOfLevels.map((lvl: any) => {
    if (lvl.id === 1) {
      return {
        ...lvl,
        arrayofoptions: lvl.arrayofoptions.map((opt: any) => {
          if (opt.option === lvlOneSelected) {
            //   let newarrayofoption = opt.arrayofOptions.push({ option: addOption, arrayofOptions: [] });
            let newarray = opt.arrayofoptions.map((optlvltwo: any) => {
              if (optlvltwo.option === lvlTwoSelected) {
                return oldValue
                  ? {
                      ...optlvltwo,
                      arrayofoptions: optlvltwo.arrayofoptions.map((opt: any) => {
                        if (opt.option === oldValue) return { ...opt, option: addOption };
                        else {
                          return opt;
                        }
                      }),
                    }
                  : { ...optlvltwo, arrayofoptions: [...optlvltwo.arrayofoptions, { option: addOption }] };
              } else {
                return optlvltwo;
              }
            });
            return { ...opt, arrayofoptions: newarray };
          } else {
            return opt;
          }
        }),
      };
    } else {
      return lvl;
    }
  });
  setArrayOfLevels(newarray);
};

export const handleAdd = (
  lvlId: number,
  newValue: string,
  list: any[],
  setlist: any,
  oldValue?: string,
  lvlOneSelected?: string,
  lvlTwoSelected?: string,
) => {
  // if oldvalue therefore update else add
  lvlId === 1 && handlelvlOneinsertion(list, setlist, newValue, oldValue);
  lvlId === 2 && handlelvlTwoinsertion(list, setlist, newValue, oldValue, lvlOneSelected);
  lvlId === 3 && handlelvlThreeinsertion(list, setlist, newValue, oldValue, lvlOneSelected, lvlTwoSelected);
};
