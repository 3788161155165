import React, { FC, useEffect } from 'react';

import StackedBarChartIcon from '../../../assets/svg/stacked-bar-chart.svg';
import DonutChart from '../../../assets/svg/donut-chart.svg';
import ShowChart from '../../../assets/svg/show-chart.svg';

type Props = {
  selectedChart: number;
  setSelectedChart: any;
  isDisplayed: Boolean;
};

const SwitchCharts: FC<Props> = ({ selectedChart, setSelectedChart, isDisplayed }) => {
  useEffect(() => {
    const switchBtn = document.getElementsByClassName('switch-container')[0];
    for (let i = 0; i < switchBtn.children.length; i++) {
      switchBtn.children[i].setAttribute('id', '');
    }
    switchBtn.children[selectedChart - 1].setAttribute('id', 'activeSwitchBtn');
  }, [selectedChart]);

  return (
    <div className="switch-container">
      <button onClick={() => setSelectedChart(1)}>
        <img src={StackedBarChartIcon} alt="" />
      </button>

      <button onClick={() => setSelectedChart(2)} className={isDisplayed ? 'd-none' : ''}>
        <img src={DonutChart} alt="" />
      </button>
      <button onClick={() => setSelectedChart(3)} className={isDisplayed ? 'd-none' : ''}>
        <img src={ShowChart} alt="" />
      </button>
    </div>
  );
};

export default SwitchCharts;
