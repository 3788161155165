import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';
import './text-editor.scss';

const TextEditor = ({ location, setHTMLContent, preDisplayedContent, viewOnly, biggerScreen, HTMLcontent }) => {
  const editor = useRef(null);
  React.useEffect(() => {}, [biggerScreen, location]);

  const config = {
    readonly: viewOnly, // all options from https://xdsoft.net/jodit/doc/
    height: '520px',

    width: '839px',
    colors: ['#ff0000', '#00ff00', '#0000ff'],
    fullsize: false,
    hidePoweredByJodit: false,
    placeholder: '',
    // buttons: [ "bold", "italic", "underline", "strikethrough", "|", "ul", "ol", "|", "center", "left", "right", "justify", "|", "link", "image"],
    removeButtons: ['hr', 'fullsize', 'fontfamily', 'paint', 'ul'],
    toolbarSticky: false,
    toolbarStickyOffset: 10,
    statusbar: false,
    toolbarButtonSize: 'medium',
    allowResizeX: false,
    useSearch: true,
    toolbarInline: false,
    saveModeInStorage: true,
    iframe: false,
    globalFullSize: false,
    styleValues: {
      colorBorder: '2px solid black',
    },
    style: {
      font: '16px Arial',
      padding: '30px',
    },
  };
  React.useEffect(() => {
    setHTMLContent(preDisplayedContent);
  }, [preDisplayedContent]);
  return (
    <JoditEditor
      ref={editor}
      value={HTMLcontent || ''}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={(newContent) => setHTMLContent(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {}}
    />
  );
};

export default TextEditor;
