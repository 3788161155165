import React from 'react';
import { FC, Fragment, Key, useState } from 'react';
import './SideMenu2.scss';
import { useHistory } from 'react-router-dom';
import reef from '../../assets/png/reef.png';
import vara from '../../assets/svg/vara.svg';
import arrowLeftTriangle from '../../assets/svg/arrow-left-triangle.svg';
import editFieldsName from '../../assets/png/edit-fields-name.png';
import Marginer from '../Marginer/Marginer';
import { getContent } from './getContent';
import { getReadonly } from './getReadOnly';
import dragReOrder from '../../assets/png/dragReOrder.png';
import { useDispatch } from 'react-redux';
import { createNewSection, createNewSubSection } from '../../redux/actions/modelMangmentActions';
import { updateSectionsArray, handleOrder } from '../../api/apiRequest';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import rightarrow from '../../assets/svg/right-arrow.svg';

type props = {
  setLocation: any;
  mainHeadersList: any[];
  setMainHeadersList: any;
  setPreDisplayedContent: any;
  ModelID: any;
  setReadOnly: any;
  preDisplayedContent: any;
  HTMLcontent: any;
  location: any;
  setHTMLContent: any;
};

const SideMenu2: FC<props> = ({
  setLocation,
  mainHeadersList,
  setMainHeadersList,
  setPreDisplayedContent,
  setReadOnly,
  ModelID,
  preDisplayedContent,
  HTMLcontent,
  location,
  setHTMLContent,
}) => {
  const [newSubHeaderInputTagId, setNewSubHeaderInputTagId] = React.useState<any>(null);
  const history = useHistory();
  const [activeId, setActiveId] = useState('');
  const [hoverId, setHoverId] = useState('');
  const [dragId, setDragId] = useState();
  const [editedValue, setEditedValue] = useState('');
  const [openInputTagId, setOpenInputTagId] = useState('');
  const [addNewHeaderFlag, setAddNewHeaderFlag] = React.useState({ mainHeader: true, subHeader: true });
  const [editId, setEditId] = React.useState('');
  const [confirmLoctionSwitch, setConfirmLoctionSwitch] = useState({ show: false, parent: '', child: '' });
  const [oldContent2, setOldContent2] = useState('');
  const dispatch = useDispatch();
  const toast = React.useRef(null);

  /////Drag and Re-order
  const [mainHeaderDragPosition, setMainHeaderDragPosition] = useState();
  const [isDragMainHeader, setIsDragMainHeader] = React.useState<boolean>();
  const handleDrag = (header: any, isMainHeader: boolean, mainHeaderDragPosition: any) => {
    setIsDragMainHeader(isMainHeader);
    setEditId('');
    setDragId(header.id);
    setMainHeaderDragPosition(mainHeaderDragPosition);
  };

  const handleDrop = (header: any, isMainHeader: boolean, MainHeaderPositionDrop: any) => {
    let tmpHeadersList = [...mainHeadersList];

    if (isMainHeader && isDragMainHeader) {
      const dragHeader = mainHeadersList.find((mainHeader) => mainHeader.id === dragId);
      const dropHeader = mainHeadersList.find((mainHeader) => mainHeader.id === header.id);

      tmpHeadersList[mainHeadersList.indexOf(dragHeader)] = dropHeader;
      tmpHeadersList[mainHeadersList.indexOf(dropHeader)] = dragHeader;
      updateSectionsArray(handleOrder(tmpHeadersList), ModelID);
    } else if (!isMainHeader && !isDragMainHeader && MainHeaderPositionDrop === mainHeaderDragPosition) {
      const dragHeader = mainHeadersList[MainHeaderPositionDrop].subSections.find(
        (subHeader: any) => subHeader.id === dragId,
      );
      const dropHeader = mainHeadersList[MainHeaderPositionDrop].subSections.find(
        (subHeader: any) => subHeader.id === header.id,
      );
      let tmpsubSections = [...mainHeadersList[MainHeaderPositionDrop].subSections];

      tmpsubSections[mainHeadersList[MainHeaderPositionDrop].subSections.indexOf(dragHeader)] = dropHeader;
      tmpsubSections[mainHeadersList[MainHeaderPositionDrop].subSections.indexOf(dropHeader)] = dragHeader;

      tmpHeadersList[MainHeaderPositionDrop].subSections = tmpsubSections;
      updateSectionsArray(handleOrder(tmpHeadersList), ModelID);
    }
    setMainHeadersList(handleOrder(tmpHeadersList));
  };

  const inputHeader = (isMainHeader: boolean, MainHeaderPosition: any) => {
    if (isMainHeader) {
      const mainHeader: any = document.getElementById('headerInput');
      const mainHeaderObject = {
        title: mainHeader?.value,
        content: '',
        id: mainHeadersList.length,
        notes: [],
        readOnly: false,
        subSections: [],
        order: mainHeadersList.length,
      };
      dispatch(createNewSection(ModelID, { ...mainHeaderObject, id: null }, mainHeadersList.length, toast));
      mainHeadersList?.sort((a: any, b: any) => {
        return a.order - b.order;
      });
      mainHeadersList?.forEach((section: any) => {
        section.subSections?.sort((a: any, b: any) => {
          return a.order - b.order;
        });
      });
      setMainHeadersList([...mainHeadersList, mainHeaderObject]);
      setAddNewHeaderFlag({ mainHeader: true, subHeader: addNewHeaderFlag.subHeader });
    } else {
      const subHeader: any = document.getElementById('subHeaderInput');
      const id = mainHeadersList[MainHeaderPosition].subSections.length;
      const subHeaderObject = {
        title: subHeader?.value,
        content: '',
        id: null,
        notes: [],
        readOnly: false,
        order: id,
      };

      mainHeadersList[MainHeaderPosition].subSections.push(subHeaderObject);
      dispatch(createNewSubSection(ModelID, { ...mainHeadersList[MainHeaderPosition] }, toast));
      setAddNewHeaderFlag({ mainHeader: addNewHeaderFlag.mainHeader, subHeader: true });
      setNewSubHeaderInputTagId(null);
    }
  };

  const editHeaderHandler = (id: any, order: any, isMainHeader: boolean, parentOrder: any) => {
    const element: any = document.getElementById(id);
    if (isMainHeader) {
      mainHeadersList[order].title = element?.value;

      dispatch(
        createNewSection(
          ModelID,
          { ...mainHeadersList[order], id: mainHeadersList[order].id },
          mainHeadersList.length,
          toast,
        ),
      );
    } else {
      mainHeadersList[parentOrder].subSections[order].title = element?.value;

      dispatch(
        createNewSection(
          ModelID,
          { ...mainHeadersList[parentOrder], id: mainHeadersList[parentOrder].id },
          mainHeadersList.length,
          toast,
        ),
      );
    }
    setEditId('');
  };
  const showInputHandler = (e: any) => {
    if (e.target.id !== openInputTagId) {
      if (!addNewHeaderFlag.mainHeader || !addNewHeaderFlag.subHeader) {
        setAddNewHeaderFlag({ mainHeader: true, subHeader: true });
      } else if (editId !== '') {
        setEditId('');
      }
    }
  };

  const switchLocationHandler = (secionId: string, subSsectionId: string) => {
    if (
      preDisplayedContent === '-' ||
      HTMLcontent === oldContent2 ||
      HTMLcontent === '' ||
      preDisplayedContent === HTMLcontent
    ) {
      setLocation({ parent: secionId, child: subSsectionId });

      if (subSsectionId === '') {
        setActiveId(`mainHeader-${secionId}`);
        setPreDisplayedContent(getContent(secionId, 'none', mainHeadersList));
        setReadOnly(getReadonly(secionId, 'none', mainHeadersList));
      } else {
        setActiveId(`subHeader-${secionId}_${subSsectionId}`);
        setPreDisplayedContent(getContent(secionId, subSsectionId, mainHeadersList));
        setReadOnly(getReadonly(secionId, subSsectionId, mainHeadersList));
      }
    } else {
      setConfirmLoctionSwitch({ show: true, parent: secionId, child: subSsectionId });
    }
  };
  const locationSwitchAccept = () => {
    setHTMLContent('');
    locationSwitch();
  };
  const locationSwitch = () => {
    setLocation({ parent: confirmLoctionSwitch.parent, child: confirmLoctionSwitch.child });
    if (confirmLoctionSwitch.child === '') {
      setActiveId(`mainHeader-${confirmLoctionSwitch.parent}`);
      setPreDisplayedContent(getContent(confirmLoctionSwitch.parent, 'none', mainHeadersList));
      setReadOnly(getReadonly(confirmLoctionSwitch.parent, 'none', mainHeadersList));
    } else {
      setActiveId(`subHeader-${confirmLoctionSwitch.parent}_${confirmLoctionSwitch.child}`);
      setPreDisplayedContent(getContent(confirmLoctionSwitch.parent, confirmLoctionSwitch.child, mainHeadersList));
      setReadOnly(getReadonly(confirmLoctionSwitch.parent, confirmLoctionSwitch.child, mainHeadersList));
    }
  };

  React.useEffect(() => {
    setOldContent2(HTMLcontent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div id="sideMenu" className="side-menu">
      <Toast ref={toast} position="top-left" />
      <ConfirmDialog
        visible={confirmLoctionSwitch.show}
        onHide={() => setConfirmLoctionSwitch({ ...confirmLoctionSwitch, show: false })}
        message={'يوجد عمل لم يتم حفظه هل تود المتابعه'}
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={locationSwitchAccept}
        className="modalBootbox"
      />
      <div className="side-menu-logos">
        <div className="reef-logo" onClick={() => history.push('/home')}>
          <Fragment>
            <div className="vara-title">
              <div className="vara-img">
                <img src={vara} alt="vara-img" />
              </div>
              <Marginer direction="vertical" margin="4rem" />
              <div>
                <img src={reef} alt="reef-logo" className="reef-img" />
              </div>
            </div>
          </Fragment>
        </div>
      </div>
      <ul onClick={showInputHandler}>
        <div className="sideMenuHeader">
          <p className="headerContent">هيكل النموذج</p>
        </div>
        <div onClick={() => history.push('/model/mangment')} className="sidemenugoback">
          <div>
            <img src={rightarrow} width={20} height={20} alt="back" />
          </div>
          <h2>العوده الي اداره النماذج</h2>
        </div>

        <div className="sideMenuContent">
          {mainHeadersList &&
            mainHeadersList
              .sort((a, b) => {
                return a.order - b.order;
              })
              .map((mainHeader: any, i: Key | null | undefined) => {
                return (
                  <div key={i}>
                    {editId === `mainHeader-${i}` ? (
                      <input
                        key={i}
                        className="inputMainHeader"
                        id={`editMainHeader-${i}`}
                        value={editedValue}
                        onChange={(e) => {
                          setEditedValue(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          e.key === 'Enter' && editHeaderHandler(`editMainHeader-${i}`, i, true, null);
                          setOpenInputTagId('');
                        }}
                      />
                    ) : (
                      <div
                        draggable={true}
                        onDragOver={(e) => e.preventDefault()}
                        onDragStart={() => handleDrag(mainHeader, true, 0)}
                        onDrop={() => handleDrop(mainHeader, true, 0)}
                        className={
                          activeId === `mainHeader-${mainHeader.id}` ? 'headersList activeHeader' : 'headersList'
                        }
                        key={i}
                        id={`mainHeader-${i}`}
                        onMouseEnter={() => {
                          setHoverId(`mainHeader-${i}`);
                        }}
                        onMouseLeave={() => {
                          setHoverId('');
                        }}
                      >
                        <img
                          src={dragReOrder}
                          alt="drag-reorder"
                          className="drag-reorder-icon"
                          style={{
                            display: hoverId === `mainHeader-${i}` ? 'block' : 'none',
                          }}
                        />

                        <div
                          key={i}
                          className="main-header-title-container"
                          style={{ marginRight: hoverId === `mainHeader-${i}` ? '0' : '15%' }}
                          onClick={() => {
                            switchLocationHandler(mainHeader.id, '');
                          }}
                        >
                          <div key={i} className="main-headers">
                            <div className="mainBulletContainer" key={i}>
                              <svg className="mainBullet" key={i}></svg>
                            </div>
                            <p>{mainHeader.title}</p>
                          </div>
                        </div>

                        <img
                          src={editFieldsName}
                          alt="edit-fields-name"
                          className="icons"
                          onClick={() => {
                            setEditId(`mainHeader-${i}`);
                            setAddNewHeaderFlag({ mainHeader: true, subHeader: true });
                            setEditedValue(mainHeader.title);
                            setOpenInputTagId(`editMainHeader-${i}`);
                          }}
                          style={{
                            display: hoverId === `mainHeader-${i}` && activeId !== `mainHeader-${i}` ? 'block' : 'none',
                          }}
                        />

                        <img
                          src={arrowLeftTriangle}
                          alt="arrow-left-triangle"
                          className="icons"
                          style={{
                            display:
                              activeId === `mainHeader-${mainHeader.id}` && hoverId !== `mainHeader-${i}`
                                ? 'block'
                                : 'none',
                          }}
                        />
                      </div>
                    )}

                    {mainHeader.subSections &&
                      mainHeader.subSections
                        .sort((a: any, b: any) => {
                          return a.order - b.order;
                        })
                        .map((subHeader: any, j: Key | null | undefined) => {
                          return editId === `subHeader-${i}_${j}` ? (
                            <input
                              key={j}
                              className="inputSubHeader"
                              id={`editSubHeader-${i}_${j}`}
                              name="editInput"
                              value={editedValue}
                              onChange={(e) => {
                                setEditedValue(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.key === 'Enter' && editHeaderHandler(`editSubHeader-${i}_${j}`, j, false, i);
                                setOpenInputTagId('');
                              }}
                            />
                          ) : (
                            <div
                              draggable={true}
                              onDragOver={(e) => e.preventDefault()}
                              onDragStart={() => handleDrag(subHeader, false, i)}
                              onDrop={() => handleDrop(subHeader, false, i)}
                              className={
                                activeId === `subHeader-${mainHeader.id}_${subHeader.id}`
                                  ? 'headersList activeHeader'
                                  : 'headersList'
                              }
                              key={j}
                              id={`subHeader-${i}_${j}`}
                              onMouseEnter={() => {
                                setHoverId(`subHeader-${i}_${j}`);
                              }}
                              onMouseLeave={() => {
                                setHoverId('');
                              }}
                            >
                              <img
                                src={dragReOrder}
                                alt="drag-reorder"
                                className="drag-reorder-sub-icon"
                                style={{
                                  display: hoverId === `subHeader-${i}_${j}` ? 'block' : 'none',
                                }}
                              />

                              <div
                                key={j}
                                className="subHeaderContainer"
                                style={{ marginRight: hoverId === `subHeader-${i}_${j}` ? '0' : '20%' }}
                                onClick={() => {
                                  switchLocationHandler(mainHeader.id, subHeader.id);
                                }}
                              >
                                <div key={j} className="sub-headers">
                                  <div className="subBulletContainer" key={j}>
                                    <svg className="subBullet" key={j}></svg>
                                  </div>
                                  <p>{subHeader.title}</p>
                                </div>
                              </div>

                              <img
                                src={editFieldsName}
                                alt="edit-fields-name"
                                className="icons"
                                onClick={() => {
                                  setEditId(`subHeader-${i}_${j}`);
                                  setEditedValue(subHeader.title);
                                  setAddNewHeaderFlag({ mainHeader: true, subHeader: true });
                                  setOpenInputTagId(`editSubHeader-${i}_${j}`);
                                }}
                                style={{
                                  display:
                                    hoverId === `subHeader-${i}_${j}` && activeId !== `subHeader-${i}_${j}`
                                      ? 'block'
                                      : 'none',
                                }}
                              />

                              <img
                                src={arrowLeftTriangle}
                                alt="arrow-left-triangle"
                                className="icons"
                                style={{
                                  display:
                                    activeId === `subHeader-${mainHeader.id}_${subHeader.id}` &&
                                    hoverId !== `subHeader-${i}_${j}`
                                      ? 'block'
                                      : 'none',
                                }}
                              />
                            </div>
                          );
                        })}

                    {addNewHeaderFlag.subHeader || newSubHeaderInputTagId !== i ? (
                      <div
                        className="addSubHeader"
                        onClick={() => {
                          setAddNewHeaderFlag({
                            mainHeader: true,
                            subHeader: false,
                          });
                          setNewSubHeaderInputTagId(i);
                          setEditId('');
                          setOpenInputTagId('subHeaderInput');
                        }}
                      >
                        <span>+ </span>
                        اضافة عنوان فرعي جديد
                      </div>
                    ) : (
                      newSubHeaderInputTagId === i && (
                        <input
                          className="inputSubHeader"
                          id="subHeaderInput"
                          onKeyPress={(e) => {
                            e.key === 'Enter' && inputHeader(false, i);
                            setOpenInputTagId('');
                          }}
                        />
                      )
                    )}
                    <hr className="horizontalMarginer" />
                  </div>
                );
              })}

          <div className="add-main-header-container">
            {addNewHeaderFlag.mainHeader ? (
              <div
                className="addMainHeader"
                onClick={() => {
                  setAddNewHeaderFlag({
                    mainHeader: false,
                    subHeader: true,
                  });
                  setEditId('');
                  setOpenInputTagId('headerInput');
                }}
              >
                <span>+ </span> اضافة عنوان رئيسي جديد
              </div>
            ) : (
              <input
                className="inputMainHeader"
                id="headerInput"
                onKeyPress={(e) => {
                  e.key === 'Enter' && inputHeader(true, 0);
                  setOpenInputTagId('');
                }}
              />
            )}
          </div>
        </div>
      </ul>
    </div>
  );
};

export default SideMenu2;
