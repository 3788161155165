import AddProject from '../../components/Tender/AddProject/AddProject';
import ArchivedProjects from '../../components/Tender/ArchivedProjects/ArchivedProjects';
import CurrentProjects from '../../components/Tender/CurrentProjects/CurrentProjects';
import Initiatives from '../../components/Tender/Initiatives/Initiatives';
import Panel from '../../components/Tender/Panel/Panel';
import Performance from '../../components/Tender/Performance/Performance';
import PersonalSettings from '../../components/Tender/PersonalSettings/PersonalSettings';
import Reports from '../../components/Tender/Reports/Reports';
import Settings from '../../components/Tender/Settings/Settings';
import Tender from '../../pages/Tender/Tender';

import { IRoutes } from '../../models/routes.model';

const routes: IRoutes = {
  TENDER: {
    path: '/tender',
    name: 'tender',
    component: Tender,
    exact: false,
    routes: {
      current: {
        path: '/tender/current-projects',
        name: 'currentProjects',
        component: CurrentProjects,
        exact: true,
      },
      archived: {
        path: '/tender/archived-projects',
        name: 'archivedProjects',
        component: ArchivedProjects,
        exact: true,
      },
      initiatives: {
        path: '/tender/initiatives',
        name: 'initiatives',
        component: Initiatives,
        exact: true,
      },
      performance: {
        path: '/tender/performance',
        name: 'performance',
        component: Performance,
        exact: true,
      },
      panel: {
        path: '/tender/panel',
        name: 'panel',
        component: Panel,
        exact: true,
      },
      reports: {
        path: '/tender/reports',
        name: 'reports',
        component: Reports,
        exact: true,
      },
      addProject: {
        path: '/tender/add-project',
        name: 'addProject',
        component: AddProject,
        exact: true,
      },
      personalSettings: {
        path: '/tender/personal-settings',
        name: 'PersonalSettings',
        component: PersonalSettings,
        exact: true,
      },
      settings: {
        path: '/tender/settings',
        name: 'settings',
        component: Settings,
        exact: true,
      },
    },
  },
};

export default routes;
