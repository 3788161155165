import {
  GET_ALL_MODELS_START,
  GET_ALL_MODELS_FAIL,
  GET_ALL_MODELS_SUCESS,
  MODEL_CREATED,
  MODEL_CREATION_FAILED,
  NEW_MODEL_SECTIONID,
  NEW_MODEL_SUBSECTIONID,
  MODEL_NOTE,
  APPROVE_MODEL,
  GET_SINGLE_MODEL,
  ARCHIVE_MODEL,
  MODEL_EDIT_REQUEST,
} from '../actions/types';

type newSectionId = {
  DbID?: number;
  OldID?: number;
  sectionID?: number;
};
type note = {
  subSectionId?: number;
  noteText?: string;
  noteId?: string;
};
type InitialState = {
  modelCreationErr: boolean;
  newModelId: number;
  models: Array<any>;
  approvedmodels: Array<any>;
  loading: boolean;
  err: boolean;
  newModelSectionId: newSectionId;
  newmodelsubsectionId: newSectionId;
  noteID: note;
  editmodel: any;
  singleModel: any;
  archivedModels: Array<any>;
};

const INITIAL_STATE: InitialState = {
  models: [],
  approvedmodels: [],
  err: false,
  modelCreationErr: false,
  newModelId: 0,
  loading: false,
  newModelSectionId: {},
  newmodelsubsectionId: {},
  noteID: {},
  editmodel: {},
  singleModel: [],
  archivedModels: [],
};

const filterModels = (models: Array<any>, status: number) => {
  const filteredmodels = models.filter((model) => model.status.id === status);

  return filteredmodels;
};
const notedecoder = (note: string) => {
  // let newnote = note.replace('+', ' ');
  // newnote = note.replace('=', ' ');
  // newnote = note.replace('%', ' ');
  //
  return note;
};

const modelMangmentReducer = (state = INITIAL_STATE, action: { type: string; payload: any }): InitialState => {
  switch (action.type) {
    case GET_SINGLE_MODEL:
      return { ...state, singleModel: action.payload };
    case GET_ALL_MODELS_START:
      return { ...state, loading: true };
    case GET_ALL_MODELS_SUCESS:
      return {
        ...state,
        loading: false,
        models: filterModels(action.payload, 1),
        approvedmodels: filterModels(action.payload, 2),
        archivedModels: filterModels(action.payload, 3),
        newModelId: 0,
      };
    case GET_ALL_MODELS_FAIL:
      return { ...state, loading: false, err: true };
    case APPROVE_MODEL:
      return {
        ...state,
        loading: false,
        err: false,
        approvedmodels: [...state.approvedmodels, action.payload],
        models: state.models.filter((model) => model.modelId !== action.payload.modelId),
      };
    case ARCHIVE_MODEL:
      return {
        ...state,
        loading: false,
        err: false,
        archivedModels: [...state.archivedModels, action.payload],
        models: state.models.filter((model) => model.modelId !== action.payload.modelId),
      };
    case MODEL_EDIT_REQUEST:
      return {
        ...state,
        loading: false,
        err: false,
        archivedModels: state.archivedModels.filter((model) => model.modelId !== action.payload.modelId),
        approvedmodels: state.approvedmodels.filter((model) => model.modelId !== action.payload.modelId),
        models: [...state.models, action.payload],
      };
    case MODEL_CREATED:
      return {
        ...state,
        models: [...state.models, action.payload],
        newModelId: action.payload.modelId,
        modelCreationErr: false,
      };
    case MODEL_CREATION_FAILED:
      return { ...state, modelCreationErr: true };
    case NEW_MODEL_SECTIONID:
      return { ...state, newModelSectionId: action.payload };
    case NEW_MODEL_SUBSECTIONID:
      return { ...state, newmodelsubsectionId: action.payload };
    case MODEL_NOTE:
      return { ...state, noteID: { ...action.payload, noteText: notedecoder(action.payload.noteText) } };
    default:
      return state;
  }
};

export default modelMangmentReducer;
