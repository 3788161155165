import {
  GET_ALL_MODELS_START,
  GET_ALL_MODELS_FAIL,
  GET_ALL_MODELS_SUCESS,
  MODEL_CREATED,
  MODEL_CREATION_FAILED,
  NEW_MODEL_SUBSECTIONID,
  NEW_MODEL_SECTIONID,
  MODEL_NOTE,
  APPROVE_MODEL,
  GET_SINGLE_MODEL,
  ARCHIVE_MODEL,
  MODEL_EDIT_REQUEST,
} from './types';
import { Toast } from 'primereact/toast';
import { modelServer } from '../../api/apiRequest';
import { errorMessages } from '../../constants/error-messages';
interface APIResponse<model = any> {
  data: model;
  message: string;
  status: boolean;
}

const showToast = (toast: React.MutableRefObject<Toast | null>, error: any) => {
  const toastData = toast.current;
  const msg =
    error.response && error.response.data && error.response.data.message ? error.response.data.message : 'حدث خطا';
  if (toastData) {
    const arMsg = errorMessages.find((ele) => ele.en === msg);
    toastData.show({ severity: 'error', detail: 'خطا', summary: arMsg ? arMsg.ar : msg, life: 3000 });
  }
};

export const getAllModels =
  (toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({ type: GET_ALL_MODELS_START });
      const res = await modelServer.get('/');
      dispatch({ type: GET_ALL_MODELS_SUCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_ALL_MODELS_FAIL });
      showToast(toast, error);
    }
  };
export const getSingleModel = (id: number) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    const res = await modelServer.get(`/${id}`);
    dispatch({ type: GET_SINGLE_MODEL, payload: res.data });
  } catch (error) {}
};
export const approveModel = (id: number) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    const res = await modelServer.patch(`/${id}/status/${2}`);

    dispatch({ type: APPROVE_MODEL, payload: res.data });
  } catch (error) {}
};
export const archiveModel = (id: number) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    const res = await modelServer.patch(`/${id}/status/${3}`);

    dispatch({ type: ARCHIVE_MODEL, payload: res.data });
  } catch (error) {}
};
export const modelEditrequest = (id: number) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    const res = await modelServer.patch(`/${id}/status/${1}`);

    dispatch({ type: MODEL_EDIT_REQUEST, payload: res.data });
  } catch (error) {}
};

export const createNewModel =
  (modelName: string, history: any, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      const { data }: APIResponse = await modelServer.post(`${modelName}`, {});

      dispatch({ type: MODEL_CREATED, payload: data });

      let id = data.modelId ? data.modelId : 0;
      history.push(`/createmodel/${id}`);
    } catch (error) {
      dispatch({ type: MODEL_CREATION_FAILED });
      showToast(toast, error);
    }
  };

export const createModelBasedonModel =
  (model: any, history: any, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const { selectedModel, name } = model;
    console.log(selectedModel, 'model id sent to the db');
    try {
      const { data }: APIResponse = await modelServer.post(`/newModelByExisting`, {
        modelId: selectedModel,
        modelName: name,
      });

      dispatch({ type: MODEL_CREATED, payload: data });

      let id = data.modelId ? data.modelId : 0;
      console.log(data);
      history.push(`/createmodel/${id}`);
    } catch (error) {
      dispatch({ type: MODEL_CREATION_FAILED });
      showToast(toast, error);
    }
  };

export const createNewSection =
  (modelid: number, section: any, oldId: number, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      const res = await modelServer.post(`/${modelid}/sections`, section);
      dispatch({ type: NEW_MODEL_SECTIONID, payload: { DbID: res.data.id, OldID: oldId } });
    } catch (error) {
      showToast(toast, error);
    }
  };

export const createNewSubSection =
  (modelid: number, section: any, toast: React.MutableRefObject<Toast | null>) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      const res = await modelServer.post(`/${modelid}/sections`, section);
      dispatch({ type: NEW_MODEL_SUBSECTIONID, payload: { DbID: res.data.subSections, sectionID: section.id } });
    } catch (error) {
      showToast(toast, error);
    }
  };

export const addNote =
  (note: any, location: any) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    let id = location.child ? location.child : location.parent;

    try {
      const res = await modelServer.post(`/sections/note`, { noteText: note, subSectionId: id });

      dispatch({ type: MODEL_NOTE, payload: res.data });
    } catch (error) {}
  };
