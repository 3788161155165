import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ModelStructureSideMenu from './ModelStructureSideMenu';
import InnerFooter from '../../InnerFooter/InnerFooter';
import InnerHeader from '../../InnerHeader/InnerHeader';
import './EditConditionsManual.scss';
import RichEditorSemiComp from '../../TextEditor/RichEditorSemiComp';
import AdminNotesSection from './AdminNotesSection';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { getManual } from '../../../redux/actions/manualManagementActions';
import { Toast } from 'primereact/toast';
import { updateManualContent } from '../../../api/apiRequest';

type Props = {};

type ModelName = {
  id: string;
};

const EditConditionsManual: React.FC<Props> = () => {
  const params = useParams<ModelName>();
  const manual = useSelector((state: RootState) => state.manualManagement.getManual);
  const [mainHeadersList, setMainHeadersList] = useState<Array<any>>([]);
  const toast = useRef<Toast | null>(null);
  const dispatch = useDispatch();

  const [preDisplayedContent, setPreDisplayedContent] = React.useState(null);
  const [HTMLcontent, setHTMLContent] = React.useState<string>('');
  const [readOnly, setReadOnly] = React.useState<boolean>(false);
  const [adminComments, setAdminComments] = React.useState<Array<any>>([]);
  const [viewNotesSection, setViewNotesSection] = React.useState<boolean>(true);

  //sidemenu2 stuff

  const [location, setLocation] = React.useState<{ parent: string; child: null | string }>({ parent: '', child: '' });

  useEffect(() => {
    dispatch(getManual(Number(params.id), toast));
  }, [params.id]);
  useEffect(() => {
    setMainHeadersList(manual.sections);
  }, [manual.sections]);

  const mapContentToHeader = () => {
    const { parent, child }: any = location; // header and sunheadder index that the user is currently selecting from the sidemenu2
    if (child === '') {
      // MAP CONTENT OR NOTES TO A HEADER

      let newMainHearderlist = mainHeadersList.map((header: any) => {
        if (parent === header.id) {
          updateManualContent(header.id, HTMLcontent);
          return { ...header, content: HTMLcontent, readonly: readOnly }; //ifnot map the content to the selected header
        }
        return header; //if not the selected header leave it as it is
      });

      setMainHeadersList(newMainHearderlist); // set the new modifed headers list
    } else {
      // MAP CONTENT OR NOTES TO A SUB_HEADER

      let newMainHearderlist = mainHeadersList.map((header: any) => {
        if (parent === header.id) {
          // the exact header in which the user has selected
          let newheader = header.subSections.map((subheader: any, i: number) => {
            if (child === subheader.id) {
              // the exact sub header in which the user has selected
              updateManualContent(subheader.id, HTMLcontent);

              return { ...subheader, content: HTMLcontent, readonly: readOnly }; //return the content from the text editor
            }
            return subheader; //if not the required subheader leave it as it is
          });
          return { ...header, subSections: newheader };
        }
        return header; ////if not the required header leave it as it is
      });

      setMainHeadersList(newMainHearderlist); // set the new modifed headers
    }
  };

  const deleteContentFromHeader = () => {
    const { parent, child }: any = location;

    if (child === '') {
      let newMainHearderlist = mainHeadersList.filter((header: any) => parent !== header.id);
      setMainHeadersList(newMainHearderlist);
    } else {
      let newHeaderList = mainHeadersList.map((header: any, i: number) => {
        if (parent === header.id) {
          let newsubheaderlist = header.subSections.filter((subheader: any) => child !== subheader.id);

          return { ...header, subSections: newsubheaderlist };
        }
        return header;
      });

      setMainHeadersList(newHeaderList);
    }
  };

  return (
    <>
      <div className="create-model-from-scratch-container">
        <div className="create-header">
          <InnerHeader selectedItem={manual?.title || ''} mainSection="تعديل كراسة الشروط" />
        </div>

        <div className="create-sidebar">
          <ModelStructureSideMenu
            setPreDisplayedContent={setPreDisplayedContent}
            mainHeadersList={mainHeadersList}
            setLocation={setLocation}
            setReadOnly={setReadOnly}
            setAdminComments={setAdminComments}
            HTMLcontent={HTMLcontent}
            preDisplayedContent={preDisplayedContent}
            setHTMLContent={setHTMLContent}
            location={location}
          />
        </div>

        <div className="create-content">
          <div style={{ display: viewNotesSection ? 'flex' : 'initial' }}>
            <div>
              <br></br>
              <h1> {manual?.title || 'جاري تحميل بيانات الكراسة'}</h1>
              <br></br>

              <RichEditorSemiComp
                readOnly={readOnly}
                location={location}
                mapContentToHeader={mapContentToHeader}
                deleteContentFromHeader={deleteContentFromHeader}
                setHTMLContent={setHTMLContent}
                HTMLcontent={HTMLcontent}
                setPreDisplayedContent={setPreDisplayedContent}
                preDisplayedContent={preDisplayedContent}
                viewNotesSection={setViewNotesSection}
              />
              <br></br>
            </div>
            {adminComments.length !== 0 && viewNotesSection ? (
              <div className="sidenotes-container">
                <div className="sidenotes-header">ملاحظات الإعداد</div>
                <div className="Admin-notes-container">
                  {adminComments.map((note) => (
                    <AdminNotesSection key={note.noteId} note={note} location={location} />
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <InnerFooter />
    </>
  );
};

export default EditConditionsManual;
