export const getSectionComments = (parent, child, headerList) => {
  if (child === 'none') {
    let exactheader = headerList.find((header) => {
      if (parent === header.id) return header;
      else return;
    });
    return exactheader.notes;
  } else {
    let exactHeader = headerList.find((header, index) => {
      if (parent === header.id) return header;
      else return;
    });

    let subheaderContent = exactHeader.subSections.find((subheader) => {
      if (child === subheader.id) return subheader;
      else return;
    });

    return subheaderContent.notes;
  }
};
