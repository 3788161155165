import { Toast } from 'primereact/toast';
import { errorMessages } from '../constants/error-messages';

export const showToast = (toast: React.MutableRefObject<Toast | null>, error: any) => {
  const toastData = toast.current;
  const msg =
    error.response && error.response.data && error.response.data.message ? error.response.data.message : 'حدث خطا';
  if (toastData) {
    const arMsg = errorMessages.find((ele) => ele.en.indexOf(msg.indexOf(':') > -1 ? msg.split(':')[0] : msg) > -1);
    toastData.show({ severity: 'error', detail: 'خطا', summary: arMsg ? arMsg.ar : msg, sticky: true });
  }
};
