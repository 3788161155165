import React, { FC } from 'react';
import './cardeditrequest.scss';
type props = {
  setEditRequest: any;
};
const CardEditRequest: FC<props> = ({ setEditRequest }) => {
  return (
    <div onClick={() => setEditRequest(false)} className="card-edit-request-container">
      <h3>التعديل عن طريق ؟</h3>
      <button className="btn-1"> استخراج كراسه</button>
      <button className="btn-2"> نسخه خاصه</button>
      <button className="btn-3">تم ارسال طلب التعديل</button>
    </div>
  );
};

export default CardEditRequest;
