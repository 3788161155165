import React from 'react';
import CreateModel from './CreateModel';

const EditModelPage: React.FC = () => {
  return (
    <>
      <CreateModel />
    </>
  );
};

export default EditModelPage;
