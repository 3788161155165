import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { FC, useState } from 'react';
import confirm from '../../assets/svg/confirm.svg';
import { passvalidator } from '../../constants/regex';
type Props = {
  showModal: boolean;
  closeModal: () => void;
  submitModal: (val: string) => void;
  isError?: boolean;
};

const PasswordModal: FC<Props> = ({ isError, showModal, closeModal, submitModal }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(true);

  return (
    <div>
      <Dialog
        className="primereact-dialouge-model password-reassign"
        visible={showModal}
        onHide={closeModal}
        draggable={false}
        showHeader={true}
        dismissableMask={true}
        header="إعادة تعيين كلمة مرور المستخدم"
      >
        <div className="accountstate-container">
          <label>
            كلمة مرور جديدة <span>*</span>
          </label>
          <div className="flex-center">
            <InputText
              value={password}
              onChange={(e) => {
                const val = e.target.value;
                setPassword(val);
                if (passvalidator.test(val)) {
                  setPasswordError(false);
                } else {
                  setPasswordError(true);
                }
              }}
              type="password"
              readOnly={false}
              className="modalInputs"
            />
            {!passwordError && (
              <div className="input-filled-icon">
                <img src={confirm} alt="confirmIcon" />
              </div>
            )}
          </div>
          {passwordError && password !== '' && (
            <p style={{ color: 'red', marginTop: '5px', fontSize: '11px' }}>
              كلمه السر يجب ان تكون مكونه علي الاقل من عشره احرف و لا تزيد عن عشرون حرف و تحتوي علي الاقل رقم و علامه
              مميزه (@ ,_,)و حرف كبير
            </p>
          )}

          <br></br>
          <br></br>
          <label>
            إعادة كتابة كلمة المرور الجديدة <span>*</span>
          </label>
          <div className="flex-center">
            <InputText
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              readOnly={false}
              className="modalInputs"
            />
            {password !== '' && confirmPassword === password && !passwordError && (
              <div className="input-filled-icon">
                <img src={confirm} alt="confirmIcon" />
              </div>
            )}
          </div>
          {isError && (
            <p style={{ color: 'red', marginTop: '10px' }}>كلمة المرور الجديدة مشابهة لكلمة المرور القديمة</p>
          )}
        </div>
        <div className="userinfo-button-container">
          <button className="cancel" onClick={closeModal}>
            إلغاء
          </button>
          <button
            className="save"
            onClick={() => {
              if (password !== confirmPassword) return;
              submitModal(password);
            }}
          >
            حفظ التغييرات
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default PasswordModal;
