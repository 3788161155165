import React, { useState } from 'react';
import AddSubData from '../AddSubData/AddSubData';
import OptionsList from '../OptionList/OptionsList';
import './ResearchData.scss';

export const checkIfUnique = (list: any[], input: string) => {
  let found = true;
  const ifFound = list.find((itemList) => itemList.option === input);
  ifFound ? (found = false) : (found = true);
  return found;
};
interface Props {
  setLvlOneSelected: any;
  lvlOneSelected?: any;
  handleAdd: any;
  arrayOfOptions: any[];
}
const ResearchData: React.FC<Props> = ({ setLvlOneSelected, lvlOneSelected, handleAdd, arrayOfOptions }) => {
  const [addTitle, setAddTitle] = useState('');
  const [addOption, setAddOption] = useState('');

  const handleTitle = (e: any) => {
    setAddTitle(e.target.value);
  };
  const handleOptions = () => {
    if (addOption === '') return;
    handleAdd(addOption, 1);
  };

  const handleEnter = (e: any) => {
    if (!checkIfUnique(arrayOfOptions, addOption)) return;

    e.key === 'Enter' && handleOptions();
    e.key === 'Enter' && setAddOption('');
  };
  const handleUpdatedOption = (editOption: string, newInput: string) => {
    handleAdd(newInput, 1, editOption);
  };
  console.log('lvl one rendderd');

  return (
    <>
      <div className="research-data">
        <div className="address-section">
          <label>عنوان البيان</label>
          <input
            onChange={(e) => {
              handleTitle(e);
            }}
            type="text"
            placeholder="الجهة الاولي"
          />
        </div>
        <div className="menu-choices">
          <label>اختيارات القائمة</label>
          <input
            value={addOption}
            onKeyDown={handleEnter}
            onChange={(e) => setAddOption(e.target.value)}
            placeholder="اضافة اختيار جديد"
            type="text"
            disabled={addTitle.length === 0 ? true : false}
            className={addTitle.length === 0 ? ' ' : 'active-to-add'}
          />
        </div>
        <OptionsList
          handleUpdatedOption={handleUpdatedOption}
          setSelectedItem={setLvlOneSelected}
          selectedItem={lvlOneSelected}
          arrayOfOptions={arrayOfOptions}
          id={1}
        />
      </div>
    </>
  );
};

export default ResearchData;
