import React, { useState } from 'react';
import OptionsList from '../OptionList/OptionsList';
import { checkIfUnique } from './ResearchData';
import './ResearchData.scss';

interface Props {
  setLvlTwoSelected: any;
  lvlTwoSelected?: any;
  lvlOneSelected: string;
  handleAdd: any;
  arrayOfOptions: any[];
}

const ReseachDataTwo: React.FC<Props> = ({
  setLvlTwoSelected,
  lvlTwoSelected,
  lvlOneSelected,
  handleAdd,
  arrayOfOptions,
}) => {
  const [addTitle, setAddTitle] = useState('');
  const [addOption, setAddOption] = useState('');

  const handleTitle = (e: any) => {
    setAddTitle(e.target.value);
  };

  function handleOption() {
    if (!lvlOneSelected) return console.log('test');
    handleAdd(addOption, 2, '', lvlOneSelected);
  }

  const handleEnter = (e: any) => {
    if (!checkIfUnique(arrayOfOptions, addOption)) return;

    e.key === 'Enter' && handleOption();
    e.key === 'Enter' && setAddOption('');
  };

  const handleUpdatedOption = (editOption: string, newInput: string) => {
    handleAdd(newInput, 2, editOption, lvlOneSelected);
  };
  console.log('lvl two rendderd', arrayOfOptions);

  return (
    <>
      <div className="research-data">
        <div className="address-section">
          <label>عنوان البيان</label>
          <input
            onChange={(e) => {
              handleTitle(e);
            }}
            type="text"
            placeholder="الجهة الثانيه"
          />
        </div>
        <div className="menu-choices">
          <div className="display-selected-option">
            <label>اختيارات القائمة</label>
            <p className="selected-option scrollable-selected-option">" {lvlOneSelected} "</p>
          </div>
          <input
            value={addOption}
            onKeyDown={handleEnter}
            onChange={(e) => setAddOption(e.target.value)}
            placeholder="اضافة اختيار جديد"
            type="text"
            disabled={addTitle.length === 0 ? true : false}
            className={addTitle.length === 0 ? ' ' : 'active-to-add'}
          />
        </div>

        <OptionsList
          handleUpdatedOption={handleUpdatedOption}
          setSelectedItem={setLvlTwoSelected}
          selectedItem={lvlTwoSelected}
          arrayOfOptions={arrayOfOptions}
          id={2}
        />
      </div>
    </>
  );
};
export const MemoizedReseachDataTwo = React.memo(ReseachDataTwo);

// export default MemoizedMovie;
