import React from 'react';
import sideMenuItems from '../../constants/newSideMenu2';
import InnerFooter from '../../components/InnerFooter/InnerFooter';
import './model.scss';
import InnerHeader from '../../components/InnerHeader/InnerHeader';
import { Redirect, Switch, useLocation, Route } from 'react-router';
import cards from '../../constants/cards';
import { fetchAllSectors } from '../../redux/actions/tenderManagementActions';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { IRoutes } from '../../models/routes.model';
import NewSideMenu from '../../components/NewSideMenu/NewSideMenu';
import { RootState } from '../../redux/reducers';
import { getAllManualsByStatus } from '../../redux/actions/manualManagementActions';

type Props = {
  subRoutes: IRoutes;
};

const Model: React.FC<Props> = ({ subRoutes }) => {
  const dispatch = useDispatch();
  const toast = React.useRef<Toast | null>(null);
  const { pathname } = useLocation();
  const [sideMenuWidth, setSideMenuWidth] = React.useState(true);
  const [mainSection, setSelectedMainSection] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState('');

  const { user } = useSelector((state: RootState) => state.user);

  React.useEffect(() => {
    const [, section, currentsubroute] = pathname.split('/');
    const baseurl = cards.find((ele) => ele.cardLink === `/${section}`)?.title || '';
    const data = sideMenuItems.find((ele) => ele.menuLink === currentsubroute);
    setSelectedMainSection(baseurl);
    setSelectedItem(data ? data.title : sideMenuItems[0].title);
    dispatch(fetchAllSectors(toast));

    return () => {
      setSelectedMainSection('');
      setSelectedItem('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedItemHandler = (item: any) => {
    setSelectedItem(item.title);
  };

  React.useEffect(() => {
    if (user) {
      dispatch(getAllManualsByStatus(2, user?.id, toast));
      dispatch(getAllManualsByStatus(1, user?.id, toast));
      dispatch(getAllManualsByStatus(3, user?.id, toast));
    }
  }, []);

  const toggledSideMenuHandler = (sideMenu: any) => {
    setSideMenuWidth(sideMenu);
  };

  return (
    <div
      className="model-page"
      style={{
        gridTemplateColumns: `${sideMenuWidth === true ? 'repeat(2, 20% 80%)' : 'repeat(1, 6% 94%)'}`,
      }}
    >
      <NewSideMenu
        sideMenuItems={sideMenuItems}
        selectedItem={selectedItemHandler}
        toggledSideMenu={toggledSideMenuHandler}
      />

      <div className="internal-routes">
        <InnerHeader mainSection={mainSection} selectedItem={selectedItem} />
        <div className="routes-container ">
          <Switch>
            <Redirect exact from="/model" to="/model/recently" />
            {Object.values(subRoutes).map((route) => (
              <Route key={route.name} exact={route.exact} path={route.path} component={route.component} />
            ))}
          </Switch>
        </div>
      </div>
      <InnerFooter />
    </div>
  );
};

export default Model;
