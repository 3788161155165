import SystemTendersIcon from '../assets/svg/system-tenders.svg';
import SettingsIcon from '../assets/svg/manuals-management-settings.svg';
import SearchDocuments from '../components/Library/SearchDocuments/SearchDocuments';
import SystemTendersIconFilled from '../assets/svg/system-tenders-filled.svg';
import SettingsIconFilled from '../assets/svg/manuals-management-settings-filled.svg';
import LibrarySettings from '../components/Library/LibrarySettings';

const librarySideMenu = [
  {
    id: 0,
    icon: `${SystemTendersIcon}`,
    iconFilled: `${SystemTendersIconFilled}`,
    title: 'مكتبة الأبحاث',
    menuLink: 'search-documents',
    component: SearchDocuments,
  },
  {
    id: 1,
    icon: `${SettingsIcon}`,
    iconFilled: `${SettingsIconFilled}`,
    title: 'إعدادات النظام',
    menuLink: 'library-settings',
    component: LibrarySettings,
  },
];

export default librarySideMenu;
