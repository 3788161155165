import React, { FC, useEffect } from 'react';
import '../CreateConditionsManual.scss';
import checkCircle from '../../../../../../assets/svg/check-circle.svg';
import { InputText } from 'primereact/inputtext';
type Props = {
  tenderName: string;
  activePage: any;
  setActivePage: any;
  modalData: any;
  setModalData: any;
  SectorName: string;
};
const Page1: FC<Props> = ({ SectorName, tenderName, activePage, setActivePage, modalData, setModalData }) => {
  /*const sectorHandler =(e :any)=>{

  }*/
  //const allSectors = useSelector((state: RootState) => state.tenderManagement.allSectors);

  useEffect(() => {
    if (modalData.manualTitle !== '') {
      setActivePage({ id: 1, isContentFilled: { ...activePage.isContentFilled, page1: true } });
    } else {
      setActivePage({ id: 1, isContentFilled: { ...activePage.isContentFilled, page1: false } });
    }
  }, [modalData]);

  return (
    <div className="modalForm">
      <div className="form-group">
        <label className="form-label">اسم المناقصة</label>
        <div className="inputsGroup">
          <InputText type="text" value={tenderName} readOnly className="modalInputs" id="readOnly" />
          <div className="circle-div">
            <img src={checkCircle} alt="check-circle-disabled" className="check-circle check-circle-disabled" />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">
          عنوان كراسة الشروط <span className="mandatory">*</span>
        </label>
        <div className="inputsGroup">
          <InputText
            placeholder="مثال: كراسة شروط منافسة انشاء صانع البن"
            className="modalInputs"
            value={modalData.manualTitle}
            onChange={(e) => {
              setModalData({ ...modalData, manualTitle: e.target.value });
            }}
          />
          <div className="circle-div">
            {modalData.manualTitle !== '' && <img className="check-circle" src={checkCircle} alt="check-circle" />}
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">قطاع الكراسة</label>
        <div className="inputsGroup">
          <InputText
            readOnly
            className="modalInputs"
            id="readOnly"
            placeholder="مثال: كراسة شروط منافسة انشاء صانع البن"
            value={SectorName}
          />
          <div className="circle-div">
            {JSON.stringify(modalData.manualSector) !== JSON.stringify({}) && (
              <img className="check-circle check-circle-disabled" src={checkCircle} alt="check-circle" />
            )}
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">التصنيف ١</label>
        <div className="inputsGroup">
          {/* <Dropdown
              value={modalData.type1}
              options={allSectors}
              onChange={(e) => {
                setModalData({ ...modalData, type1: e.value });
              }}
              //optionLabel="sectorName"
              placeholder="اختر التصنيف المناسب"
            /> */}
          <InputText readOnly className="modalInputs" id="readOnly" value={modalData.type1} />
          <div className="circle-div">
            {JSON.stringify(modalData.type1) !== JSON.stringify({}) && (
              <img className="check-circle check-circle-disabled" src={checkCircle} alt="check-circle" />
            )}
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">التصنيف ٢</label>
        <div className="inputsGroup">
          {/* <Dropdown
              value={modalData.type2}
              options={allSectors}
              onChange={(e) => {
                setModalData({ ...modalData, type2: e.value });
              }}
              //optionLabel="sectorName"
              placeholder="اختر التصنيف المناسب"
            /> */}
          <InputText readOnly className="modalInputs" id="readOnly" value={modalData.type2} />
          <div className="circle-div">
            {JSON.stringify(modalData.type2) !== JSON.stringify({}) && (
              <img src={checkCircle} alt="check-circle" className="check-circle check-circle-disabled" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page1;
