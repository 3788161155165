import { analyisResponse } from './../../../models/Analysis';
import { AnalysisObject, APIAnalysisRequest, dimension, displayData } from '../../../models/Analysis';
import { title } from 'process';

export const handleAnalysisResp = (anlysis: analyisResponse, type: string = 'normal') => {
  let data: displayData = { labels: [], datasets: [] };
  //TO MAKE TEH DATA FROM  API READY FOR THE CHARTS

  const dataSet = [];
  const labels = [];

  if (anlysis.dataAnalysis.length !== 0) {
    // JUST FOR TEST FIRST BARCHART DATA
    if (anlysis.dataAnalysis[0].dataOfSecondDimension?.length === undefined) {
      type === 'pie'
        ? dataSet.push(
            generatePieDataSet('عدد المشروعات', anlysis.dataAnalysis, '#42A5F5', anlysis.totalNumberOfProjects),
          )
        : dataSet.push(generateDataSet('عدد المشروعات', anlysis.dataAnalysis, '#42A5F5'));

      for (let i = 0; i < anlysis.dataAnalysis.length; i++) {
        labels.push(anlysis.dataAnalysis[i].nameOfDimension);
      }
    } else {
      for (let i = 0; i < anlysis.dataAnalysis.length; i++) {
        const secD = anlysis.dataAnalysis[i].dataOfSecondDimension || [];
        dataSet.push({
          label: anlysis.dataAnalysis[i].nameOfDimension,
          type: 'bar',
          data: Array(0),
          backgroundColor: RandomColorsGenerator(),
        });
        for (let j = 0; j < secD.length; j++) {
          if (i === 0) labels.push(secD[j].nameOfDimension);
          dataSet[i].data.push(secD[j].countOfProjects);
        }
      }
    }

    data = {
      labels: labels,
      datasets: dataSet,
    };
  }

  return data;
};

const generateDataSet = (title: string, data: any, color: string) => {
  return {
    label: title,
    labels: data.map((obj: AnalysisObject) => {
      return obj.nameOfDimension;
    }),
    backgroundColor: color,
    data: data.map((obj: AnalysisObject) => {
      return obj.countOfProjects;
    }),
    ///for line  data:
    fill: false,
    borderColor: color,
    tension: 0.4,
  };
};
const generatePieDataSet = (title: string, data: any, color: string, totalProjects: number) => {
  return {
    label: title,
    data: data.map((obj: AnalysisObject) => {
      return obj.countOfProjects;
    }),
    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
  };
};

export const analysisData = (dimensions: Array<dimension>) => {
  let data: APIAnalysisRequest = {
    firstDimension: { ids: [], name: '' },
    // geographicalAreasIds: [],
    // initiativesCodesIds: [],
    // sectorsIds: [],
    // statusIds: [],
  };

  getDimensionData(dimensions[0], data, 'firstDimension');

  if (dimensions[1].choices.length) {
    data.secondDimension = { ids: [], name: '' };
    getDimensionData(dimensions[1], data, 'secondDimension');
  }
  if (dimensions[2].choices.length) {
    data.thirdDimension = { ids: [], name: '' };
    getDimensionData(dimensions[2], data, 'thirdDimension');
  }

  return data;
};

const getDimensionData = (dimension: dimension, data: APIAnalysisRequest, dimensionName: string) => {
  if (dimension.selectionId === 1) {
    for (let i = 0; i < dimension.choices.length; i++) {
      data[dimensionName].ids.push(dimension.choices[i].sectorCode);
    }
    data[dimensionName].name = 'sectors';

    return data;
  }
  if (dimension.selectionId === 2) {
    for (let i = 0; i < dimension.choices.length; i++) {
      data[dimensionName].ids.push(dimension.choices[i].initiativeCode);
    }
    data[dimensionName].name = 'initiativesCodes';
    return data;
  }
  if (dimension.selectionId === 3) {
    for (let i = 0; i < dimension.choices.length; i++) {
      data[dimensionName].ids.push(dimension.choices[i].lookupId);
    }
    data[dimensionName].name = 'geographicalAreas';
    return data;
  }
  if (dimension.selectionId === 4) {
    for (let i = 0; i < dimension.choices.length; i++) {
      data[dimensionName].ids.push(dimension.choices[i].lookupId);
    }
    data[dimensionName].name = 'status';
    return data;
  }
};

const RandomColorsGenerator = () => {
  let color = '#ffffff';
  color = '#' + Math.floor(Math.random() * 16777215).toString(16);
  return color;
};

export const getSpecificDoughnutData = (analysis: analyisResponse) => {
  let data: displayData = {
    labels: [],
    datasets: [{ type: 'bar', data: [], label: '', backgroundColor: [], hoverBackgroundColor: [] }],
  };
  if (analysis.dataAnalysis.length !== 0) {
    analysis.dataAnalysis.forEach((obj: any) => {
      data.labels.push(obj.nameOfDimension);
      data.datasets[0].data.push(obj.countOfProjects);
      let color = RandomColorsGenerator();
      data.datasets[0].backgroundColor.push(color);
      data.datasets[0].hoverBackgroundColor.push(color);
    });
  }

  return data;
};

export const getDoughnutData = (analysis: analyisResponse) => {
  console.log('getDoughnut', analysis);
  let data: displayData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };
  console.log(analysis, 'getDough');
  if (analysis.dataAnalysis.length !== 0) {
    data.labels.push('القطاعات');
    let sum = 0;
    for (let i = 0; i < analysis.dataAnalysis.length; i++) {
      sum += analysis.dataAnalysis[i].countOfProjects;
    }
    data.datasets[0].data.push(sum);
  }

  console.log(data);
  return data;
};
