import React from 'react'
import './userssettings.scss'


const UsersSettings:React.FC = () => {
    return (
        <div>
            USERS SETTINGS ROUTE
        </div>
    )
}

export default UsersSettings
