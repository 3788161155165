import React, { useState, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cardHeaderImg from '../../assets/svg/card-header-menu.svg';
import { Module } from '../../models/User';
import { RootState } from '../../redux/reducers';
import { hasAccessToModule } from '../../utils/checkAccess';
import './CardHeader.scss';

const CardHeader: FC = () => {
  const history = useHistory();
  const { modules, user } = useSelector((state: RootState) => state.user);
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    const closeCardHeaderBodyHandler = () => {
      setEnable(false);
    };

    document.body.addEventListener('click', closeCardHeaderBodyHandler);

    return () => {
      document.body.removeEventListener('click', closeCardHeaderBodyHandler);
    };
  }, [enable]);

  const showCardHeaderItems = () => {
    setEnable(!enable);
  };

  const moduleHandler = (module: Module) => {
    return () => {
      if (!user) return;
      const hasAccess = hasAccessToModule(module, user);

      if (hasAccess) {
        history.push(module.moduleLink || '');
      }
    };
  };

  return (
    <div className="card-header" onClick={(event) => event.stopPropagation()}>
      <span>تطبيقات النظام</span>
      <button onClick={showCardHeaderItems} className="card-header-btn">
        <img src={cardHeaderImg} alt="card-header-img" />
      </button>
      {enable && (
        <div className="card-header-items">
          {modules.map((card) => (
            <div onClick={moduleHandler(card)} className="card-header-item" key={card.moduleId}>
              <div className="card-header-item-icon">
                <span className={`${card.moduleIcon}`} />
              </div>
              <div className="card-header-item-title">{card.moduleNameA}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardHeader;
