import React, { useState } from 'react';
import editFieldsName from '../../../assets/png/edit-fields-name.png';
import _ from '../../../assets/png/edit-fields-name.png';
import { checkIfUnique } from '../ResearchData/ResearchData';
import arrowLeftRectangle from '../../../assets/svg/arrow-left-triangle.svg';
import './OptionList.scss';

type Props = {
  setSelectedItem?: any;
  selectedItem: string;
  arrayOfOptions: any[];
  lvlOneSelected?: any;
  id?: number;
  handleUpdatedOption: any;
};

const OptionsList: React.FC<Props> = ({ setSelectedItem, selectedItem, arrayOfOptions, id, handleUpdatedOption }) => {
  const [hoverId, sethoverId] = useState<number | undefined>();
  const [editOption, seteditOption] = useState('');
  const [newInput, setNewInput] = useState('');

  const handleEditOption = (oldOption: string) => {
    seteditOption(oldOption); // this state to keep track of the  old item to be able to update the list
    setNewInput(oldOption);
  };
  const handleUpdate = (e: any) => {
    if (e.key !== 'Enter') return;
    seteditOption('');
    //todo update array of levels
    console.log(checkIfUnique(arrayOfOptions, newInput));
    {
      checkIfUnique(arrayOfOptions, newInput) && handleUpdatedOption(editOption, newInput);
    }
  };

  return (
    <>
      <div className="scrollable">
        {arrayOfOptions.map((opt, i) => (
          <div key={`${i}-${id}`}>
            {opt?.option === editOption ? (
              <input
                onKeyDown={(e) => handleUpdate(e)}
                value={newInput}
                onChange={(e) => setNewInput(e.target.value)}
                className="editoptioninputfield"
              />
            ) : (
              <div
                className={selectedItem === opt.option ? 'selecteditem option' : 'option'}
                onClick={() => setSelectedItem && setSelectedItem((old: any) => (old === opt.option ? '' : opt.option))}
                onMouseEnter={() => sethoverId(i)}
                onMouseLeave={() => sethoverId(undefined)}
              >
                <div className="subBulletContainer" key={i}>
                  <svg className="subBullet" key={i}></svg>
                </div>
                <div className="singleoptioncontainer">
                  <div className="optiontitle">{opt.option}</div>
                  {i === hoverId && (
                    <img
                      className="editicon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditOption(opt.option);
                      }}
                      height={30}
                      width={30}
                      src={editFieldsName}
                    />
                  )}

                  <div className={selectedItem === opt.option ? 'arrowLeftRectangleIcon' : 'hidden-icon'}>
                    {selectedItem ? <img src={arrowLeftRectangle} height={10} width={10} alt="" /> : ''}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default OptionsList;
