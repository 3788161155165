import { Dropdown } from 'primereact/dropdown';
import React from 'react';

export const monthNavigatorTemplate = (e: any) => {
  return (
    <Dropdown
      emptyMessage="لا توجد بيانات"
      value={e.value}
      options={e.options}
      onChange={(event) => e.onChange(event.originalEvent, event.value)}
      style={{ lineHeight: 1 }}
    />
  );
};

export const yearNavigatorTemplate = (e: any) => {
  return (
    <Dropdown
      emptyMessage="لا توجد بيانات"
      value={e.value}
      options={e.options}
      onChange={(event) => e.onChange(event.originalEvent, event.value)}
      className="p-ml-2"
      style={{ lineHeight: 1 }}
    />
  );
};
