import React, { useState } from 'react';
import NoProjects from '../NoProjects/NoProjects';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEventParams, DataTableSortOrderType } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import plus from '../../../assets/svg/circle-button.svg';
import { Project } from '../../../models/add-project.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import cancel from '../../../assets/svg/cancel.svg';

import './ProjectTable.scss';
import '../../../styles/Checkbox.scss';

type Props = {
  projects: Project[];
  totalRecords: number;
  perPage: number;
  first: number;
  setFirst: (num: number) => void;
  rowClickHandler: (e: DataTableRowClickEventParams) => void;
  setParamsHandler: (key: string, value: string | number) => void;
  gotToAddProjectHandler: () => void;
};

const ProjectTable: React.FC<Props> = ({
  projects,
  perPage,
  totalRecords,
  first,
  setFirst,
  setParamsHandler,
  rowClickHandler,
  gotToAddProjectHandler,
}) => {
  const sectorTemplate = (rowData: Project) => {
    let sectorType: any;
    // eslint-disable-next-line
    allSectors.map((sector: any) => {
      if (rowData.sectorCode === sector.sectorCode) {
        sectorType = sector.sectorName;
      }
    });

    return <>{sectorType}</>;
  };

  const allSectors = useSelector((state: RootState) => state.tenderManagement.allSectors);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState<DataTableSortOrderType>();
  const [tableColumns, setTableColumns] = useState([
    {
      header: 'كود المشروع',
      field: 'projectCode',
      static: true,
      checked: true,
    },
    {
      header: 'مسمى المشروع',
      field: 'projectName',
      static: true,
      checked: true,
    },
    {
      header: 'مدة المشروع (بالأشهر)',
      field: 'projectDuration',
      static: true,
      checked: true,
    },
    {
      header: 'القطاع',
      field: 'sectorCode',
      body: true,
      static: true,
      checked: true,
    },
    {
      header: 'المبادرة',
      field: 'prtsInitiativeCode.initiativeShortName',
      static: true,
      checked: true,
    },
    {
      header: 'النوع (تشغيلي/رأسمالي)',
      field: 'prtsLookupMainCodeByTypeCode.lookupMainName',
      static: true,
      checked: true,
    },
    {
      header: 'مسمى المشروع فى التنمية الريفية',
      field: 'projectNameRural',
      static: false,
      checked: false,
    },
    {
      header: 'الجهة المالكة',
      field: 'admSideCode.sideName',
      static: false,
      checked: false,
    },
    {
      header: 'المرحلة',
      field: 'prtsLookupMainCodeByStageCode.lookupMainName',
      static: false,
      checked: false,
    },
    {
      header: 'عدد الكراسات',
      field: 'brochuresNum',
      static: false,
      checked: false,
    },
  ]);

  const handleTableComlumns = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eleIdx = +e.target.value;
    if (tableColumns[eleIdx].static) return;
    const eleChecked = e.target.checked;
    const allColumns = [...tableColumns];
    allColumns[eleIdx].checked = eleChecked;
    setTableColumns([...allColumns]);
  };

  return (
    <div className="table-item" style={{ direction: 'ltr', marginTop: '28px' }}>
      {projects && projects.length > 0 ? (
        <>
          <DataTable
            value={[...projects]}
            className="p-datatable-responsive-demo"
            onRowClick={rowClickHandler}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={({ sortField, sortOrder }) => {
              const sort = sortOrder === -1 ? 'DESC' : 'ASC';
              setParamsHandler('sort', `${sortField},${sort}`);
              setSortField(sortField);
              setSortOrder(sortOrder);
            }}
          >
            {tableColumns.map((col, idx) => {
              if (col.static || col.checked) {
                return (
                  <Column
                    key={idx}
                    field={col.field}
                    header={col.header}
                    body={col.body ? sectorTemplate : null}
                    sortable
                  />
                );
              }
              return null;
            })}
          </DataTable>
          <Paginator
            rows={perPage}
            totalRecords={totalRecords}
            first={first}
            onPageChange={(e) => {
              setFirst(e.first);
              setParamsHandler('page', e.page);
            }}
          ></Paginator>

          <button
            style={{
              backgroundColor: 'unset',
              border: '0',
              cursor: 'pointer',
              position: 'absolute',
              left: '-15px',
              top: '10px',
            }}
            onClick={() => setToggleFilter(!toggleFilter)}
          >
            <img title="عرض" width="30px" src={plus} alt="plus" />
          </button>
          <div dir="rtl" className={`show-rows ${toggleFilter ? 'show-filter' : 'hide-filter'}`}>
            <div className="filter-title">
              <span>
                <button className="close-icon" onClick={() => setToggleFilter(!toggleFilter)}>
                  <img width="16px" src={cancel} alt="cancel-icon" />
                </button>
                <span>عرض</span>
              </span>
              <img style={{ width: '2.5rem', height: '2.5rem' }} width="15px" src={plus} alt="filter" />
            </div>
            <div className="filter-content">
              <div className="items">
                {tableColumns.map((col, idx) => (
                  <label key={idx} className="container">
                    <span>{col.header}</span>
                    <input
                      type="checkbox"
                      disabled={col.static}
                      checked={col.checked}
                      onChange={handleTableComlumns}
                      value={idx}
                    />
                    <span className="checkmark"></span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoProjects btnHandler={gotToAddProjectHandler} />
      )}
    </div>
  );
};

export default ProjectTable;
