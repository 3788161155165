import UsersMangment from '../components/Users/UsersMangment/UsersMangment';
import UsersSettings from '../components/Users/UsersSettings/UsersSettings';

import BussinessManagementIcon from '../assets/svg/bussiness-management.svg';
import BussinessManagementIconFilled from '../assets/svg/bussiness-management-filled.svg';

import SettingsIcon from '../assets/svg/manuals-management-settings.svg';
import SettingsIconFilled from '../assets/svg/manuals-management-settings-filled.svg';

export const defaultUserMenuItems = [
  {
    id: 1,
    icon: `${BussinessManagementIcon}`,
    iconFilled: `${BussinessManagementIconFilled}`,
    title: 'إدارة المستخدمين',
    menuLink: 'mangment',
    component: UsersMangment,
  },
];

const userMenuItems = [
  {
    id: 1,
    icon: `${BussinessManagementIcon}`,
    iconFilled: `${BussinessManagementIconFilled}`,
    title: 'إدارة المستخدمين',
    menuLink: 'mangment',
    component: UsersMangment,
  },
  {
    id: 2,
    icon: `${SettingsIcon}`,
    iconFilled: `${SettingsIconFilled}`,
    title: 'إعدادات',
    menuLink: '',
    component: UsersSettings,
  },
];

export default userMenuItems;
