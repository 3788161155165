import React from 'react';
import './card.scss';
import threedots from '../../../assets/svg/three-dots.svg';
import threedotsgreen from '../../../assets/svg/three-dots-green.svg';
import LockIcon from '../../../assets/svg/lock.svg';
import UnlockIcon from '../../../assets/svg/unlock.svg';
import MaleImg from '../../../assets/png/male-img.png';
import ModelClicked from './ModelClicked';
import { Dialog } from 'primereact/dialog';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { isNormalUser } from '../../../utils/checkAccess';

type Props = {
  card: any;
  locked: boolean;
  archived: boolean;
};
const splitDate = (backenddate: any) => {
  if (!backenddate) return null;
  let newdate = backenddate.split('T');
  if (newdate.length === 0) return null;
  return newdate[0];
};

const ModelCard: React.FC<Props> = ({ card, locked, archived }) => {
  const [clicked, setClicked] = React.useState(false);
  const [editRequest, setEditRequest] = React.useState(false);
  const [svgHover, setSvgHover] = React.useState(false);
  const [nameChange, setNameChange] = React.useState({ show: false, name: '' });
  const { user } = useSelector((state: RootState) => state.user);


  //let locked = card.locked ? card.locked : false;
  const handleClick = () => {
    setClicked(!clicked);
  };
  const changeNamheHandler = () => {
    !isNormalUser(5, user) && setNameChange({ ...nameChange, show: true });
  };
  if (editRequest) {
    return <div>{/* <CardEditR equest setEditRequest={setEditRequest} /> */}</div>;
  }
  if (clicked) {
    return (
      <div onClick={handleClick}>
        <ModelClicked
          changeNamheHandler={changeNamheHandler}
          model={card}
          setEditRequest={setEditRequest}
          locked={locked}
          archived={archived}
        />
      </div>
    );
  } else {
    return (
      <>
        <div
          className="model-card-container"
          id="ModelCard"
          onClick={() => {
            handleClick();
          }}
        >
          <div className={archived ? 'top-dash-black' : locked ? 'top-dash-green' : 'top-dash-yellow'}></div>
          <div className="model-card-title">
            <div className="modelCard">{card.name ? card.name : ' نموذج المشاريع الإنشائية الصناعية'}</div>
            <div
              onClick={() => {
                //setClicked(false);
                handleClick();
              }}
              onMouseEnter={() => setSvgHover(true)}
              onMouseLeave={() => setSvgHover(false)}
              className="threedots"
            >
              {/* <div>
                <img src={svgHover ? threedotsgreen : threedots} width={'23px'} alt="threeDotsIcon" />
              </div> */}
              <div className={` ${locked ? 'title-icon' : 'title-icon-unlocked'}`}>
                {!archived &&
                  (locked ? (
                    <img className="lock-icon" src={LockIcon} alt="LockIcon" />
                  ) : (
                    <img className="unlock-icon" src={UnlockIcon} alt="UnlockIcon" />
                  ))}
              </div>
            </div>
          </div>
          <div className="model-card-bottom" onClick={() => handleClick()}>
            <div className="last-edit-container">
              <div className="last-edit"> اخر تعديل</div>
              <div className="card-date">
                {splitDate(card?.lastModifyDate) || splitDate(card?.createDate) || '4-4-2022'}{' '}
              </div>
            </div>

            <div>بواسطه</div>
            <div className="user">
              <img className="male-img" src={MaleImg} alt="MaleImg" />
              <span>{card?.createdBy.userName || '  اسم المغير'}</span>
            </div>
          </div>
        </div>
        <Dialog
          className="primereact-dialouge-model model-info-dialog"
          visible={nameChange.show}
          onHide={() => setNameChange({ ...nameChange, show: false })}
          draggable={false}
          showHeader={true}
          dismissableMask={true}
          header="تغير اسم النموذج "
        >
          <div className="create-new-model">
            <div className="modelname">
              <div style={{ margin: '10px' }}>
                اسم النموذج الجديد<span>*</span>
              </div>

              <input
                value={nameChange.name}
                onChange={(e) => setNameChange({ ...nameChange, name: e.target.value })}
                className="model-name-input"
                placeholder="مثال :: نموذج المشاريع الانشائيه"
              />
            </div>
            <br></br>

            <button
              disabled={nameChange.name === '' ? true : false}
              onClick={() => setNameChange({ ...nameChange, show: false })}
              className="contine-model-btn"
            >
              حفظ اسم النموذج
            </button>
          </div>
        </Dialog>
      </>
    );
  }
};

export default ModelCard;
