import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { User } from '../../../models/User';
import { addUser, getAllModules } from '../../../redux/actions/user';
import { RootState } from '../../../redux/reducers';
import Loader from '../../Loader/Loader';
import './createuser.scss';
import BasicInfo from './Steps/BasicInfo';
import SystemAcess from './Steps/SystemAcess';
import UserAccessbility from './Steps/UserAccessbility';

const CreateUser: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedStep, setSelectedStep] = useState(1);
  const [user, setUser] = useState<Partial<User>>({
    admUserModules: [],
    isEnable: false,
    userPass: '',
    userEmail: '',
    userName: '',
    userJob: '',
    birthDate: '',
    userAddress: '',
    hiringDate: '',
    departmentName: '',
    userPhone: '',
  });
  const { modules, loading } = useSelector((state: RootState) => state.user);
  const toast = useRef<Toast | null>(null);

  useEffect(() => {
    if (modules.length > 0) {
      return;
    }
    dispatch(getAllModules(toast));
    // eslint-disable-next-line
  }, [dispatch]);

  const addUserHandler = (data: any) => {
    const userData = { ...user, ...data };
    //
    setUser(userData);

    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }

    if (selectedStep === 3) {
      dispatch(addUser(userData, history, toast));
    }
  };

  const changeStepHandler = (stepNum: number) => {
    setSelectedStep(stepNum);
  };

  return (
    <>
      <Toast ref={toast} position="top-left" />
      {loading && <Loader />}
      <div className="createuser-container">
        <div className="usercreation-step">
          <div
            className="usercreation-stepnumber"
            style={{
              backgroundColor: selectedStep === 1 ? '#1EB05D' : '#ddd8d8',
              color: selectedStep === 1 ? 'white' : '#2E293B',
            }}
          >
            1
          </div>
          <div className="border-radius" style={{ borderBottom: selectedStep === 1 ? '3.5px solid #136F3B' : '' }}>
            بيانات المستخدم
          </div>
        </div>
        <div className="usercreation-dash"></div>
        <div className="usercreation-step">
          <div
            className="usercreation-stepnumber"
            style={{
              backgroundColor: selectedStep === 2 ? '#1EB05D' : '#ddd8d8',
              color: selectedStep === 2 ? 'white' : '#2E293B',
            }}
          >
            2
          </div>
          <div className="border-radius" style={{ borderBottom: selectedStep === 2 ? '3.5px solid #136F3B' : '' }}>
            صلاحيات النظام
          </div>
        </div>
        <div className="usercreation-dash"></div>
        <div className="usercreation-step">
          <div
            className="usercreation-stepnumber"
            style={{
              backgroundColor: selectedStep === 3 ? '#1EB05D' : '#ddd8d8',
              color: selectedStep === 3 ? 'white' : '#2E293B',
            }}
          >
            3
          </div>
          <div className="border-radius" style={{ borderBottom: selectedStep === 3 ? '3.5px solid #136F3B' : '' }}>
            الوصول
          </div>
        </div>
      </div>
      {selectedStep === 1 && (
        <BasicInfo
          setUser={setUser}
          user={user}
          addUserHandler={addUserHandler}
          currentStep={selectedStep}
          goBackStep={changeStepHandler}
        />
      )}
      {selectedStep === 2 && (
        <SystemAcess
          user={user}
          addUserHandler={addUserHandler}
          currentStep={selectedStep}
          goBackStep={changeStepHandler}
          setUser={setUser}
        />
      )}
      {selectedStep === 3 && (
        <UserAccessbility
          user={user}
          addUserHandler={addUserHandler}
          currentStep={selectedStep}
          goBackStep={changeStepHandler}
        />
      )}
    </>
  );
};

export default CreateUser;
