import React from 'react';
import ModelEditors from './ModelEditiors/ModelEditors';
import ModelStructure from './ModelStrucure/ModelStructure';
import './newmodel.scss';
import RepeatedElements from './RepeatedElements/RepeatedElements';
import RequiredInfo from './RequiredInfo/RequiredInfo';

const steps = [
  {
    id: 1,
    title: 'البيانات الاساسيه',
  },
  {
    id: 2,
    title: 'هياكل النموذج',
  },
  {
    id: 3,
    title: 'العناصر المتكره',
  },
  {
    id: 4,
    title: 'محررين النموذج',
  },
];

const NewModel = () => {
  const [selectedItem, setSelectedItem] = React.useState(1);

  React.useEffect(() => {}, [selectedItem]);

  return (
    <div className="new-model">
      <div className="create-tp-secton">
        <div className="steps-container" id="new-model-steps">
          {steps.map((stp) => (
            <div key={stp.id} onClick={() => setSelectedItem(stp.id)}>
              <div className={selectedItem === stp.id ? 'step-active' : 'step'}>
                {stp.title} <span>*</span>
                <div className={`${selectedItem === stp.id ? 'selected-step' : 'hide'} `}></div>
              </div>
            </div>
          ))}
        </div>

        <div className="tp-left">
          <button>حفظ النموذج</button>
          <button className="button-red">الغاء الانشاء</button>
        </div>
      </div>

      {selectedItem === 1 && <RequiredInfo />}

      {selectedItem === 2 && <ModelStructure />}

      {selectedItem === 3 && <RepeatedElements />}

      {selectedItem === 4 && <ModelEditors />}
    </div>
  );
};

export default NewModel;
