import React, { FC } from 'react';
import { InputNumber } from 'primereact/inputnumber';

type Props = {
  val: number | undefined;
  readOnly?: boolean;
  keyName?: string;
  placeholder?: string;
  changeHandler: (val: number, key?: string | undefined) => void;
};
const InputNumberComp: FC<Props> = ({ val, changeHandler, keyName, placeholder, readOnly = false }) => {
  return (
    <InputNumber
      className="input-prime"
      placeholder={placeholder}
      mode="decimal"
      locale="en-US"
      readOnly={readOnly}
      min={1}
      onValueChange={(e) => {
        const val = e.target.value;
        if (keyName) {
          changeHandler(val, keyName);
        } else {
          changeHandler(val);
        }
      }}
      value={val}
    />
  );
};

export default InputNumberComp;
