import './SystemTenders.scss';
import CreateConditionsManual from '../components/Modals/CreateConditionsManualModal/CreateConditionsManual';
import threeDots from '../../../assets/svg/three-dots.svg';
import threeDotsGreen from '../../../assets/svg/three-dots-green.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllSectors, fetchCurrentProjects } from '../../../redux/actions/tenderManagementActions';
import Loader from '../../Loader/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { RootState } from '../../../redux/reducers';
import { Toast } from 'primereact/toast';
import { Project } from '../../../models/add-project.model';
import { getAllManualsByStatus } from '../../../redux/actions/manualManagementActions';
import { useHistory } from 'react-router-dom';

const SystemTenders = () => {
  /*const state = {
    onGoing: 'جاري التعديل',
    notYet: 'لم يتم البدأ',
  };
*/
  const [hoverId, setHoverId] = useState('');
  const [showMenu, setShowMenu] = useState(true);
  const [isSeeMoreHovering, setIsSeeMoreHovering] = useState(false);
  const [showCreateConditionsManualModal, setShowCreateConditionsManualModal] = useState(false);
  const [selectedTender, setSelectedTender] = React.useState<any>();
  const dispatch = useDispatch();
  const { currentProjects, totalRecords } = useSelector((state: RootState) => state.tenderManagement);
  const { underModificationManuals, approvedManuals } = useSelector((state: RootState) => state.manualManagement);
  const { user } = useSelector((state: RootState) => state.user);
  const history = useHistory();

  const loading = useSelector((state: RootState) => state.tenderManagement.loading);

  const sectors = useSelector((state: RootState) => state.tenderManagement.allSectors);

  const toast = useRef<Toast | null>(null);
  useEffect(() => {
    // dispatch actions
    dispatch(fetchCurrentProjects(toast, { state: 1, page: 0, size: 0, sort: '' }));
    dispatch(fetchAllSectors(toast));
    if (user) {
      dispatch(getAllManualsByStatus(2, user?.id, toast)); // approved
      dispatch(getAllManualsByStatus(1, user?.id, toast)); //underModification
      dispatch(getAllManualsByStatus(3, user?.id, toast)); //archived
    }
  }, [dispatch, user]);

  const hoverHandler = (e: any, id: any) => {
    if (id !== '') e.stopPropagation();

    if (id !== hoverId) {
      setShowMenu(false);
      let children = document.getElementById(hoverId)?.getElementsByTagName('td');
      if (children) {
        for (let i = 0; i < children?.length; i++) {
          children[i].className = '';
        }
      }
      children = document.getElementById(id)?.getElementsByTagName('td');

      if (children) {
        for (let i = 0; i < children?.length; i++) {
          children[i].className = 'selected';
        }
      }
      setHoverId(id);
    }
  };

  const getSectorName = (id: any) => {
    const sector = sectors.find((sector) => sector.sectorCode === id);
    //
    return sector?.sectorName || 'not found';
  };

  const goToManual = (project: any) => {
    const manual = [...underModificationManuals, ...approvedManuals].find((manual) => {
      return manual.projectId === project.projectId;
    });
    history.push(`/edit-conditions-manual/${manual.tenderId}`);
    // console.log('==>', manual);
  };

  return (
    <div
      onMouseOver={(e) => {
        hoverHandler(e, '');
      }}
      className="systemTendersContent"
    >
      {loading && <Loader />}
      <Toast ref={toast} position="top-left" />
      <h1 id="title">المناقصات المعتمدة</h1>

      <br></br>

      <div
        className={hoverId !== '' ? 'tableArea' : 'tableArea overflow-hidden'}
        style={{
          overflowY: hoverId !== '' ? 'auto' : 'scroll',
        }}
      >
        <table>
          <thead>
            <tr>
              <th>اسم المناقصة</th>
              <th>تاريخ الاعتماد</th>
              <th>القطاع</th>
              <th>حالة كراسة الشروط</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentProjects.map((project: Project, i) => {
              return (
                <tr
                  key={project.projectId}
                  id={String(project.projectId)}
                  onMouseOver={(e) => {
                    hoverHandler(e, `${project.projectId}`);
                    //
                  }}
                >
                  <td>
                    <div className="bulletContainer">
                      <svg className="bullet"></svg>
                    </div>
                    <p>{project.projectName}</p>
                  </td>
                  <td>22/2/2222</td>
                  <td>{getSectorName(project.sectorCode)}</td>
                  <td>
                    {approvedManuals.some((manual) => manual.projectId === project.projectId) ||
                    underModificationManuals.some((manual) => manual.projectId === project.projectId) ? (
                      <div className="onGoing">جاري التعديل</div>
                    ) : (
                      <div className="notYet">لم يتم البدأ</div>
                    )}
                  </td>
                  <td
                    /*style={{ display: 'inline-block' }}*/
                    onMouseMove={() => {
                      setIsSeeMoreHovering(true);
                    }}
                    onMouseOut={() => {
                      setIsSeeMoreHovering(false);
                    }}
                    onClick={() => {
                      setShowMenu(true);
                    }}
                  >
                    <div>
                      <img
                        className="seeMore"
                        alt="seeMoreIcon"
                        src={
                          hoverId === `${project.projectId}` && (showMenu || isSeeMoreHovering)
                            ? threeDotsGreen
                            : threeDots
                        }
                      />
                    </div>
                    <div
                      id="optionsMenu"
                      style={{ display: hoverId === `${project.projectId}` && showMenu ? 'flex' : 'none' }}
                    >
                      <ul>
                        <li
                          onClick={
                            // data.state === state.notYet
                            approvedManuals.some((manual) => manual.projectId === project.projectId) ||
                            underModificationManuals.some((manual) => manual.projectId === project.projectId)
                              ? () => {}
                              : () => {
                                  setShowCreateConditionsManualModal(true);
                                  setSelectedTender(project);
                                }
                          }
                          style={{
                            opacity:
                              approvedManuals.some((manual) => manual.projectId === project.projectId) ||
                              underModificationManuals.some((manual) => manual.projectId === project.projectId)
                                ? '0.5'
                                : '1',
                          }}
                        >
                          انشاء كراسة شروط
                        </li>
                        {/* <li>تغيير حالة الكراسة</li> */}
                        <li onClick={() => goToManual(project)}>ذهاب الى الكراسة</li>
                        {/* <li>تحميل ملف الكراسة</li>
                        <li>تحميل ملف المناقصة</li> */}
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showCreateConditionsManualModal && (
        <CreateConditionsManual
          show={showCreateConditionsManualModal}
          hide={() => {
            setShowCreateConditionsManualModal(false);
          }}
          selectedTender={selectedTender}
          SectorName={getSectorName(selectedTender.sectorCode)}
          setSelectedTender={setSelectedTender}
        />
      )}
    </div>
  );
};

export default SystemTenders;
