import React, { FC, Fragment, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import reef from '../../assets/png/g-reef.png';
import collapsedLogo from '../../assets/png/collapsed-logo.png';
import menurectangle from '../../assets/svg/menu-rectangle.svg';
import vara from '../../assets/svg/vara.svg';
import leftarrow from '../../assets/svg/left-arrow.svg';
import './NewSideMenu.scss';
import Marginer from '../Marginer/Marginer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { isNormalUser } from '../../utils/checkAccess';
import InnerFooter from '../InnerFooter/InnerFooter';

type Props = {
  sideMenuItems: any[];
  selectedItem: any;
  toggledSideMenu: any;
};

const NewSideMenu: FC<Props> = ({ sideMenuItems, selectedItem, toggledSideMenu }) => {
  const history = useHistory();
  const location = useLocation();
  const [toggleSideMenu, setToggleSideMenu] = useState(true);
  const [activeLinkId, setActiveLinkId] = React.useState<string>();
  const { user, modules } = useSelector((state: RootState) => state.user);
  const { pathname } = useLocation();
  useEffect(() => {
    //
    toggledSideMenu(toggleSideMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleSideMenu]);

  const toggleSideMenuHandler = () => {
    let sideMenuItem = document.getElementById('sideMenu');
    let leftArrow = document.getElementById('leftArrow');
    setToggleSideMenu(!toggleSideMenu);
    sideMenuItem?.classList.toggle('collapseSideMenu');
    leftArrow?.classList.toggle('rotateCollapsed');
  };

  const sideMenuHandler = () => {
    if (isNormalUser(5, user)) {
      let filterSideMenu = sideMenuItems.filter((ele) => ele.id !== 0);
      return filterSideMenu;
    } else {
      return sideMenuItems;
    }
  };
  // console.log('>>>>>', sideMenuHandler())

  // console.log('-------', isNormalUser(4, user));

  const handleDashboard = (item: any) => {
    if (item.id === 5) {
      return toggleSideMenuHandler();
    }
  };

  useEffect(() => {
    setActiveLinkId(document.getElementsByClassName('active')[0]?.id);
  }, [location]);
  return (
    <div id="sideMenu" className="new-side-menu">
      <div className="side-menu-logos">
        <div className="reef-logo" onClick={() => history.push('/home')}>
          {toggleSideMenu && (
            <Fragment>
              <div className="vara-title">
                <div className="vara-img">
                  <img src={vara} alt="vara-img" />
                </div>
                <Marginer direction="vertical" margin="4rem" />
                <div>
                  <img className="reef-img" src={reef} alt="reef-logo" />
                </div>
              </div>
            </Fragment>
          )}
          {!toggleSideMenu && <img src={collapsedLogo} alt="collapsed-logo" />}
        </div>
      </div>
      <ul>
        {sideMenuHandler().map((sideMenuItem) => {
          return (
            <Fragment key={sideMenuItem.id}>
              {sideMenuItem.menuLink ? (
                <NavLink
                  key={sideMenuItem.id}
                  exact={true}
                  id={`side-menu-link-${sideMenuItem.id}`}
                  onClick={() => {
                    selectedItem(sideMenuItem);
                    setActiveLinkId(`side-menu-link-${sideMenuItem.id}`);
                    handleDashboard(sideMenuItem);
                  }}
                  activeClassName="active"
                  to={`${sideMenuItem.menuLink}`}
                  className="side-menu-link"
                >
                  <span className={`icon ${toggleSideMenu === false ? 'collapsed-side-menu' : 'side-menu-margin'}`}>
                    <img
                      src={
                        `side-menu-link-${sideMenuItem.id}` === activeLinkId
                          ? sideMenuItem.iconFilled
                          : sideMenuItem.icon
                      }
                      alt="sideMenuItem.icon"
                    />
                  </span>
                  {toggleSideMenu && <span className="title">{sideMenuItem.title}</span>}
                  <img className="menu-rectangle" src={menurectangle} alt="menu-rectangle" />
                </NavLink>
              ) : (
                <button className={pathname === '/users/userinfo' ? 'disabledbutton active' : 'disabledbutton'}>
                  <span className={`icon ${toggleSideMenu === false ? 'collapsed-side-menu' : 'side-menu-margin'}`}>
                    <img src={sideMenuItem.icon} alt="sideMenuItem.icon" />
                  </span>
                  {toggleSideMenu && <span className="title">{sideMenuItem.title}</span>}
                  <img className="menu-rectangle" src={menurectangle} alt="menu-rectangle" />
                </button>
              )}
            </Fragment>
          );
        })}
      </ul>
      <button className="collapsed-btn" onClick={toggleSideMenuHandler}>
        <img id="leftArrow" src={leftarrow} alt="left-arrow" />
      </button>
    <InnerFooter />
    </div>
  );
};

export default NewSideMenu;
