import mime from 'mime-types';
import { Dialog } from 'primereact/dialog';
// @ts-ignore
import FilePreviewer from 'react-file-previewer';
import leftArrow from '../../assets/svg/left-arrow.svg';
import rightArrow from '../../assets/svg/left-arrow.svg';

import './index.scss';
type Props = {
  fileBase64: string;
  fileType: string;
  isPreviewOpen: boolean;
  handlePreviewOpen: any; 
  selectedFilePreview?: any;
  handleFilePreview?: any;
  filesLength?: number;
  id?: string;
  headerTitle?: string;
};
const FilesPreview = ({
  handleFilePreview,
  fileBase64,
  fileType,
  handlePreviewOpen,
  isPreviewOpen,
  selectedFilePreview,
  filesLength,
  id,
  headerTitle,
}: Props) => {
  return (
    <Dialog
      id={id}
      visible={isPreviewOpen}
      onHide={() => {
        handlePreviewOpen(false);
      }}
      header={headerTitle}
      blockScroll={true}
      rtl={true}
      closeOnEscape={false}
      draggable={false}
      resizable={false}
    >
      <div className="modal-body preview-files-container">
        {/* Show arrows if we have multiple files to navigate for? */}
        {selectedFilePreview && (
          <div className="navigationButtons">
            <button
              className="next-btn"
              disabled={filesLength === selectedFilePreview.index + 1}
              onClick={() => {
                handleFilePreview(selectedFilePreview.index + 1);
              }}
            >
              <img width={30} height={20} src={rightArrow} alt="التالي" />
            </button>
            <button
              className="prev-btn"
              disabled={selectedFilePreview.index === 0}
              onClick={() => {
                handleFilePreview(selectedFilePreview.index - 1);
              }}
            >
              <img width={30} height={20} src={leftArrow} alt="السابق" />
            </button>
          </div>
        )}
        <div className="pageContent">
          <FilePreviewer
            file={{
              data: fileBase64,
              mimeType: mime.lookup(fileType),
              name: 'download.' + fileType, // for download
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default FilesPreview;
