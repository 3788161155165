export interface Sector {
  sectorCode: number;
  sectorName: string;
  prtsInitiativeCodes: Initiative[];
}

export interface Initiative {
  sectorCode: number;
  initiativeCode: number;
  initiativeName: string;
  initiativeShortName: string;
  initiativeOperationalExpense: number;
  initiativeCapitalExpenses: number;
  initiativeData1: string;
  initiativeData2: string;
  initiativeData3: string;
  initiativeData4: string;
  initiativeData5: string;
}

export const initiativeDefaultValue: Readonly<Initiative> = {
  sectorCode: 0,
  initiativeCode: 0,
  initiativeName: '',
  initiativeShortName: '',
  initiativeOperationalExpense: 0,
  initiativeCapitalExpenses: 0,
  initiativeData1: '',
  initiativeData2: '',
  initiativeData3: '',
  initiativeData4: '',
  initiativeData5: '',
};
