import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import plus from '../../../assets/svg/plus.svg';
import SearchIcon from '../../../assets/svg/search.svg';
import { getAllUsers, searchUser } from '../../../redux/actions/user';
import { RootState } from '../../../redux/reducers';
import Loader from '../../Loader/Loader';
import './usersmangment.scss';

const UsersMangment: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { allUsers, loading, totalRecords } = useSelector((state: RootState) => state.user);

  const [firstSearch, setFirstSearch] = useState(true);
  const [first, setFirst] = useState(0);

  const [params, setParams] = useState({
    page: first,
    size: 15,
    sort: '',
    direction: '',
    state: 1,
  });
  const [emailPrams, setEmailPrams] = useState({ ...params, email: '' });
  const toast = useRef<Toast | null>(null);

  const [columns] = useState([
    { field: 'userName', header: 'اسم المستخدم' },
    { field: 'userJob', header: 'الوظيفة' },
    { field: 'departmentName', header: 'القسم' },
    { field: 'userEmail', header: 'الإيميل' },
    { field: 'userPhone', header: 'رقم الهاتف' },
  ]);

  useEffect(() => {
    dispatch(getAllUsers(toast, params));
  }, [params, dispatch]);

  useEffect(() => {
    if (firstSearch) return;
    dispatch(searchUser(emailPrams, toast));

    return () => setFirstSearch(true);
    // eslint-disable-next-line
  }, [dispatch, emailPrams]);

  const setParamsHandler = (key: string, value: string | number) => {
    setParams({ ...params, [key]: value });
    setEmailPrams({ ...emailPrams, [key]: value });
  };

  const handleRowClick = (row: any) => {
    const { data } = row;

    history.push('/users/userinfo', {
      user: data,
    });
  };
  console.log({ totalRecords }, params.size);
  const dynamicColumns = columns.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });
  return (
    <div className="model-preration-container">
      {loading && <Loader />}
      <Toast ref={toast} position="top-left" />
      <div className="mp-top-section">
        <Link to="/users/createuser">
          <button className="model-create-button" id="newUserBtn">
            <img className="plussvg" src={plus} alt="plusIcon" />
            <p> مستخدم جديد </p>{' '}
          </button>
        </Link>
        <div className="top-left-section">
          <InputText
            type="email"
            className="search-doc"
            onChange={(e) => {
              setFirstSearch(false);
              setEmailPrams({ ...emailPrams, email: e.target.value });
            }}
            value={emailPrams.email}
            placeholder=" بحث المستخدمين"
            tooltip=" بحث المستخدمين عن طريق الايميل فقط"
            tooltipOptions={{ position: 'bottom', className: 'tooltipstyle' }}
          />
          <img style={{ marginTop: '10px' }} className="search-icon" src={SearchIcon} alt="SearchIcon" />
        </div>
      </div>
      <div className="after-create-tp-secton">
        <DataTable
          onRowClick={(row) => handleRowClick(row)}
          value={allUsers}
          rowHover={true}
          className="userManagementDataTable"
        >
          {dynamicColumns}
        </DataTable>

        <Paginator
          totalRecords={totalRecords}
          first={first}
          onPageChange={(e) => {
            setFirst(e.first);
            setParamsHandler('page', e.page);
          }}
          rows={params.size}
        ></Paginator>
      </div>
    </div>
  );
};

export default UsersMangment;
