import React, { FC, useState } from 'react';
import { Chart } from 'primereact/chart';

type props = {
  data: any;
};
const LineChart: FC<props> = ({ data }) => {
  let labels = [];
  let dataset = [];
  console.log(data, 'line');
  labels = data?.labels?.map((ele: any) => {
    return ele;
  });

  dataset = data.datasets?.map((el: any) => {
    return el.data;
  });

  const [basicData] = useState({
    labels: labels,
    datasets: [
      {
        label: 'عدد المشروعات',
        data: dataset[0],
        fill: false,
        borderColor: '#42A5F5',
        tension: 0.4,
      },
      // {
      //   label: 'Second Dataset',
      //   data: [28, 48, 40, 19, 86, 27, 90],
      //   fill: false,
      //   borderColor: '#FFA726',
      //   tension: 0.4,
      // },
    ],
  });
  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: '#495057',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
        y: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
      },
    };

    return {
      basicOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  return (
    <div className="line-chart">
      <div className="card">
        <Chart type="line" data={basicData} options={basicOptions} />
      </div>
    </div>
  );
};
export default LineChart;
