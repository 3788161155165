
export const getReadonly = (headerID,subheaderID ,headerslist)=>{
 
            if(subheaderID === 'none'){
            const exactheader= headerslist.find((header) =>{
                    if(header.id === headerID) return header;
                    else return;
                })
                return exactheader.readOnly; 
            }else{
                         
        let exactHeader = headerslist.find((header) => {
            if (headerID === header.id)   return header;  
            else return; 
          })
  
          let subheader = exactHeader.subSections.find((subheader) => {
            if (subheaderID === subheader.id)   return subheader;
            else return;
      })
                return subheader.readOnly;
            }
}