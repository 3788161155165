import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/reducers';
import InnerFooter from '../../../InnerFooter/InnerFooter';
import InnerHeader from '../../../InnerHeader/InnerHeader';
import SideMenu2 from '../../../SideMenu2/SideMenu2';
import RichEditorFullComp from '../../../TextEditor/RichEditorFullComp';
import CommentsSection from './CommentsSection';
import './createmodel.scss';
import { deleteSection, updateSectionModel } from '../../../../api/apiRequest';
import { getSingleModel } from '../../../../redux/actions/modelMangmentActions';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';

type modelId = {
  id: string;
};

const CreateModel: React.FC = () => {
  const dispatch = useDispatch();
  const sectionID = useSelector((state: RootState) => state.modelMangment.newModelSectionId);
  const subSectionID = useSelector((state: RootState) => state.modelMangment.newmodelsubsectionId);
  const singleModel = useSelector((state: RootState) => state.modelMangment.singleModel);
  const NewNote = useSelector((state: RootState) => state.modelMangment.noteID);

  const [mainHeadersList, setMainHeadersList] = React.useState<Array<any>>([]);
  const [preDisplayedContent, setPreDisplayedContent] = React.useState('-');
  const [HTMLcontent, setHTMLContent] = React.useState<string>('');
  const [readOnly, setReadOnly] = React.useState<boolean>(false);
  const [ModelID, setModelID] = React.useState(0);
  const toast = useRef<Toast | null>(null);
  const params = useParams<modelId>();

  //sidemenu2 stuff
  const [location, setLocation] = React.useState<{ parent: string; child: null | string }>({ parent: '', child: '' });
  React.useEffect(() => {
    singleModel.sections?.sort((a: any, b: any) => {
      return a.order - b.order;
    });
    singleModel.sections?.forEach((section: any) => {
      section.subSections?.sort((a: any, b: any) => {
        return a.order - b.order;
      });
    });
    setMainHeadersList(singleModel.sections);
  }, [singleModel]);

  React.useEffect(() => {
    setModelID(Number(params.id));
    dispatch(getSingleModel(Number(params.id)));
  }, [dispatch, params.id]);

  React.useEffect(() => {
    if (mainHeadersList) {
      const mainHeadersListDB = mainHeadersList.map((section: any) => {
        if (section.id === sectionID.OldID) {
          return { ...section, id: sectionID.DbID };
        } else {
          return section;
        }
      });
      mainHeadersListDB?.sort((a: any, b: any) => {
        return a.order - b.order;
      });
      mainHeadersListDB?.forEach((section: any) => {
        section.subSections?.sort((a: any, b: any) => {
          return a.order - b.order;
        });
      });
      setMainHeadersList(mainHeadersListDB);
    }
  }, [sectionID]);

  React.useEffect(() => {
    const mainHeadersListDB = mainHeadersList.map((section: any) => {
      if (section.id === subSectionID.sectionID) {
        return { ...section, subSections: subSectionID.DbID };
      } else {
        return section;
      }
    });
    mainHeadersListDB?.sort((a: any, b: any) => {
      return a.order - b.order;
    });
    mainHeadersListDB?.forEach((section: any) => {
      section.subSections?.sort((a: any, b: any) => {
        return a.order - b.order;
      });
    });
    setMainHeadersList(mainHeadersListDB);
  }, [subSectionID]);

  React.useEffect(() => {
    const { parent, child } = location;
    if (child === '') {
      let newHeaderList = mainHeadersList.map((header: any) => {
        if (header.id === parent) {
          return { ...header, notes: [...header.notes, NewNote] };
        } else return header;
      });

      setMainHeadersList(newHeaderList);
    } else {
      let newHeaderList = mainHeadersList.map((header: any) => {
        if (header.id === parent) {
          let newsubheaderList = header.subSections.map((subheader: any) => {
            if (subheader.id === child) {
              return {
                ...subheader,
                notes: [...subheader.notes, NewNote],
              };
            } else return subheader;
          });
          return { ...header, subSections: newsubheaderList };
        } else return header;
      });

      setMainHeadersList(newHeaderList);
    }
  }, [NewNote]);

  //
  //
  const mapContentToHeader = () => {
    const { parent, child }: any = location; // header and sunheadder index that the user is currently selecting from the sidemenu2
    if (child === '') {
      // MAP CONTENT OR NOTES TO A HEADER

      let newMainHearderlist = mainHeadersList.map((header: any) => {
        if (parent === header.id) {
          updateSectionModel({ ...header, content: HTMLcontent, readOnly: readOnly }, ModelID);
          return { ...header, content: HTMLcontent, readOnly: readOnly }; //ifnot map the content to the selected header
        }
        return header; //if not the selected header leave it as it is
      });
      setMainHeadersList(newMainHearderlist); // set the new modifed headers list
    } else {
      // MAP CONTENT OR NOTES TO A SUB_HEADER

      let newMainHearderlist = mainHeadersList.map((header: any) => {
        if (parent === header.id) {
          // the exact header in which the user has selected
          let newheader = header.subSections.map((subheader: any, i: number) => {
            if (child === subheader.id) {
              // the exact sub header in which the user has selected

              return { ...subheader, content: HTMLcontent, readOnly: readOnly }; //return the content from the text editor
            }
            return subheader; //if not the required subheader leave it as it is
          });
          updateSectionModel({ ...header, subSections: newheader }, ModelID);
          return { ...header, subSections: newheader };
        }
        return header; ////if not the required header leave it as it is
      });
      setMainHeadersList(newMainHearderlist); // set the new modifed headers
    }
  };

  const deleteContentFromHeader = () => {
    const { parent, child }: any = location;

    if (child === '') {
      let newMainHearderlist = mainHeadersList.filter((header: any) => parent !== header.id);
      let orderednewsubheaderlist = newMainHearderlist.map((section: any, i: number) => {
        return { ...section, order: i };
      });

      deleteSection(Number(location.parent), orderednewsubheaderlist, ModelID);
      setMainHeadersList(orderednewsubheaderlist);
    } else {
      let newHeaderList = mainHeadersList.map((header: any, i: number) => {
        if (parent === header.id) {
          let newsubheaderlist = header.subSections.filter((subheader: any) => child !== subheader.id);
          let orderednewsubheaderlist = newsubheaderlist.map((subsection: any, i: number) => {
            return { ...subsection, order: i };
          });
          return { ...header, subSections: orderednewsubheaderlist };
        }
        return header;
      });

      deleteSection(Number(location.child), newHeaderList, ModelID);
      setMainHeadersList(newHeaderList);
    }
  };

  return (
    <>
      <Toast ref={toast} position="top-left" />
      <div className="create-model-from-scratch-container">
        <div className="create-header">
          <InnerHeader selectedItem={singleModel?.name || ''} mainSection="انشاء نموذج جديد" />
        </div>

        <div className="create-sidebar">
          <SideMenu2
            setPreDisplayedContent={setPreDisplayedContent}
            setMainHeadersList={setMainHeadersList}
            mainHeadersList={mainHeadersList}
            setLocation={setLocation}
            setReadOnly={setReadOnly}
            ModelID={ModelID}
            preDisplayedContent={preDisplayedContent}
            HTMLcontent={HTMLcontent}
            location={location}
            setHTMLContent={setHTMLContent}
          />
        </div>

        <div className="create-content">
          <br></br>
          <h1> {singleModel?.name || 'جاري تحميل بيانات النموذج'}</h1>
          <br></br>
          <RichEditorFullComp
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            location={location}
            HTMLcontent={HTMLcontent}
            mapContentToHeader={mapContentToHeader}
            deleteContentFromHeader={deleteContentFromHeader}
            setHTMLContent={setHTMLContent}
            setPreDisplayedContent={setPreDisplayedContent}
            preDisplayedContent={preDisplayedContent}
            viewOnly={false}
          />
          <br></br>

          <CommentsSection
            location={location}
            mainHeadersList={mainHeadersList}
            setMainHeadersList={setMainHeadersList}
          />
        </div>
      </div>

      <InnerFooter />
    </>
  );
};

export default CreateModel;
