import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import Switch from 'react-switch';
import { ConfirmDialog } from 'primereact/confirmdialog';
import confirm from '../../../assets/svg/confirm.svg';
import './userinfo.scss';
import PasswordModal from '../../modals/PasswordModal';
import { User } from '../../../models/User';
import { passvalidator } from '../../../constants/regex';

type Props = {
  user: User;
  saveUser: (data: any, isPassowrd: boolean) => void;
  cancelEdit: () => void;
  deleteUser: () => void;
  editUser: any;
};

const AccountState: React.FC<Props> = ({ saveUser, cancelEdit, deleteUser, user, editUser }) => {
  const [activated, setActivated] = useState(user.isEnable);
  const [visible, setVisible] = useState(false);
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const handlePassword = () => {
    if (password && confirmPassword && password !== confirmPassword) {
      return;
    }
    saveUser({ password, isEnable: activated }, true);
  };

  React.useEffect(() => {
    if (password === '' && password !== confirmPassword && !passwordError) {
      return;
    }
    editUser({ ...user, userPass: password });
  }, [password, confirmPassword, passwordError]);

  React.useEffect(() => {
    editUser({ ...user, isEnable: activated });
  }, [activated]);

  return (
    <>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="في حالة حذف السماح لن يتمكن المستخدم من الوصول مرة آخري إلي هذا البرنامج"
        header="تأكيد إلغاء السماح"
        acceptLabel="تأكيد"
        rejectLabel="تجاهل"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={deleteUser}
        className="modalBootbox"
      />

      <div className="accountstate-container">
        <h2> إعادة تعيين كلمة المرور؟</h2>
        <br></br>

        <br></br>
        <div>
          <label>كلمة السر</label>
          <div className="flex-center">
            <InputText
              value={password}
              onChange={(e) => {
                const val = e.target.value;
                setPassword(val);
                if (passvalidator.test(val)) {
                  setPasswordError(false);
                } else {
                  setPasswordError(true);
                }
              }}
              type="password"
              readOnly={false}
              className="modalInputs"
            />
            {password && !passwordError && (
              <div className="input-filled-icon">
                <img src={confirm} alt="confirmIcon" />
              </div>
            )}
          </div>
          {passwordError && password !== '' && (
            <p style={{ color: 'red', marginTop: '5px', fontSize: '11px' }}>
              كلمه السر يجب ان تكون مكونه علي الاقل من عشره احرف و لا تزيد عن عشرون حرف و تحتوي علي الاقل رقم و علامه
              مميزه (@ ,_,)و حرف كبير
            </p>
          )}
        </div>
        <br></br>

        <br></br>
        <div>
          <label>إعادة كتابة كلمة السر</label>
          <div className="flex-center">
            <InputText
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              className="modalInputs"
            />
            {confirmPassword && confirmPassword === password && (
              <div className="input-filled-icon">
                <img src={confirm} alt="confirmIcon" />
              </div>
            )}
          </div>
        </div>
        <br></br>
        <div className="flex-center">
          <div className="valid-account" style={{ color: `${activated ? '#136F3B' : '#969696'}` }}>
            حساب فعال
          </div>
          <Switch
            onChange={() => setActivated(!activated)}
            checked={activated}
            offHandleColor="#969696"
            activeBoxShadow="0 0 2px 3px #c8d0d4"
            uncheckedIcon={false}
            offColor="#ffffff"
            handleDiameter={14}
            height={22}
            width={50}
            className="switch-class"
          />
        </div>
        <br></br>
        <div className="delete-account" onClick={() => setVisible(true)}>
          حذف الحساب نهائياً؟
        </div>
      </div>
      <div className="userinfo-button-container">
        <button className="cancel" onClick={cancelEdit}>
          إلغاء
        </button>

        <button className="save" onClick={handlePassword}>
          حفظ التغييرات
        </button>
      </div>

      {newPasswordModal && (
        <PasswordModal
          submitModal={(data) => {
            saveUser({ password: data, isEnable: activated }, true);
          }}
          closeModal={() => setNewPasswordModal(false)}
          showModal={newPasswordModal}
        />
      )}
    </>
  );
};

export default AccountState;
