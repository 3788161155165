import React, { FC } from 'react';
import { deleteNote, editNote } from '../../../../api/apiRequest';
import { addNote } from '../../../../redux/actions/modelMangmentActions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

import './createmodel.scss';

type Props = {
  location: any;
  mainHeadersList: Array<any>;
  setMainHeadersList: any;
};

const CommentsSection: FC<Props> = ({ mainHeadersList, location, setMainHeadersList }) => {
  const [isEditing, setIsEditing] = React.useState<number>();
  const [newComment, setNewComment] = React.useState('');
  const [preDisplayedNotes, setpreDisplayedNotes] = React.useState<Array<string>>([]);
  const [comment, setComment] = React.useState<string>('');
  const dispatch = useDispatch();
  const NewNote = useSelector((state: RootState) => state.modelMangment.noteID);

  const formatter = (string: string) => {
    // let newstring = string?.replaceAll('"', '');
    //
    return string;
  };
  React.useEffect(() => {
    const { parent, child } = location;
    if (child === '') {
      let newHeaderList = mainHeadersList.map((header: any) => {
        if (header.id === parent) {
          return { ...header, notes: [...header.notes, NewNote] };
        } else return header;
      });
      setMainHeadersList(newHeaderList);
    } else {
      let newHeaderList = mainHeadersList.map((header: any) => {
        if (header.id === parent) {
          let newsubheaderList = header.subSections.map((subheader: any) => {
            if (subheader.id === child) {
              return {
                ...subheader,
                notes: [...subheader.notes, NewNote],
              };
            } else return subheader;
          });
          return { ...header, subSections: newsubheaderList };
        } else return header;
      });

      setMainHeadersList(newHeaderList);
    }
  }, [NewNote]);
  React.useEffect(() => {
    const { parent, child } = location;

    if (child === '') {
      let exactheader = mainHeadersList.find((header) => {
        if (parent === header.id) return header;
        return;
      });
      setpreDisplayedNotes(exactheader?.notes || []);
    } else {
      let exactHeader = mainHeadersList.find((header) => {
        if (parent === header.id) return header;
        return;
      });

      let subheaderContent = exactHeader?.subSections?.find((subheader: any) => {
        if (child === subheader.id) return subheader;
        return;
      });
      setpreDisplayedNotes(subheaderContent?.notes || []);
    }
  }, [location, mainHeadersList]);

  const deleteComment = (id: any) => {
    const { parent, child } = location;
    deleteNote(id);
    if (child === '') {
      let newheaderList = mainHeadersList.map((header) => {
        if (parent === header.id) {
          let newnotes = header.notes.filter((note: any) => note.noteId !== id);
          return { ...header, notes: newnotes };
        } else return header;
      });
      setMainHeadersList(newheaderList);
    } else {
      let newheaderList = mainHeadersList.map((header) => {
        if (header.id === parent) {
          let newsubheaderlist = header.subSections.map((subheader: any) => {
            if (subheader.id === child) {
              let newnotes = subheader.notes.filter((note: any) => note.noteId !== id);

              return { ...subheader, notes: newnotes };
            } else return subheader;
          });
          return { ...header, subSections: newsubheaderlist };
        } else return header;
      });
      setMainHeadersList(newheaderList);
    }
  };

  const commentHandler = (id: number, comment: any) => {
    editNote(comment, newComment); //database call
    const { parent, child } = location;
    if (child === '') {
      //editing the notes of a header
      let newheaderList = mainHeadersList.map((header) => {
        if (parent === header.id) {
          let newnotes = header.notes.map((note: any) => {
            if (id === note.noteId) {
              return { ...note, noteText: newComment };
            }
            return note;
          });
          return { ...header, notes: newnotes };
        } else return header;
      });
      setMainHeadersList(newheaderList);
    } else {
      //editing the notes of a sub-header
      let newheaderList = mainHeadersList.map((header) => {
        if (header.id === parent) {
          let newsubheaderlist = header.subSections.map((subheader: any) => {
            if (subheader.id === child) {
              let newnotes = subheader.notes.map((note: any) => {
                if (id === note.noteId) {
                  return { ...note, noteText: newComment }; //returning the edited comment
                  //deleteing the note
                } else return note;
              });
              return { ...subheader, notes: newnotes };
            } else return subheader;
          });
          return { ...header, subSections: newsubheaderlist };
        } else return header;
      });

      //set the main headerlist here
      setMainHeadersList(newheaderList);
    }
  };
  return (
    <>
      <div
        style={{
          pointerEvents: location.parent !== '' ? 'auto' : 'none',
          opacity: location.parent !== '' ? '1' : '0.45',
        }}
        className="notes-section"
        onClick={() => setIsEditing(0)}
      >
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="notes-input"
          rows={3}
          cols={55}
          placeholder="يمكنك إضافة ملاحظات عن هذا التبويب  هنا.."
        />

        <button
          onClick={() => {
            setComment('');
            comment !== '' && dispatch(addNote(comment, location)); //database call
          }}
        >
          اضافه
        </button>
      </div>
      <div className="comments-comnatiner">
        {preDisplayedNotes.length === 0 ? (
          <div className="comments-comnatiner no-comments">لا توجد ايه تعليقات لهذا العنوان</div>
        ) : (
          preDisplayedNotes?.map((comment: any, i: number) => {
            if (comment.noteText !== '' && comment) {
              return (
                <div key={i} className="comment-container">
                  {isEditing === comment.noteId ? (
                    <textarea
                      rows={2}
                      cols={40}
                      value={formatter(newComment)}
                      onChange={(e) => {
                        setNewComment(e.target.value);
                      }}
                      className="input-coment-edit"
                      onKeyPress={(e) => {
                        e.key === 'Enter' && commentHandler(comment.noteId, comment);
                        e.key === 'Enter' && setIsEditing(0);
                      }}
                    />
                  ) : (
                    <div className="comment"> {formatter(comment.noteText)} </div>
                  )}
                  <div className="comment-buttons-container">
                    <button
                      className="button-coomment-1"
                      onClick={() => {
                        setNewComment(comment.noteText);
                        setIsEditing(comment.noteId);
                      }}
                    >
                      تعديل
                    </button>

                    <button className="button-coomment-2" onClick={() => deleteComment(comment.noteId)}>
                      حذف
                    </button>
                  </div>
                </div>
              );
            }
          })
        )}
      </div>
    </>
  );
};

export default CommentsSection;
