import React, { FC } from 'react';
import TextEditor from './TextEditor';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Switch from 'react-switch';
import './text-editor.scss';

type props = {
  setReadOnly: any;
  readOnly: boolean;
  setHTMLContent: any;
  mapContentToHeader: any;
  location: any;
  preDisplayedContent: string | null;
  setPreDisplayedContent: any;
  deleteContentFromHeader: any;
  viewOnly: boolean;
  HTMLcontent: string | null;
};

const RichEditorFullComp: FC<props> = ({
  setReadOnly,
  readOnly,
  setHTMLContent,
  mapContentToHeader,
  location,
  preDisplayedContent,
  setPreDisplayedContent,
  deleteContentFromHeader,
  viewOnly,
  HTMLcontent,
}) => {
  const [showMsg, setShowMsg] = React.useState({ show: false, msg: '' });
  const [biggerScreen, setBiggerScreen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const onSave = () => {
    mapContentToHeader();
    setPreDisplayedContent('-');
    // setReadOnly(false);

    setShowMsg({ show: true, msg: 'green' });
    const timer = setTimeout(() => {
      setShowMsg({ show: false, msg: '' });
    }, 1500);

    return () => clearTimeout(timer);
  };

  const onDelete = () => {
    deleteContentFromHeader();

    setPreDisplayedContent('');
    setShowMsg({ show: true, msg: 'red' });
    const timer = setTimeout(() => {
      setShowMsg({ show: false, msg: '' });
    }, 1500);

    return () => clearTimeout(timer);
  };

  return (
    <div
      className={
        location.parent === ''
          ? 'text-editor-container-disapled'
          : biggerScreen
          ? 'text-editor-container full-screen'
          : 'text-editor-container'
      }
    >
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message={`هل تريد حذف هذا ${location?.child !== '' ? 'العنوان الفرعي' : 'العنوان الرئيسي'}`}
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={onDelete}
        className="modalBootbox"
      />

      <h3 className="text-editor-title">تحرير محتوي القسم</h3>

      <div className="text-editor">
        <TextEditor
          viewOnly={viewOnly}
          preDisplayedContent={preDisplayedContent}
          setHTMLContent={setHTMLContent}
          location={location}
          biggerScreen={biggerScreen}
          HTMLcontent={HTMLcontent}
        />
      </div>

      <br></br>
      <br></br>

      <div className="editor-button-container">
        <div className="editable">
          <div className="editable-div" style={{ color: readOnly ? '#136F3B' : '#969696' }}>
            للأطلاع فقط
          </div>
          <label>
            <span></span>
            <Switch
              onChange={() => setReadOnly((old: any) => !old)}
              checked={readOnly}
              offHandleColor="#fff"
              activeBoxShadow="0 0 2px 3px #c8d0d4"
              uncheckedIcon={false}
              offColor="#969696"
              handleDiameter={16}
              disabled={viewOnly}
            />
          </label>
        </div>
        <button onClick={() => onSave()} className="button-save">
          حفظ التغييرات
        </button>
        <button onClick={() => setVisible(true)} className="buttton-delete">
          حذف القسم
        </button>
        <label
          style={{ color: showMsg.msg === 'red' ? 'red' : '#136F3B' }}
          className={showMsg.show ? 'label-show' : 'hide'}
        >
          {showMsg.msg === 'red' ? '!تم حذف المقطع!' : '!تم حفظ التغيرات'}
        </label>
      </div>

      <button onClick={() => setBiggerScreen(!biggerScreen)} className="fullscreen-button">
        {!biggerScreen ? 'عرض شاشة كاملة' : 'تصغير الشاشه'}
      </button>
      <br></br>
    </div>
  );
};

export default RichEditorFullComp;
