import { Module } from '../models/User';

export const hasAccessToModule = (module: Module, user: any) => {
  let hasAccess = false;
  if (!user || !user?.userModuleRoles || user?.userModuleRoles.length === 0) {
    hasAccess = false;
  }
  user?.userModuleRoles.forEach((role: { moduleId: number }) => {
    if (role.moduleId === module.moduleId) {
      hasAccess = true;
    }
  });
  return hasAccess;
};

export const isNormalUser = (moduled: number, user: any) => {
  let normalUser = false;
  if (!user) {
    return true;
  }
  let module = user?.userModuleRoles.find((module: any) => module.moduleId === moduled);
  //
  if (module?.userRole === 'ROLE_USER') {

    return true;
  }
  return normalUser;
};

export const isSuperAdmin = (user: any) => {
  let superadmin = false;
  //
  if (!user?.userModuleRoles) {
    return false;
  }
  if (
    hasAccessToModule(
      {
        moduleIcon: 'icon-Users',
        moduleId: 22,
        moduleLink: '/users',
        moduleNameA: 'إدارة المستخدمين',
        moduleNameE: 'USER_MANAGEMENT',
        moduleShortname: 'USER_MANAGEMENT',
      },
      user,
    )
  ) {
    return true;
  }
  for (let i = 0; i < user?.userModuleRoles.length; i++) {
    if (user?.userModuleRoles[i]?.userRole !== 'ROLE_SUPER_ADMIN') {
      superadmin = false;
      break;
    } else {
      superadmin = true;
    }
  }
  //
  return superadmin;
};
