import React from 'react'

const ModelEditors = () => {
    return (
        <div className='after-create-tp-secton'>
            Model editors
        </div>
    )
}

export default ModelEditors
