import moment from 'moment';

type FormatType = 'DD-MM-yyyy';

export const formatDate = (date: Date, format: FormatType = 'DD-MM-yyyy') => {
  return moment(date).format(format);
};

export const getDateFromString = (date: string | Date, format: FormatType = 'DD-MM-yyyy') => {
  return moment(date, format).toDate();
};

export const addToDate = (date: Date, amount: number, unit: moment.unitOfTime.DurationConstructor = 'day') => {
  return moment(date).add(amount, unit).toDate();
};
