import Model from '../../pages/Model/Model';
import ModelPrepration from '../../components/BookPrepration/ModelPrepartion/ModelPrepration';
import BookPrepartion from '../../components/BookPrepration/BookPrepration/BookPrepartion';
import MyBussMang from '../../components/BookPrepration/MyBussniesMang/MyBussMang';
import ModelSettiengs from '../../components/BookPrepration/ModelSettiengs/ModelSettiens';
import NewModel from '../../components/BookPrepration/NewModel/NewModel';
import SystemTenders from '../../components/BookPrepration/SystemTenders/SystemTenders';
import RecentlyOpened from '../../components/BookPrepration/RecentlyOpened/RecentlyOpened';

import { IRoutes } from '../../models/routes.model';
import CreateModelPage from '../../components/BookPrepration/ModelPrepartion/CreateModel/CreateModelPage';
import EditModelPage from '../../components/BookPrepration/ModelPrepartion/CreateModel/EditModelPage';
import EditConditionsManual from '../../components/BookPrepration/EditConditionsManual/EditConditionsManual';

const routes: IRoutes = {
  MODEL: {
    path: '/model',
    name: 'model',
    exact: false,
    component: Model,
    routes: {
      Tenders: {
        path: '/model/tenders',
        name: 'tenders',
        component: SystemTenders,
        exact: true,
      },
      Mangment: {
        path: '/model/mangment',
        name: 'mangment',
        component: ModelPrepration,
        exact: true,
      },
      BookMangment: {
        path: '/model/book',
        name: 'book',
        component: BookPrepartion,
        exact: true,
      },
      BussniesMangment: {
        path: '/model/mybussnies',
        name: 'mybussnies',
        component: MyBussMang,
        exact: true,
      },
      SeetingsMangment: {
        path: '/model/settings',
        name: 'modelsettings',
        component: ModelSettiengs,
        exact: true,
      },
      NewModel: {
        path: '/model/create-new-model',
        name: 'creaetnewmodel',
        component: NewModel,
        exact: true,
      },
      RecentlyOpened: {
        path: '/model/recently',
        name: 'recently',
        component: RecentlyOpened,
        exact: true,
      },
    },
  },
  CREATEMODEL: {
    path: '/createmodel/:id',
    name: 'model',
    component: CreateModelPage,
    exact: false,
  },
  EDITMODEL: {
    path: '/editmodel/:id',
    name: 'editmodel',
    component: EditModelPage,
    exact: false,
  },
  EditConditionsManual: {
    path: '/edit-conditions-manual/:id',
    name: 'edit-conditions-manual',
    component: EditConditionsManual,
    exact: false,
  },
};

export default routes;
