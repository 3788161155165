import React from 'react';
import { Link } from 'react-router-dom';
import './BreadCrumb.scss';

type Props = {
  mainSection: string;
  selectedItem: string;
};

const BreadCrumb: React.FC<Props> = ({ selectedItem, mainSection }) => {
  return (
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/home">الرئيسية</Link>
        </li>
        {mainSection && (
          <>
            <li>{mainSection}</li>
            <li>{selectedItem}</li>
          </>
        )}
      </ul>
    </div>
  );
};

export default BreadCrumb;
