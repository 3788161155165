// import '../../components/BookPrepration/SystemTenders/SystemTenders.scss';
import './SearchDocuments.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { fetchAllSectors, fetchCurrentProjects } from '../../../redux/actions/tenderManagementActions';
import { getAllManualsByStatus } from '../../../redux/actions/manualManagementActions';
import threeDotsGreen from '../../../assets/svg/three-dots-green.svg';
import threeDots from '../../../assets/svg/three-dots.svg';
type Props = {};
const dumy = [
  { id: 0, title: 'sajk', type: 'pdf', geha: '1', speciality: '1', spicification: '1', date: '1-1' },
  { id: 0, title: 'sajk', type: 'pdf', geha: '1', speciality: '1', spicification: '1', date: '1-1' },
  { id: 0, title: 'sajk', type: 'pdf', geha: '1', speciality: '1', spicification: '1', date: '1-1' },
  { id: 0, title: 'sajk', type: 'pdf', geha: '1', speciality: '1', spicification: '1', date: '1-1' },
  { id: 0, title: 'sajk', type: 'pdf', geha: '1', speciality: '1', spicification: '1', date: '1-1' },
  { id: 0, title: 'sajk', type: 'pdf', geha: '1', speciality: '1', spicification: '1', date: '1-1' },
];

const array = [
  {
    projectId: 0,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 1,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 2,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 3,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 4,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 5,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 6,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 7,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 8,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 9,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 10,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
  {
    projectId: 11,
    projectName: 'تأثير مصادر الطاقة النظيفة علي المياة',
    projectDuration: 'date1',
    sectorCode: 1,
    state: 1,
  },
];

const SearchDocuments = () => {
  ///
  const [allClicked, setAllClicked] = useState<Array<any>>([]);
  const [checked, setChecked] = useState(false);
  const [hoverId, setHoverId] = useState('');
  const [showMenu, setShowMenu] = useState(true);
  const [isSeeMoreHovering, setIsSeeMoreHovering] = useState(false);
  const [showCreateConditionsManualModal, setShowCreateConditionsManualModal] = useState(false);
  const [selectedTender, setSelectedTender] = React.useState<any>();
  const dispatch = useDispatch();
  const { currentProjects, totalRecords } = useSelector((state: RootState) => state.tenderManagement);
  const { underModificationManuals, approvedManuals } = useSelector((state: RootState) => state.manualManagement);
  const { user } = useSelector((state: RootState) => state.user);
  const history = useHistory();

  const loading = useSelector((state: RootState) => state.tenderManagement.loading);

  const sectors = useSelector((state: RootState) => state.tenderManagement.allSectors);

  const toast = useRef<Toast | null>(null);
  useEffect(() => {
    // dispatch actions
    dispatch(fetchCurrentProjects(toast, { state: 1, page: 0, size: 0, sort: '' }));
    dispatch(fetchAllSectors(toast));
    if (user) {
      dispatch(getAllManualsByStatus(2, user?.id, toast)); // approved
      dispatch(getAllManualsByStatus(1, user?.id, toast)); //underModification
      dispatch(getAllManualsByStatus(3, user?.id, toast)); //archived
    }
  }, [dispatch, user]);

  const hoverHandler = (e: any, id: any) => {
    if (id !== '') e.stopPropagation();

    if (id !== hoverId) {
      setShowMenu(false);
      let children = document.getElementById(hoverId)?.getElementsByTagName('td');
      if (children) {
        for (let i = 0; i < children?.length; i++) {
          children[i].className = '';
        }
      }
      children = document.getElementById(id)?.getElementsByTagName('td');

      if (children) {
        for (let i = 0; i < children?.length; i++) {
          children[i].className = 'selected';
        }
      }
      setHoverId(id);
    }
  };

  const getSectorName = (id: any) => {
    const sector = sectors.find((sector) => sector.sectorCode === id);
    //
    return sector?.sectorName || 'not found';
  };

  const goToManual = (project: any) => {
    const manual = [...underModificationManuals, ...approvedManuals].find((manual) => {
      return manual.projectId === project.projectId;
    });
    history.push(`/edit-conditions-manual/${manual.tenderId}`);
  };
  ///
  const HandleAllClick = () => {
    if (allClicked.length !== array.length) {
      setAllClicked(array);
    } else {
      setAllClicked([]);
    }
  };
  console.log(allClicked);

  ///
  const handleChecked = (research: any) => {
    console.log(allClicked.find((res) => research.projectId === res.projectId));
    if (allClicked.find((res) => research.projectId === res.projectId)) {
      console.log(allClicked.filter((res) => res.projectId !== research.projectId));

      setAllClicked(allClicked.filter((res) => res.projectId !== research.projectId));
      return;
    }

    setAllClicked([...allClicked, research]);
  };
  const checkedOrNot = (researcch: any) => {
    let checked = false;
    const research = allClicked.find((selectedresearch: any) => selectedresearch === researcch);
    if (research) {
      checked = true;
      return checked;
    }

    return checked;
  };
  ///
  return (
    <>
      <div
        onMouseOver={(e) => {
          hoverHandler(e, '');
        }}
        className="searchDocumentContent"
      >
        <div
          className={hoverId !== '' ? 'tableArea' : 'tableArea overflow-hidden'}
          style={{
            overflowY: hoverId !== '' ? 'auto' : 'scroll',
          }}
        >
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    checked={allClicked.length === array.length ? true : false}
                    onClick={(e) => {
                      HandleAllClick();
                    }}
                    type="checkbox"
                  />
                </th>
                {/* <th>research code</th>
                <th>research name</th>
                <th> research address</th>
                <th>resector1</th>
                <th>speciality</th>
                <th>modification</th>
                <th>date</th> */}
                <th>كود البحث</th>
                <th>عنوان البحث</th>
                <th>النوع</th>
                <th>الجهة 1</th>
                <th>التخصص 1</th>
                <th>التصنيف 1</th>
                <th>تاريخ الرفع</th>
              </tr>
            </thead>
            <tbody>
              {array.map((project: any, i) => {
                return (
                  <tr
                    key={project.projectId}
                    id={String(project.projectId)}
                    onMouseOver={(e) => {
                      hoverHandler(e, `${project.projectId}`);
                      //
                    }}
                  >
                    <span>
                      {' '}
                      <input
                        onClick={() => {
                          handleChecked(project);
                        }}
                        checked={checkedOrNot(project)}
                        type="checkbox"
                      />
                    </span>
                    <td>A-230217</td>
                    <td>
                      <p>{project.projectName}</p>
                    </td>
                    <td>PDF</td>
                    <td>{getSectorName(project.sectorCode)} </td>
                    <td>
                      {/* {approvedManuals.some((manual) => manual.projectId === project.projectId) ||
                    underModificationManuals.some((manual) => manual.projectId === project.projectId) ? (
                      <div className="onGoing">جاري التعديل</div>
                    ) : (
                      <div className="notYet">لم يتم البدأ</div>
                    )} */}
                      التخصص 1
                    </td>

                    <td>التصنيف 1 </td>
                    <td>13 - 02 - 2022</td>

                    <td
                      /*style={{ display: 'inline-block' }}*/
                      onMouseMove={() => {
                        setIsSeeMoreHovering(true);
                      }}
                      onMouseOut={() => {
                        setIsSeeMoreHovering(false);
                      }}
                      onClick={() => {
                        setShowMenu(true);
                      }}
                    >
                      <div>
                        <img
                          className="seeMore"
                          alt="seeMoreIcon"
                          src={
                            hoverId === `${project.projectId}` && (showMenu || isSeeMoreHovering)
                              ? threeDotsGreen
                              : threeDots
                          }
                        />
                      </div>
                      <div
                        id="optionsMenu"
                        style={{ display: hoverId === `${project.projectId}` && showMenu ? 'flex' : 'none' }}
                      >
                        <ul>
                          <li
                            onClick={
                              // data.state === state.notYet
                              approvedManuals.some((manual) => manual.projectId === project.projectId) ||
                              underModificationManuals.some((manual) => manual.projectId === project.projectId)
                                ? () => {}
                                : () => {
                                    setShowCreateConditionsManualModal(true);
                                    setSelectedTender(project);
                                  }
                            }
                            style={{
                              opacity:
                                approvedManuals.some((manual) => manual.projectId === project.projectId) ||
                                underModificationManuals.some((manual) => manual.projectId === project.projectId)
                                  ? '0.5'
                                  : '1',
                            }}
                          >
                            Modify data
                          </li>
                          {/* <li>تغيير حالة الكراسة</li> */}
                          {/* <li onClick={() => goToManual(project)}>ذهاب الى الكراسة</li> */}
                          <li>Update</li>
                          <li>Upload</li>
                          <li>Delete</li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SearchDocuments;
