import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import api, { baseURL } from '../../../api';
import collpase from '../../../assets/png/collpase.png';
import expand from '../../../assets/png/expand.png';
import back from '../../../assets/svg/back.svg';
import { offeringApprovalData } from '../../../constants/OfferingComponent';
import { projectRequiredData } from '../../../constants/projectRequiredData';
import { userTypes } from '../../../constants/userTypes';
import {
  addProjectsDefaultValue,
  AdmSideCode,
  Project,
  PrtsLookupMainCodeBy,
  PrtsProject,
  PrtsProjectDescription,
  PrtsProjectDocument,
  PrtsProjectNote,
  PrtsProjectOfferingComponent,
  PrtsProjectProcedure,
  PrtsProjectPurchase,
  PrtsProjectStatus,
  PrtsProjectTiming,
} from '../../../models/add-project.model';
import { Initiative, Sector } from '../../../models/sector.model';
import {
  addProject,
  deleteProject,
  editProject,
  fetchAllRoles,
  fetchAllSectors,
  fetchAllSideLevelCodes,
  fetchAllUsers,
  fetchMainLookups,
} from '../../../redux/actions/tenderManagementActions';
import { getAllModules } from '../../../redux/actions/user';
import { RootState } from '../../../redux/reducers';
import { fileType } from '../../../utils/fileType';
import { formatDate, getDateFromString } from '../../../utils/moment';
import { getStorageData } from '../../../utils/storage';
import { monthNavigatorTemplate, yearNavigatorTemplate } from '../../../utils/yearMonthTemplate';
import InputNumberComp from '../../Form/InputNumber';
import Loader from '../../Loader/Loader';
import Segment from '../../Segment/Segment';
import ProjectName from '../Shared/ProjectName';
import './AddProject.scss';
import InputErrorMsg from './InputErrorMsg';
import { isNormalUser } from '../../../utils/checkAccess';

type DescriptionDropType = keyof PrtsProjectDescription;

// type FinanceType = keyof PrtsProjectFinance;

type PurchaseType = keyof PrtsProjectPurchase;

type ProjectStatusType = keyof PrtsProjectStatus;

type ProjectTiming = keyof PrtsProjectTiming;

type ProjectStudy = keyof PrtsProject;

type OfferingComponent = keyof PrtsProjectOfferingComponent;

const headers = { Authorization: `JWT ${getStorageData('token')}` };

const CalendarData: FC<{
  disabled: boolean;
  value: string;
  label: string;
  name: ProjectTiming | ProjectStudy;
  type?: string;
  handler: (name: any, value: any, type?: any) => void;
}> = ({ disabled, value, label, name, type, handler }) => {
  return (
    <div className="tabs-content-item-content-child">
      <label>{label}</label>
      <div className="" style={{ display: 'flex' }}>
        <div className="p-field p-col-12 p-md-4"></div>
        <Calendar
          minDate={value ? getDateFromString(value) : new Date()}
          value={getDateFromString(value)}
          yearRange={`${(new Date().getFullYear() - 10).toString()}:${(new Date().getFullYear() + 10).toString()}`}
          onChange={(e) => {
            if (type) {
              handler(name, e.value as Date, type);
            } else {
              handler(name, e.value as Date);
            }
          }}
          monthNavigator
          yearNavigator
          dateFormat="yy-mm-dd"
          monthNavigatorTemplate={monthNavigatorTemplate}
          yearNavigatorTemplate={yearNavigatorTemplate}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const AddProject: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state }: { state: { project: Project; edit: boolean; archived: boolean } } = useLocation();

  const mainLookups = useSelector((state: RootState) => state.tenderManagement.mainLookups);
  const allSectors = useSelector((state: RootState) => state.tenderManagement.allSectors);
  const loading = useSelector((state: RootState) => state.tenderManagement.loading);
  const users = useSelector((state: RootState) => state.tenderManagement.users);
  const roles = useSelector((state: RootState) => state.tenderManagement.roles);
  const sideLevelCodes = useSelector((state: RootState) => state.tenderManagement.sideLevelCodes);
  const { user, modules } = useSelector((state: RootState) => state.user);

  const [activeIndex, setActiveIndex] = useState<any>(null);
  const [mainLookupStage, setMainLookupStage] = useState<any>(null);
  const [mainLookupCode, setMainLookupCode] = useState<any>(null);

  const [project, setProject] = useState<Project>(addProjectsDefaultValue);

  // basic data
  const [projectCode, setProjectCode] = useState<string | undefined>('');
  const [projectName, setProjectName] = useState<string | undefined>('');
  const [projectNameRural, setProjectNameRural] = useState<string | undefined>('');
  const [projectDuration, setProjectDuration] = useState<number | undefined>();
  const [sector, setSector] = useState<Sector | undefined>();
  const [stage, setStage] = useState<any>();
  const [typeCode, setTypeCode] = useState<any>();
  const [brochuresNumData, setBrochuresNum] = useState<number | undefined>(0);

  const [mainSides, setMainSides] = useState<AdmSideCode[]>([]);
  const [subSides1, setSubSides1] = useState<AdmSideCode[]>([]);
  const [subSides2, setSubSides2] = useState<AdmSideCode[]>([]);
  const [subSides3, setSubSides3] = useState<AdmSideCode[]>([]);
  const [selectedMainSide, setSelectedMainSide] = useState<AdmSideCode | undefined>();
  const [selectedSubSide1, setSelectedSubSide1] = useState<AdmSideCode | undefined>();
  const [selectedSubSide2, setSelectedSubSide2] = useState<AdmSideCode | undefined>();
  const [selectedSubSide3, setSelectedSubSide3] = useState<AdmSideCode | undefined>();

  // project description
  // الصفات
  const [mainLookupStatus1, setMainLookupStatus1] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus2, setMainLookupStatus2] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus3, setMainLookupStatus3] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus4, setMainLookupStatus4] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus5, setMainLookupStatus5] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus6, setMainLookupStatus6] = useState<PrtsLookupMainCodeBy[]>([]);
  const [status1, setStatus1] = useState<PrtsLookupMainCodeBy | null>(null);
  const [status2, setStatus2] = useState<PrtsLookupMainCodeBy | null>(null);
  const [status3, setStatus3] = useState<PrtsLookupMainCodeBy | null>(null);
  const [status4, setStatus4] = useState<PrtsLookupMainCodeBy | null>(null);
  const [status5, setStatus5] = useState<PrtsLookupMainCodeBy | null>(null);
  const [status6, setStatus6] = useState<PrtsLookupMainCodeBy | null>(null);

  const [projectGoal, setProjectGoal] = useState<string>('');
  const [projectDesc, setProjectDesc] = useState<string>('');
  const [projectOutput, setProjectOutput] = useState<string>('');

  // finance Data
  const [projectSignedValue, setProjectSignedValue] = useState<number>(0);
  const [projectOperationalValue, setProjectOperationalValue] = useState<number>(0);
  const [projectCapitalValue, setProjectCapitalValue] = useState<number>(0);
  const [projectOperationalExpenses, setProjectOperationalExpenses] = useState<number>(0);
  const [projectCapitalExpenses, setProjectCapitalExpenses] = useState<number>(0);

  // purchase Data
  const [anotherNum, setAnotherNum] = useState<number>(0);
  const [awardNum, setAwardNum] = useState<number>(0);
  const [budgetReserveNum, setBudgetReserveNum] = useState<number>(0);
  const [competitionNum, setCompetitionNum] = useState<number>(0);
  const [purchaseOrderNum, setPurchaseOrderNum] = useState<number>(0);
  const [secretLetterIssuedNum, setSecretLetterIssuedNum] = useState<number>(0);

  // project status Data
  const [mainLookupStatus7, setMainLookupStatus7] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus8, setMainLookupStatus8] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus9, setMainLookupStatus9] = useState<PrtsLookupMainCodeBy[]>([]);
  const [mainLookupStatus10, setMainLookupStatus10] = useState<PrtsLookupMainCodeBy[]>([]);
  const [status7, setStatus7] = useState<PrtsLookupMainCodeBy | null>(null);
  const [status8, setStatus8] = useState<PrtsLookupMainCodeBy | null>(null);
  const [status9, setStatus9] = useState<PrtsLookupMainCodeBy | null>(null);
  const [status10, setStatus10] = useState<PrtsLookupMainCodeBy | null>(null);

  // timing data
  const [planOfferingDate, setPlanOfferingDate] = useState<string>('');
  const [actualOfferingDate, setActualOfferingDate] = useState<string>('');
  const [planReceivingOffers, setPlanReceivingOffers] = useState<string>('');
  const [actualReceivingOffers, setActualReceivingOffers] = useState<string>('');
  const [planConfidentialLetter, setPlanConfidentialLetter] = useState<string>('');
  const [actualConfidentialLetter, setActualConfidentialLetter] = useState<string>('');
  const [planOpeningDate, setPlanOpeningDate] = useState<string>('');
  const [actualOpeningDate, setActualOpeningDate] = useState<string>('');
  const [planDecisionDate, setPlanDecisionDate] = useState<string>('');
  const [actualDecisionDate, setActualDecisionDate] = useState<string>('');
  const [planAwardDate, setPlanAwardDate] = useState<string>('');
  const [actualAwardDate, setActualAwardDate] = useState<string>('');
  const [planContractDate, setPlanContractDate] = useState<string>('');
  const [actualContractDate, setActualContractDate] = useState<string>('');
  const [planSiteReceiptDate, setPlanSiteReceiptDate] = useState<string>('');
  const [actualSiteReceiptDate, setActualSiteReceiptDate] = useState<string>('');

  // technical study data
  const [planStartDate, setPlanStartDate] = useState<string>('');
  const [actualStartDate, setActualStartDate] = useState<string>('');
  const [planEndDate, setPlanEndDate] = useState<string>('');
  const [actualEndDate, setActualEndDate] = useState<string>('');
  const [elapsedTimeRate, setElapsedTimeRate] = useState<number>();
  const [completionRate, setCompletionRate] = useState<number>();

  // engineering study data
  const [engPlanStartDate, setEngPlanStartDate] = useState<string>('');
  const [engActualStartDate, setEngActualStartDate] = useState<string>('');
  const [engPlanEndDate, setEngPlanEndDate] = useState<string>('');
  const [engActualEndDate, setEngActualEndDate] = useState<string>('');
  const [engElapsedTimeRate, setEngElapsedTimeRate] = useState<number>();
  const [engCompletionRate, setEngCompletionRate] = useState<number>();

  // tender writing data
  const [tenderPlanStartDate, setTenderPlanStartDate] = useState<string>('');
  const [tenderActualStartDate, setTenderActualStartDate] = useState<string>('');
  const [tenderPlanEndDate, setTenderPlanEndDate] = useState<string>('');
  const [tenderActualEndDate, setTenderActualEndDate] = useState<string>('');
  const [tenderElapsedTimeRate, setTenderElapsedTimeRate] = useState<number>();
  const [tenderCompletionRate, setTenderCompletionRate] = useState<number>();

  // offering components data
  const [projectDocumentApproval, setProjectDocumentApproval] = useState<number>(0);
  const [technicalStudyApproval, setTechnicalStudyApproval] = useState<number>(0);
  const [engineerStudyApproval, setEngineerStudyApproval] = useState<number>(0);
  const [tenderApproval, setTenderApproval] = useState<number>(0);
  const [landAllocation, setLandAllocation] = useState<number>(0);
  const [programApproval, setProgramApproval] = useState<number>(0);
  const [nationalDirectorApproval, setNationalDirectorApproval] = useState<number>(0);
  const [ownerApproval, setOwnerApproval] = useState<number>(0);
  const [isOfferingComponentRequired, setIsOfferingComponentRequired] = useState(false);

  // people data
  const [projectPeople, setProjectPeople] = useState<{
    [key: number]: {
      userRoleCode: number;
      userId?: number;
      isVisable: boolean;
    };
  }>({});

  // documents data
  // const [projectDocuments, setProjectDocuments] = useState<PrtsProjectDocument[]>([]);

  // notes data
  const [notes, setNotes] = useState<PrtsProjectNote[]>([
    {
      noteDate: formatDate(new Date(), 'DD-MM-yyyy'),
      noteText: '',
      userId: user ? user.id : 0,
    },
  ]);
  // procedures data
  const [procedures, setProcedures] = useState<PrtsProjectProcedure[]>([
    {
      procedureDate: formatDate(new Date(), 'DD-MM-yyyy'),
      procedureText: '',
      procedureTypeCode: 13,
      userId: user ? user.id : 0,
    },
  ]);

  // check if input is touched
  const [touchedInputs, setTouchedInputs] = useState({ ...projectRequiredData });

  // all initiatives in sector
  const [initiatives, setInitiatives] = useState<Initiative[]>([]);
  const [initiativeItem, setInitiativeItem] = useState<any>();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isArchived, setIsArchived] = useState<boolean>(false);

  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [visibleArchive, setVisibleArchive] = useState<boolean>(false);
  const [visibleRetreive, setVisibleRetreive] = useState<boolean>(false);

  const toast = useRef<Toast | null>(null);

  const [segmentButtons] = useState([
    'البيانات الأساسية',
    'توصيف المشروع',
    'الماليات',
    'التوقيتات',
    'بيانات المشتريات',
    'الحاله الراهنة',
    'الدراسات الفنية',
    'الدراسات الهندسية',
    'كتابه الكراسة',
    'مكونات الطرح',
    'الأشخاص',
    'الوثائق',
    'الملاحظات',
    'الإجراءات',
  ]);

  // fetch data
  useEffect(() => {
    dispatch(fetchMainLookups(toast));
    dispatch(fetchAllSectors(toast));

    dispatch(fetchAllRoles(toast));
    dispatch(fetchAllSideLevelCodes(toast));
  }, [dispatch]);

  useEffect(() => {
    if (modules.length === 0) {
      return;
    }
    const tenderModule = modules.find((ele) => ele.moduleNameE === 'TENDERS_MANAGEMENT');
    //
    if (tenderModule) {
      dispatch(fetchAllUsers(tenderModule.moduleId, userTypes.USER, toast));
    }
    // eslint-disable-next-line
  }, [dispatch, modules]);

  useEffect(() => {
    if (modules.length > 0) {
      return;
    }
    dispatch(getAllModules(toast));
    // eslint-disable-next-line
  }, [dispatch]);

  // lookups
  useEffect(() => {
    if (mainLookups.length === 0) return;
    // eslint-disable-next-line
    mainLookups.map((mainLookup) => {
      // stage
      if (mainLookup.lookupType === 1) {
        setMainLookupStage(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 2) {
        // type
        setMainLookupCode(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 3) {
        // المنطقة الجغرافية
        setMainLookupStatus1(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 4) {
        // تصنيف الأهمية
        setMainLookupStatus2(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 5) {
        // الصفة 3
        setMainLookupStatus3(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 6) {
        // الصفة 4
        setMainLookupStatus4(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 7) {
        // الصفة 5
        setMainLookupStatus5(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 8) {
        // الصفة 6
        setMainLookupStatus6(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 9) {
        // الحالة الراهنة 1
        setMainLookupStatus7(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 10) {
        // الحالة الراهنة 2
        setMainLookupStatus8(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 11) {
        // الحالة الراهنة 3
        setMainLookupStatus9(mainLookup.prtsLookupMainCodes);
      } else if (mainLookup.lookupType === 12) {
        // الحالة الراهنة 4
        setMainLookupStatus10(mainLookup.prtsLookupMainCodes);
      }
    });
  }, [mainLookups]);

  // admsidecodes
  useEffect(() => {
    if (sideLevelCodes.length === 0) return;
    const admSideCodes = sideLevelCodes[0].admSideCodes;
    setMainSides([...admSideCodes]);
  }, [sideLevelCodes]);

  // set project data when view project
  useEffect(() => {
    if (!state) return;
    //

    const { archived, edit, project: projectData } = state;
    if (edit) {
      setIsEdit(true);
    }
    if (archived) {
      setIsArchived(true);
    }
    if (!projectData) return;
    setProject({ ...projectData });
    const {
      projectCode,
      projectName,
      projectNameRural,
      brochuresNum,
      projectDuration,
      prtsLookupMainCodeByTypeCode,
      prtsLookupMainCodeByStageCode,
      prtsInitiativeCode,
      prtsProjectDescription,
      prtsProjectFinance,
      prtsProjectPurchase,
      prtsProjectStatus,
      prtsProjectTiming,
      prtsProjectTechnicalStudy,
      prtsProjectEngineeringStudy,
      prtsProjectTenderWriting,
      prtsProjectNotes,
      prtsProjectProcedures,
      prtsProjectOfferingComponent,
      prtsProjectPeople,
      admSideCode,
    } = projectData;
    setBrochuresNum(brochuresNum);
    setProjectDuration(projectDuration);
    setProjectCode(projectCode);
    setProjectName(projectName);
    setProjectNameRural(projectNameRural);
    const { prtsLookupType: _, ...TypeCodeData }: any = prtsLookupMainCodeByTypeCode;
    setTypeCode({ ...TypeCodeData });
    const { prtsLookupType, ...rest }: any = prtsLookupMainCodeByStageCode;
    setStage({ ...rest });
    const sectorData = allSectors.find((ele) => ele.sectorCode === prtsInitiativeCode?.sectorCode);
    if (sectorData) {
      setSector(sectorData);
      setInitiatives([...sectorData.prtsInitiativeCodes]);
      setInitiativeItem({ ...prtsInitiativeCode });
    }

    if (prtsProjectDescription) {
      Object.entries(prtsProjectDescription).forEach(([key, value]: any) => {
        descriptionDataHandler(key, value);
      });
    }
    if (prtsProjectFinance) {
      Object.entries(prtsProjectFinance).forEach(([key, value]: any) => {
        financeHandler(key, value);
      });
    }
    if (prtsProjectPurchase) {
      Object.entries(prtsProjectPurchase).forEach(([key, value]: any) => {
        purchaseHandler(key, value);
      });
    }
    if (prtsProjectStatus) {
      Object.entries(prtsProjectStatus).forEach(([key, value]: any) => {
        projectStatusHandler(key, value);
      });
    }
    if (prtsProjectTiming) {
      Object.entries(prtsProjectTiming).forEach(([key, value]: any) => {
        projectTimingHandler(key, value);
      });
    }
    if (prtsProjectOfferingComponent) {
      Object.entries(prtsProjectOfferingComponent).forEach(([key, value]: any) => {
        offeringComponentHandler(key, value);
      });
    }
    if (prtsProjectTechnicalStudy) {
      Object.entries(prtsProjectTechnicalStudy).forEach(([key, value]: any) => {
        projectStudyHandler(key, value, 'Technical');
      });
    }
    if (prtsProjectEngineeringStudy) {
      Object.entries(prtsProjectEngineeringStudy).forEach(([key, value]: any) => {
        projectStudyHandler(key, value, 'Engineering');
      });
    }
    if (prtsProjectTenderWriting) {
      Object.entries(prtsProjectTenderWriting).forEach(([key, value]: any) => {
        projectStudyHandler(key, value, 'Tender');
      });
    }

    if (prtsProjectNotes && prtsProjectNotes.length > 0) {
      setNotes([...prtsProjectNotes]);
    }

    if (prtsProjectProcedures && prtsProjectProcedures.length > 0) {
      setProcedures([...prtsProjectProcedures]);
    }

    if (prtsProjectPeople && prtsProjectPeople.length > 0) {
      let peopleData: any = {};
      prtsProjectPeople.forEach((ele) => {
        peopleData[ele.userRoleCode] = ele;
      });
      setProjectPeople({ ...peopleData });
    }

    if (admSideCode && sideLevelCodes.length > 0) {
      if (admSideCode.sideLevelCode === 40) {
        const admSideCodes3 = sideLevelCodes[3].admSideCodes.filter(
          (ele) => ele.parentSideCode === admSideCode.parentSideCode,
        );
        setSelectedSubSide3(admSideCode);
        setSubSides3(admSideCodes3);

        const parent2 = sideLevelCodes[2].admSideCodes.find((ele) => ele.sideCode === admSideCode.parentSideCode);
        if (!parent2) return;
        const admSideCodes2 = sideLevelCodes[2].admSideCodes.filter(
          (ele) => ele.parentSideCode === parent2.parentSideCode,
        );
        setSelectedSubSide2(parent2);
        setSubSides2(admSideCodes2);

        const parent1 = sideLevelCodes[1].admSideCodes.find((ele) => ele.sideCode === parent2.parentSideCode);
        if (!parent1) return;
        const admSideCodes1 = sideLevelCodes[1].admSideCodes.filter(
          (ele) => ele.parentSideCode === parent1.parentSideCode,
        );
        setSelectedSubSide1(parent1);
        setSubSides1(admSideCodes1);

        const parent = sideLevelCodes[0].admSideCodes.find((ele) => ele.sideCode === parent1.parentSideCode);
        if (!parent) return;
        setSelectedMainSide(parent);
      } else if (admSideCode.sideLevelCode === 30) {
        const admSideCodes2 = sideLevelCodes[2].admSideCodes.filter(
          (ele) => ele.parentSideCode === admSideCode.parentSideCode,
        );
        setSelectedSubSide2(admSideCode);
        setSubSides2(admSideCodes2);

        const parent1 = sideLevelCodes[1].admSideCodes.find((ele) => ele.sideCode === admSideCode.parentSideCode);
        if (!parent1) return;
        const admSideCodes1 = sideLevelCodes[1].admSideCodes.filter(
          (ele) => ele.parentSideCode === parent1.parentSideCode,
        );
        setSelectedSubSide1(parent1);
        setSubSides1(admSideCodes1);

        const parent = sideLevelCodes[0].admSideCodes.find((ele) => ele.sideCode === parent1.parentSideCode);
        if (!parent) return;
        setSelectedMainSide(parent);
      } else if (admSideCode.sideLevelCode === 20) {
        const admSideCodes1 = sideLevelCodes[1].admSideCodes.filter(
          (ele) => ele.parentSideCode === admSideCode.parentSideCode,
        );
        setSelectedSubSide1(admSideCode);
        setSubSides1(admSideCodes1);

        const parent = sideLevelCodes[0].admSideCodes.find((ele) => ele.sideCode === admSideCode.parentSideCode);
        if (!parent) return;
        setSelectedMainSide(parent);
      } else if (admSideCode.sideLevelCode === 10) {
        setSelectedMainSide(admSideCode);
      }
    }
  }, [state, allSectors, sideLevelCodes]);

  const segmentHandler = (itemIndex: number) => {
    let _activeIndex = activeIndex ? [...activeIndex] : [];

    if (_activeIndex.length === 0) {
      _activeIndex.push(itemIndex);
    } else {
      const index = _activeIndex.indexOf(itemIndex);
      if (index === -1) {
        _activeIndex.push(itemIndex);
      } else {
        _activeIndex.splice(index, 1);
      }
    }

    setActiveIndex(_activeIndex);
  };

  const expandCollaseAll = (type: 'expand' | 'collapse') => {
    if (type === 'collapse') {
      setActiveIndex([]);
    } else {
      const all: number[] = [];
      segmentButtons.map((_ele, idx) => all.push(idx));
      setActiveIndex(all);
    }
  };

  const backToProjectsScreenHandler = () => history.push('/tender/current-projects');

  // basic data handler
  const projectCodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const codeInput = e.target.value;
    setProjectCode(codeInput);
    setProject({ ...project, projectCode: codeInput });
  };

  const projectNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameInput = e.target.value;
    setProjectName(nameInput);
    setProject({ ...project, projectName: nameInput });
  };

  const projectNameRuralHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameRuralInput = e.target.value;
    setProjectNameRural(nameRuralInput);
    setProject({ ...project, projectNameRural: nameRuralInput });
  };

  const projectBrochuresNumHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const brochuresNumInput = +e.target.value;
    setBrochuresNum(brochuresNumInput);
    setProject({ ...project, brochuresNum: brochuresNumInput });
  };

  const projectDurationHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const durationInput = +e.target.value;
    setProjectDuration(durationInput);
    setProject({ ...project, projectDuration: durationInput });
  };

  const sectorHandler = (e: DropdownChangeParams) => {
    const sectorInput: Sector = e.target.value;
    setSector(sectorInput);
    setProject({ ...project, sectorCode: sectorInput.sectorCode });
    // eslint-disable-next-line
    allSectors.map((sector) => {
      if (sector.sectorCode === sectorInput.sectorCode) {
        setInitiatives([...sector.prtsInitiativeCodes]);
      }
    });
  };

  const stageCodeHandler = (e: DropdownChangeParams) => {
    setStage(e.target.value);
    setProject({
      ...project,
      stageCode: e.target.value.lookupId,
      prtsLookupMainCodeByStageCode: e.target.value,
    });
  };

  const typeCodeHandler = (e: DropdownChangeParams) => {
    setTypeCode(e.target.value);
    setProject({
      ...project,
      typeCode: e.target.value.lookupId,
      prtsLookupMainCodeByTypeCode: e.target.value,
    });
  };

  const initiativeHandler = (e: DropdownChangeParams) => {
    setInitiativeItem(e.value);
    setProject({
      ...project,
      initiativeCode: e.target.value.initiativeCode,
      prtsInitiativeCode: e.target.value,
    });
  };

  const admSideCodesHandler = (level: 10 | 20 | 30 | 40, value: AdmSideCode) => {
    if (level === 10) {
      setSelectedMainSide(value);
      const admSideCodes1 = sideLevelCodes[1].admSideCodes.filter((ele) => ele.parentSideCode === value.sideCode);
      setSubSides1(admSideCodes1);
      setSubSides2([]);
      setSubSides3([]);
      setSelectedSubSide1(undefined);
      setSelectedSubSide2(undefined);
      setSelectedSubSide3(undefined);
    } else if (level === 20) {
      setSelectedSubSide1(value);
      const admSideCodes2 = sideLevelCodes[2].admSideCodes.filter((ele) => ele.parentSideCode === value.sideCode);
      setSubSides2(admSideCodes2);
      setSubSides3([]);
      setSelectedSubSide2(undefined);
      setSelectedSubSide3(undefined);
    } else if (level === 30) {
      setSelectedSubSide2(value);
      const admSideCodes3 = sideLevelCodes[3].admSideCodes.filter((ele) => ele.parentSideCode === value.sideCode);
      setSubSides3(admSideCodes3);
      setSelectedSubSide3(undefined);
    } else {
      setSelectedSubSide3(value);
    }
    setProject({
      ...project,
      admSideCode: value,
      sideCode: value.sideCode,
    });
  };

  // description handlers
  const setDescProjectData = (key: DescriptionDropType, value: any) => {
    const data = descriptionDataHandler(key, value);
    setProject({
      ...project,
      prtsProjectDescription: {
        ...project.prtsProjectDescription,
        ...data,
        [key]: value,
      },
    });
  };

  const descriptionDataHandler = (key: DescriptionDropType, value: any) => {
    let data: any = {};
    if (key === 'prtsLookupMainCodeByLookupId3') {
      setStatus1(value);
      data['lookupId3'] = value ? value.lookupId : null;
    } else if (key === 'prtsLookupMainCodeByLookupId4') {
      setStatus2(value);
      data['lookupId4'] = value ? value.lookupId : null;
    } else if (key === 'prtsLookupMainCodeByLookupId5') {
      setStatus3(value);
      data['lookupId5'] = value ? value.lookupId : null;
    } else if (key === 'prtsLookupMainCodeByLookupId6') {
      setStatus4(value);
      data['lookupId6'] = value ? value.lookupId : null;
    } else if (key === 'prtsLookupMainCodeByLookupId7') {
      setStatus5(value);
      data['lookupId7'] = value ? value.lookupId : null;
    } else if (key === 'prtsLookupMainCodeByLookupId8') {
      setStatus6(value);
      data['lookupId8'] = value ? value.lookupId : null;
    } else if (key === 'projectDesc') {
      setProjectDesc(value);
    } else if (key === 'projectGoal') {
      setProjectGoal(value);
    } else if (key === 'projectOutput') {
      setProjectOutput(value);
    }
    return data;
  };

  // finance handler
  const setFinanceProjectData = (value: number, key?: string) => {
    if (!key) return;
    financeHandler(key, value);
    setProject({
      ...project,
      prtsProjectFinance: { ...project.prtsProjectFinance, [key]: value },
    });
  };
  const financeHandler = (key: string, value: number) => {
    if (key === 'projectCapitalExpenses') {
      setProjectCapitalExpenses(value);
    } else if (key === 'projectCapitalValue') {
      setProjectCapitalValue(value);
    } else if (key === 'projectOperationalExpenses') {
      setProjectOperationalExpenses(value);
    } else if (key === 'projectOperationalValue') {
      setProjectOperationalValue(value);
    } else if (key === 'projectSignedValue') {
      setProjectSignedValue(value);
    }
  };

  // purchase handler
  const setPurchaseProjectData = (key: PurchaseType, value: any) => {
    purchaseHandler(key, value);
    if (project) {
      setProject({
        ...project,
        prtsProjectPurchase: { ...project.prtsProjectPurchase, [key]: value },
      });
    }
  };
  const purchaseHandler = (key: PurchaseType, value: number) => {
    if (key === 'anotherNum') {
      setAnotherNum(value);
    } else if (key === 'awardNum') {
      setAwardNum(value);
    } else if (key === 'budgetReserveNum') {
      setBudgetReserveNum(value);
    } else if (key === 'competitionNum') {
      setCompetitionNum(value);
    } else if (key === 'purchaseOrderNum') {
      setPurchaseOrderNum(value);
    } else if (key === 'secretLetterIssuedNum') {
      setSecretLetterIssuedNum(value);
    }
  };

  // project status handler
  const setProjectStatusData = (key: ProjectStatusType, value: any) => {
    projectStatusHandler(key, value);
    setProject({
      ...project,
      prtsProjectStatus: { ...project.prtsProjectStatus, [key]: value },
    });
  };

  const projectStatusHandler = (key: ProjectStatusType, value: any) => {
    if (key === 'prtsLookupMainCodeByLookupId9') {
      setStatus7(value);
    } else if (key === 'prtsLookupMainCodeByLookupId10') {
      setStatus8(value);
    } else if (key === 'prtsLookupMainCodeByLookupId11') {
      setStatus9(value);
    } else if (key === 'prtsLookupMainCodeByLookupId12') {
      setStatus10(value);
    }
  };

  // timing Handlers
  const setProjectTimingData = (key: ProjectTiming, date: any) => {
    const value = formatDate(date);
    projectTimingHandler(key, value);
    setProject({
      ...project,
      prtsProjectTiming: { ...project.prtsProjectTiming, [key]: value },
    });
  };

  const projectTimingHandler = (key: ProjectTiming, value: any) => {
    if (key === 'actualAwardDate') {
      setActualAwardDate(value);
    } else if (key === 'actualConfidentialLetter') {
      setActualConfidentialLetter(value);
    } else if (key === 'actualContractDate') {
      setActualContractDate(value);
    } else if (key === 'actualDecisionDate') {
      setActualDecisionDate(value);
    } else if (key === 'actualOfferingDate') {
      setActualOfferingDate(value);
    } else if (key === 'actualOpeningDate') {
      setActualOpeningDate(value);
    } else if (key === 'actualReceivingOffers') {
      setActualReceivingOffers(value);
    } else if (key === 'actualSiteReceiptDate') {
      setActualSiteReceiptDate(value);
    } else if (key === 'planAwardDate') {
      setPlanAwardDate(value);
    } else if (key === 'planConfidentialLetter') {
      setPlanConfidentialLetter(value);
    } else if (key === 'planContractDate') {
      setPlanContractDate(value);
    } else if (key === 'planDecisionDate') {
      setPlanDecisionDate(value);
    } else if (key === 'planOfferingDate') {
      setPlanOfferingDate(value);
    } else if (key === 'planOpeningDate') {
      setPlanOpeningDate(value);
    } else if (key === 'planReceivingOffers') {
      setPlanReceivingOffers(value);
    } else if (key === 'planSiteReceiptDate') {
      setPlanSiteReceiptDate(value);
    }
  };

  // study Handlers
  const setProjectStudyData = (key: ProjectStudy, data: any, type: 'Technical' | 'Tender' | 'Engineering') => {
    const value = typeof data === 'object' ? formatDate(data) : data;
    projectStudyHandler(key, value, type);
    if (type === 'Engineering') {
      setProject({
        ...project,
        prtsProjectEngineeringStudy: {
          ...project.prtsProjectEngineeringStudy,
          [key]: value,
        },
      });
    } else if (type === 'Technical') {
      setProject({
        ...project,
        prtsProjectTechnicalStudy: {
          ...project.prtsProjectTechnicalStudy,
          [key]: value,
        },
      });
    } else if (type === 'Tender') {
      setProject({
        ...project,
        prtsProjectTenderWriting: {
          ...project.prtsProjectTenderWriting,
          [key]: value,
        },
      });
    }
  };

  const projectStudyHandler = (key: ProjectStudy, value: any, type: 'Technical' | 'Tender' | 'Engineering') => {
    if (key === 'actualEndDate') {
      if (type === 'Engineering') {
        setEngActualEndDate(value);
      } else if (type === 'Technical') {
        setActualEndDate(value);
      } else if (type === 'Tender') {
        setTenderActualEndDate(value);
      }
    } else if (key === 'actualStartDate') {
      if (type === 'Engineering') {
        setEngActualStartDate(value);
      } else if (type === 'Technical') {
        setActualStartDate(value);
      } else if (type === 'Tender') {
        setTenderActualStartDate(value);
      }
    } else if (key === 'completionRate') {
      if (type === 'Engineering') {
        setEngCompletionRate(value);
      } else if (type === 'Technical') {
        setCompletionRate(value);
      } else if (type === 'Tender') {
        setTenderCompletionRate(value);
      }
    } else if (key === 'elapsedTimeRate') {
      if (type === 'Engineering') {
        setEngElapsedTimeRate(value);
      } else if (type === 'Technical') {
        setElapsedTimeRate(value);
      } else if (type === 'Tender') {
        setTenderElapsedTimeRate(value);
      }
    } else if (key === 'planEndDate') {
      if (type === 'Engineering') {
        setEngPlanEndDate(value);
      } else if (type === 'Technical') {
        setPlanEndDate(value);
      } else if (type === 'Tender') {
        setTenderPlanEndDate(value);
      }
    } else if (key === 'planStartDate') {
      if (type === 'Engineering') {
        setEngPlanStartDate(value);
      } else if (type === 'Technical') {
        setPlanStartDate(value);
      } else if (type === 'Tender') {
        setTenderPlanStartDate(value);
      }
    }
  };

  // offering component
  const setOfferingComponentData = (key: OfferingComponent, value: number) => {
    const date = offeringComponentHandler(key, value);
    setProject({
      ...project,
      prtsProjectOfferingComponent: {
        ...project.prtsProjectOfferingComponent,
        ...date,
        [key]: value,
      },
    });
  };

  const offeringComponentHandler = (type: OfferingComponent, value: number) => {
    setIsOfferingComponentRequired(true);
    let val: any = {};
    const date = formatDate(new Date());
    if (type === 'projectDocumentApproval') {
      setProjectDocumentApproval(value);
      if (value === 3) {
        val['docPlandate'] = date;
      } else {
        val['docActualdate'] = date;
      }
    } else if (type === 'engineerStudyApproval') {
      setEngineerStudyApproval(value);
      if (value === 3) {
        val['engineerPlandate'] = date;
      } else {
        val['engineerActualdate'] = date;
      }
    } else if (type === 'technicalStudyApproval') {
      setTechnicalStudyApproval(value);
      if (value === 3) {
        val['technicalPlandate'] = date;
      } else {
        val['technicalActualdate'] = date;
      }
    } else if (type === 'tenderApproval') {
      setTenderApproval(value);
      if (value === 3) {
        val['tenderPlandate'] = date;
      } else {
        val['tenderActualdate'] = date;
      }
    } else if (type === 'landAllocation') {
      setLandAllocation(value);
      if (value === 3) {
        val['landAllocatePlandate'] = date;
      } else {
        val['landAllocateActualdate'] = date;
      }
    } else if (type === 'programApproval') {
      setProgramApproval(value);
      if (value === 3) {
        val['programPlandate'] = date;
      } else {
        val['programActualdate'] = date;
      }
    } else if (type === 'nationalDirectorApproval') {
      setNationalDirectorApproval(value);
      if (value === 3) {
        val['nationalPlandate'] = date;
      } else {
        val['nationalActualdate'] = date;
      }
    } else if (type === 'ownerApproval') {
      setOwnerApproval(value);
      if (value === 3) {
        val['ownerPlandat'] = date;
      } else {
        val['ownerActauldate'] = date;
      }
    }
    return val;
  };

  // people handler
  const peopleHandler = (userRoleCode: number, userId: number) => {
    let peopleData = { ...projectPeople };
    peopleData[userRoleCode] = { userRoleCode, userId, isVisable: true };
    setProjectPeople({ ...peopleData });
    setProject({
      ...project,
      prtsProjectPeople: [...Object.values(peopleData)],
    });
  };

  // document handlers
  const documentsHandler = (files: string[] = []) => {
    const documents: PrtsProjectDocument[] = [];
    files.forEach((fileFullPath) => {
      const fileSplitted = fileFullPath.split('/');
      const documentDesc = fileSplitted[fileSplitted.length - 1];
      const documentData: PrtsProjectDocument = {
        uploadDate: formatDate(new Date(), 'DD-MM-yyyy'),
        userId: user ? user.id : 0,
        documentDesc,
        fileFullPath,
      };
      documents.push(documentData);
    });
    setProject({
      ...project,
      prtsProjectDocuments: [...project.prtsProjectDocuments, ...documents],
    });
  };

  const dowloadDocmentHandler = async (doc: PrtsProjectDocument) => {
    try {
      const response = await api.get('/prts/projects/download', {
        responseType: 'arraybuffer',
        params: { fileName: doc.documentDesc },
        headers,
      });
      //
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // Create blob link to download
      link.setAttribute('download', doc.documentDesc);

      // // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      if (link.parentNode) link.parentNode.removeChild(link);
    } catch (error) {}
  };

  const deleteDocmentHandler = (doc: PrtsProjectDocument) => {
    const allDocs = [...project.prtsProjectDocuments].filter((ele) => ele.documentId !== doc.documentId);
    setProject({ ...project, prtsProjectDocuments: allDocs });
  };

  // notes procedures handler
  const addNotesProceduresHandler = (type: 'notes' | 'procedures') => {
    if (type === 'notes') {
      setNotes([
        ...notes,
        {
          noteDate: formatDate(new Date(), 'DD-MM-yyyy'),
          noteText: '',
          userId: user ? user.id : 0,
          username: user ? user.username : '',
        },
      ]);
    } else {
      setProcedures([
        ...procedures,
        {
          procedureDate: formatDate(new Date(), 'DD-MM-yyyy'),
          procedureText: '',
          userId: user ? user.id : 0,
          procedureTypeCode: 13,
        },
      ]);
    }
  };

  const projectNotesHandler = (value: string, index: number) => {
    const allNotes = [...notes];
    allNotes[index] = { ...allNotes[index], noteText: value };
    setNotes([...allNotes]);
    setProject({
      ...project,
      prtsProjectNotes: [...allNotes],
    });
  };

  const projectProcedureHandler = (value: string, index: number) => {
    const allProcedures = [...procedures];
    allProcedures[index] = { ...allProcedures[index], procedureText: value };
    setProcedures([...allProcedures]);
    setProject({
      ...project,
      prtsProjectProcedures: [...allProcedures],
    });
  };

  // blur handler
  const handleBlur = (name: keyof typeof projectRequiredData) => {
    //
    if (!name || touchedInputs[name]) return;
    setTouchedInputs({
      ...touchedInputs,
      [name]: true,
    });
  };

  const showToast = () => {
    toast.current?.show({ severity: 'error', detail: 'خطا', summary: 'من فضلك ادخل البيانات المطلوبة', sticky: true });
  };

  // check data before Send to DE
  const validateData = () => {
    const {
      projectCode,
      projectName,
      sectorCode,
      typeCode,
      admSideCode,
      initiativeCode,
      projectDuration,
      prtsProjectOfferingComponent,
      stageCode,
      prtsProjectStatus,
      prtsProjectDescription,
      prtsProjectFinance,
    } = project;
    if (
      // !projectCode ||
      !projectName ||
      !sectorCode ||
      !typeCode ||
      !admSideCode ||
      !initiativeCode ||
      !projectDuration ||
      !stageCode ||
      !prtsProjectStatus ||
      !prtsProjectDescription ||
      !prtsProjectFinance
    ) {
      showToast();
      return false;
    }
    if (isOfferingComponentRequired && prtsProjectOfferingComponent) {
      const {
        projectDocumentApproval,
        technicalStudyApproval,
        engineerStudyApproval,
        tenderApproval,
        landAllocation,
        programApproval,
        nationalDirectorApproval,
        ownerApproval,
      } = prtsProjectOfferingComponent;
      if (
        !projectDocumentApproval ||
        !technicalStudyApproval ||
        !engineerStudyApproval ||
        !tenderApproval ||
        !landAllocation ||
        !programApproval ||
        !nationalDirectorApproval ||
        !ownerApproval
      ) {
        showToast();
        return false;
      }
    }
    return true;
  };

  // project handlers
  const addProjectHandler = () => {
    const checkData = validateData();
    //
    if (!checkData) return;
    if (isEdit && !project.projectId) return;
    const { projectId, ...data } = project;
    //
    if (isEdit) {
      dispatch(editProject(project, 'current', 'edit', history, toast));
    } else {
      dispatch(addProject(data, history, toast));
    }
  };

  const archiveProjectHandler = () => {
    if (!project.projectId) return;
    dispatch(editProject(project, 'archived', 'archived', history, toast));
  };

  const retreiveProjectHandler = () => {
    if (!project.projectId) return;
    dispatch(editProject(project, 'current', 'retreive', history, toast));
  };

  const deleteProjectHandler = () => {
    if (!project.projectId) return;
    dispatch(deleteProject(project.projectId, history, toast));
  };

  return (
    <div className="add-project">
      {loading && <Loader />}
      <Toast ref={toast} position="top-left" />
      <ConfirmDialog
        visible={visibleDelete}
        onHide={() => setVisibleDelete(false)}
        message="هل تريد حذف هذا المشروع؟"
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        icon="pi pi-exclamation-triangle"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={deleteProjectHandler}
      />
      <ConfirmDialog
        visible={visibleArchive}
        onHide={() => setVisibleArchive(false)}
        message="هل تريد ارشفة هذا المشروع؟"
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        icon="pi pi-exclamation-triangle"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={archiveProjectHandler}
      />
      <ConfirmDialog
        visible={visibleRetreive}
        onHide={() => setVisibleRetreive(false)}
        message="هل تريد استرجاع هذا المشروع؟"
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        icon="pi pi-exclamation-triangle"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={retreiveProjectHandler}
      />
      <div className="sticky-menu">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            padding: '16px 24px',
          }}
        >
          <div
            className="project-actions"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="project-actions-btns">
              <button
                title="عرض الكل"
                style={{ width: '3rem', height: '3rem', cursor: 'pointer', margin: '0 1rem' }}
                onClick={() => expandCollaseAll('expand')}
              >
                <img src={expand} alt="print-pdf" />
              </button>
              <button
                title="اغلاق الكل"
                style={{ width: '3rem', height: '3rem', cursor: 'pointer', margin: '0 1rem' }}
                onClick={() => expandCollaseAll('collapse')}
              >
                <img src={collpase} alt="print-pdf" />
              </button>
            </div>
            <ProjectName name={project && project.projectName ? project.projectName : ''} />
          </div>
          <button
            style={{
              cursor: 'pointer',
              background: 'unset',
              border: 0,
              backgroundColor: 'unset',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={backToProjectsScreenHandler}
          >
            <img width="25px" src={back} alt="back" />
            <span style={{ fontSize: '14px', marginRight: '10px' }}>العوده الي المشاريع</span>
          </button>
        </div>
        <Segment buttons={segmentButtons} activeIndex={activeIndex} btnHandler={segmentHandler} />
      </div>
      <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <AccordionTab header="البيانات الأساسية">
          <div className="tabs-content-item-content">
            {state && (
              <div className="tabs-content-item-content-child">
                <label className="label-required">
                  كود المشروع
                  <span>*</span>
                </label>
                <InputText
                  maxLength={4}
                  readOnly={isArchived || isNormalUser(4, user) ? true : false}
                  className="input-prime"
                  name="projectCode"
                  value={projectCode}
                  onChange={projectCodeHandler}
                  onBlur={(e) => handleBlur('projectCode')}
                />
                <InputErrorMsg showInputError={!projectCode && touchedInputs.projectCode} />
                   {/* {console.log(isNormalUser(4, user))} */}
                
              </div>

            )}
            <div className="tabs-content-item-content-child">
              <label className="label-required" >
                مسمى المشروع
                <span>*</span>
              </label>
              <InputText
                maxLength={250}
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={projectName}
                onChange={projectNameHandler}
                onBlur={(e) => handleBlur('projectName')}
              />
              <InputErrorMsg showInputError={!projectName && touchedInputs.projectName} />
            </div>
            <div className="tabs-content-item-content-child">
              <label>مسمى المشروع في التنمية الريفية</label>
              <InputText
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={projectNameRural || ''}
                onChange={projectNameRuralHandler}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label htmlFor="sector"></label>
              <label className="label-required">
                القطاع
                <span>*</span>
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="sectorName"
                value={sector}
                options={allSectors}
                onChange={sectorHandler}
                onBlur={() => handleBlur('sectorCode')}
                name="sectorCode"
                id="sectorCode"
                placeholder="القطاع"
              />
              <InputErrorMsg showInputError={!sector && touchedInputs.sectorCode} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                المرحلة <span>*</span>
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={stage}
                options={mainLookupStage}
                onChange={stageCodeHandler}
                name="stageCode"
                placeholder="المرحلة"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                النوع
                <span>*</span>
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={typeCode}
                options={mainLookupCode}
                onChange={typeCodeHandler}
                onBlur={(e) => handleBlur('typeCode')}
                name="typeCode"
                placeholder="النوع"
              />
              <InputErrorMsg showInputError={!typeCode && touchedInputs.typeCode} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                الجهة الفنية الأساسية
                <span>*</span>
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="sideName"
                options={mainSides}
                value={selectedMainSide}
                onChange={(e) => {
                  admSideCodesHandler(10, e.value);
                }}
                onBlur={(e) => handleBlur('admSideCode')}
                name="admSideCode"
                placeholder="الجهة الفنية الأساسية"
              />
              <InputErrorMsg showInputError={!selectedMainSide && touchedInputs.admSideCode} />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الجهة الفنية الفرعية 1</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="sideName"
                value={selectedSubSide1}
                options={subSides1}
                onChange={(e) => {
                  admSideCodesHandler(20, e.value);
                }}
                placeholder="الجهة الفنية الفرعية 1"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الجهة الفنية الفرعية 2</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="sideName"
                value={selectedSubSide2}
                options={subSides2}
                onChange={(e) => {
                  admSideCodesHandler(30, e.value);
                }}
                placeholder="الجهة الفنية الفرعية 2"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الجهة الفنية الفرعية 3</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="sideName"
                value={selectedSubSide3}
                options={subSides3}
                onChange={(e) => {
                  admSideCodesHandler(40, e.value);
                }}
                placeholder="الجهة الفنية الفرعية 3"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                المبادرة
                <span>*</span>
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="initiativeShortName"
                value={initiativeItem}
                options={initiatives}
                onChange={initiativeHandler}
                onBlur={(e) => handleBlur('initiative')}
                name="initiatve"
                placeholder="المبادرة"
              />
              <InputErrorMsg showInputError={!initiativeItem && touchedInputs.initiative} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                مدة المشروع بالأشهر
                <span>*</span>
              </label>
              <InputText
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                type="number"
                min={0}
                className="input-prime"
                value={projectDuration || ''}
                onBlur={(e) => handleBlur('duration')}
                onChange={projectDurationHandler}
              />
              <InputErrorMsg showInputError={!projectDuration && touchedInputs.duration} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">عدد الكراسات</label>
              <InputText
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                type="number"
                min={0}
                className="input-prime"
                value={brochuresNumData || ''}
                onChange={projectBrochuresNumHandler}
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="توصيف المشروع">
          <div className="tabs-content-item-content">
            <div className="tabs-content-item-content-child">
              <label className="label-required" htmlFor="sector">
                المنطقة الجغرافية<span>*</span>
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status1}
                options={mainLookupStatus1}
                placeholder="المنطقة الجغرافية"
                onChange={(e) => {
                  setDescProjectData('prtsLookupMainCodeByLookupId3', e.value);
                }}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>تصنيف الأهمية</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status2}
                options={mainLookupStatus2}
                onChange={(e) => {
                  setDescProjectData('prtsLookupMainCodeByLookupId4', e.value);
                }}
                placeholder="تصنيف الأهمية"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الصفة 3</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status3}
                options={mainLookupStatus3}
                onChange={(e) => {
                  setDescProjectData('prtsLookupMainCodeByLookupId5', e.value);
                }}
                placeholder="الصفة 3"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الصفة 4</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status4}
                options={mainLookupStatus4}
                onChange={(e) => {
                  setDescProjectData('prtsLookupMainCodeByLookupId6', e.value);
                }}
                placeholder="الصفة 4"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الصفة 5</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status5}
                options={mainLookupStatus5}
                onChange={(e) => {
                  setDescProjectData('prtsLookupMainCodeByLookupId7', e.value);
                }}
                placeholder="الصفة 5"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الصفة 6</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status6}
                options={mainLookupStatus6}
                onChange={(e) => {
                  setDescProjectData('prtsLookupMainCodeByLookupId8', e.value);
                }}
                placeholder="الصفة 6"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>نبذة عن المشروع</label>
              <InputText
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={projectDesc || ''}
                onChange={(e) => setDescProjectData('projectDesc', e.target.value)}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>أهداف المشروع</label>
              <InputText
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={projectGoal || ''}
                onChange={(e) => setDescProjectData('projectGoal', e.target.value)}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>مخرجات المشروع</label>
              <InputText
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={projectOutput || ''}
                onChange={(e) => setDescProjectData('projectOutput', e.target.value)}
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="الماليات">
          <div className="tabs-content-item-content">
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                قيمة المشروع المتوقعة <span>*</span>
              </label>
              <InputNumberComp
                keyName="projectSignedValue"
                changeHandler={setFinanceProjectData}
                val={projectSignedValue || undefined}
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>قيمة بند المشروع التشغيلية</label>
              <InputNumberComp
                keyName="projectOperationalExpenses"
                changeHandler={setFinanceProjectData}
                val={projectOperationalExpenses || undefined}
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>قيمة بند المشروع الرأسمالية</label>
              <InputNumberComp
                keyName="projectCapitalExpenses"
                changeHandler={setFinanceProjectData}
                val={projectCapitalExpenses || undefined}
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>المصاريف التشغيلية</label>
              <InputNumberComp
                keyName="projectOperationalValue"
                changeHandler={setFinanceProjectData}
                val={projectOperationalValue || undefined}
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>المصاريف الرأسمالية</label>
              <InputNumberComp
                keyName="projectCapitalValue"
                changeHandler={setFinanceProjectData}
                val={projectCapitalValue || undefined}
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="التوقيتات">
          <div className="tabs-content-item-content">
            <CalendarData
              label="تاريخ الطرح المحدد"
              value={planOfferingDate}
              name="planOfferingDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ الطرح الفعلي"
              value={actualOfferingDate}
              name="actualOfferingDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="اخر تاريخ لتلقي العروض المخططه"
              value={planReceivingOffers}
              name="planReceivingOffers"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="اخر تاريخ لتلقي العروض الفعليه"
              value={actualReceivingOffers}
              name="actualReceivingOffers"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ ارسال الخطاب السري المخطط"
              value={planConfidentialLetter}
              name="planConfidentialLetter"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ ارسال الخطاب السري الفعلي"
              value={actualConfidentialLetter}
              name="actualConfidentialLetter"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ فتح المظاريف المخططه"
              value={planOpeningDate}
              name="planOpeningDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ فتح المظاريف الفعلي"
              value={actualOpeningDate}
              name="actualOpeningDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ البت والترسيه المخطط"
              value={planDecisionDate}
              name="planDecisionDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ البت والترسيه الفعلي"
              value={actualDecisionDate}
              name="actualDecisionDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ التعميد المخطط"
              value={planAwardDate}
              name="planAwardDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ التعميد الفعلي"
              value={actualAwardDate}
              name="actualAwardDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ التعاقد المخطط"
              value={planContractDate}
              name="planContractDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ التعاقد الفعلي"
              value={actualContractDate}
              name="actualContractDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ استلام الموقع المخطط"
              value={planSiteReceiptDate}
              name="planSiteReceiptDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ استلام الموقع الفعلى"
              value={actualSiteReceiptDate}
              name="actualSiteReceiptDate"
              handler={setProjectTimingData}
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
          </div>
        </AccordionTab>
        <AccordionTab header="بيانات المشتريات">
          <div className="tabs-content-item-content">
            <div className="tabs-content-item-content-child">
              <label>رقم طلب الشراء</label>
              <InputText
                type="number"
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={purchaseOrderNum || ''}
                onChange={(e) => setPurchaseProjectData('purchaseOrderNum', e.target.value)}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>رقم الحجز من الميزانيه</label>
              <InputText
                type="number"
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={budgetReserveNum || ''}
                onChange={(e) => setPurchaseProjectData('budgetReserveNum', e.target.value)}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>رقم المنافسه</label>
              <InputText
                type="number"
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={competitionNum || ''}
                onChange={(e) => setPurchaseProjectData('competitionNum', e.target.value)}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>رقم التعميد</label>
              <InputText
                type="number"
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={awardNum || ''}
                onChange={(e) => setPurchaseProjectData('awardNum', e.target.value)}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>رقم صادر الخطاب السري</label>
              <InputText
                type="number"
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={secretLetterIssuedNum || ''}
                onChange={(e) => setPurchaseProjectData('secretLetterIssuedNum', e.target.value)}
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>رقم اخر</label>
              <InputText
                type="number"
                readOnly={isArchived || isNormalUser(4, user) ? true : false}
                className="input-prime"
                value={anotherNum || ''}
                onChange={(e) => setPurchaseProjectData('anotherNum', e.target.value)}
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="الحالة الراهنة">
          <div className="tabs-content-item-content">
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                حالة الطرح <span>*</span>
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status7}
                options={mainLookupStatus7}
                onChange={(e) => setProjectStatusData('prtsLookupMainCodeByLookupId9', e.value)}
                placeholder="حالة الطرح"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الحالة الراهنة ٢</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status8}
                options={mainLookupStatus8}
                onChange={(e) => setProjectStatusData('prtsLookupMainCodeByLookupId10', e.value)}
                placeholder="الحالة الراهنة ٢"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الحالة الراهنة ٣</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status9}
                options={mainLookupStatus9}
                onChange={(e) => setProjectStatusData('prtsLookupMainCodeByLookupId11', e.value)}
                placeholder="الحالة الراهنة ٣"
              />
            </div>
            <div className="tabs-content-item-content-child">
              <label>الحالة الراهنة ٤</label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
                optionLabel="lookupMainName"
                value={status10}
                options={mainLookupStatus10}
                onChange={(e) => setProjectStatusData('prtsLookupMainCodeByLookupId12', e.value)}
                placeholder="الحالة الراهنة ٤"
              />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="الدراسات الفنية">
          <div className="tabs-content-item-content">
            <CalendarData
              label="تاريخ بدايه العمل المخطط"
              value={planStartDate}
              name="planStartDate"
              handler={setProjectStudyData}
              type="Technical"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ بدايه العمل الفعلي"
              value={actualStartDate}
              name="actualStartDate"
              handler={setProjectStudyData}
              type="Technical"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ انتهاء العمل المخطط"
              value={planEndDate}
              name="planEndDate"
              handler={setProjectStudyData}
              type="Technical"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ انتهاء العمل الفعلي"
              value={actualEndDate}
              name="actualEndDate"
              handler={setProjectStudyData}
              type="Technical"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <div className="tabs-content-item-content-child">
              <label>نسبه الوقت المنقضي</label>
              <div className="double-input" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="p-field p-col-12 p-md-4">
                  <InputText
                    readOnly={isArchived || isNormalUser(4, user) ? true : false}
                    min={0}
                    type="number"
                    value={elapsedTimeRate || ''}
                    onChange={(e) => setProjectStudyData('elapsedTimeRate', +e.target.value, 'Technical')}
                  />
                </div>
              </div>
            </div>
            <div className="tabs-content-item-content-child">
              <label>نسبه الانجاز</label>
              <div className="double-input" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="p-field p-col-12 p-md-4">
                  <InputText
                    readOnly={isArchived || isNormalUser(4, user) ? true : false}
                    min={0}
                    type="number"
                    value={completionRate || ''}
                    onChange={(e) => setProjectStudyData('completionRate', +e.target.value, 'Technical')}
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="الدراسات الهندسية">
          <div className="tabs-content-item-content">
            <CalendarData
              label="تاريخ بدايه العمل المخطط"
              value={engPlanStartDate}
              name="planStartDate"
              handler={setProjectStudyData}
              type="Engineering"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ بدايه العمل الفعلي"
              value={engActualStartDate}
              name="actualStartDate"
              handler={setProjectStudyData}
              type="Engineering"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ انتهاء العمل المخطط"
              value={engPlanEndDate}
              name="planEndDate"
              handler={setProjectStudyData}
              type="Engineering"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ انتهاء العمل الفعلي"
              value={engActualEndDate}
              name="actualEndDate"
              handler={setProjectStudyData}
              type="Engineering"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <div className="tabs-content-item-content-child">
              <label>نسبه الوقت المنقضي</label>
              <div className="double-input" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="p-field p-col-12 p-md-4">
                  <InputText
                    min={0}
                    type="number"
                    value={engElapsedTimeRate || ''}
                    onChange={(e) => setProjectStudyData('elapsedTimeRate', +e.target.value, 'Engineering')}
                    readOnly={isArchived || isNormalUser(4, user) ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="tabs-content-item-content-child">
              <label>نسبه الانجاز</label>
              <div className="double-input" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="p-field p-col-12 p-md-4">
                  <InputText
                    min={0}
                    type="number"
                    value={engCompletionRate || ''}
                    onChange={(e) => setProjectStudyData('completionRate', +e.target.value, 'Engineering')}
                    readOnly={isArchived || isNormalUser(4, user) ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="كتابه الكراسه">
          <div className="tabs-content-item-content">
            <CalendarData
              label="تاريخ بدايه العمل المخطط"
              value={tenderPlanStartDate}
              name="planStartDate"
              handler={setProjectStudyData}
              type="Tender"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ بدايه العمل الفعلي"
              value={tenderActualStartDate}
              name="actualStartDate"
              handler={setProjectStudyData}
              type="Tender"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ انتهاء العمل المخطط"
              value={tenderPlanEndDate}
              name="planEndDate"
              handler={setProjectStudyData}
              type="Tender"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <CalendarData
              label="تاريخ انتهاء العمل الفعلي"
              value={tenderActualEndDate}
              name="actualEndDate"
              handler={setProjectStudyData}
              type="Tender"
              disabled={isArchived || isNormalUser(4, user) ? true : false}
            />
            <div className="tabs-content-item-content-child">
              <label>نسبه الوقت المنقضي</label>
              <div className="double-input" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="p-field p-col-12 p-md-4">
                  <InputText
                    min={0}
                    type="number"
                    value={tenderElapsedTimeRate || ''}
                    onChange={(e) => setProjectStudyData('elapsedTimeRate', +e.target.value, 'Tender')}
                    readOnly={isArchived || isNormalUser(4, user) ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="tabs-content-item-content-child">
              <label>نسبه الانجاز</label>
              <div className="double-input" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="p-field p-col-12 p-md-4">
                  <InputText
                    min={0}
                    type="number"
                    value={tenderCompletionRate || ''}
                    onChange={(e) => setProjectStudyData('completionRate', +e.target.value, 'Tender')}
                    readOnly={isArchived || isNormalUser(4, user) ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="مكونات الطرح">
          <div className="tabs-content-item-content">
            <div className="tabs-content-item-content-child">
              <label className="label-required" htmlFor="sector">
                اعتماد وثيقه المشروع
                {isOfferingComponentRequired && <span>*</span>}
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="name"
                optionValue="id"
                value={projectDocumentApproval}
                options={offeringApprovalData}
                onChange={(e) => setOfferingComponentData('projectDocumentApproval', e.value)}
                placeholder="اعتماد وثيقه المشروع"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
              />
              <InputErrorMsg showInputError={isOfferingComponentRequired && !projectDocumentApproval} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                اعتماد الدراسات الفنيه
                {isOfferingComponentRequired && <span>*</span>}
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="name"
                optionValue="id"
                value={technicalStudyApproval}
                options={offeringApprovalData}
                onChange={(e) => setOfferingComponentData('technicalStudyApproval', e.value)}
                placeholder="اعتماد الدراسات الفنيه"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
              />
              <InputErrorMsg showInputError={isOfferingComponentRequired && !technicalStudyApproval} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                اعتماد الدراسات الهندسيه
                {isOfferingComponentRequired && <span>*</span>}
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="name"
                optionValue="id"
                value={engineerStudyApproval}
                options={offeringApprovalData}
                onChange={(e) => setOfferingComponentData('engineerStudyApproval', e.value)}
                placeholder="اعتماد الدراسات الهندسيه"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
              />
              <InputErrorMsg showInputError={isOfferingComponentRequired && !engineerStudyApproval} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                اعتماد الكراسه
                {isOfferingComponentRequired && <span>*</span>}
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="name"
                optionValue="id"
                value={tenderApproval}
                options={offeringApprovalData}
                onChange={(e) => setOfferingComponentData('tenderApproval', e.value)}
                placeholder="اعتماد الكراسه"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
              />
              <InputErrorMsg showInputError={isOfferingComponentRequired && !tenderApproval} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                تخصيص الارض
                {isOfferingComponentRequired && <span>*</span>}
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="name"
                optionValue="id"
                value={landAllocation}
                options={offeringApprovalData}
                onChange={(e) => setOfferingComponentData('landAllocation', e.value)}
                placeholder="تخصيص الارض"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
              />
              <InputErrorMsg showInputError={isOfferingComponentRequired && !landAllocation} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                اعتماد البرنامج
                {isOfferingComponentRequired && <span>*</span>}
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="name"
                optionValue="id"
                value={programApproval}
                options={offeringApprovalData}
                onChange={(e) => setOfferingComponentData('programApproval', e.value)}
                placeholder="اعتماد البرنامج"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
              />
              <InputErrorMsg showInputError={isOfferingComponentRequired && !programApproval} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                اعتماد المدير الوطني
                {isOfferingComponentRequired && <span>*</span>}
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="name"
                optionValue="id"
                value={nationalDirectorApproval}
                options={offeringApprovalData}
                onChange={(e) => setOfferingComponentData('nationalDirectorApproval', e.value)}
                placeholder="اعتماد المدير الوطني"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
              />
              <InputErrorMsg showInputError={isOfferingComponentRequired && !nationalDirectorApproval} />
            </div>
            <div className="tabs-content-item-content-child">
              <label className="label-required">
                اعتماد الجهه المالكه
                {isOfferingComponentRequired && <span>*</span>}
              </label>
              <Dropdown
                emptyMessage="لا توجد بيانات"
                optionLabel="name"
                optionValue="id"
                value={ownerApproval}
                options={offeringApprovalData}
                onChange={(e) => setOfferingComponentData('ownerApproval', e.value)}
                placeholder="اعتماد الجهه المالكه"
                disabled={isArchived || isNormalUser(4, user) ? true : false}
              />
              <InputErrorMsg showInputError={isOfferingComponentRequired && !ownerApproval} />
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="الاشخاص">
          <div className="tabs-content-item-content persons">
            {projectPeople &&
              roles &&
              roles.map((role) => (
                <div key={role.userRoleCode} className="tabs-content-item-content-child persons">
                  <label htmlFor="sector">{role.userRoleName}</label>
                  <Dropdown
                    emptyMessage="لا توجد بيانات"
                    optionLabel="userName"
                    optionValue="userId"
                    value={projectPeople[role.userRoleCode]?.userId ? projectPeople[role.userRoleCode].userId : 0}
                    options={users}
                    placeholder={role.userRoleName}
                    onChange={(e) => {
                      peopleHandler(role.userRoleCode, e.value);
                    }}
                    disabled={isArchived || isNormalUser(4, user) ? true : false}
                  />
                </div>
              ))}
          </div>
        </AccordionTab>
        <AccordionTab header="الوثائق">
          <div className="docs tabs-content-item-content" style={{ display: 'block' }}>
            <div className="tabs-content-item-content-child">
              <div className="upload">
                <FileUpload
                  disabled={isArchived || isNormalUser(4, user) ? true : false}
                  url={`${baseURL}/prts/projects/directory`}
                  onBeforeSend={(e) => {
                    e.xhr.setRequestHeader('Authorization', `JWT ${getStorageData('token')}`);
                  }}
                  onUpload={(e) => {
                    documentsHandler(JSON.parse(e.xhr.response));
                  }}
                  name="files"
                  multiple
                  accept="file/*"
                  maxFileSize={1000000}
                  chooseLabel="اضافه وثائق"
                  uploadLabel="رفع الوثيقة"
                  cancelLabel="إلغاء الوثيقة"
                  emptyTemplate={
                    <>
                      <p className="p-m-0" style={{ textAlign: 'center' }}>
                        اسحب الملفات وأفلتها هنا للتحميل
                      </p>
                      <div>
                        {project &&
                          project.prtsProjectDocuments &&
                          project.prtsProjectDocuments.map((doc, idx) => (
                            <div key={idx} className="p-fileupload-files">
                              <div className="p-fileupload-row">
                                <div>
                                  <img
                                    alt={doc.documentDesc}
                                    role="presentation"
                                    src={fileType(doc.documentDesc)}
                                    width="50"
                                  />
                                </div>
                                <div className="p-fileupload-filename">{doc.documentDesc}</div>
                                {/* <div>207.143 KB</div> */}
                                <div>
                                  <button
                                    type="button"
                                    onClick={() => dowloadDocmentHandler(doc)}
                                    className="p-button p-component p-button-icon-only"
                                    disabled={isArchived || isNormalUser(4, user) ? true : false}
                                  >
                                    <span className="p-button-icon p-c pi pi-arrow-down"></span>
                                    <span className="p-button-label p-c">&nbsp;</span>
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => deleteDocmentHandler(doc)}
                                    className="p-button p-component p-button-icon-only"
                                    disabled={isArchived || isNormalUser(4, user) ? true : false}
                                  >
                                    <span className="p-button-icon p-c pi pi-times"></span>
                                    <span className="p-button-label p-c">&nbsp;</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="الملاحظات">
          <div className="tabs-content-item-content" style={{ display: 'block' }}>
            <div className="tabs-content-item-content-child">
              <div className="notes-container">
                <button
                  disabled={isArchived || isNormalUser(4, user) ? true : false}
                  onClick={() => addNotesProceduresHandler('notes')}
                  className="save-btn-add"
                >
                  اضافة ملاحظة
                </button>
                <div className="notes">
                  {notes.map((note, index) => (
                    <div className="note-container">
                      <InputTextarea
                        key={index}
                        rows={5}
                        cols={30}
                        value={note.noteText}
                        onChange={(e) => projectNotesHandler(e.target.value, index)}
                        autoResize
                        disabled={isArchived || isNormalUser(4, user) ? true : false}
                      />
                      {state && state.project.prtsProjectNotes.length !== 0 && (
                        <div key={index}>
                          <div className="note-by">
                            <div> بواسطة :</div>
                            <div> {note.username || ''}</div>
                          </div>
                          <div className="note-by">
                            <div> بتاريخ :</div>
                            <div> {note.noteDate} </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header="الاجراءات">
          <div className="tabs-content-item-content" style={{ display: 'block' }}>
            <div className="tabs-content-item-content-child">
              <div className="notes-container">
                <button
                  disabled={isArchived || isNormalUser(4, user) ? true : false}
                  onClick={() => addNotesProceduresHandler('procedures')}
                  className="save-btn-add"
                >
                  اضافة اجراء
                </button>
                <div className="notes">
                  {procedures.map((note, index) => (
                    <InputTextarea
                      key={index}
                      rows={5}
                      cols={30}
                      value={note.procedureText}
                      onChange={(e) => projectProcedureHandler(e.target.value, index)}
                      autoResize
                      disabled={isArchived || isNormalUser(4, user) ? true : false}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <div className="btn-contianer">
        {isArchived ? (
          <>
            <button
              disabled={isNormalUser(4, user) ? true : false}
              onClick={() => setVisibleRetreive(true)}
              className="save-btn-add archive"
              id='restore'
            >
              استرجاع
            </button>
            <button
              disabled={isNormalUser(4, user) ? true : false}
              onClick={() => setVisibleDelete(true)}
              className="save-btn-add delete"
              id='delete'
            >
              حذف
            </button>
          </>
        ) : (
          <button disabled={isNormalUser(4, user) ? true : false} onClick={addProjectHandler} className="save-btn-add" id='save'>
            حفظ
          </button>
        )}
        {isEdit && (
          <button
            disabled={isNormalUser(4, user) ? true : false}
            onClick={() => setVisibleArchive(true)}
            className="save-btn-add archive"
            id='archive'
          >
            ارشفة
          </button>
        )}
      </div>
    </div>
  );
};

export default AddProject;
