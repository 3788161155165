export const errorMessages = [
  { ar: 'من فضلك اضف اسم الجدول', en: 'Please provide tableName' },
  { ar: 'من فضلك اضف اسم ووصف الجدول', en: 'Please provide tableName & tableDesc' },
  { ar: 'لا يمكن اضافة المشروع بمباردة محذوفة', en: 'Can not add project with initiative deleted' },
  { ar: 'حدث خطا اثناء اضافة المشروع', en: 'Unable to add project, failure in db' },
  { ar: 'حدث خطا', en: 'Unable to generate sequence, failure in db' },
  { ar: 'من فضلك اضف كود القطاع', en: 'Please provide a valid sector code' },
  { ar: 'من فضلك اضف كود القطاع وكود المباردة', en: 'Please provide a valid sectorCode & initiativeCode' },
  { ar: 'حدث خطا اثناء تحديث المشروعو مشروع غير موجود', en: 'Unable to Update project, project not found.' },
  { ar: 'من فضلك اضف نوع المشروع', en: 'Please provide a valid project id & state' },
  { ar: 'من فضلك اضف نوع المشروع', en: 'Please provide a valid state' },
  { ar: 'من فضلك اضف كود للقطارع', en: 'Please provide a valid sector code in the request body' },
  { ar: 'غير قادر على  فه الملف', en: 'Unable to upload file' },
  { ar: 'حدث خطاء اثناء حفظ المباردة', en: 'Error happen while saving initiative to DB' },
  { ar: 'حدث خطاء اثناء حذف المباردة', en: 'Error happen while deleting initiative to DB' },
  { ar: 'لا يمكن حذف مبادرة مرتبطة بمشاريع', en: 'Can not delete initiative associated with a projects' },
  { ar: 'حدث خطا اثناء تحديث المشروع', en: 'Unable to update project, failure in db' },
  { ar: 'حدث خطا اثناء الحصول على المشاريع', en: 'Unable to get projects, failure in db' },
  { ar: 'لا توجد ادوار للمستخدمين', en: 'Not Found Users Roles In DataBase' },
  { ar: 'من فضلك اضف بيانات للمباردة صالحة', en: 'Please provide a valid prtsInitiativeRequestDTO' },
  { ar: 'من فضلك اضف اسم مباردة صالح', en: 'Please provide a valid initiative name' },
  { ar: 'من فضلك اضف كود مشروع صالح', en: 'Please provide a valid project code' },
  { ar: 'من فضلك اضف اسم للمشروع صالح', en: 'Please provide a valid project name' },
  { ar: 'من فضلك اضف بيانات للمباردة صالحة', en: 'Please provide a valid initiative Data 1' },
  { ar: 'لا يوجد مستحدمين ', en: 'Not Found Users In DataBase' },
  { ar: 'لا يمكن قراءة الملف', en: 'Could not read the file' },
  { ar: 'من فضلك اضف اسم صالح للمباردة', en: 'Please provide a valid initiative short name' },
  { ar: 'تم حذف البادرة بالفعل', en: 'Invalid initiative state to delete' },
  { ar: 'توجد مبادرة مسبقا بهذ الاسم المختصر', en: 'There is an initiative already exist with short name:' },
  { ar: 'من فضلك ادخل معرف للمستخدم', en: 'Please provide a valid user id' },
  { ar: 'الايميل موجود بالفعل', en: 'Email already exist' },
  { ar: 'خطا فى البيانات', en: 'Invalid user state' },
  { ar: 'خطا فى البيانات', en: 'Error in parameters' },
  { ar: 'من فضلك ادخل رقم هاتف صحيح', en: 'Please provide a valid phone number' },
  { ar: 'خطا فى البيانات', en: 'Please provide a valid state or module ID or role ID' },
  { ar: 'خطا فى البيانات', en: 'Please provide a valid User passwordDTO' },
  { ar: 'كلمة السر الجديدة لا يمكن ان تساوى القديمة', en: "New password can't be equal to old password" },
  { ar: 'كلمة السر تغيرت بنجاح', en: 'User password reset successfully' },
  { ar: 'لا يمكن تغيير كلمة السر بعد اول تسجيل دخول', en: "Can't reset password after first login" },
  { ar: 'لا يمكن تغيير كلمة السر لمستخدم غير نشط', en: "Can't reset password for disabled user" },
  { ar: 'لا يمكن تغيير كلمة السر لمستخدم محذوف', en: "Can't reset password for deleted user" },
  { ar: 'كلمة القديمة خطا', en: "Can't reset password because of invalid old password" },
  { ar: 'يوجد اكتر من معرف من فضلك سجل الخروج', en: 'More than one row with the given identifier, Kindly Logout' },
  { ar: 'الايميل او كلمة السر خطا', en: 'Invalid email or password. Please try again' },
  { ar: 'من فضلك ادخل بيانات مستخدم صحيحة', en: 'Please provide a valid User Login DTO' },
  { ar: 'تم تسجيل الخروج', en: 'Log out successful!' },
  { ar: 'لا يوجد مستخدمين', en: 'Not Found Users In DataBase' },
  { ar: 'لا يوجد مستخدم بهذا الاسم', en: 'Not Found This User In DataBase' },
  {
    ar: 'هذا المستخدم مسجل دخوله من جهاز اخر من فضلك سجل خروجك ',
    en: 'This user is already logged in from another device, please logout first and try to login again',
  },
  { ar: 'من فضلك قم بتسجيل الدخول', en: "Can't refresh your access token, Please make a new login request" },
  { ar: 'لم يتم تسجيل دخول المستخدم', en: 'User is not currently logged in' },
  { ar: 'انتهت صلاحيه الجلسة', en: 'User session timed out' },
  { ar: 'المستخدم غير مسموح له بدخول هذا المحتوى', en: 'User is not authorized to access this resource' },
  { ar: 'خطا', en: 'Unable to save session for user' },
  { ar: 'من فضلك ادخل بيانات مستخدم صحيحة', en: 'Please provide a valid User DTO' },
  { ar: 'خطا فى البيانات', en: 'Bad credentials' },
];
