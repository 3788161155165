import React, { useMemo, useState } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import '../librarySetting.scss';
import './MultiLevelMenus.scss';
import eye from '../../../assets/svg/eye.svg';
import { MemoizedReseachDataTwo } from '../ResearchData/ReseachDataTwo';
import ReseachDataThree from '../ResearchData/ResearchDataThree';
import NewSubReport from '../NewSubReport/NewSubReport';
import { handleAdd } from './multilevelCRUD';
import ResearchData from '../ResearchData/ResearchData';
const defaultLevels: any[] = [
  {
    title: '',
    arrayofoptions: [],
    id: 1,
  },
  {
    title: '',
    arrayofoptions: [],
    id: 2,
  },
  {
    title: '',
    arrayofoptions: [],
    id: 3,
  },
];

export default function MultiLevelMenus() {
  const [lvlOneSelected, setLvlOneSelected] = useState('');
  const [lvlTwoSelected, setLvlTwoSelected] = useState('');
  const [arrayOfLevels, setArrayOfLevels] = useState<any[]>(defaultLevels);
  React.useEffect(() => {
    setLvlTwoSelected('');
  }, [lvlOneSelected]);

  const handleInsertion = (arrayOfLevels: any, setArrayOfLevels: any) => {
    return (newValue: string, i: number, oldValue?: string, lvlOneSelected?: string, lvlTwoSelected?: string) =>
      handleAdd(i, newValue, arrayOfLevels, setArrayOfLevels, oldValue, lvlOneSelected, lvlTwoSelected);
  };
  const getLvlOneArray = useMemo(() => arrayOfLevels[0].arrayofoptions || [], [arrayOfLevels]);

  const getLvlTwoArray = useMemo(
    () =>
      arrayOfLevels[0].arrayofoptions
        .find((opt: any) => opt.option === lvlOneSelected)
        ?.arrayofoptions.map((opt: any) => {
          return { option: opt.option };
        }) || [],
    [lvlOneSelected, arrayOfLevels],
  );

  const getLvlThreeArray = () =>
    arrayOfLevels[0]?.arrayofoptions
      .find((opt: any) => opt.option === lvlOneSelected)
      ?.arrayofoptions.find((opt2: any) => opt2.option === lvlTwoSelected)?.arrayofoptions || [];

  return (
    <>
      <div className="levels">
        <ul>
          <li>المستوي الأول</li>
          <li>المستوي الثاني</li>
          <li>المستوي الثالث</li>
        </ul>
        <div className="dashed-border">
          <ResearchData
            lvlOneSelected={lvlOneSelected}
            setLvlOneSelected={setLvlOneSelected}
            handleAdd={handleInsertion(arrayOfLevels, setArrayOfLevels)}
            arrayOfOptions={getLvlOneArray}
          />
          {lvlOneSelected && (
            <MemoizedReseachDataTwo
              lvlOneSelected={lvlOneSelected}
              lvlTwoSelected={lvlTwoSelected}
              setLvlTwoSelected={setLvlTwoSelected}
              handleAdd={handleInsertion(arrayOfLevels, setArrayOfLevels)}
              arrayOfOptions={getLvlTwoArray}
            />
          )}

          {lvlTwoSelected && (
            <ReseachDataThree
              lvlOneSelected={lvlOneSelected}
              lvlTwoSelected={lvlTwoSelected}
              handleAdd={handleInsertion(arrayOfLevels, setArrayOfLevels)}
              arrayOfOptions={getLvlThreeArray()}
            />
          )}
        </div>
      </div>
    </>
  );
}
