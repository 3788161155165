import React, { useState } from 'react';
import './userinfo.scss';
import { InputText } from 'primereact/inputtext';
import confirm from '../../../assets/svg/confirm.svg';
import { User } from '../../../models/User';
import { emailValidator, mobileValidator2, specialcrachvalidator, usernamevalidator } from '../../../constants/regex';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { isEmailExists } from '../../../redux/actions/user';
import { formatDate, getDateFromString } from '../../../utils/moment';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

type Props = {
  user: User;
  saveUser: (user: User, isPassowrd?: boolean) => void;
  cancelEdit: () => void;
  setUser: any;
};
const AccountInfo: React.FC<Props> = ({ user, saveUser, cancelEdit, setUser }) => {
  const [userNameError, setUserNameError] = useState(false);
  const { isEmailExist } = useSelector((state: RootState) => state.user);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);

  const dispatch = useDispatch();

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="line-height"
      />
    );
  };

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2 line-height"
      />
    );
  };

  return (
    <>
      <div className="userinfo-container">
        <div className="userinfo-right">
          <div>
            <label className="label-required">
              الاسم بالكامل <span>*</span>
            </label>
            <div className="flex-center">
              <InputText
                className="modalInputs"
                value={user.userName}
                onChange={(e) => {
                  if (usernamevalidator.test(e.target.value) || specialcrachvalidator.test(e.target.value)) {
                    setUserNameError(true);
                  } else {
                    setUserNameError(false);
                    setUser({ ...user, userName: e.target.value });
                  }
                }}
              />
              {user.userName && !userNameError && (
                <div className="input-filled-icon">
                  <img src={confirm} alt="confirmIcon" />
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="label-required">
              رقم الهاتف <span>*</span>
            </label>
            <div className="flex-center">
              <InputText
                type="tel"
                inputMode="tel"
                className="modalInputs tel"
                value={user.userPhone}
                onChange={(e) => {
                  const val = e.target.value;
                  if (mobileValidator2.test(val) && val.length < 20 && val.length > 10) {
                    setMobileError(false);
                  } else {
                    setMobileError(true);
                  }
                  setUser({ ...user, userPhone: val });
                }}
              />
              {user.userPhone && !mobileError && (
                <div className="input-filled-icon">
                  <img src={confirm} alt="confirmIcon" />
                </div>
              )}
            </div>
            {mobileError && (
              <p style={{ color: 'red', marginTop: '5px', fontSize: '11px' }}>
                (2001XXXXXXX+) دخل رقم هاتف صحيح بهذا الشكل
              </p>
            )}
          </div>
          <div>
            <label className="label-required">
              الوظيفة <span>*</span>
            </label>
            <div className="flex-center">
              <InputText
                className="modalInputs"
                value={user.userJob}
                onChange={(e) => setUser({ ...user, userJob: e.target.value })}
              />
              {user.userJob && (
                <div className="input-filled-icon">
                  <img src={confirm} alt="confirmIcon" />
                </div>
              )}
            </div>
          </div>
          <div className="date-container">
            <div className=" ">
              <label className="label-required">
                تاريخ الميلاد <span>*</span>
              </label>
              <div>
                <Calendar
                  maxDate={new Date()}
                  value={getDateFromString(user.birthDate || '')}
                  onChange={(e) => setUser({ ...user, birthDate: formatDate(e.value as Date) })}
                  monthNavigator
                  yearNavigator
                  dateFormat="yy/mm/dd"
                  yearRange={`1960:${(new Date().getFullYear() - 22).toString()}`}
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                />
              </div>
            </div>{' '}
          </div>
        </div>

        <div className="userinfo-left">
          <div>
            <label className="label-required">
              البريد الالكتروني <span>*</span>
            </label>

            <div className="flex-center">
              <InputText
                className="modalInputs"
                value={user.userEmail}
                onChange={(e) => {
                  const val = e.target.value;
                  setUser({ ...user, userEmail: val });
                  if (emailValidator.test(val)) {
                    setEmailError(false);
                    dispatch(isEmailExists(val));
                    //api call to check if the email is already taken if the format of the emtered email is corect
                  } else {
                    setEmailError(true);
                  }
                }}
              />
              {user.userEmail && !emailError && !isEmailExist && (
                <div className="input-filled-icon">
                  <img src={confirm} alt="confirmIcon" />
                </div>
              )}
            </div>
            {emailError && <p style={{ color: 'red', marginTop: '5px', fontSize: '11px' }}>ادخل بريد الكترونى صحيح</p>}
            {isEmailExist && (
              <p style={{ color: 'red', marginTop: '5px', fontSize: '11px' }}>هذا البريد الكترونى مستخدم من قبل</p>
            )}
          </div>
          <div>
            <label className="label-required">
              العنوان <span>*</span>
            </label>

            <div className="flex-center">
              <InputText
                className="modalInputs"
                value={user.userAddress}
                onChange={(e) => setUser({ ...user, userAddress: e.target.value })}
              />
              {user.userAddress && (
                <div className="input-filled-icon">
                  <img src={confirm} alt="confirmIcon" />
                </div>
              )}
            </div>
          </div>
          <div>
            <label className="label-required">
              القسم <span>*</span>
            </label>
            <div className="flex-center">
              <InputText
                className="modalInputs"
                value={user.departmentName}
                onChange={(e) => setUser({ ...user, departmentName: e.target.value })}
              />
              {user.departmentName && (
                <div className="input-filled-icon">
                  <img src={confirm} alt="confirmIcon" />
                </div>
              )}
            </div>
          </div>
          <div className="date-container">
            <div className=" ">
              <label className="label-required">
                تاريخ التعيين <span>*</span>
              </label>
              <div>
                <Calendar
                  maxDate={new Date()}
                  value={getDateFromString(user.hiringDate || '')}
                  onChange={(e) => setUser({ ...user, hiringDate: formatDate(e.value as Date) })}
                  monthNavigator
                  yearNavigator
                  dateFormat="yy-mm-dd"
                  yearRange={`1970:${new Date().getFullYear().toString()}`}
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                />
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <div className="userinfo-button-container">
        <button className="cancel" onClick={cancelEdit}>
          إلغاء
        </button>

        <button
          className="save"
          onClick={() => {
            saveUser(user);
          }}
        >
          حفظ التغييرات
        </button>
      </div>
    </>
  );
};

export default AccountInfo;
