import { Toast } from 'primereact/toast';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdmUserModule, User } from '../../../models/User';
import { deleteUser, getAllModules, updateUser } from '../../../redux/actions/user';
import { RootState } from '../../../redux/reducers';
import Loader from '../../Loader/Loader';
import AccountInfo from './AccountInfo';
import AccountState from './AccountState';
import SystemAllowance from './SystemAllowance';
import './userinfo.scss';

type Props = {
  location: any;
};

const statuses = [
  {
    id: 1,
    title: 'بيانات الحساب ',
  },
  {
    id: 2,
    title: 'صلاحيات النظام ',
  },
  {
    id: 3,
    title: ' الوصول وحالة الحساب ',
  },
];

const UserInfo: FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, modules } = useSelector((state: RootState) => state.user);

  const [selectedItem, setSelectedItem] = useState(1);
  const { state }: { state: { user: User } } = useLocation();
  const [user, setUser] = useState<User>(state.user);
  const [arrayInputs, setArrayInputs] = React.useState<AdmUserModule[]>(user.admUserModules);

  const toast = useRef<Toast | null>(null);

  useEffect(() => {
    if (!state) return;
    //
    if (!state.user) {
      history.goBack();
      return;
    }
  }, [state, history]);

  useEffect(() => {
    if (modules.length > 0) {
      return;
    }
    dispatch(getAllModules(toast));
    // eslint-disable-next-line
  }, [dispatch]);

  const editUser = (newuser: User) => {
    setUser(newuser);
  };

  useEffect(() => {
    const previouslyassigned = ifuserhadpreviouslymoduleID(arrayInputs, state.user);
    if (previouslyassigned.length !== 0 && arrayInputs.length !== modules.length) {
      let newamdusermodules = previouslyassigned.map((module: any) => {
        let newassining = arrayInputs.find(
          (inputmodule: any) => inputmodule.admModule.moduleId === module.admModule.moduleId,
        );
        return { ...module, ...newassining };
      });
      setUser({ ...user, admUserModules: mergetwoarrays(newamdusermodules, arrayInputs) });
      return;
    }
    //this condition checks if the user is being super admin
    if (arrayInputs.length === modules.length && user?.admUserModules.length !== 0) {
      let newamdusermodules = previouslyassigned.map((acess: any) => {
        return { ...acess, userType: 3 };
      });
      setUser({ ...user, admUserModules: mergetwoarrays(newamdusermodules, arrayInputs) });
      return;
    }
    if (previouslyassigned.length === 0) {
      setUser({ ...user, admUserModules: arrayInputs });
      return;
    }
    setUser({ ...user, admUserModules: arrayInputs });
    // eslint-disable-next-line
  }, [arrayInputs]);

  const handleUserData = (data: any, isPassowrd = false) => {
    let updatedUser = { ...user };

    if (!updatedUser.userId) return;
    dispatch(updateUser(updatedUser, history, toast));
  };

  const cancelEdit = () => history.push('/users');

  const deleteUserHandler = () => {
    if (!user) return;
    dispatch(deleteUser(user.userId, history, toast));
  };

  return (
    <div className="new-model" style={{ marginBottom: '20px' }}>
      {loading && <Loader />}
      <Toast ref={toast} position="top-left" />
      <div className="create-tp-secton">
        <div className="steps-container">
          {statuses.map((stp, i) => (
            <Fragment key={i}>
              <div onClick={() => setSelectedItem(stp.id)}>
                <div key={`step${i}`} className={selectedItem === stp.id ? 'step-active' : 'step'}>
                  {stp.title}
                  <div key={`hide${i}`} className={`${selectedItem === stp.id ? 'selected-step' : 'hide'} `}></div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      {user && (
        <div className="selected-step-content">
          {selectedItem === 1 && (
            <AccountInfo setUser={setUser} cancelEdit={cancelEdit} saveUser={handleUserData} user={user} />
          )}
          {selectedItem === 2 && (
            <SystemAllowance
              setArrayInputs={setArrayInputs}
              arrayInputs={arrayInputs}
              user={user}
              cancelEdit={cancelEdit}
              saveUser={handleUserData}
            />
          )}
          {selectedItem === 3 && (
            <AccountState
              deleteUser={deleteUserHandler}
              cancelEdit={cancelEdit}
              saveUser={handleUserData}
              user={user}
              editUser={editUser}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserInfo;

const mergetwoarrays = (userarry: any, superadminarr: any) => {
  // we want to get old admysermodules wich contain usermoduleid with the the rest of th emodules
  let newarr = superadminarr;
  userarry.forEach((element: any) => {
    newarr = newarr.filter((module: any) => module.admModule.moduleId !== element.admModule.moduleId);
  });
  return [...newarr, ...userarry];
  // const admUserModules = superadminarr
};

const ifuserhadpreviouslymoduleID = (admUserModules: any, user: any) => {
  let prevouislyassignedmodule: Array<any> = [];
  admUserModules.forEach((element: any) => {
    let module = user.admUserModules.find((module: any) => module.admModule.moduleId === element.admModule.moduleId);
    if (module) {
      prevouislyassignedmodule.push(module);
    }
  });
  return prevouislyassignedmodule || [];
};
