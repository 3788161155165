import React, { FC, useEffect, useRef } from 'react';
import '../CreateConditionsManual.scss';
import SearchIcon from '../../../../../../assets/svg/search.svg';
import { Typeahead } from 'react-bootstrap-typeahead';
import avatar from '../../../../../../assets/png/avatar.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/reducers';
import { getAllUsersWithModuleId } from '../../../../../../redux/actions/manualManagementActions';
import { Toast } from 'primereact/toast';

type Props = {
  activePage: any;
  setActivePage: any;
  modalData: any;
  setModalData: any;
};

const Page2: FC<Props> = ({ activePage, setActivePage, modalData, setModalData }) => {
  const { allUsersWithModuleId } = useSelector((state: RootState) => state.manualManagement);
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const toast = useRef<Toast | null>(null);
  const typeaheadref = useRef<any>(null);
  useEffect(() => {
    dispatch(getAllUsersWithModuleId(toast));
  }, [dispatch]);

  const handleChange = ([selectedEmployee]: any) => {
    if (
      selectedEmployee !== undefined &&
      !modalData.employees.some((employee: any) => employee.userId === selectedEmployee.userId)
    ) {
      setModalData({ ...modalData, employees: [...modalData.employees, selectedEmployee] });
      setActivePage({ id: 2, isContentFilled: { ...activePage.isContentFilled, page2: true } });
    }
    if (typeaheadref?.current) {
      typeaheadref.current.clear();
    }
  };

  const removeEmployee = (employeeId: any) => {
    if (modalData.employees.length === 1) {
      setActivePage({ id: 2, isContentFilled: { ...activePage.isContentFilled, page2: false } });
    }
    setModalData({
      ...modalData,
      employees: modalData.employees.filter((employee: any) => employee.userId !== employeeId),
    });
  };

  return (
    <div id="modal-page2">
      <p>بحث الموظفين</p>
      <div className="searchDiv">
        <Toast ref={toast} position="top-left" />
        <Typeahead
          className="typeAhead"
          id="searchInput"
          options={allUsersWithModuleId.filter((moduleuser) => moduleuser.userId !== user?.id)}
          labelKey="userName"
          paginate={false}
          placeholder="اكتب اسم الموظف"
          emptyLabel="اسم الموظف غير موجود"
          onChange={handleChange}
          ref={typeaheadref}
        />
        <img className="search-icon" src={SearchIcon} alt="SearchIcon" />
      </div>

      <div className="employeesList">
        {modalData.employees &&
          modalData.employees.map((employee: any, i: any) => {
            return (
              <div className="employee" key={i}>
                <img className="avatar" src={avatar} alt="avatar" />
                <p>{employee.userName}</p>
                <div>
                  <button
                    className="removeEmplyeeBtn"
                    onClick={() => {
                      removeEmployee(employee.userId);
                    }}
                  >
                    x
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Page2;
