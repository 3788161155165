import React from 'react'

const RepeatedElements = () => {
    return (
        <div className='after-create-tp-secton'>
            reapeted elments
        </div>
    )
}

export default RepeatedElements
