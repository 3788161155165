import React, { FC, Fragment, Key, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import reef from '../../../assets/png/g-reef.png';
import vara from '../../../assets/svg/vara.svg';
import arrowLeftTriangle from '../../../assets/svg/arrow-left-triangle.svg';
import Marginer from '../../Marginer/Marginer';
import { getContent } from '../../SideMenu2/getContent';
import { getReadonly } from '../../SideMenu2/getReadOnly';
import { getSectionComments } from './getSectionComments';
import rightarrow from '../../../assets/svg/right-arrow.svg';
import { ConfirmDialog } from 'primereact/confirmdialog';
import '../../SideMenu2/SideMenu2.scss';

type props = {
  setLocation: any;
  mainHeadersList: any[];
  setPreDisplayedContent: any;
  setReadOnly: any;
  setAdminComments: any;
  preDisplayedContent: any;
  HTMLcontent: string;
  setHTMLContent: any;
  location: any;
};

const ModelStructureSideMenu: FC<props> = ({
  setLocation,
  mainHeadersList,
  setPreDisplayedContent,
  setReadOnly,
  setAdminComments,
  HTMLcontent,
  preDisplayedContent,
  setHTMLContent,
  location,
}) => {
  const history = useHistory();
  const [activeId, setActiveId] = useState('');
  const [confirmLoctionSwitch, setConfirmLoctionSwitch] = useState({ show: false, parent: '', child: '' });
  const [oldContent2, setOldContent2] = useState('');
  const switchLocationHandler = (secionId: string, subSsectionId: string) => {
    if (
      preDisplayedContent === '-' ||
      HTMLcontent === oldContent2 ||
      HTMLcontent === '' ||
      preDisplayedContent === HTMLcontent
    ) {
      setLocation({ parent: secionId, child: subSsectionId });

      if (subSsectionId === '') {
        setActiveId(`mainHeader-${secionId}`);
        setPreDisplayedContent(getContent(secionId, 'none', mainHeadersList));
        setReadOnly(getReadonly(secionId, 'none', mainHeadersList));
        setAdminComments(getSectionComments(secionId, 'none', mainHeadersList));
      } else {
        setActiveId(`subHeader-${secionId}_${subSsectionId}`);
        setPreDisplayedContent(getContent(secionId, subSsectionId, mainHeadersList));
        setReadOnly(getReadonly(secionId, subSsectionId, mainHeadersList));
        setAdminComments(getSectionComments(secionId, subSsectionId, mainHeadersList));
      }
    } else {
      setConfirmLoctionSwitch({ show: true, parent: secionId, child: subSsectionId });
    }
  };
  const locationSwitchAccept = () => {
    setHTMLContent('');
    locationSwitch();
  };
  const locationSwitch = () => {
    setLocation({ parent: confirmLoctionSwitch.parent, child: confirmLoctionSwitch.child });
    if (confirmLoctionSwitch.child === '') {
      setActiveId(`mainHeader-${confirmLoctionSwitch.parent}`);
      setPreDisplayedContent(getContent(confirmLoctionSwitch.parent, 'none', mainHeadersList));
      setReadOnly(getReadonly(confirmLoctionSwitch.parent, 'none', mainHeadersList));
      setAdminComments(getSectionComments(confirmLoctionSwitch.parent, 'none', mainHeadersList));
    } else {
      setActiveId(`subHeader-${confirmLoctionSwitch.parent}_${confirmLoctionSwitch.child}`);
      setPreDisplayedContent(getContent(confirmLoctionSwitch.parent, confirmLoctionSwitch.child, mainHeadersList));
      setReadOnly(getReadonly(confirmLoctionSwitch.parent, confirmLoctionSwitch.child, mainHeadersList));
      setAdminComments(getSectionComments(confirmLoctionSwitch.parent, confirmLoctionSwitch.child, mainHeadersList));
    }
  };

  useEffect(() => {
    setOldContent2(HTMLcontent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div id="sideMenu" className="side-menu">
      <ConfirmDialog
        visible={confirmLoctionSwitch.show}
        onHide={() => setConfirmLoctionSwitch({ ...confirmLoctionSwitch, show: false })}
        message={'يوجد عمل لم يتم حفظه هل تود المتابعه'}
        header="تاكيد"
        acceptLabel="نعم"
        rejectLabel="لا"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={locationSwitchAccept}
        className="modalBootbox"
      />
      <div className="side-menu-logos">
        <div className="reef-logo" onClick={() => history.push('/home')}>
          <Fragment>
            <div className="vara-title">
              <div className="vara-img">
                <img src={vara} alt="vara-img" />
              </div>
              <Marginer direction="vertical" margin="4rem" />
              <div>
                <img className="reef-img" src={reef} alt="reef-logo" />
              </div>
            </div>
          </Fragment>
        </div>
      </div>
      <ul>
        <div className="sideMenuHeader">
          <p className="headerContent">هيكل النموذج</p>
        </div>
        <div onClick={() => history.push('/model/book')} className="sidemenugoback">
          <div>
            <img src={rightarrow} width={20} height={20} alt="back" />
          </div>
          <h2>العوده الي اداره الكراسات</h2>
        </div>

        <div className="sideMenuContent">
          {mainHeadersList &&
            mainHeadersList
              .sort((a, b) => {
                return a.order - b.order;
              })
              .map((mainHeader: any, i: Key | null | undefined) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        activeId === `mainHeader-${mainHeader.id}` ? 'headersList activeHeader' : 'headersList'
                      }
                      key={i}
                      id={`mainHeader-${i}`}
                    >
                      <div
                        key={i}
                        className="main-header-title-container"
                        onClick={() => {
                          switchLocationHandler(mainHeader.id, '');
                        }}
                      >
                        <div key={i} className="main-headers">
                          <div className="mainBulletContainer" key={i}>
                            <svg className="mainBullet" key={i}></svg>
                          </div>
                          <p>{mainHeader.title}</p>
                        </div>
                      </div>
                      <img
                        src={arrowLeftTriangle}
                        alt="arrow-left-triangle"
                        className="icons"
                        style={{
                          display: activeId !== `mainHeader-${mainHeader.id}` ? 'none' : 'block',
                        }}
                      />
                    </div>
                    {mainHeader.subSections &&
                      mainHeader.subSections
                        .sort((a: any, b: any) => {
                          return a.order - b.order;
                        })
                        .map((subHeader: any, j: Key | null | undefined) => {
                          return (
                            <div
                              className={
                                activeId === `subHeader-${mainHeader.id}_${subHeader.id}`
                                  ? 'headersList activeHeader'
                                  : 'headersList'
                              }
                              key={j}
                              id={`subHeader-${i}_${j}`}
                            >
                              <div
                                key={j}
                                className="subHeaderContainer"
                                onClick={() => {
                                  switchLocationHandler(mainHeader.id, subHeader.id);
                                }}
                              >
                                <div key={j} className="sub-headers">
                                  <div className="subBulletContainer" key={j}>
                                    <svg className="subBullet" key={j}></svg>
                                  </div>
                                  <p>{subHeader.title}</p>
                                </div>
                              </div>
                              <img
                                src={arrowLeftTriangle}
                                alt="arrow-left-triangle"
                                className="icons"
                                style={{
                                  display: activeId !== `subHeader-${mainHeader.id}_${subHeader.id}` ? 'none' : 'block',
                                }}
                              />
                            </div>
                          );
                        })}
                    <hr className="horizontalMarginer" />
                  </div>
                );
              })}
        </div>
      </ul>
    </div>
  );
};

export default ModelStructureSideMenu;
