import React from 'react';
// import { Accordion, AccordionTab } from 'primereact/accordion';
// import report from '../../../assets/png/Report.png';
import './Reports.scss';
import '../../../styles/Tabs.scss';
import '../../../styles/Accordion.scss';
// import Segment from '../../Segment/Segment';

const Reports: React.FC = () => {
  // const [activeIndex, setActiveIndex] = useState<any>(null);

  // const [segmentButtons] = useState(['التقارير القياسية', 'التقارير الشخصية']);

  // const segmentHandler = (itemIndex: any) => {
  //   let _activeIndex = activeIndex ? [...activeIndex] : [];

  //   if (_activeIndex.length === 0) {
  //     _activeIndex.push(itemIndex);
  //   } else {
  //     const index = _activeIndex.indexOf(itemIndex);
  //     if (index === -1) {
  //       _activeIndex.push(itemIndex);
  //     } else {
  //       _activeIndex.splice(index, 1);
  //     }
  //   }

  //   setActiveIndex(_activeIndex);
  // };

  // return (
  //   <div className="plans">
  //     <div className="plans-data">
  //       <Segment buttons={segmentButtons} activeIndex={activeIndex} btnHandler={segmentHandler} />
  //     </div>
  //     <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
  //       <AccordionTab header="التقارير القياسية">
  //         <div className="reports">
  //           <div className="report">
  //             <img width="200px" src={report} alt="report" />
  //             <span>عنوان التقرير</span>
  //           </div>
  //           <div className="report">
  //             <img width="200px" src={report} alt="report" />
  //             <span>عنوان التقرير</span>
  //           </div>
  //         </div>
  //       </AccordionTab>
  //       <AccordionTab header="التقارير الشخصية">
  //         <div className="reports">
  //           <div className="report">
  //             <img width="200px" src={report} alt="report" />
  //             <span>عنوان التقرير</span>
  //           </div>
  //           <div className="report">
  //             <img width="200px" src={report} alt="report" />
  //             <span>عنوان التقرير</span>
  //           </div>
  //         </div>
  //       </AccordionTab>
  //     </Accordion>
  //   </div>
  // );
  return <div></div>;
};

export default Reports;
