import React from 'react';
import './Card.scss';

type Props = {
  cardHandler?: () => void;
  bgcolor: string;
};
const Card: React.FC<Props> = ({ children, cardHandler, bgcolor }) => {
  return (
    <div
      className="card-container"
      style={{ backgroundColor: bgcolor, cursor: bgcolor === 'grey' ? 'not-allowed' : 'pointer' }}
      onClick={cardHandler}
    >
      {children}
    </div>
  );
};

export default Card;
